// @ts-nocheck
import Logger from "@/utils/logger";
import { Mutex } from "async-mutex";

const _mutex = new Mutex();
const _dbName = "wnaDb";
const _storeName = "wnaDbStore";
const _indexName = "keyIndex";

declare var window: any;
window = global;

// This works on all devices/browsers, and uses IndexedDBShim as a final fallback
const indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;
const _dbVersion = 4;

export class WnaIdbProvider {
    public static async existsKeyAsync(key: string): Promise<boolean> {
        // return await _mutex.runExclusive(async () => {
        return new Promise((resolve, reject) => {
            const onSuccess = (event) => {
                try {
                    // Logger.start(WnaIdbProvider.name, WnaIdbProvider.existsKeyAsync.name, key);
                    // Start a new transaction
                    const db = event.target.result;
                    const transaction = db.transaction(_storeName, "readonly");
                    const store = transaction.objectStore(_storeName);
                    const request = store.openCursor(key);
                    request.onsuccess = function (e) {
                        const cursor = e.target.result;
                        if (cursor) {
                            // key already exist
                            resolve(true);
                        } else {
                            // key not exist
                            resolve(false);
                        }
                    };
                } catch (error) {
                    Logger.error(
                        WnaIdbProvider.name,
                        WnaIdbProvider.existsKeyAsync.name,
                        error
                    );
                    // Logger.end(WnaIdbProvider.name, WnaIdbProvider.existsKeyAsync.name, key);
                    reject();
                }
            };
            const onError = () => {
                Logger.error("checking keyExists failed for " + key);
                // Logger.end(WnaIdbProvider.name, WnaIdbProvider.existsKeyAsync.name, key);
                reject();
            };
            WnaIdbProvider.init(onSuccess, onError);
            // });
        });
    }

    public static async setItemAsync(key: string, item: string): Promise<void> {
        return await _mutex.runExclusive(async () => {
            return new Promise((resolve, reject) => {
                const onSuccess = (event) => {
                    try {
                        // Logger.start(WnaIdbProvider.name, WnaIdbProvider.setItemAsync.name, key);
                        // Start a new transaction
                        const db = event.target.result;
                        const transaction = db.transaction(
                            _storeName,
                            "readwrite"
                        );
                        const store = transaction.objectStore(_storeName);
                        // Add some data
                        store.put({ key: key, val: item });

                        // Close the db when the transaction is done
                        transaction.oncomplete = () => {
                            // Logger.info(
                            //     WnaIdbProvider.name,
                            //     WnaIdbProvider.setItemAsync.name,
                            //     "successfully cached: " + key
                            // );
                            // Logger.end(WnaIdbProvider.name, WnaIdbProvider.setItemAsync.name, key);
                            db.close();
                            resolve();
                        };

                        transaction.onerror = () => {
                            Logger.error(
                                WnaIdbProvider.name,
                                WnaIdbProvider.setItemAsync.name,
                                "failed to cache cached; " + key
                            );
                            db.close();
                            reject();
                        };

                        transaction.commit();
                    } catch (error) {
                        Logger.error(
                            WnaIdbProvider.name,
                            WnaIdbProvider.setItemAsync.name,
                            error
                        );
                        // Logger.end(WnaIdbProvider.name, WnaIdbProvider.setItemAsync.name, key);
                        reject();
                    }
                };
                const onError = () => {
                    Logger.error("caching failed for " + key);
                    // Logger.end(WnaIdbProvider.name, WnaIdbProvider.setItemAsync.name, key);
                    reject();
                };
                WnaIdbProvider.init(onSuccess, onError);
            });
        });
    }

    public static async getItemAsync(key: string): Promise<string> {
        return await _mutex.runExclusive(async () => {
            return new Promise((resolve, reject): string => {
                const onSuccess = (event) => {
                    try {
                        // Start a new transaction
                        const db = event.target.result;
                        const transaction = db.transaction(
                            _storeName,
                            "readonly"
                        );
                        const store = transaction.objectStore(_storeName);

                        // Query the data
                        const request = store.get(key);
                        request.onsuccess = () => {
                            // Logger.info("successfully loaded cached " + key);
                            //Logger.end(WnaIdbProvider.name, WnaIdbProvider.getItemAsync.name, key);
                            db.close();
                            // debugger;
                            if (
                                typeof request.result === "object" &&
                                request.result.val
                            )
                                resolve(request.result.val);
                            else resolve(request.result);
                        };
                        request.onerror = () => {
                            Logger.error("failed to load cached " + key);
                            // Logger.end(WnaIdbProvider.name, WnaIdbProvider.getItemAsync.name, key);
                            db.close();
                            reject();
                        };
                    } catch (error) {
                        Logger.error(
                            WnaIdbProvider.name,
                            WnaIdbProvider.getItemAsync.name,
                            error
                        );
                        reject();
                    }
                };
                const onError = () => {
                    Logger.error("failed to load cached " + key);
                    reject();
                };
                WnaIdbProvider.init(onSuccess, onError);
            });
        });
    }

    public static async clearAsync(prefix?: string | null): Promise<void> {
        let cachedItemKeysWithPrefix = [];
        if (prefix !== null && prefix !== "")
            cachedItemKeysWithPrefix =
                await WnaIdbProvider.getAllKeysWithPrefix(prefix);

        return new Promise((resolve, reject) => {
            const onSuccessClearAll = (event) => {
                try {
                    // Start a new transaction
                    const db = event.target.result;
                    const transaction = db.transaction(_storeName, "readwrite");
                    const store = transaction.objectStore(_storeName);

                    // Add some data
                    store.clear();
                    db.close();

                    // Close the db when the transaction is done
                    transaction.oncomplete = () => resolve();
                } catch (error) {
                    Logger.error(
                        WnaIdbProvider.name,
                        onSuccessClearAll.name,
                        error
                    );
                    reject();
                }
            };

            const onSuccessClearAllWithPrefix = (event) => {
                try {
                    // Start a new transaction
                    const db = event.target.result;
                    const transaction = db.transaction(_storeName, "readwrite");
                    const store = transaction.objectStore(_storeName);

                    for (const keyToDelete of cachedItemKeysWithPrefix) {
                        store.delete(keyToDelete);
                    }
                    db.close();

                    // Close the db when the transaction is done
                    transaction.oncomplete = () => resolve();
                } catch (error) {
                    Logger.error(
                        WnaIdbProvider.name,
                        onSuccessClearAllWithPrefix.name,
                        error
                    );
                    reject();
                }
            };

            const onError = () => {
                Logger.error("clearing failed!");
                reject();
            };

            if (prefix === null || prefix === "")
                WnaIdbProvider.init(onSuccessClearAll, onError);
            else {
                WnaIdbProvider.init(onSuccessClearAllWithPrefix, onError);
            }
        });
    }

    private static init(resolve: (event) => void, reject: (event) => void) {
        // Open (or create) the database
        const request = indexedDB.open(_dbName, _dbVersion);
        request.onsuccess = resolve;

        // Create the schema
        request.onupgradeneeded = (event) => {
            Logger.warn(
                WnaIdbProvider.name,
                WnaIdbProvider.init.name,
                "onupgradeneeded"
            );
            const db = event.target.result;

            if (
                event.oldVersion < _dbVersion &&
                db.objectStoreNames.contains(_storeName)
            )
                db.deleteObjectStore(_storeName);

            const objectStore = db.createObjectStore(_storeName, {
                keyPath: "key",
            });
            objectStore.createIndex(_indexName, "key", { unique: true });
            resolve(event);
        };

        request.onblocked = (event) => {
            Logger.error("onblocked - failed to open db");
            reject(event);
        };
        request.onerror = (event) => {
            Logger.error("onerror - failed to open db");
            reject(event);
        };
    }

    private static async getAllKeysWithPrefix(prefix: string) {
        return new Promise((resolve, reject): string => {
            const onSuccess = (event) => {
                try {
                    // Logger.start(WnaIdbProvider.name, WnaIdbProvider.getAllKeysWithPrefix.name, key);
                    // Start a new transaction
                    const db = event.target.result;
                    const transaction = db.transaction(_storeName, "readonly");
                    const store = transaction.objectStore(_storeName);

                    // Query the data
                    const myIndex = store.index(_indexName);
                    const request = myIndex.getAllKeys();
                    request.onsuccess = () => {
                        // Logger.info("successfully loaded cached " + key);
                        // Logger.end(WnaIdbProvider.name, WnaIdbProvider.getAllKeysWithPrefix.name, key);
                        db.close();
                        const ret = [];
                        for (const key of request.result) {
                            if (key.startsWith(prefix)) ret.push(key);
                        }

                        resolve(ret);
                    };
                    request.onerror = () => {
                        Logger.error(
                            "failed to get all keys with prefix: " + prefix
                        );
                        // Logger.end(WnaIdbProvider.name, WnaIdbProvider.getAllKeysWithPrefix.name, key);
                        db.close();
                        reject();
                    };
                } catch (error) {
                    Logger.error(
                        WnaIdbProvider.name,
                        WnaIdbProvider.getAllKeysWithPrefix.name,
                        error
                    );
                    // Logger.end(WnaIdbProvider.name, WnaIdbProvider.getItemAsync.name, key);
                    reject();
                }
            };
            const onError = () => {
                Logger.error("failed to getAllKeysWithPrefix " + prefix);
                // Logger.end(WnaIdbProvider.name, WnaIdbProvider.getAllKeysWithPrefix.name, key);
                reject();
            };
            WnaIdbProvider.init(onSuccess, onError);
        });
    }
}
