import ApiSettings from "@/api/apiSettings";
import WnaButtonText from "@components/buttons/WnaButtonText";
import WnaImage from "@components/images/WnaImage";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View } from "react-native";

export type WnaAppointmentListHeaderProps = {
    appColors: Colors;
    appStyle: AppStyle;
    navigateToAccommodationScreen: () => void;
    t: TFunction<string[], undefined>;
};

class WnaAppointmentListHeader extends Component<WnaAppointmentListHeaderProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaAppointmentListHeaderProps>
    ): boolean {
        return nextProps.appColors.isDark !== this.props.appColors.isDark;
    }

    render() {
        return (
            <View
                style={[
                    this.props.appStyle.containerCenterMaxWidth,
                    {
                        backgroundColor: this.props.appColors.white,
                        // padding: 16,
                        gap: 16,
                        // marginLeft: 16, // bug in flashlist
                        // marginBottom: 8
                    },
                ]}>
                <WnaImage
                    appColors={this.props.appColors}
                    imageUrl={`${ApiSettings.apiBaseUrl}/resources/ph_007.jpg`}
                    style={{
                        height: 240,
                        maxHeight: 240,
                        backgroundColor: this.props.appColors.coolgray2,
                    }}
                />

                <Text style={[this.props.appStyle.textTitleLarge]}>
                    {this.props.t(i18nKeys.screenSubtitleAppointmentList)}
                </Text>

                <WnaButtonText
                    appColors={this.props.appColors}
                    appStyle={this.props.appStyle}
                    text={this.props.t(
                        i18nKeys.screenTitleAccommodationDetails
                    )}
                    onPress={this.props.navigateToAccommodationScreen}
                    t={this.props.t}
                    checkInternetConnection={false}
                />
            </View>
        );
    }
}

export default WnaAppointmentListHeader;
