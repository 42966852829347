import deleteAppointmentAsync from "@/api/appointment/deleteAppointment";
import getAppointmentAsync from "@/api/appointment/getAppointment";
import WnaAppointment, {
    getAppointmentDisplayText,
} from "@/types/entities/wnaAppointment";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaAppointmentReadOnly from "@components/appointment/WnaAppointmentReadOnly";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useLocalSearchParams, useRouter } from "expo-router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Toast from "react-native-toast-message";

export default function AppointmentScreen() {
    const router = useRouter();
    const { appointmentIdentifier } = useLocalSearchParams<{
        appointmentIdentifier: string;
    }>();
    const { t } = useTranslation(["common"]);
    const {
        isAppInitialized,
        appSettings,
        appColors,
        appStyle,
        currentAdminUser,
    } = useContext(WnaAppContext);
    const [appointment, setAppointment] = useState<WnaAppointment>();
    const [isBusy, setIsBusy] = useState(true);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);

    useEffect(() => {
        if (isBusy) {
            getAppointmentAsync(appointmentIdentifier).then((appointment) => {
                if (!appointment) {
                    setRedirectToNotFound(true);
                    Toast.show({
                        text1: "not found",
                        type: "error",
                    });
                    return;
                }

                setAppointment(appointment!);
                setIsBusy(false);
            });
        }
        // eslint-disable-next-line
    }, [appointmentIdentifier]);

    const edit = () =>
        router.push(
            WnaNavigationRouteProvider.appointmentsIdentifierUpdate(
                appointmentIdentifier
            )
        );

    const deleteAppointment = () => {
        deleteAppointmentAsync(appointmentIdentifier).then(
            (resultAppointment) => {
                if (!resultAppointment) {
                    Toast.show({
                        text1: t(i18nKeys.errorDelete),
                        type: "error",
                    });
                } else {
                    Toast.show({
                        text1: t(i18nKeys.successDelete),
                        type: "success",
                    });
                    router.replace(WnaNavigationRouteProvider.appointments);
                }
            }
        );
    };

    if (!isAppInitialized || redirectToNotFound)
        return <Redirect href={WnaNavigationRouteProvider.appointments} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={getAppointmentDisplayText(appointment)}
            headerButton0={
                currentAdminUser ? (
                    <WnaButtonHeader
                        appColors={appColors}
                        appStyle={appStyle}
                        iconName={"trash-alt"}
                        text={t(i18nKeys.actionDelete)}
                        onPress={deleteAppointment}
                        checkInternetConnection={true}
                        t={t}
                    />
                ) : null
            }
            headerButton1={
                currentAdminUser ? (
                    <WnaButtonHeader
                        appColors={appColors}
                        appStyle={appStyle}
                        iconName={"pen"}
                        text={t(i18nKeys.actionEdit)}
                        onPress={edit}
                        checkInternetConnection={true}
                        t={t}
                    />
                ) : null
            }>
            <WnaAppointmentReadOnly
                appColors={appColors}
                appStyle={appStyle}
                appSettings={appSettings!}
                currentItem={appointment!}
                t={t}
            />
        </WnaBaseScreen>
    );
}
