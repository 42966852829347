import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync, {
    getCachedResponseAsync,
} from "@/api/fetchAndCacheGet";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";

const encrypt = true;
const useDb = true;

export default async function getDiaryEntriesAsync(
    userId: string | undefined,
    chapterIdentifier: string | undefined,
    getCached: boolean,
    orderAscending: boolean
) {
    if (!isUserId(userId) || !isDateTimeIdentifier(chapterIdentifier))
        return undefined;

    const route = `users/${userId}/chapters/${chapterIdentifier}/diaryentries`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;

    let diaryEntries: WnaDiaryEntry[] | undefined;
    if (getCached)
        diaryEntries =
            (await getCachedResponseAsync<WnaDiaryEntry[]>(
                route,
                encrypt,
                useDb
            )) ?? [];
    else
        diaryEntries =
            (await fetchAndCacheGetAsync<WnaDiaryEntry[]>(
                route,
                url,
                encrypt,
                useDb
            )) ?? [];

    if (diaryEntries && orderAscending) diaryEntries.reverse();

    return diaryEntries;
}
