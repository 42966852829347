import WnaGraphData, {
    getDefaultWnaGraphData,
} from "@/types/entities/wnaGraphData";
import WnaRoute, { getDefaultWnaRoute } from "@/types/entities/wnaRoute";

export default interface WnaRouteDetails {
    route: WnaRoute;
    graphData: WnaGraphData;
    localFileContent?: Blob;
}

export function getDefaultWnaRouteDetails() {
    return {
        route: getDefaultWnaRoute(),
        graphData: getDefaultWnaGraphData(),
        localFileContent: undefined,
    } as WnaRouteDetails;
}
