import getAppointmentAsync from "@/api/appointment/getAppointment";
import updateAppointmentAsync from "@/api/appointment/updateAppointment";
import WnaAppointment, {
    getAppointmentDisplayText,
} from "@/types/entities/wnaAppointment";
import { getUnixMinDate } from "@/utils/dateTimeHelper";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaAppointmentEdit from "@components/appointment/WnaAppointmentEdit";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useLocalSearchParams, useRouter } from "expo-router";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Toast from "react-native-toast-message";

export default function UpdateAppointmentScreen() {
    const {
        isAppInitialized,
        appColors,
        currentAdminUser,
        appStyle,
        appSettings,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const [isBusy, setIsBusy] = useState<boolean>(true);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);
    const { appointmentIdentifier } = useLocalSearchParams<{
        appointmentIdentifier: string;
    }>();

    const [appointment, setAppointment] = useState<WnaAppointment>({
        comment: "",
        designator: "",
        dateStart: getUnixMinDate(),
        dateEnd: getUnixMinDate(),
    } as WnaAppointment);

    const appointmentRef = useRef<WnaAppointment>(appointment);

    useEffect(() => {
        if (isBusy) {
            getAppointmentAsync(appointmentIdentifier).then((appointment) => {
                if (!appointment) {
                    setRedirectToNotFound(true);
                    Toast.show({
                        text1: "not found",
                        type: "error",
                    });
                    return;
                }

                setAppointment(appointment!);
                setIsBusy(false);
            });
        }
        // eslint-disable-next-line
    }, [appointmentIdentifier]);

    const save = () => {
        setIsBusy(true);
        updateAppointmentAsync(appointmentRef.current).then((appointment) => {
            setIsBusy(false);
            if (appointment === undefined) {
                Toast.show({
                    text1: t(i18nKeys.errorSave),
                    type: "error",
                });
            } else {
                Toast.show({
                    text1: t(i18nKeys.successSave),
                    type: "success",
                });
                router.replace(WnaNavigationRouteProvider.appointments);
            }
        });
    };

    if (!isAppInitialized || !currentAdminUser || redirectToNotFound)
        return <Redirect href={WnaNavigationRouteProvider.appointments} />;

    if (!appointment) return null;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={getAppointmentDisplayText(appointment)}
            headerButton0={
                currentAdminUser ? (
                    <WnaButtonHeader
                        appColors={appColors}
                        appStyle={appStyle}
                        iconName={"check"}
                        text={t(i18nKeys.actionSave)}
                        onPress={save}
                        checkInternetConnection={true}
                        t={t}
                    />
                ) : null
            }>
            <WnaAppointmentEdit
                appColors={appColors}
                appStyle={appStyle}
                appSettings={appSettings!}
                currentItem={appointment}
                onUpdate={(newAppointment) => {
                    appointmentRef.current = newAppointment;
                }}
                t={t}
            />
        </WnaBaseScreen>
    );
}
