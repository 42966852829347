import WnaListCardHeader from "@components/cards/WnaListCardHeader";
import WnaListCardImage from "@components/cards/WnaListCardImage";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import { View } from "react-native";

export type WnaListCardWhiteImageProps = {
    appColors: Colors;
    appStyle: AppStyle;
    children?: React.ReactNode;
    color?: string;
    headerTitle?: string;
    imageUrl?: string;
    tinyImageUrl?: string;
    hasRoute?: boolean;
    hasImages?: boolean;
    hasDownload?: boolean;
    forceRightTitle?: boolean;
};

const _borderRadius = 8;

class WnaListCardWhiteImage extends Component<WnaListCardWhiteImageProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaListCardWhiteImageProps>
    ): boolean {
        return (
            nextProps.children !== this.props.children ||
            nextProps.color !== this.props.color ||
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.hasImages !== this.props.hasImages ||
            nextProps.hasRoute !== this.props.hasRoute ||
            nextProps.headerTitle !== this.props.headerTitle ||
            nextProps.imageUrl !== this.props.imageUrl ||
            nextProps.tinyImageUrl !== this.props.tinyImageUrl
        );
    }

    render() {
        return (
            <LinearGradient
                colors={[
                    this.props.color &&
                    this.props.color !== "" &&
                    this.props.color !== "transparent"
                        ? this.props.color
                        : this.props.appColors.coolgray2,
                    this.props.appColors.coolgray2,
                    this.props.appColors.coolgray2,
                    this.props.color &&
                    this.props.color !== "" &&
                    this.props.color !== "transparent"
                        ? this.props.color
                        : this.props.appColors.coolgray2,
                ]}
                start={[0, 0]}
                end={[1, 1]}
                locations={[0, 0.3, 0.7, 1]}
                pointerEvents="none"
                style={[{ padding: 1, borderRadius: _borderRadius }]}>
                <View
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor: this.props.appColors.white,
                        borderRadius: _borderRadius,
                        overflow: "hidden",
                    }}>
                    <View
                        style={{
                            width: "100%",
                            backgroundColor: this.props.appColors.white,
                        }}>
                        <View>
                            <WnaListCardHeader
                                appColors={this.props.appColors}
                                appStyle={this.props.appStyle}
                                headerTitle={this.props.headerTitle}
                                forceRightTitle={this.props.forceRightTitle}
                                hasImages={this.props.hasImages}
                                hasRoute={this.props.hasRoute}
                                hasDownload={this.props.hasDownload}
                            />

                            <WnaListCardImage
                                appColors={this.props.appColors}
                                appStyle={this.props.appStyle}
                                imageUrl={this.props.imageUrl}
                                tinyImageUrl={this.props.tinyImageUrl}
                            />
                        </View>
                        {!this.props.children ? null : (
                            <View
                                style={{
                                    padding: 24,
                                    backgroundColor: this.props.appColors.white,
                                }}>
                                {this.props.children}
                            </View>
                        )}
                    </View>
                </View>
            </LinearGradient>
        );
    }
}

export default WnaListCardWhiteImage;
