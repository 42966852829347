import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import WnaDiaryEntry, {
    getDiaryEntryDateStartTitle,
    getMapImageUrl,
} from "@/types/entities/wnaDiaryEntry";
import { getColorByWeekday } from "@/utils/dateTimeHelper";
import { stripHtml } from "@/utils/htmlSanitizer";
import { cleanAndTruncate } from "@/utils/stringHelper";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaListCardWhiteImage from "@components/cards/WnaListCardWhiteImage";
import WnaText from "@components/text/WnaText";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { Text, View } from "react-native";

export type WnaDiaryEntryListItemProps = {
    diaryEntry: WnaDiaryEntry;
    diaryEntries: WnaDiaryEntry[];
    appColors: Colors;
    appStyle: AppStyle;
    userSettings?: WnaUserSettings;
    onPress: (currentItem: WnaDiaryEntry) => void;
    t: TFunction<string[], undefined>;
};

class WnaDiaryEntryListItem extends Component<WnaDiaryEntryListItemProps> {
    shouldComponentUpdate(nextProps: WnaDiaryEntryListItemProps) {
        return (
            nextProps.userSettings !== this.props.userSettings ||
            nextProps.diaryEntries !== this.props.diaryEntries ||
            nextProps.diaryEntry.dateCreate !==
                this.props.diaryEntry.dateCreate ||
            nextProps.diaryEntry.dateUpdate !==
                this.props.diaryEntry.dateUpdate ||
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.diaryEntry.imageUrl !== this.props.diaryEntry.imageUrl ||
            nextProps.diaryEntry.routeStatic256Url !==
                this.props.diaryEntry.routeStatic256Url ||
            nextProps.diaryEntry.routeStatic512Url !==
                this.props.diaryEntry.routeStatic512Url ||
            nextProps.diaryEntry.designator !==
                this.props.diaryEntry.designator ||
            cleanAndTruncate(stripHtml(nextProps.diaryEntry.comment)) !==
                cleanAndTruncate(stripHtml(this.props.diaryEntry.comment))
        );
    }

    render() {
        return (
            <WnaPressable
                disableHover
                style={[
                    this.props.appStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                        backgroundColor: this.props.appColors.white,
                    },
                ]}
                onPress={() => this.props.onPress(this.props.diaryEntry)}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteImage
                    appStyle={this.props.appStyle}
                    appColors={this.props.appColors}
                    forceRightTitle={true}
                    headerTitle={getDiaryEntryDateStartTitle(
                        this.props.diaryEntry,
                        this.props.diaryEntries
                    )}
                    color={getColorByWeekday(this.props.diaryEntry.dateStart)}
                    hasRoute={
                        (this.props.diaryEntry.routeIdentifier ?? "") !== ""
                    }
                    hasImages={this.props.diaryEntry.images.length > 0}
                    imageUrl={this.props.diaryEntry.imageUrl}
                    tinyImageUrl={getMapImageUrl(
                        this.props.diaryEntry.routeStatic512Url,
                        this.props.userSettings
                    )}>
                    {this.props.diaryEntry.designator !== "" &&
                    this.props.diaryEntry.designator.trim() !== "" ? (
                        <Text
                            style={[
                                this.props.appStyle.textTitleLarge,
                                { marginBottom: 8 },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {this.props.diaryEntry.designator}
                        </Text>
                    ) : null}
                    {cleanAndTruncate(
                        stripHtml(this.props.diaryEntry.comment)
                    ).trim().length > 3 ? (
                        <View style={{ maxHeight: 256, overflow: "hidden" }}>
                            <WnaText
                                appColors={this.props.appColors}
                                appStyle={this.props.appStyle}
                                style={[this.props.appStyle.textSmall]}
                                fontFamily={FontFamilies.Courgette}
                                textBreakStrategy={"highQuality"}
                                numberOfLines={8}
                                ellipseMode={"tail"}
                                maxHeight={256}
                                text={this.props.diaryEntry.comment}
                            />
                        </View>
                    ) : null}
                </WnaListCardWhiteImage>
            </WnaPressable>
        );
    }
}

export default WnaDiaryEntryListItem;
