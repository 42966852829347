import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import WnaRoute from "@/types/entities/wnaRoute";

export default async function getRoutesAsync() {
    const route = `routes`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return (
        (await fetchAndCacheGetAsync<WnaRoute[]>(route, url, true, true)) ??
        new Array<WnaRoute>()
    );
}
