export default class WnaPage {
    readonly index: number = 0;
    readonly length: number = 0;

    constructor(index: number, length: number) {
        this.index = index;
        this.length = length;
    }

    isInitialized = () => this.length > 0;

    isPrevVisible = () => this.index > 0;

    isNextVisible = () => this.isInitialized() && this.index < this.length - 1;

    nextIndex = () =>
        this.isInitialized() && this.index < this.length
            ? this.index + 1
            : this.index;

    prevIndex = () =>
        this.isInitialized() && this.index > 1 ? this.index - 1 : 0;
}
