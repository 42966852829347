import fetchWithTimeout from "@/api/fetchWithTimeoutProvider";

export default async function handleResponseAsync(
    url: string,
    request: RequestInit,
    timeout: number
) {
    try {
        const response = await fetchWithTimeout(url, request, {
            timeout: timeout,
            abortHandler: (err) => {
                throw err;
            },
        });

        switch (response.status) {
            case 200:
            case 201:
            case 202:
            case 203:
            case 204:
            case 205:
            case 206:
                return response;
            default:
                const responseError = {
                    type: "Error",
                    message:
                        response.status ??
                        "" + " - " + response.statusText ??
                        "Something went wrong",
                    code: response.status ?? "",
                };

                const error = new Error();
                error.cause = responseError;
                return error;
        }
    } catch {
        const responseError = {
            type: "Error",
            message: "Something went wrong",
            code: "999",
        };

        const error = new Error();
        error.cause = responseError;
        return error;
    }
}
