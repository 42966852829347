import ApiSettings from "@/api/apiSettings";
import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import WnaFileInfo from "@/types/entities/wnaFileInfo";
import WnaDiaryEntryListItem from "@components/diaryEntry/WnaDiaryEntryListItem";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { TFunction } from "i18next";
import { Component } from "react";
import { ScrollView } from "react-native-gesture-handler";

export type WnaDiaryEntryListEmptyProps = {
    appColors: Colors;
    appStyle: AppStyle;
    onPress: () => void;
    t: TFunction<string[], undefined>;
};

const _today = new Date();

const _dummy0 = {
    imageUrl: `${ApiSettings.apiBaseUrl}/resources/ph_003.jpg`,
    dateStart: _today,
    dateCreate: _today,
    dateUpdate: _today,
    designator: "Erstelle einen neuen Eintrag",
    comment: "",
    images: new Array<WnaFileInfo>(),
    routeIdentifier: "",
} as WnaDiaryEntry;

class WnaDiaryEntryListEmpty extends Component<WnaDiaryEntryListEmptyProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDiaryEntryListEmptyProps>
    ): boolean {
        return nextProps.appColors.isDark !== this.props.appColors.isDark;
    }

    render() {
        return (
            <ScrollView
                style={[
                    this.props.appStyle.containerFillPage,
                    {
                        padding: 16,
                    },
                ]}>
                <WnaDiaryEntryListItem
                    appStyle={this.props.appStyle}
                    appColors={this.props.appColors}
                    key={"dummy0"}
                    diaryEntry={_dummy0}
                    diaryEntries={[_dummy0]}
                    onPress={this.props.onPress}
                    t={this.props.t}
                />
            </ScrollView>
        );
    }
}

export default WnaDiaryEntryListEmpty;
