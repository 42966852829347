import { CurrentColors } from "@constants/currentColors";
import { View } from "react-native";

export default function WnaSeparatorHorizontal() {
    return (
        <View
            style={[
                {
                    minHeight: 1,
                    margin: 16,
                    minWidth: 128,
                    width: "90%",
                    backgroundColor: CurrentColors["light"].staticCoolgray4,
                },
            ]}
        />
    );
}
