import Logger from "wna-logger";

/**
 * Abort handler.
 * Prevent error thrown when `fetch` aborted.
 *
 * @param err Error
 * @returns Empty JSON response (`{}`)
 */
export const AbortHandler = (err: Error) => {
    if (err?.name === "AbortError") {
        Logger.error("AbortError", err);
        return new Response("{}");
    }

    throw err;
};

/**
 * Options of `fetchWithTimeout`.
 */
export interface FetchWithTimeoutOptions {
    /**
     * Timeout (ms) (`false` for disabling timeout. Use plain `fetch`.)
     *
     * @default false
     */
    timeout?: number | false;
    /**
     * Abort controller
     *
     * @default new AbortController()
     */
    abortController?: AbortController;
    /**
     * Handler when `fetch` aborted.
     *
     * @default AbortHandler
     */
    abortHandler?: typeof AbortHandler;
}

export default async function fetchWithTimeout(
    input: RequestInfo,
    init?: RequestInit,
    options: FetchWithTimeoutOptions = {
        timeout: false,
        abortController: new AbortController(),
        abortHandler: AbortHandler,
    }
): Promise<Response> {
    const {
        timeout = false,
        abortController = new AbortController(),
        abortHandler = AbortHandler,
    } = options;

    init = {
        ...init,
        signal: abortController.signal,
    };

    if (!timeout) {
        return fetch(input, init).catch(abortHandler);
    }

    return Promise.race([
        fetch(input, init).catch(abortHandler),
        new Promise<Response>((_, __) => {
            setTimeout(() => abortController.abort(), timeout);
        }),
    ]);
}
