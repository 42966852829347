import getDiaryEntryAsync from "@/api/diaryEntry/getDiaryEntry";
import WnaFileInfo from "@/types/entities/wnaFileInfo";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaImageViewer from "@components/images/WnaImageViewer/WnaImageViewer";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import { Redirect, Stack, useLocalSearchParams, useRouter } from "expo-router";
import { useContext, useEffect, useState } from "react";
import Toast from "react-native-toast-message";

export default function DiaryEntryImagesScreen() {
    const router = useRouter();
    const { chapterIdentifier, diaryEntryIdentifier, tab, startIndex } =
        useLocalSearchParams<{
            chapterIdentifier: string;
            diaryEntryIdentifier: string;
            startIndex: string;
            tab: string;
        }>();
    let startIndexNumber = 0;
    let tabNumber = 0;
    try {
        startIndexNumber = parseInt(startIndex);
        tabNumber = parseInt(tab);
    } catch {
        // ignored
    }
    const { isAppInitialized, currentUser, currentUserSettings } =
        useContext(WnaAppContext);
    const { props } = useLocalSearchParams<{ props: string }>();
    const [isBusy, setIsBusy] = useState<boolean>(true);

    const [images, setImages] = useState<WnaFileInfo[]>([]);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);
    const onClose = () =>
        router.canGoBack()
            ? router.back()
            : router.replace(
                  WnaNavigationRouteProvider.diaryEntry(
                      chapterIdentifier,
                      diaryEntryIdentifier,
                      false,
                      tabNumber
                  )
              );

    const loadData = async () => {
        const diaryEntry = await getDiaryEntryAsync(
            currentUser?.id ?? "",
            chapterIdentifier,
            diaryEntryIdentifier,
            false
        );
        if (!diaryEntry) {
            setRedirectToNotFound(true);
            Toast.show({
                text1: "not found",
                type: "error",
            });
            return;
        }

        setImages(diaryEntry.images);
        setIsBusy(false);
    };

    useEffect(() => {
        if (isBusy) loadData();
        // eslint-disable-next-line
    }, [props]);

    if (!isAppInitialized || redirectToNotFound)
        return <Redirect href={WnaNavigationRouteProvider.chapters} />;

    return (
        <>
            <Stack.Screen
                options={{
                    headerShown: false,
                    // statusBarHidden: true,
                }}
            />
            <WnaImageViewer
                startIndex={startIndexNumber}
                chapterIdentifier={chapterIdentifier}
                diaryEntryIdentifier={diaryEntryIdentifier}
                tab={tabNumber}
                order={currentUserSettings?.diaryOrderBy ?? 0}
                images={images}
                onClose={onClose}
            />
        </>
    );
}
