import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import Logger from "@/utils/logger";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";

const key = "userSettings";
const encrypt = false;
const userDb = false;

export async function getUserSettingsAsync(): Promise<WnaUserSettings> {
    try {
        const objJson = await WnaDataStorageProvider.getItemAsync(
            key,
            encrypt,
            userDb
        );
        return JSON.parse(objJson) as WnaUserSettings;
    } catch {
        Logger.warn(getUserSettingsAsync.name, "Failed to get user settings");
        const userSettings = new WnaUserSettings();
        await setUserSettingsAsync(userSettings);
        return userSettings;
    }
}

export async function setUserSettingsAsync(userSettings: WnaUserSettings) {
    try {
        const objJson = JSON.stringify(userSettings);
        await WnaDataStorageProvider.setItemAsync(
            key,
            objJson,
            encrypt,
            userDb
        );
    } catch (e) {
        Logger.error(setUserSettingsAsync.name, e);
    }
}
