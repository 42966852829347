import getCountUnreadNotifications from "@/api/notification/getCountUnreadNotifications";
import { isUserId } from "@/api/validators/userIdValidator";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIconText from "@components/buttons/WnaButtonIconText";
import WnaSeparatorHorizontal from "@components/misc/WnaSeparatorHorizontal";
import { WnaShadowStyle } from "@components/misc/WnaShadowStyle";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { FontAwesome5 } from "@expo/vector-icons";
import { signInWithGoogleAsync } from "@services/auth/google/wnaGoogleSignIn";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useRouter } from "expo-router";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import Toast from "react-native-toast-message";

export default function LogInScreen() {
    const { t } = useTranslation(["common"]);
    const loginTimeOut = 30000; // 30 sec
    const router = useRouter();
    const {
        isAppInitialized,
        currentUser,
        setCurrentUserAsync,
        setUnreadNotificationCount,
        appStyle,
        appColors,
    } = useContext(WnaAppContext);

    const onCancel = () => {
        if (currentUser && isUserId(currentUser.id)) return;
        WnaNavigationRouteProvider.navigateToRoot(router);
    };

    const onLogInWithGoogleAsync = async () => {
        const user = await signInWithGoogleAsync();
        if (user) {
            const unreadMessages = await getCountUnreadNotifications(user.id);
            setUnreadNotificationCount(unreadMessages);
            await setCurrentUserAsync(user);
        }
        setIsBusy(false);
        if (!user || !isUserId(user.id)) {
            Toast.show({
                text1: t(i18nKeys.errorUnknown),
                type: "error",
            });
        } else {
            WnaNavigationRouteProvider.navigateToRoot(router);
            Toast.show({
                text1: t(i18nKeys.infoUserLoggedIn),
                type: "success",
            });
        }
        setTimeout(() => {
            onCancel();
        }, loginTimeOut);
    };

    const onLogInWithFacebookAsync = async () => {
        Toast.show({
            text1: "not implemented",
            type: "info",
        });
        setIsBusy(false);
        setTimeout(() => {
            onCancel();
        }, loginTimeOut);
        return;
    };

    useEffect(() => {
        if (currentUser?.id) {
            router.navigate(WnaNavigationRouteProvider.menuProfile);
            return;
        }
        // eslint-disable-next-line
    }, []);

    const [isBusy, setIsBusy] = useState(false);

    if (!isAppInitialized)
        return <Redirect href={WnaNavigationRouteProvider.menu} />;

    if (currentUser)
        return <Redirect href={WnaNavigationRouteProvider.menuProfile} />;

    return (
        <WnaBaseScreen isBusy={isBusy} title={t(i18nKeys.actionLogIn)}>
            <View style={appStyle.containerCenterCenter}>
                <View
                    style={[
                        WnaShadowStyle(),
                        appStyle.containerCenter,
                        {
                            backgroundColor: appColors.white,
                            borderRadius: 16,
                            paddingVertical: 44,
                            paddingHorizontal: 24,
                            maxWidth: 386,
                        },
                    ]}>
                    <View
                        style={[
                            appStyle.containerCenterCenter,
                            {
                                width: 96,
                                height: 96,
                                maxHeight: 96,
                                backgroundColor: appColors.staticAccent4,
                                borderRadius: 48,
                                overflow: "hidden",
                                padding: 22,
                            },
                        ]}>
                        <FontAwesome5
                            name={"user"}
                            size={48}
                            color={appColors.staticWhite}
                        />
                    </View>
                    <Text
                        style={[
                            appStyle.textTitleLarge,
                            {
                                marginTop: 16,
                                color: appColors.coolgray6,
                            },
                        ]}>
                        {t(i18nKeys.actionLogIn)}
                    </Text>
                    <WnaSeparatorHorizontal />
                    <View style={{ gap: 16, minWidth: 256 }}>
                        <WnaButtonIconText
                            appColors={appColors}
                            iconName="google"
                            backgroundColor="#ffffff"
                            textColor={appColors.staticCoolgray6}
                            borderColor={appColors.staticCoolgray6}
                            borderWidth={1}
                            text={t(i18nKeys.actionLogInWithGoogle)}
                            onPress={() => {
                                setIsBusy(true);
                                onLogInWithGoogleAsync();
                                setTimeout(() => setIsBusy(false), 10000);
                            }}
                            t={t}
                            checkInternetConnection={true}
                        />
                        <WnaButtonIconText
                            appColors={appColors}
                            iconName="facebook"
                            backgroundColor="#3A4F83"
                            text={t(i18nKeys.actionLogInWithFacebook)}
                            onPress={() => {
                                setIsBusy(true);
                                onLogInWithFacebookAsync();
                                setTimeout(() => setIsBusy(false), 10000);
                            }}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>
                    <View
                        pointerEvents="none"
                        style={[
                            appStyle.containerCenter,
                            {
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                padding: 8,
                            },
                        ]}></View>
                </View>
            </View>
        </WnaBaseScreen>
    );
}
