export default interface WnaFileInfo {
    isLocal: boolean;
    isDeleted: boolean;
    isFavourite: boolean;
    url: string;
    urlTh256: string;
    urlTh512: string;
    fileName: string;
    fileName256: string;
    fileName512: string;
    width: number;
    Width256: number;
    Width512: number;
    height: number;
    height256: number;
    height512: number;
    isDirty: boolean;
}

export function sanitizeFileInfos(source: WnaFileInfo[]) {
    const ret = new Array<WnaFileInfo>();
    if (source)
        for (const sourceFi of source) ret.push(sanitizeFileInfo(sourceFi));
    return ret;
}

export function sanitizeFileInfo(source: WnaFileInfo) {
    return {
        isLocal: source.isLocal,
        isDeleted: source.isDeleted,
        isFavourite: source.isFavourite,
        url: "",
        urlTh256: "",
        urlTh512: "",
        fileName: source.fileName,
        fileName256: "",
        fileName512: "",
        width: source.width,
        Width256: source.Width256,
        Width512: source.Width512,
        height: source.height,
        height256: source.height256,
        height512: source.height512,
    } as WnaFileInfo;
}

export function getDefaultFileInfo() {
    return {
        isLocal: false,
        isDeleted: false,
        isFavourite: false,
        url: "",
        urlTh256: "",
        urlTh512: "",
        fileName: "",
        fileName256: "",
        fileName512: "",
        width: 0,
        Width256: 0,
        Width512: 0,
        height: 0,
        height256: 0,
        height512: 0,
    } as WnaFileInfo;
}
