import { healthcheckAsync } from "@/api/apiHealthcheck";
import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";

export async function getCachedResponseAsync<T>(
    route: string,
    decrypt: boolean,
    useDb: boolean
) {
    try {
        const objJson = await WnaDataStorageProvider.getItemAsync(
            route,
            decrypt,
            useDb
        );
        return objJson ? (JSON.parse(objJson) as T) : undefined;
    } catch (e) {
        Logger.error(getCachedResponseAsync.name, e);
        return undefined;
    }
}

export default async function fetchAndCacheGetAsync<T>(
    route: string,
    url: string,
    encrypt: boolean,
    useDb: boolean,
    timeout: number = apiTimeouts.long
) {
    try {
        if (!(await healthcheckAsync()))
            return await getCachedResponseAsync<T>(route, encrypt, useDb);

        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const result = await handleResponseAsync(url, request, timeout);
        if (result instanceof Response) {
            // cache it
            const obj = (await result.json()) as T;
            const objJson = JSON.stringify(obj);
            await WnaDataStorageProvider.setItemAsync(
                route,
                objJson,
                encrypt,
                useDb
            );
            return obj;
        } else {
            // get cached on error
            return await getCachedResponseAsync<T>(route, encrypt, useDb);
        }
    } catch (e) {
        Logger.error(fetchAndCacheGetAsync.name, e);
        return undefined;
    }
}
