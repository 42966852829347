import WnaAppointment from "@/types/entities/wnaAppointment";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import { addDays } from "@/utils/dateTimeHelper";
import WnaDatePicker from "@components/misc/WnaDatePicker";
import WnaRichTextEditor from "@components/text/WnaRichTextEditor/WnaRichTextEditor";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { TFunction } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

export type AppointmentEditProps = {
    appColors: Colors;
    appStyle: AppStyle;
    appSettings: WnaAppSettings;
    currentItem: WnaAppointment;
    onUpdate: (item: WnaAppointment) => void;
    t: TFunction<string[], undefined>;
};

const WnaAppointmentEdit: React.FC<AppointmentEditProps> = (props) => {
    const currentItemRef = useRef(props.currentItem);

    const [currentDateStart, setCurrentDateStart] = useState<Date>(new Date());
    const currentDateStartRef = useRef(currentDateStart);

    const [currentDateEnd, setCurrentDateEnd] = useState<Date>(new Date());
    const currentDateEndRef = useRef(currentDateEnd);

    const [currentComment, setCurrentComment] = useState<string>("");
    const currentCommentRef = React.useRef(currentComment);

    const isBusyRef = useRef(false);
    const { t } = useTranslation(["common"]);

    const onUpdate = () => {
        const updatedItem = {
            identifier: currentItemRef.current.identifier,
            dateStart: currentDateStartRef.current,
            dateEnd: currentDateEndRef.current,
            comment: currentCommentRef.current,
        } as WnaAppointment;
        props.onUpdate(updatedItem);
    };

    const onStartDateSelect = (dt?: Date) => {
        if (!dt) return;
        if (isBusyRef.current) return;
        currentDateStartRef.current = dt;
        setCurrentDateStart(dt);

        const start = dt;
        const end = currentDateEndRef.current;

        if (end < start) {
            isBusyRef.current = true;
            currentDateEndRef.current = start;
            setCurrentDateEnd(start);
            isBusyRef.current = false;
        }

        onUpdate();
    };

    const onEndDateSelect = (dt?: Date) => {
        if (!dt) return;
        if (isBusyRef.current) return;
        currentDateEndRef.current = dt;
        setCurrentDateEnd(dt);

        const end = dt;
        const start = currentDateStartRef.current;

        if (start > end) {
            isBusyRef.current = true;
            currentDateStartRef.current = end;
            setCurrentDateStart(end);
            isBusyRef.current = false;
        }

        onUpdate();
    };

    const onCommentUpdate = (text: string) => {
        currentCommentRef.current = text;
        onUpdate();
    };

    useEffect(() => {
        currentItemRef.current = props.currentItem;

        setCurrentDateStart(new Date(props.currentItem.dateStart));
        currentDateStartRef.current = new Date(props.currentItem.dateStart);

        setCurrentDateEnd(new Date(props.currentItem.dateEnd));
        currentDateEndRef.current = new Date(props.currentItem.dateEnd);

        setCurrentComment(props.currentItem.comment);
        currentCommentRef.current = props.currentItem.comment;
    }, [props]);

    return (
        <View style={props.appStyle.containerFillPage}>
            <View
                style={{
                    flex: 1,
                    margin: 16,
                    alignContent: "stretch",
                    gap: 16,
                }}>
                <View
                    style={{
                        gap: 16,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        alignContent: "center",
                        justifyContent: "flex-start",
                        alignSelf: "flex-start",
                    }}>
                    <View style={{ gap: 8 }}>
                        <Text style={props.appStyle.textSmall}>
                            {t(i18nKeys.wordBegin)}
                        </Text>
                        <WnaDatePicker
                            minDate={addDays(new Date(), -732)}
                            maxDate={addDays(new Date(), 732)}
                            selectedDate={new Date(currentDateStart)}
                            onSelect={onStartDateSelect}
                        />
                    </View>

                    <View style={{ gap: 8 }}>
                        <Text style={props.appStyle.textSmall}>
                            {t(i18nKeys.wordEnd)}
                        </Text>
                        <WnaDatePicker
                            minDate={addDays(new Date(), -732)}
                            maxDate={addDays(new Date(), 732)}
                            selectedDate={currentDateEnd}
                            onSelect={onEndDateSelect}
                        />
                    </View>
                </View>

                <View style={{ flex: 1, alignSelf: "stretch" }}>
                    <WnaRichTextEditor
                        appStyle={props.appStyle}
                        appColors={props.appColors}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 64,
                            width: "auto",
                            verticalAlign: "top",
                        }}
                        text={currentComment}
                        onUpdate={(text) => {
                            // console.log(text);
                            onCommentUpdate(text);
                        }}
                        placeHolderText={t(
                            i18nKeys.placeholderAppointmentDetails
                        )}
                        onFocus={() => {
                            // handleCommentFocus(true)
                        }}
                        onBlur={() => {
                            // handleCommentFocus(false)
                        }}
                    />
                </View>
            </View>
        </View>
    );
};

export default WnaAppointmentEdit;
