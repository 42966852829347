import getChapterAsync from "@/api/chapter/getChapter";
import getChaptersAsync from "@/api/chapter/getChapters";
import getDiaryEntriesAsync from "@/api/diaryEntry/getDiaryEntries";
import getDiaryEntryAsync from "@/api/diaryEntry/getDiaryEntry";
import downloadFileAsync from "@/api/fileDownload";
import getRouteDetailsAsync from "@/api/route/getRouteDetails";
import getAllUsersAsync from "@/api/user/getAllUsers";
import WnaUser, { getDefaultWnaUser } from "@/types/entities/wnaUser";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIconText from "@components/buttons/WnaButtonIconText";
import WnaButtonText from "@components/buttons/WnaButtonText";
import DialogProvider from "@components/dialog/dialogProvider";
import WnaImage from "@components/images/WnaImage";
import WnaPicker, { WnaDataSourceItem } from "@components/misc/WnaPicker";
import WnaSeparatorHorizontal from "@components/misc/WnaSeparatorHorizontal";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { signOutWithGoogleAsync } from "@services/auth/google/wnaGoogleSignIn";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useRouter } from "expo-router";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, View } from "react-native";
import Toast from "react-native-toast-message";

export default function ProfileScreen() {
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const {
        isAppInitialized,
        appColors,
        appStyle,
        currentUser,
        currentAdminUser,
        currentUserSettings,
        setCurrentUserAsync,
    } = useContext(WnaAppContext);

    const onLogout = () => {
        signOutWithGoogleAsync().then(async () => {
            await setCurrentUserAsync(getDefaultWnaUser());
            WnaNavigationRouteProvider.navigateToRoot(router);
            Toast.show({
                text1: t(i18nKeys.infoUserLoggedOut),
                type: "success",
            });
        });
    };

    const usersDsRawRef = useRef<WnaUser[]>([]);
    const [usersDs, setUsersDs] = useState<WnaDataSourceItem[]>([]);
    const [selectedUserDsVal, setSelectedUserDsVal] = useState<any>();
    const [isDsLoaded, setIsDsLoaded] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [isBusyText, setIsBusyText] = useState("");
    const cancelRef = useRef<boolean>(false);

    const setSelectedUserAsync = async (selectedUser: WnaUser) => {
        if (selectedUser.id === currentUser?.id) return;

        await setCurrentUserAsync(selectedUser);
        Toast.show({
            text1: selectedUser.email,
            type: "success",
        });
        WnaNavigationRouteProvider.navigateToRoot(router);
    };

    const onCancel = () => {
        cancelRef.current = true;
    };

    const buildCacheAsync = async () => {
        try {
            if (isBusy) return;

            const currentUserId = currentUser?.id ?? "";
            if (currentUserId === "") return;

            const withImages =
                await DialogProvider.showYesNoAsync("Cache HQ Images?");

            setIsBusy(true);
            const chapters = await getChaptersAsync(currentUserId);
            let chapterProgress = 0;
            for (const chapter of chapters) {
                if (cancelRef.current) return;

                chapterProgress++;
                setIsBusyText(
                    `${t(i18nKeys.screenTitleDayList)}: ${chapterProgress} / ${chapters.length}\n \n `
                );

                // chapter
                const chapterDl = await getChapterAsync(
                    currentUserId,
                    chapter.identifier
                );

                if (!chapterDl) continue;

                await downloadFileAsync(chapterDl.imageUrl);
                const diaryEntries = await getDiaryEntriesAsync(
                    currentUserId,
                    chapterDl.identifier,
                    false,
                    currentUserSettings?.diaryOrderBy === 1
                );

                if (!diaryEntries) continue;

                let diaryProgress = 0;
                for (const diaryEntry of diaryEntries) {
                    if (cancelRef.current) return;

                    diaryProgress++;
                    setIsBusyText(
                        `${t(i18nKeys.screenTitleDayList)}: ${chapterProgress} / ${chapters.length}\n${t(i18nKeys.screenTitleDayDetails)}: ${diaryProgress} / ${diaryEntries.length}\n `
                    );
                    const diaryEntryDl = await getDiaryEntryAsync(
                        currentUserId,
                        chapterDl.identifier,
                        diaryEntry.identifier,
                        false
                    );

                    if (!diaryEntryDl) continue;

                    // route
                    if (diaryEntryDl.routeIdentifier !== "") {
                        const route = await getRouteDetailsAsync(
                            diaryEntryDl.routeIdentifier
                        );

                        // route images
                        await downloadFileAsync(
                            route.route.staticTerrain512Url
                        );
                        await downloadFileAsync(
                            route.route.staticTerrain256Url
                        );
                    }

                    // images
                    await downloadFileAsync(diaryEntryDl.imageUrl);

                    let imageProgress = 0;
                    for (const fi of diaryEntryDl.images) {
                        if (cancelRef.current) return;

                        imageProgress++;
                        setIsBusyText(
                            `${t(i18nKeys.screenTitleDayList)}: ${chapterProgress} / ${chapters.length}\n${t(i18nKeys.screenTitleDayDetails)}: ${diaryProgress} / ${diaryEntries.length}\nImages: ${imageProgress} / ${diaryEntryDl.images.length}\n`
                        );
                        if (withImages) await downloadFileAsync(fi.url);

                        await downloadFileAsync(fi.urlTh512);
                        await downloadFileAsync(fi.urlTh256);
                    }
                }
            }

            Toast.show({
                text1: t(i18nKeys.successDownload),
                type: "success",
            });
        } catch {
            Toast.show({
                text1: t(i18nKeys.errorUnknown),
                type: "error",
            });
        } finally {
            setIsBusy(false);
            setIsBusyText("");
            cancelRef.current = false;
        }
    };

    const loadUsers = () => {
        try {
            if (!currentAdminUser || isDsLoaded) return;

            setIsDsLoaded(true);
            getAllUsersAsync().then((users) => {
                usersDsRawRef.current = users;
                setUsersDs(
                    usersDsRawRef.current.map((item) => {
                        return {
                            value: item.id,
                            label: item.email,
                        } as WnaDataSourceItem;
                    })
                );
                const selectedUser = usersDsRawRef.current.find(
                    (x) => x.id === currentUser?.id
                );
                if (selectedUser) setSelectedUserDsVal(selectedUser.id);
            });
        } catch (error) {
            Logger.error(loadUsers.name, error);
        }
    };

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line
    }, []);

    if (!isAppInitialized || !currentUser)
        return <Redirect href={WnaNavigationRouteProvider.menu} />;

    return (
        <WnaBaseScreen
            title={t(i18nKeys.screenTitleProfile)}
            isBusy={isBusy}
            isBusyText={isBusyText}
            preventBack={isBusy}
            onCancel={onCancel}>
            <ScrollView style={[{ padding: 16 }]}>
                <View
                    style={[
                        appStyle.containerCenterMaxWidth,
                        {
                            padding: 24,
                            backgroundColor: appColors.white,
                        },
                    ]}>
                    {/*Admin*/}
                    {currentAdminUser ? (
                        <View
                            style={[
                                appStyle.containerCenterMaxWidth,
                                { zIndex: 2000 },
                            ]}>
                            {/* switch user */}
                            <WnaPicker
                                appStyle={appStyle}
                                appColors={appColors}
                                dataSource={usersDs}
                                selectedKey={selectedUserDsVal}
                                onSelectionChanged={(val) => {
                                    if (val === null) return;

                                    const selectedUser =
                                        usersDsRawRef.current.find(
                                            (x) => x.id === val
                                        );
                                    if (selectedUser !== undefined) {
                                        setSelectedUserDsVal(selectedUser.id);
                                        setSelectedUserAsync(selectedUser);
                                    }
                                }}
                            />
                        </View>
                    ) : null}
                    <View style={[appStyle.containerCenter, { gap: 16 }]}>
                        <WnaImage
                            imageUrl={currentUser?.picture ?? ""}
                            appColors={appColors}
                            hideBackground={true}
                            style={{
                                width: 96,
                                height: 96,
                                borderRadius: 48,
                                borderColor: appColors.white,
                                borderWidth: 2,
                                backgroundColor: "transparent",
                            }}
                        />
                        <Text
                            style={[
                                appStyle.textTitleLarge,
                                {
                                    marginTop: 16,
                                    color: appColors.coolgray6,
                                    maxWidth: 256,
                                },
                            ]}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {currentUser?.name}
                        </Text>
                    </View>
                    <View style={[appStyle.containerCenter, { gap: 16 }]}>
                        <WnaSeparatorHorizontal />

                        {/* logout */}
                        <WnaButtonText
                            appStyle={appStyle}
                            appColors={appColors}
                            style={{ width: 256 }}
                            text={t(i18nKeys.actionLogOut)}
                            onPress={onLogout}
                            checkInternetConnection={false}
                            t={t}
                        />

                        {/* logout and delete */}
                        <WnaButtonText
                            appStyle={appStyle}
                            appColors={appColors}
                            style={{ width: 256 }}
                            text={t(i18nKeys.actionLogOutAndDeleteData)}
                            onPress={() => {
                                Toast.show({
                                    text1: "not implemented",
                                    type: "info",
                                });
                            }}
                            t={t}
                            checkInternetConnection={true}
                        />

                        <WnaSeparatorHorizontal />

                        <WnaButtonIconText
                            appColors={appColors}
                            style={{ width: 256 }}
                            text={t(i18nKeys.actionDownload)}
                            onPress={buildCacheAsync}
                            t={t}
                            checkInternetConnection={true}
                            iconName={"download"}
                        />
                    </View>
                </View>
            </ScrollView>
        </WnaBaseScreen>
    );
}
