import ApiSettings from "@/api/apiSettings";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";

let _jwt = "";

const setJwt = (jwt: string) => {
    _jwt = jwt;
};

export async function prepareRequestAsync() {
    if (_jwt !== "") return;

    const jwt = await WnaDataStorageProvider.getItemAsync("jwt", true, false);
    setJwt(jwt ?? "");
}

const initJsonRequest = (
    method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH"
) => {
    return {
        headers: {
            ApiKey: ApiSettings.apiKey,
            Authorization: `Bearer ${_jwt}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        method: method,
        mode: "cors",
    } as RequestInit;
};

const initHealthCheckRequest = () => {
    return {
        headers: {
            ApiKey: ApiSettings.apiKey,
        },
        method: "HEAD",
        mode: "cors",
    } as RequestInit;
};

const initPdfRequest = () => {
    return {
        headers: {
            ApiKey: ApiSettings.apiKey,
            Authorization: `Bearer ${_jwt}`,
            "Content-Type": "application/pdf",
            Accept: "application/pdf",
        },
        method: "GET",
        mode: "cors",
    } as RequestInit;
};

const initFormRequest = () => {
    return {
        headers: {
            ApiKey: ApiSettings.apiKey,
            Authorization: `Bearer ${_jwt}`,
            Accept: "application/json",
        },
        method: "POST",
        mode: "cors",
    } as RequestInit;
};
export {
    initFormRequest,
    initHealthCheckRequest,
    initJsonRequest,
    initPdfRequest,
    setJwt,
};
