import { initPdfRequest } from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import GetPdfResponse from "@/types/getPdfResponse";
import Logger from "@/utils/logger";

const getPdfAsync = async (
    userId: string | undefined,
    chapterIdentifier: string | undefined
) => {
    if (!isUserId(userId) || !isDateTimeIdentifier(chapterIdentifier))
        return undefined;

    return getPdfByUrlAsync(
        `${ApiSettings.apiBaseUrl}/users/${userId}/chapters/${chapterIdentifier}/pdf`
    );
};

const getPdfByUrlAsync = async (url: string) => {
    try {
        const timeOut = 1200000; // 20 min
        const request = initPdfRequest();
        const result = await handleResponseAsync(url, request, timeOut);
        if (result instanceof Response) {
            const data = await result.blob();
            return {
                httpStatusCode: result.status,
                data: data,
            } as GetPdfResponse;
        } else {
            Logger.error(getPdfByUrlAsync.name, "could not get pdf.");
            return undefined;
        }
    } catch (e) {
        Logger.error(getPdfByUrlAsync.name, e);
        return undefined;
    }
};

export { getPdfAsync, getPdfByUrlAsync };
