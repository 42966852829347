import { execWhenOnline } from "@services/wnaExecWhenOnline";
import { TFunction } from "i18next";
import React, { useRef, useState } from "react";
import { Platform, Pressable, View, ViewStyle } from "react-native";
import { Popable } from "react-native-popable";

export type WnaPressableState = Readonly<{
    pressed: boolean;
    hovered?: boolean;
    focused?: boolean;
}>;

export type WnaPressableProps = {
    children: React.ReactNode;
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
    toolTip?: string;
    toolTipPosition?: "top" | "right" | "bottom" | "left" | undefined;
    style?: ViewStyle | ViewStyle[];
    rippleWhite?: boolean;
    disableHover?: boolean;
};

const WnaPressable: React.FC<WnaPressableProps> = (props) => {
    const [isEnabled, setIsEnabled] = useState(true);
    const isEnabledRef = useRef(isEnabled);
    const [isToolTipVisible, setIsToolTipVisible] = useState(false);
    const toolTip = props.toolTip ?? "";
    const onPress = async () => {
        if (!isEnabledRef.current) return;

        setIsEnabled(false);
        isEnabledRef.current = false;

        if (props.checkInternetConnection) {
            execWhenOnline(
                props.checkInternetConnection,
                props.t,
                props.onPress
            );
        } else {
            props.onPress();
        }
        setTimeout(() => {
            setIsEnabled(true);
            isEnabledRef.current = true;
        }, 500);
    };
    const toolTipPosition = props.toolTipPosition ?? "bottom";

    return (
        <>
            {toolTipPosition === "top" ? (
                <Popable
                    content={toolTip}
                    position={props.toolTipPosition ?? "bottom"}
                    // strictPosition
                    // action="hover"
                    visible={toolTip !== "" && isToolTipVisible}>
                    {null}
                </Popable>
            ) : null}
            <View style={[props.style, { overflow: "hidden" }]}>
                <Pressable
                    android_ripple={{
                        color: props.rippleWhite === true ? "white" : "black",
                    }}
                    onHoverIn={() => {
                        if (toolTip !== "" && Platform.OS === "web") {
                            setIsToolTipVisible(true);
                        }
                    }}
                    onHoverOut={() => {
                        setIsToolTipVisible(false);
                    }}
                    style={({
                        pressed,
                        hovered,
                        focused,
                    }: WnaPressableState) => [
                        Platform.OS === "web"
                            ? {
                                  opacity: pressed
                                      ? 0.7
                                      : props.disableHover === undefined
                                        ? hovered || focused
                                            ? 0.9
                                            : 1
                                        : 1,
                                  flex: 1,
                                  cursor: "pointer",
                              }
                            : {
                                  opacity: pressed ? 0.7 : 1,
                                  flex: 1,
                              },
                    ]}
                    onPress={() => {
                        setIsToolTipVisible(false);
                        onPress();
                    }}>
                    {props.children}
                </Pressable>
            </View>
            {toolTipPosition !== "top" ? (
                <Popable
                    content={toolTip}
                    position={props.toolTipPosition ?? "bottom"}
                    visible={toolTip !== "" && isToolTipVisible}>
                    {null}
                </Popable>
            ) : null}
        </>
    );
};
export default WnaPressable;
