import { getUnixMinDate, toStringDate } from "@/utils/dateTimeHelper";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaPressable from "@components/buttons/WnaPressable";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { FontAwesome5 } from "@expo/vector-icons";
import {
    DateTimePickerAndroid,
    DateTimePickerEvent,
} from "@react-native-community/datetimepicker";
import React, { createElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";

export type WnaDatePickerProps = {
    minDate: Date;
    maxDate: Date;
    selectedDate: Date;
    onSelect: (selectedDate?: Date) => void;
};

const _componentHeight = 42;
const _componentWidthNative = 144;
const _componentWidthWeb = 128;
const _componentFontSize = 18;
const _cornerRadius = 8;

const WnaDatePicker: React.FC<WnaDatePickerProps> = (props) => {
    const { appColors, appStyle } = useContext(WnaAppContext);

    const [date, setDate] = useState<Date | undefined>(new Date());
    const [minDate, setMinDate] = useState(getUnixMinDate());
    const [maxDate, setMaxDate] = useState(new Date(2039, 11, 31));
    // const [show, setShow] = useState(false);
    const { t } = useTranslation(["common"]);
    // const onChange = (event: DateTimePickerEvent, selectedDate?: Date) => {
    //     const currentDate = selectedDate || date;
    //     setShow(Platform.OS === "ios");
    //     setDate(currentDate);
    // };

    const onChangeWeb = (e: any) => {
        const val = e.target.value;
        if (val === null || val === undefined || val === "") return;

        const selectedDate = new Date(e.target.value);
        setDate(selectedDate);
    };

    const onBlurWeb = () => {
        props.onSelect(date);
    };

    const onChangeAndroid = (e: DateTimePickerEvent, date?: Date) => {
        if (date === null) return;

        setDate(date);
        props.onSelect(date);
    };

    const openAndroidPicker = () => {
        DateTimePickerAndroid.open({
            mode: "date",
            value: date ?? new Date(),
            style: {
                backgroundColor: appColors.yellow7,
            },
            onChange: onChangeAndroid,
        });
        // DateTimePickerAndroid.dismiss(mode: AndroidNativeProps['mode'])
    };

    useEffect(() => {
        setDate(new Date(props.selectedDate));
        setMaxDate(new Date(props.maxDate));
        setMinDate(new Date(props.minDate));
    }, [props]);

    return Platform.OS === "web" ? (
        createElement("input", {
            type: "date",
            value: date!.toISOString().split("T")[0],
            min: minDate.toISOString().split("T")[0],
            max: maxDate.toISOString().split("T")[0],
            onChange: onChangeWeb,
            onBlur: onBlurWeb,
            style: {
                height: _componentHeight,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: appColors.coolgray2,
                borderRadius: _cornerRadius,
                width: _componentWidthWeb,
                fontFamily: FontFamilies.Roboto,
                padding: _cornerRadius,
                fontSize: _componentFontSize,
                lineHeight: _componentFontSize + 4,
                backgroundColor: appColors.white,
                color: appColors.black,
            },
        })
    ) : (
        <View
            style={[
                {
                    width: _componentWidthNative,
                    height: _componentHeight,
                    borderRadius: _cornerRadius,
                    overflow: "hidden",
                },
            ]}>
            <WnaPressable
                toolTip=""
                onPress={openAndroidPicker}
                style={{
                    height: _componentHeight,
                    borderRadius: _cornerRadius,
                }}
                t={t}
                checkInternetConnection={false}>
                <View
                    style={[
                        appStyle.textInput,
                        {
                            width: _componentWidthNative,
                            flexDirection: "row",
                            alignItems: "center",
                        },
                    ]}>
                    <Text
                        style={{
                            color: appColors.black,
                            fontSize: _componentFontSize,
                        }}>
                        {toStringDate(date)}
                    </Text>
                    <View
                        style={{
                            position: "absolute",
                            right: 0,
                            paddingRight: 8,
                        }}>
                        <FontAwesome5
                            size={_componentFontSize}
                            name={"calendar"}
                            color={appColors.coolgray6}
                        />
                    </View>
                </View>
            </WnaPressable>
        </View>
    );
};

export default WnaDatePicker;
