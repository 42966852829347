import { healthcheckAsync } from "@/api/apiHealthcheck";
import registerFcmUserAsync from "@/api/fcm/registerFcmUser";
import getCountUnreadNotificationsAsync from "@/api/notification/getCountUnreadNotifications";
import { getBackgroundImageUrl } from "@/api/publicResources/getBackgroundImageUrl";
import { getStoredUserAsync } from "@/api/user/userStorageProvider";
import verifyJwtAsync from "@/api/user/verfiyJwt";
import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaDialog from "@components/dialog/WnaDialog";
import { CurrentColors } from "@constants/currentColors";
import { initFcmProvider } from "@services/fcm/wnaFcmProvider";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { ErrorBoundaryProps, Stack } from "expo-router";
import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "expo-status-bar";
import React, { FC, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Dimensions, Text, useColorScheme, View } from "react-native";
import Toast from "react-native-toast-message";

// Prevent the splash screen from auto-hiding before asset loading is complete.
SplashScreen.preventAutoHideAsync();

export function ErrorBoundary(props: ErrorBoundaryProps) {
    return (
        <View style={{ flex: 1, backgroundColor: "red" }}>
            <Text>{props.error.message}</Text>
            <Text onPress={props.retry}>Try Again?</Text>
        </View>
    );
}

export type AppComponentProps = {
    appSettings: WnaAppSettings;
    userSettings: WnaUserSettings;
};
let dimensionTimerHandle: any = undefined;
const WnaApp: FC<AppComponentProps> = (props) => {
    const colorScheme = useColorScheme();
    const isInternetReachableRef = useRef(false);
    const { t } = useTranslation("common");
    // appContext
    const {
        isAppInitialized,
        darkAppBackgroundImage,
        lightAppBackgroundImage,
        setCurrentScreenHeight,
        setCurrentWindowHeight,
        setCurrentWindowWidth,
        setCurrentScreenWidth,
        setIsLandscape,
        setAppColors,
        setDarkAppBackgroundImage,
        setLightAppBackgroundImage,
        setAppSettings,
        setCurrentUserSettings,
        setIsInternetReachable,
        setCurrentUserAsync,
        setUnreadNotificationCount,
        setIsAppInitialized,
    } = useContext(WnaAppContext);

    const startDimensionsTimer = () => {
        dimensionTimerHandle = setTimeout(() => {
            const sW = Dimensions.get("screen").width;
            const sH = Dimensions.get("screen").height;
            const wW = Dimensions.get("window").width;
            const wH = Dimensions.get("window").height;
            const isLandscape = wW > wH;
            setCurrentScreenWidth(sW);
            setCurrentScreenHeight(sH);
            setCurrentWindowWidth(wW);
            setCurrentWindowHeight(wH);
            setIsLandscape(isLandscape);
            Logger.info(`wW: ${wW} | wH: ${wH} | isLandscape: ${isLandscape}`);
        }, 500);
    };

    useEffect(() => {
        if (!props.appSettings) return;

        const updateLayout = () => {
            clearTimeout(dimensionTimerHandle);
            startDimensionsTimer();
        };
        Dimensions.addEventListener("change", updateLayout);

        const healthCheckInterval = setInterval(async () => {
            const isHealthy = await healthcheckAsync();
            if (isHealthy !== isInternetReachableRef.current) {
                isInternetReachableRef.current = isHealthy;
                setIsInternetReachable(isHealthy);
            }
        }, 5000);

        setAppSettings(props.appSettings);
        setCurrentUserSettings(props.userSettings);

        let appColors = CurrentColors[colorScheme ?? "light"];
        if (props.userSettings.appThemeId === 1)
            appColors = CurrentColors["light"];
        else if (props.userSettings.appThemeId === 2)
            appColors = CurrentColors["dark"];

        setAppColors(appColors);
        setLightAppBackgroundImage(
            getBackgroundImageUrl(props.appSettings.backgroundImageUrl)
        );
        setDarkAppBackgroundImage(
            getBackgroundImageUrl(props.appSettings.backgroundImageDarkUrl)
        );

        getStoredUserAsync().then(async (user) => {
            const isOnline = await healthcheckAsync();
            if (user && isOnline) {
                // reauthenticate
                const jwt = await verifyJwtAsync(user.id);
                if (jwt !== "") {
                    const unreadNotificationCount =
                        await getCountUnreadNotificationsAsync(user.id);

                    setUnreadNotificationCount(unreadNotificationCount);

                    initFcmProvider(
                        props.appSettings,
                        (token: string) => {
                            registerFcmUserAsync(user!.id, token);
                        },
                        (title: string, body: string) => {
                            getCountUnreadNotificationsAsync(user!.id).then(
                                (unreadNotificationCount) => {
                                    Toast.show({
                                        text1: title,
                                        text2: body,
                                        type: "info",
                                    });
                                    setUnreadNotificationCount(
                                        unreadNotificationCount
                                    );
                                }
                            );
                        }
                    );
                } else {
                    user = undefined;
                    Toast.show({
                        text1: t(i18nKeys.infoUserLoggedOut),
                        type: "info",
                    });
                }
            }
            await setCurrentUserAsync(user);
            setIsAppInitialized(true);
            await SplashScreen.hideAsync();
        });

        return () => {
            try {
                removeEventListener("change", updateLayout);
            } catch {
                // ignored
            }
            clearInterval(healthCheckInterval);
        };
        // eslint-disable-next-line
    }, []);

    if (
        !isAppInitialized ||
        darkAppBackgroundImage === "" ||
        lightAppBackgroundImage === ""
    ) {
        return (
            <View
                style={{
                    backgroundColor: colorScheme === "dark" ? "#111" : "#fff",
                }}
            />
        );
    }

    return (
        <>
            <Stack
                screenOptions={{
                    headerShown: false,
                    title: "",
                }}>
                <Stack.Screen name="(tabs)" />
                <Stack.Screen name="+not-found" />
            </Stack>
            <WnaDialog />
            <Toast position={"bottom"} bottomOffset={64} />
            <StatusBar style="light" />
        </>
    );
};
export default WnaApp;
