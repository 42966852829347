import Logger from "@/utils/logger";
import AsyncStorage from "@react-native-async-storage/async-storage";

export async function setObjectToStorageAsync(key: string, value: object) {
    try {
        const jsonValue = JSON.stringify(value);
        await AsyncStorage.setItem(key, jsonValue);
    } catch (e) {
        Logger.error(setObjectToStorageAsync.name, e);
    }
}

export async function setStringToStorageAsync(key: string, value: string) {
    try {
        await AsyncStorage.setItem(key, value);
    } catch (e) {
        Logger.error(setStringToStorageAsync.name, e);
    }
}

export async function getStringFromStorageAsync(key: string) {
    try {
        const value = await AsyncStorage.getItem(key);
        return value !== null ? value : "";
    } catch (e) {
        Logger.error(getStringFromStorageAsync.name, e);
    }
}

export async function getObjectFromStorageAsync(key: string) {
    try {
        const jsonValue = await AsyncStorage.getItem(key);
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        Logger.error(getObjectFromStorageAsync.name, e);
    }
}

export async function removeItemsFromStorageAsync(keys: string[]) {
    try {
        await AsyncStorage.multiRemove(keys);
    } catch (e) {
        Logger.error(removeItemsFromStorageAsync.name, e);
    }
}

export async function removeItemFromStorageAsync(key: string) {
    try {
        await AsyncStorage.removeItem(key);
    } catch (e) {
        Logger.error(removeItemFromStorageAsync.name, e);
    }
}

export const clearStorageAsync = async () => {
    try {
        await AsyncStorage.clear();
    } catch (e) {
        Logger.error(clearStorageAsync.name, e);
    }
};
