import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import { WnaDiaryEntryPlaceholders } from "@/types/wnaDiaryEntryPlaceholder";
import { addDays } from "@/utils/dateTimeHelper";
import WnaDatePicker from "@components/misc/WnaDatePicker";
import WnaRichTextEditor from "@components/text/WnaRichTextEditor/WnaRichTextEditor";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import React, { useEffect, useRef, useState } from "react";
import { TextInput, View } from "react-native";

export type WnaDiaryEntryDataViewEditProps = {
    appColors: Colors;
    appStyle: AppStyle;
    diaryEntry: WnaDiaryEntry;
    onUpdate: (dateStart: Date, designator: string, comment: string) => void;
    onCommentTyping?: (isTyping: boolean) => void;
    placeholders: WnaDiaryEntryPlaceholders;
};

const WnaDiaryEntryDataViewEdit: React.FC<WnaDiaryEntryDataViewEditProps> = (
    props
) => {
    const onUpdate = () => {
        props.onUpdate(
            currentDateStartRef.current,
            currentDesignatorRef.current,
            currentCommentRef.current
        );
    };

    const onSelectDateStart = (dateStart?: Date) => {
        if (!dateStart) return;
        currentDateStartRef.current = dateStart;
        onUpdate();
    };

    const onCommentTextChanged = (text: string) => {
        currentCommentRef.current = text;
        onUpdate();
    };

    const onDesignatorTextChanged = (text: string) => {
        setCurrentDesignator(text);
        currentDesignatorRef.current = text;
        onUpdate();
    };

    const [currentDateStart, setCurrentDateStart] = useState<Date>(new Date());
    const currentDateStartRef = React.useRef(currentDateStart);

    const [currentDesignator, setCurrentDesignator] = useState<string>("");
    const currentDesignatorRef = React.useRef(currentDesignator);
    const designatorInputRef = useRef<TextInput>(null);

    const [currentComment, setCurrentComment] = useState<string>("");
    const currentCommentRef = React.useRef(currentComment);
    const commentInputRef = useRef<TextInput>(null);

    const [isDatePickerVisible, setIsDatePickerVisible] = useState(true);
    const [isDesignatorVisible, setIsDesignatorVisible] = useState(true);

    const placeholder =
        props.placeholders.de.length < 1
            ? {
                  designator: "Panoramawanderung am See",
                  comment:
                      "Liebes Tagebuch, heute habe ich mich auf eine Wanderung durch den Wald begeben, und es war eine unvergessliche Erfahrung. Die Natur hat mich mit ihrer Pracht und Stille in ihren Bann gezogen. Der Morgen begann mit dem Zwitschern der Vögel und dem sanften Rascheln der Blätter. Ich konnte den erfrischenden Duft des Waldes in der kühlen Morgenluft förmlich einatmen. Die Sonnenstrahlen drangen durch die dichten Baumkronen und ließen den Wald in ein warmes, goldenes Licht tauchen.",
              }
            : props.placeholders.de[new Date().getDate() - 1];

    const handleCommentFocus = (isFocused: boolean) => {
        setIsDatePickerVisible(!isFocused);
        setIsDesignatorVisible(!isFocused);
        if (props.onCommentTyping) props.onCommentTyping(isFocused);
    };

    useEffect(() => {
        setCurrentDateStart(props.diaryEntry.dateStart);
        currentDateStartRef.current = props.diaryEntry.dateStart;

        setCurrentDesignator(props.diaryEntry.designator);
        currentDesignatorRef.current = props.diaryEntry.designator;

        setCurrentComment(props.diaryEntry.comment);
        currentCommentRef.current = props.diaryEntry.comment;
    }, [props]);

    return (
        <View style={props.appStyle.containerFillPage}>
            <View
                style={{
                    flex: 1,
                    margin: 16,
                    alignContent: "stretch",
                    gap: 16,
                }}>
                {/* date */}
                {isDatePickerVisible ? (
                    <WnaDatePicker
                        maxDate={new Date()}
                        minDate={addDays(new Date(), -3660)}
                        selectedDate={currentDateStart}
                        onSelect={onSelectDateStart}
                    />
                ) : null}

                {/* designator */}
                {isDesignatorVisible ? (
                    <TextInput
                        ref={designatorInputRef}
                        style={[
                            props.appStyle.textInput,
                            {
                                width: "100%",
                                backgroundColor: props.appColors.white,
                                color: props.appColors.black,
                            },
                        ]}
                        maxLength={256}
                        selectionColor={props.appColors.accent1}
                        value={currentDesignator}
                        onChangeText={(text) => onDesignatorTextChanged(text)}
                        onSubmitEditing={() => {
                            commentInputRef.current?.focus();
                        }}
                        placeholder={placeholder.designator}
                        placeholderTextColor={props.appColors.coolgray3}
                    />
                ) : null}
                {/* comment */}
                <View style={{ flex: 1, alignSelf: "stretch" }}>
                    <WnaRichTextEditor
                        appStyle={props.appStyle}
                        appColors={props.appColors}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 48,
                            width: "auto",
                            verticalAlign: "top",
                        }}
                        text={currentComment}
                        onUpdate={(text) => onCommentTextChanged(text)}
                        placeHolderText={placeholder.comment}
                        onFocus={() => handleCommentFocus(true)}
                        onBlur={() => handleCommentFocus(false)}
                    />
                </View>
            </View>
        </View>
    );
};

export default WnaDiaryEntryDataViewEdit;
