import WnaFileInfo from "@/types/entities/wnaFileInfo";
import Logger from "@/utils/logger";
import * as DocumentPicker from "expo-document-picker";
import { Platform } from "react-native";

export default async function pickImagesAsync(
    multiple: boolean,
    onError: (errorMessage: string) => void
): Promise<WnaFileInfo[]> {
    try {
        const result = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: Platform.OS !== "web",
            type:
                Platform.OS !== "web"
                    ? ["image/jpeg", "image/png", "image/gif", "image/bmp"]
                    : ["*.*"],
            multiple: multiple,
        });

        if (!result || !result.assets || result.assets.length < 1)
            return new Array<WnaFileInfo>();

        const fileInfos = new Array<WnaFileInfo>();
        for (let i = 0; i < result.assets.length; i++) {
            const fileName = result.assets[i].name;
            const fileExt = fileName.split(".").pop();
            const fileExtLow = fileExt!.toLocaleLowerCase();
            switch (fileExtLow) {
                case "png":
                case "jpg":
                case "jpeg":
                case "bmp":
                case "gif":
                    break;
                default:
                    onError("file extension not supported");
                    return new Array<WnaFileInfo>();
            }
            // https://stackoverflow.com/a/76913577
            fileInfos.push({
                fileName: fileName,
                url: result.assets[i].uri,
                urlTh256: result.assets[i].uri,
                urlTh512: result.assets[i].uri,
                isLocal: true,
                isDirty: true,
                isDeleted: false,
            } as WnaFileInfo);
        }

        return fileInfos;
    } catch (error) {
        Logger.error(pickImagesAsync.name, error);
        onError("something went wrong");
        return new Array<WnaFileInfo>();
    }
}
