import {
    initFormRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import WnaRoute from "@/types/entities/wnaRoute";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function createRouteAsync(routeDto: WnaRoute, file: Blob) {
    try {
        const route = `routes/create`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initFormRequest();
        request.body = new FormData();
        request.body.append("route", JSON.stringify(routeDto));
        request.body.append("file", file);
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.long
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaRoute;
        } else {
            Logger.error(createRouteAsync.name, "could not create route.");
            return undefined;
        }
    } catch (e) {
        Logger.error(createRouteAsync.name, e);
        return undefined;
    }
}
