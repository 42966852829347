import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import WnaNotification, {
    getDefaultNotification,
} from "@/types/entities/wnaNotification";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function sendNotificationToAllUsersAsync(
    notification: WnaNotification
) {
    try {
        const route = `admin/notifications/sendToAllUsers`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("POST");
        request.body = JSON.stringify(notification);
        const result = await handleResponseAsync(url, request, apiTimeouts.max);
        if (result instanceof Response) {
            return (await result.json()) as WnaNotification;
        } else {
            Logger.error(
                sendNotificationToAllUsersAsync.name,
                "could not send notification to all users."
            );
            return getDefaultNotification();
        }
    } catch (e) {
        Logger.error(sendNotificationToAllUsersAsync.name, e);
        return getDefaultNotification();
    }
}
