import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import { Editor } from "react-draft-wysiwyg";
// @ts-ignore
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";

import { WnaAppContext } from "@app/WnaAppContext";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { View } from "react-native";

// https://jpuri.github.io/react-draft-wysiwyg/#/demo
// https://github.com/jpuri/react-draft-wysiwyg
// https://jpuri.github.io/react-draft-wysiwyg/#/docs

export type WnaRichTextEditorProps = {
    appColors: Colors;
    appStyle: AppStyle;
    text: string;
    placeHolderText: string;
    style: any;
    onUpdate: (text: string) => void;
    onFocus: () => void;
    onBlur: () => void;
};

const WnaRichTextEditor: React.FC<WnaRichTextEditorProps> = (props) => {
    const { appColors } = useContext(WnaAppContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const blocksFromHTML = htmlToDraft(props.text); //convertFromHTML(props.text);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        const newEditorState = EditorState.createWithContent(state);
        setEditorState(newEditorState);
    }, [props]);
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
        props.onUpdate(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    };

    return (
        <View style={props.style}>
            <Editor
                editorState={editorState}
                editorStyle={{
                    backgroundColor: appColors.staticWhite,
                    color: appColors.staticBlack,

                    position: "absolute",
                    top: 40,
                    bottom: 0,
                    left: 0,
                    right: 0,

                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: appColors.coolgray2,
                    borderRadius: 8,
                    fontFamily: FontFamilies.Roboto,
                    padding: 16,
                    paddingTop: 0,
                    fontSize: 18,
                }}
                toolbarStyle={{
                    backgroundColor: "transparent",
                    border: 0,
                    height: 40,
                    padding: 0,
                    margin: -3,
                }}
                toolbar={{
                    options: [
                        "blockType",
                        "inline",
                        "colorPicker",
                        "list",
                        "textAlign",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                    ],
                    blockType: {
                        inDropdown: true,
                        options: ["Normal", "H1", "H2", "Blockquote"],
                    },
                    inline: {
                        inDropdown: false,
                        options: ["underline", "strikethrough"],
                    },
                    history: { inDropdown: false },
                    colorPicker: {
                        colors: [
                            props.appColors.staticBlack,
                            props.appColors.staticCoolgray8,
                            props.appColors.staticCoolgray6,
                            props.appColors.staticCoolgray4,
                            props.appColors.staticWhite,

                            props.appColors.staticAccent8,
                            props.appColors.staticAccent6,
                            props.appColors.staticAccent4,
                            props.appColors.staticAccent3,
                            props.appColors.staticAccent2,

                            props.appColors.yellow8,
                            props.appColors.yellow6,
                            props.appColors.yellow4,
                            props.appColors.yellow3,
                            props.appColors.yellow2,

                            props.appColors.orange8,
                            props.appColors.orange6,
                            props.appColors.orange4,
                            props.appColors.orange3,
                            props.appColors.orange2,

                            props.appColors.red8,
                            props.appColors.red6,
                            props.appColors.red4,
                            props.appColors.red3,
                            props.appColors.red2,

                            props.appColors.burgundy8,
                            props.appColors.burgundy6,
                            props.appColors.burgundy4,
                            props.appColors.burgundy3,
                            props.appColors.burgundy2,

                            props.appColors.pink8,
                            props.appColors.pink6,
                            props.appColors.pink4,
                            props.appColors.pink3,
                            props.appColors.pink2,

                            props.appColors.violet8,
                            props.appColors.violet6,
                            props.appColors.violet4,
                            props.appColors.violet3,
                            props.appColors.violet2,

                            props.appColors.blue8,
                            props.appColors.blue6,
                            props.appColors.blue4,
                            props.appColors.blue3,
                            props.appColors.blue2,

                            props.appColors.cyan8,
                            props.appColors.cyan6,
                            props.appColors.cyan4,
                            props.appColors.cyan3,
                            props.appColors.cyan2,

                            props.appColors.turquoise8,
                            props.appColors.turquoise6,
                            props.appColors.turquoise4,
                            props.appColors.turquoise3,
                            props.appColors.turquoise2,

                            props.appColors.lightgreen8,
                            props.appColors.lightgreen6,
                            props.appColors.lightgreen4,
                            props.appColors.lightgreen3,
                            props.appColors.lightgreen2,

                            props.appColors.green8,
                            props.appColors.green6,
                            props.appColors.green4,
                            props.appColors.green3,
                            props.appColors.green2,
                        ],
                    },
                    list: {
                        inDropdown: true,
                    },
                    textAlign: {
                        inDropdown: true,
                        options: ["left", "center", "right"],
                    },
                }}
                onEditorStateChange={onEditorStateChange}
                //onBlur={props.onBlur} // this is suboptimal on browser
                //onFocus={props.onFocus} // this is suboptimal on browser
                placeholder={props.placeHolderText}
            />
        </View>
    );
};
export default WnaRichTextEditor;
