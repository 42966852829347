import { getUnixMinDate } from "@/utils/dateTimeHelper";

export default interface WnaChapter {
    identifier: string;
    designator: string;
    comment: string;
    dateCreate: Date;
    dateUpdate: Date;
    dateRead: Date;
    dateDelete: Date;
    dateStart: Date;
    dateEnd: Date;
    imageUrl: string;
    pdfDownloadUrl: string;
}

export function getDefaultChapter() {
    return {
        identifier: "",
        designator: "",
        comment: "",
        dateCreate: getUnixMinDate(),
        dateUpdate: getUnixMinDate(),
        dateRead: getUnixMinDate(),
        dateDelete: getUnixMinDate(),
        dateEnd: getUnixMinDate(),
        dateStart: getUnixMinDate(),
        imageUrl: "",
        pdfDownloadUrl: "",
    } as WnaChapter;
}
