import generatePdfAsync from "@/api/diaryGenerator/diaryPrintProvider";
import getHtmlPaginatedAsync from "@/api/diaryGenerator/getDiaryAsHtmlPaginated";
import WnaPage from "@/types/wnaPage";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaButtonIcon from "@components/buttons/WnaButtonIcon";
import WnaPaginationInfoView from "@components/misc/WnaPaginationInfoView";
import WnaWebView from "@components/misc/WnaWebView";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useLocalSearchParams, useRouter } from "expo-router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import Toast from "react-native-toast-message";

export default function PdfPreviewScreen() {
    const router = useRouter();
    const { t } = useTranslation(["common"]);
    const { isAppInitialized, appColors, appStyle, currentUser, isLandscape } =
        useContext(WnaAppContext);
    const { chapterIdentifier } = useLocalSearchParams<{
        chapterIdentifier: string;
    }>();

    const [isBusy, setIsBusy] = useState(true);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);
    const [currentPage, setCurrentPage] = useState(new WnaPage(0, 0));
    const [html, setHtml] = useState("");
    const pagesToLoadLength = 1;
    const loadDataAsync = async (currentPageIndex: number) => {
        setIsBusy(true);
        try {
            const resp = await getHtmlPaginatedAsync(
                currentUser?.id,
                chapterIdentifier,
                currentPageIndex,
                pagesToLoadLength
            );

            if (resp.html === "") {
                setRedirectToNotFound(true);
                Toast.show({
                    text1: "not found",
                    type: "error",
                });
                return;
            }

            setHtml(resp.html);
            setCurrentPage(new WnaPage(resp.startIndex, resp.length));
        } catch (e) {
            Logger.error(loadDataAsync.name, e);
            setIsBusy(false);
        }
    };

    const startGeneratePdf = () => {
        setIsBusy(true);
        generatePdfAsync(currentUser!.id, chapterIdentifier, t).then(() => {
            setIsBusy(false);
            router.back();
        });
    };

    const navigateToNextPage = async () => {
        if (currentPage.isNextVisible())
            await loadDataAsync(currentPage.nextIndex());
    };

    const navigateToPrevPage = async () => {
        if (currentPage.isPrevVisible())
            await loadDataAsync(currentPage.prevIndex());
    };

    useEffect(() => {
        if (html === "") loadDataAsync(currentPage.index);
        // eslint-disable-next-line
    }, [html]);

    if (!isAppInitialized || !currentUser || redirectToNotFound)
        return <Redirect href={WnaNavigationRouteProvider.chapters} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={t(i18nKeys.screenTitleDiaryPreview)}
            headerButton0={
                <WnaButtonHeader
                    appColors={appColors}
                    appStyle={appStyle}
                    t={t}
                    checkInternetConnection={true}
                    iconName={"file-pdf"}
                    toolTipPosition={"bottom"}
                    text={t(i18nKeys.actionPrint)}
                    onPress={startGeneratePdf}
                />
            }>
            <>
                <View
                    style={{
                        flex: 1,
                        padding: 16,
                        opacity: isBusy ? 0 : 1,
                    }}>
                    <WnaWebView
                        urlOrHtml={html}
                        onLoaded={() => {
                            setTimeout(() => {
                                setIsBusy(false);
                            }, 250);
                        }}
                    />
                </View>
                {isBusy ? null : (
                    <>
                        <WnaPaginationInfoView
                            index={currentPage.index}
                            length={currentPage.length}
                            appStyle={appStyle}
                            appColors={appColors}
                            isLandscape={isLandscape}
                        />

                        {/*prev*/}
                        {currentPage.isPrevVisible() ? (
                            <WnaButtonIcon
                                toolTip={t(i18nKeys.actionPreviousPage)}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrow-left"
                                onPress={() => navigateToPrevPage()}
                                t={t}
                                checkInternetConnection={true}
                                style={{
                                    position: "absolute",
                                    bottom: 16,
                                    left: 16,
                                }}
                            />
                        ) : null}
                        {/*next*/}
                        {currentPage.isNextVisible() ? (
                            <WnaButtonIcon
                                toolTip={t(i18nKeys.actionNextPage)}
                                toolTipPosition={"left"}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrow-right"
                                onPress={() => navigateToNextPage()}
                                t={t}
                                checkInternetConnection={true}
                                style={{
                                    position: "absolute",
                                    bottom: 16,
                                    right: 16,
                                }}
                            />
                        ) : null}
                    </>
                )}
            </>
        </WnaBaseScreen>
    );
}
