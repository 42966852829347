import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import WnaAppointment from "@/types/entities/wnaAppointment";

const encrypt = false;
const useDb = true;

export default async function getAppointmentAsync(
    identifier: string | undefined
) {
    if (!isDateTimeIdentifier(identifier)) return undefined;

    const route = `appointments/${identifier}`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return await fetchAndCacheGetAsync<WnaAppointment>(
        route,
        url,
        encrypt,
        useDb
    );
}
