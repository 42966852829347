import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import WnaAppointment from "@/types/entities/wnaAppointment";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function createAppointmentAsync(
    appointment: WnaAppointment | undefined
) {
    if (!appointment) return undefined;

    try {
        const route = `admin/appointments/create`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        const request = initJsonRequest("POST");
        request.body = JSON.stringify(appointment);
        await prepareRequestAsync();
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaAppointment;
        } else {
            Logger.error(
                createAppointmentAsync.name,
                "could not create Appointment"
            );
            return undefined;
        }
    } catch (e) {
        Logger.error(createAppointmentAsync.name, e);
        return undefined;
    }
}
