import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaNotification from "@/types/entities/wnaNotification";

export default async function getNotificationAsync(
    userId: string | undefined,
    identifier: string | undefined
) {
    if (!isUserId(userId) || !isDateTimeIdentifier(identifier))
        return undefined;

    const route = `users/${userId}/notifications/${identifier}`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return await fetchAndCacheGetAsync<WnaNotification>(route, url, true, true);
}
