export interface WnaDiaryEntryPlaceholder {
    designator: string;
    comment: string;
}

export interface WnaDiaryEntryPlaceholders {
    de: WnaDiaryEntryPlaceholder[];
    en: WnaDiaryEntryPlaceholder[];
}

export const DefaultWnaDiaryEntryPlaceholders = {
    de: [],
    en: [],
} as WnaDiaryEntryPlaceholders;
