import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import React, { Component } from "react";
import { Text, View } from "react-native";

export type WnaListCardHeaderProps = {
    appColors: Colors;
    appStyle: AppStyle;
    headerTitle?: string;
    hasRoute?: boolean;
    hasImages?: boolean;
    hasDownload?: boolean;
    forceRightTitle?: boolean;
};

class WnaListCardHeader extends Component<WnaListCardHeaderProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaListCardHeaderProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.hasImages !== this.props.hasImages ||
            nextProps.hasRoute !== this.props.hasRoute ||
            nextProps.headerTitle !== this.props.headerTitle
        );
    }

    render() {
        return (
            <>
                {(this.props.headerTitle !== null &&
                    this.props.headerTitle !== "") ||
                this.props.forceRightTitle ||
                this.props.hasImages === true ||
                this.props.hasRoute === true ? (
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignContent: "center",
                        }}>
                        <View
                            style={{
                                flexDirection: "row",
                                alignSelf: "flex-start",
                                alignItems: "center",
                                alignContent: "center",
                                gap: 12,
                                padding: 8,
                            }}>
                            {this.props.hasImages === true ? (
                                <FontAwesome5
                                    size={16}
                                    name={"images"}
                                    color={this.props.appColors.coolgray6}
                                />
                            ) : null}
                            {this.props.hasRoute === true ? (
                                <FontAwesome5
                                    size={16}
                                    name={"map"}
                                    color={this.props.appColors.coolgray6}
                                />
                            ) : null}
                        </View>
                        {this.props.headerTitle === null ||
                        this.props.headerTitle === "" ? null : (
                            <Text
                                style={[
                                    this.props.appStyle.textSmall,
                                    {
                                        alignSelf: "flex-end",
                                        color: this.props.appColors.accent4,
                                        textAlign: "right",
                                        padding: 8,
                                    },
                                ]}>
                                {this.props.headerTitle}
                            </Text>
                        )}
                    </View>
                ) : null}
            </>
        );
    }
}

export default WnaListCardHeader;
