import * as FileSystem from "expo-file-system";
import { EncodingType } from "expo-file-system";
import { shareAsync } from "expo-sharing";
import { LogBox, Platform } from "react-native";
import {
    consoleTransport,
    fileAsyncTransport,
    logger,
} from "react-native-logs";

const IGNORED_LOGS = [
    // Substring or regex match
    new RegExp(
        /.*Support for defaultProps will be removed from function components in a future major release.*/
    ),
    new RegExp(
        /.*"start" method does not exist in console and will not be available.*/
    ),
    new RegExp(
        /.*"end" method does not exist in console and will not be available.*/
    ),
];

// https://reactnative.dev/docs/debugging#console-errors-and-warnings
LogBox.ignoreLogs(IGNORED_LOGS);

const withoutIgnored =
    (logger: (...args: any[]) => void) =>
    (...args: any[]): void => {
        const output = args.map((arg) => String(arg)).join(" ");

        // eslint-disable-next-line
        if (!IGNORED_LOGS.some((log) => output.includes(log.toString()))) {
            logger(...args);
        }
    };

const config = {
    levels: {
        debug: 0,
        log: 1,
        start: 4,
        end: 4,
        info: 4,
        warn: 5,
        error: 6,
    },
    transport: [consoleTransport],
    transportOptions: {
        mapLevels: {
            debug: "log",
            log: "log",
            info: "info",
            warn: "warn",
            err: "error",
            start: "info",
            enf: "info",
        },
        colors: {
            debug: "white",
            log: "white",
            start: "grey",
            end: "grey",
            info: "blueBright",
            warn: "yellowBright",
            error: "redBright",
        },
        FS: FileSystem,
        fileName: `app.log`, // Create a new file every day
    },
    dateFormat: "iso",
    printDate: false,
    printLevel: true,
    enabled: true,
};

if (Platform.OS !== "web") {
    config.printDate = true;
    // @ts-ignore
    config.transport.push(fileAsyncTransport);
}

// @ts-ignore
const reactLogger = logger.createLogger(config);
reactLogger.patchConsole();

console.log = withoutIgnored(console.log);
console.info = withoutIgnored(console.info);
console.warn = withoutIgnored(console.warn);
console.error = withoutIgnored(console.error);

reactLogger.log = withoutIgnored(reactLogger.log);
reactLogger.info = withoutIgnored(reactLogger.info);
reactLogger.warn = withoutIgnored(reactLogger.warn);
reactLogger.error = withoutIgnored(reactLogger.error);

export enum LogPrefix {
    start,
    end,
    info,
    warn,
    error,
}

const logFilePath = FileSystem.documentDirectory + "app.log";

export default class Logger {
    public static isEnabled: boolean = Platform.OS === "web";

    public static async shareLogfileAsync() {
        const mimeType = "text/plain";
        await shareAsync(logFilePath, {
            dialogTitle: "logfile",
            mimeType: mimeType.toString(),
        });
    }

    public static async deleteLogFileAsync() {
        const info = await FileSystem.getInfoAsync(logFilePath);
        if (info.exists) await FileSystem.deleteAsync(logFilePath);
    }

    public static async readLogFileAsync(): Promise<string> {
        try {
            return await FileSystem.readAsStringAsync(logFilePath, {
                encoding: EncodingType.UTF8,
            });
        } catch {
            return "";
        }
    }

    public static info(msg: unknown): void {
        if (!this.isEnabled) return;

        this.log(LogPrefix.info, msg ?? "");
    }

    public static warn(methodName: string, msg: unknown) {
        this.log(LogPrefix.warn, msg ?? "", methodName);
    }

    public static error(methodName: string, msg: unknown) {
        this.log(LogPrefix.error, msg, methodName);
    }

    private static log(prefix: LogPrefix, msg: unknown, methodName?: string) {
        try {
            const l = `${methodName ?? "methodName"}: ${msg ?? "msg"}`;
            if (IGNORED_LOGS.some((regex) => l.match(regex))) return;

            switch (prefix) {
                case LogPrefix.error:
                    reactLogger.error(l);
                    break;
                case LogPrefix.warn:
                    reactLogger.warn(l);
                    break;
                case LogPrefix.info:
                    reactLogger.info(l);
                    break;
                case LogPrefix.start:
                    reactLogger.info(l);
                    break;
                case LogPrefix.end:
                    reactLogger.info(l);
                    break;
            }
        } catch {
            reactLogger.error("COULD NOT WRITE LOG!!!");
        }
    }
}
