export default class DialogProvider {
    private static _isDialogVisible: boolean = false;
    private static _setIsVisible: (isVisible: boolean) => void;
    private static _setShowCancel: (isVisible: boolean) => void;
    private static _onOk: () => void;
    private static _onCancel: () => void;
    public static text: string = "";

    public static showOk(text: string, onOk: () => void = () => {}) {
        if (DialogProvider._isDialogVisible) return;

        DialogProvider._setShowCancel(false);
        DialogProvider.text = text;
        DialogProvider._onOk = onOk;
        DialogProvider._onCancel = () => {};
        DialogProvider._setIsVisible(true);
        DialogProvider._isDialogVisible = true;
    }

    public static async showYesNoAsync(text: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (DialogProvider._isDialogVisible) return reject;

            DialogProvider._setShowCancel(true);
            DialogProvider.text = text;
            DialogProvider._onOk = () => {
                resolve(true);
            };
            DialogProvider._onCancel = () => {
                resolve(false);
            };
            DialogProvider._setIsVisible(true);
            DialogProvider._isDialogVisible = true;
        });
    }

    public static showYesNo(text: string, onOk: () => void) {
        if (DialogProvider._isDialogVisible) return;

        DialogProvider._setShowCancel(true);
        DialogProvider.text = text;
        DialogProvider._onOk = onOk;
        DialogProvider._onCancel = () => {};
        DialogProvider._setIsVisible(true);
        DialogProvider._isDialogVisible = true;
    }

    public static onOk() {
        DialogProvider._onOk();
        DialogProvider._setIsVisible(false);
        DialogProvider._isDialogVisible = false;
    }
    public static onCancel() {
        DialogProvider._onCancel();
        DialogProvider._setIsVisible(false);
        DialogProvider._isDialogVisible = false;
    }

    public static init(
        setIsVisible: (isVisible: boolean) => void,
        setShowCancel: (isVisible: boolean) => void
    ) {
        DialogProvider._setIsVisible = setIsVisible;
        DialogProvider._setShowCancel = setShowCancel;
    }
}
