import Logger from "@/utils/logger";
import CryptoES from "crypto-es";

export function encryptText(value: string, passphrase: string) {
    try {
        const encrypted = CryptoES.AES.encrypt(value, passphrase);
        return JSON.stringify(encrypted);
    } catch (error) {
        Logger.error(encryptText.name, error);
        return "";
    }
}

export function decryptText(encryptedValue: string, passphrase: string) {
    try {
        const json = JSON.parse(encryptedValue);
        // decrypt and UTF-8 decode
        return CryptoES.AES.decrypt(json, passphrase).toString(
            CryptoES.enc.Utf8
        );
    } catch (error) {
        Logger.error(decryptText.name, error);
        return "";
    }
}
