import WnaListCardWhiteDecent from "@components/cards/WnaListCardWhiteDecent";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import React, { Component } from "react";
import { Text, View } from "react-native";

export type WnaSettingsItemFrameProps = {
    appColors: Colors;
    appStyle: AppStyle;
    text: string;
    icon: string;
    children: React.ReactNode;
};

class WnaSettingsItemFrame extends Component<WnaSettingsItemFrameProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaSettingsItemFrameProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.text !== this.props.text
        );
    }

    render() {
        return (
            <View
                style={[
                    this.props.appStyle.containerCenterMaxWidth,
                    { borderRadius: 8, padding: 0 },
                ]}>
                <WnaListCardWhiteDecent appColors={this.props.appColors}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            gap: 16,
                            alignContent: "center",
                        }}>
                        <View
                            style={{
                                flex: 1,
                                gap: 16,
                            }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    gap: 16,
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <FontAwesome5
                                    name={this.props.icon}
                                    size={20}
                                    color={this.props.appColors.accent5}
                                />
                                <Text
                                    style={[
                                        this.props.appStyle.textTitleLarge,
                                        {
                                            color: this.props.appColors.black,
                                            alignSelf: "center",
                                        },
                                    ]}
                                    textBreakStrategy={"highQuality"}
                                    numberOfLines={2}
                                    ellipsizeMode={"tail"}>
                                    {this.props.text.replace(/\.[^.$]+$/, "")}
                                </Text>
                            </View>
                            {this.props.children}
                        </View>
                    </View>
                </WnaListCardWhiteDecent>
            </View>
        );
    }
}

export default WnaSettingsItemFrame;
