import ApiSettings from "@/api/apiSettings";
import WnaCatalogItem from "@/types/wnaCatalogItem";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaSettingsRadioButton from "@components/buttons/WnaSettingsRadioButton";
import WnaListCardWhiteDecent from "@components/cards/WnaListCardWhiteDecent";
import WnaQrCode from "@components/misc/WnaQrCode";
import WnaNavigationItem from "@components/navigation/WnaNavigationItem";
import WnaNavigationItemProfile from "@components/navigation/WnaNavigationItemProfile";
import WnaNavigationList, {
    WnaMenuItem,
} from "@components/navigation/WnaNavigationList";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaNotificationHeaderRight from "@components/navigation/WnaNotificationHeaderRight";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { WnaCatalogYesNo } from "@constants/catalogs/wnaCatalogYesNo";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { useRouter } from "expo-router";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform, View } from "react-native";

export default function Menu() {
    const {
        isAppInitialized,
        appSettings,
        appColors,
        appStyle,
        currentUser,
        unreadNotificationCount,
    } = useContext(WnaAppContext);
    const router = useRouter();
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [];

    // web fullscreen
    if (Platform.OS === "web") {
        // web
        items.push({
            route: "",
            text: t(i18nKeys.actionFullscreen),
            icon: "",
            rightIcon: "",
            type: "webToggleFullscreen",
            data: undefined,
        });
    }

    // user profile
    items.push({
        route: "",
        text: "",
        icon: "",
        rightIcon: "",
        type: "userEdit",
        data: undefined,
    });

    // paypal
    if ((appSettings?.paypalUrl ?? "") !== "")
        items.push({
            route: "",
            text: t(i18nKeys.actionDonate),
            icon: "paypal",
            rightIcon: "",
            type: "paypal",
            data: undefined,
        });

    // settings
    items.push({
        route: WnaNavigationRouteProvider.menuSettings,
        text: t(i18nKeys.screenTitleSettings),
        icon: "cog",
        rightIcon: "angle-right",
        type: "screen",
        data: undefined,
    });

    // disclaimer and privacy
    items.push(
        {
            route: WnaNavigationRouteProvider.menuPrivacy,
            text: t("common:screenTitlePrivacy"),
            icon: "balance-scale",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            route: WnaNavigationRouteProvider.menuDisclaimer,
            text: t("common:screenTitleDisclaimer"),
            icon: "info-circle",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            route: "",
            text: "",
            icon: "",
            rightIcon: "",
            type: "qr",
            data: undefined,
        }
    );

    const onSelectWebFullScreen = async (currentItem: WnaCatalogItem) => {
        if (currentItem.id === 1)
            await document.documentElement.requestFullscreen();
        else await document.exitFullscreen();
    };

    const openPayPalAsync = async () => {
        const url = appSettings?.paypalUrl ?? "";
        if (url === "") return;

        await Linking.openURL(url);
    };

    const handleClick = function (text: string) {
        const currentItem = items.find((x) => x.text === text)!;
        if (currentItem.route !== "") router.navigate(currentItem.route);
    };

    const renderItem = (currentItem: any) => {
        switch (currentItem.type) {
            case "webToggleFullscreen":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon="expand"
                        data={WnaCatalogYesNo}
                        selectedId={document.fullscreenElement ? 1 : 0}
                        isEditMode={true}
                        onSelect={onSelectWebFullScreen}
                    />
                );
            case "paypal":
                return (
                    <WnaNavigationItem
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        iconName={currentItem.icon}
                        onPress={openPayPalAsync}
                        t={t}
                    />
                );
            case "userEdit":
                return (
                    <WnaNavigationItemProfile
                        appStyle={appStyle}
                        appColors={appColors}
                        currentUser={currentUser}
                        logInTitle={t("common:actionLogIn")}
                        onPress={() => {
                            router.navigate(
                                currentUser
                                    ? WnaNavigationRouteProvider.menuProfile
                                    : WnaNavigationRouteProvider.menuProfileLogin
                            );
                        }}
                        t={t}
                    />
                );
            case "qr":
                return (
                    <WnaListCardWhiteDecent
                        style={[
                            appStyle.containerCenterMaxWidth,
                            {
                                backgroundColor: appColors.isDark
                                    ? appColors.staticCoolgray8
                                    : appColors.staticCoolgray6,
                            },
                        ]}
                        appColors={appColors}>
                        <View
                            style={[
                                {
                                    gap: 24,
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                },
                            ]}>
                            {/*TODO - OPTIONS!!!*/}
                            <WnaQrCode
                                url="https://app.fasten-mit-volker.de"
                                imageUrl={`${ApiSettings.apiBaseUrl}/resources/logo_pwa_96.png`}
                                size={256}
                                appColors={appColors}
                                t={t}
                            />

                            {/*TODO - OPTIONS!!!*/}
                            <WnaQrCode
                                url="https://play.google.com/store/apps/details?id=com.wna.fastentagebuch&pli=1"
                                imageUrl={`${ApiSettings.apiBaseUrl}/resources/logo_gp_96.png`}
                                size={256}
                                appColors={appColors}
                                t={t}
                            />
                        </View>
                    </WnaListCardWhiteDecent>
                );
            default:
                return (
                    <WnaNavigationItem
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        iconName={currentItem.icon}
                        onPress={handleClick}
                        t={t}
                    />
                );
        }
    };

    if (!isAppInitialized) return null;

    return (
        <WnaBaseScreen
            title={t(i18nKeys.screenTitleMenu)}
            headerButton0={
                <WnaNotificationHeaderRight
                    unreadNotificationCount={unreadNotificationCount}
                    appStyle={appStyle}
                    appColors={appColors}
                    currentUser={currentUser}
                    router={router}
                    t={t}
                />
            }>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreen>
    );
}
