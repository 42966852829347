import { initHealthCheckRequest } from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { apiTimeouts } from "@constants/apiTimeouts";

export async function healthcheckAsync() {
    const url = `${ApiSettings.apiBaseUrl}/healthcheck`;
    const request = initHealthCheckRequest();
    const result = await handleResponseAsync(url, request, apiTimeouts.short);
    return result instanceof Response;
}
