import getDiaryEntriesAsync from "@/api/diaryEntry/getDiaryEntries";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import { useRouter } from "expo-router";
import React, { useEffect, useRef, useState } from "react";

export type WnaDiaryEntryNavigatorProps = {
    userId?: string;
    chapterIdentifier: string;
    diaryEntryIdentifier: string;
    tab: number;
    order: number;
    navigateToPev: boolean;
    navigateToNext: boolean;
    refreshOnUpdate: boolean;
};

type navigatorState = {
    nextDiaryEntryIdentifier: string;
    prevDiaryEntryIdentifier: string;
};

export default function WnaDiaryEntryNavigator(
    props: WnaDiaryEntryNavigatorProps
) {
    const router = useRouter();

    const isLoadingRef = useRef(false);
    const [navigatorState, setNavigatorState] = useState<navigatorState>({
        prevDiaryEntryIdentifier: "",
        nextDiaryEntryIdentifier: "",
    } as navigatorState);

    const getUrl = (diaryEntryIdentifier: string) =>
        WnaNavigationRouteProvider.diaryEntry(
            props.chapterIdentifier,
            diaryEntryIdentifier,
            false,
            props.tab,
            props.order
        );

    const loadUrls = () => {
        if (
            isLoadingRef.current ||
            navigatorState.nextDiaryEntryIdentifier !== ""
        ) {
            return;
        }

        isLoadingRef.current = true;
        getDiaryEntriesAsync(
            props.userId ?? "",
            props.chapterIdentifier,
            true,
            props.order === 1
        ).then((diaryEntries) => {
            if (!diaryEntries) return;
            const currentIndex = diaryEntries.findIndex(
                (x) => x.identifier === props.diaryEntryIdentifier
            );
            if (currentIndex < 0) return;
            const nextIndex =
                currentIndex < diaryEntries.length - 1 ? currentIndex + 1 : 0;
            const nextIdentifier = diaryEntries[nextIndex].identifier;

            const prevIndex =
                currentIndex > 0 ? currentIndex - 1 : diaryEntries.length - 1;
            const prevIdentifier = diaryEntries[prevIndex].identifier;

            setNavigatorState({
                nextDiaryEntryIdentifier: nextIdentifier,
                prevDiaryEntryIdentifier: prevIdentifier,
            } as navigatorState);
        });
    };

    useEffect(() => {
        if (navigatorState.nextDiaryEntryIdentifier === "") loadUrls();
        const dismissToUrl = getUrl(props.diaryEntryIdentifier);

        if (
            props.navigateToNext &&
            navigatorState.nextDiaryEntryIdentifier !== ""
        ) {
            router.dismissTo(dismissToUrl);
            router.replace(getUrl(navigatorState.nextDiaryEntryIdentifier));
        }

        if (
            props.navigateToPev &&
            navigatorState.prevDiaryEntryIdentifier !== ""
        ) {
            router.dismissTo(dismissToUrl);
            router.replace(getUrl(navigatorState.prevDiaryEntryIdentifier));
        }

        if (props.refreshOnUpdate) {
            router.dismissTo(dismissToUrl);
            router.replace(getUrl(props.diaryEntryIdentifier));
        }

        // eslint-disable-next-line
    }, [props, navigatorState]);
    return <></>;
}
