import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaGenerateHtmlResponse from "@/types/wnaGenerateHtmlResponse";
import Logger from "@/utils/logger";

const defaultResp = {
    html: "",
    length: 0,
    startIndex: 0,
    endIndex: 0,
} as WnaGenerateHtmlResponse;

export default async function getHtmlPaginatedAsync(
    userId: string | undefined,
    chapterIdentifier: string | undefined,
    startIndex: number,
    length: number
) {
    if (!isUserId(userId) || !isDateTimeIdentifier(chapterIdentifier))
        return defaultResp;

    try {
        const route = `users/${userId}/chapters/${chapterIdentifier}/html-paginated?startIndex=${startIndex ?? 0}&length=${length ?? 1}&prepareForPdf=${false}`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const result = await handleResponseAsync(url, request, 60000);
        if (result instanceof Response) {
            return (await result.json()) as WnaGenerateHtmlResponse;
        } else {
            Logger.error(getHtmlPaginatedAsync.name, "could not get html.");
            return defaultResp;
        }
    } catch (e) {
        Logger.error(getHtmlPaginatedAsync.name, e);
        return defaultResp;
    }
}
