import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import WnaText from "@components/text/WnaText";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { Component } from "react";
import { Platform, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

export type WnaDiaryEntryDataViewReadOnlyProps = {
    appColors: Colors;
    appStyle: AppStyle;
    diaryEntry: WnaDiaryEntry;
};

class WnaDiaryEntryDataViewReadOnly extends Component<WnaDiaryEntryDataViewReadOnlyProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDiaryEntryDataViewReadOnlyProps>
    ): boolean {
        return (
            nextProps.diaryEntry.designator !==
                this.props.diaryEntry.designator ||
            nextProps.diaryEntry.comment !== this.props.diaryEntry.comment ||
            nextProps.appColors.isDark !== this.props.appColors.isDark
        );
    }

    render() {
        return (
            <ScrollView
                style={[
                    this.props.appStyle.containerFillPage,
                    { padding: 16 },
                ]}>
                <View
                    style={[
                        this.props.appStyle.containerCenterMaxWidth,
                        {
                            backgroundColor: this.props.appColors.white,
                            gap: 16,
                            padding: 0,
                            // borderRadius: 8,
                            overflow: "hidden",
                        },
                    ]}>
                    <View style={{ gap: 16, padding: 16 }}>
                        {/*designator*/}
                        <Text
                            style={[this.props.appStyle.textTitleLarge]}
                            textBreakStrategy={"highQuality"}>
                            {this.props.diaryEntry.designator !== ""
                                ? this.props.diaryEntry.designator
                                : "Ohne Titel"}
                        </Text>

                        {/*comment*/}
                        <WnaText
                            appColors={this.props.appColors}
                            appStyle={this.props.appStyle}
                            style={[
                                this.props.appStyle.textSmall,
                                {
                                    // height: "100%"
                                    height: 512,
                                },
                            ]}
                            textBreakStrategy={"highQuality"}
                            text={
                                this.props.diaryEntry.comment !== ""
                                    ? this.props.diaryEntry.comment
                                    : "Ohne Inhalt"
                            }
                            showHtml
                            fontSize={16}
                            fontFamily={FontFamilies.Courgette}
                            fontColor={this.props.appColors.black}
                        />
                    </View>
                </View>
                {Platform.OS !== "web" ? ( // needed cause padding / margin not working for android
                    <View style={{ height: 32 }} />
                ) : null}
            </ScrollView>
        );
    }
}

export default WnaDiaryEntryDataViewReadOnly;
