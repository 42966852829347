import ApiSettings from "@/api/apiSettings";
import getResourceAsTextAsync from "@/api/resources/getResourceAsText";
import WnaAppointment from "@/types/entities/wnaAppointment";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import Logger from "@/utils/logger";
import WnaButtonIconText from "@components/buttons/WnaButtonIconText";
import WnaImage from "@components/images/WnaImage";
import WnaHtmlRenderer from "@components/misc/WnaHtmlRenderer";
import WnaText from "@components/text/WnaText";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { getLangCode } from "@services/i18n/i18n";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { Linking, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

export type AppointmentReadOnlyProps = {
    appColors: Colors;
    appStyle: AppStyle;
    appSettings: WnaAppSettings;
    currentItem?: WnaAppointment;
    t: TFunction<string[], undefined>;
};

const _btnWidth = 256;

class WnaAppointmentReadOnly extends Component<AppointmentReadOnlyProps> {
    state = {
        html: "",
    };

    shouldComponentUpdate(
        nextProps: Readonly<AppointmentReadOnlyProps>
    ): boolean {
        return (
            nextProps.currentItem !== this.props.currentItem ||
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            this.state.html === ""
        );
    }

    render() {
        if (this.state.html === "") {
            const document =
                getLangCode() === "en"
                    ? "appointment-en.html"
                    : "appointment-de.html";
            getResourceAsTextAsync(document).then((html) =>
                this.setState({ html: html })
            );
        }
        return this.state.html === "" ? (
            <></>
        ) : (
            <ScrollView
                style={[
                    this.props.appStyle.containerFillPage,
                    { paddingHorizontal: 16 },
                ]}>
                <View
                    style={[
                        this.props.appStyle.containerCenterMaxWidth,
                        {
                            backgroundColor: this.props.appColors.white,
                            padding: 16,
                            gap: 16,
                            margin: 16,
                        },
                    ]}>
                    <WnaImage
                        appColors={this.props.appColors}
                        imageUrl={`${ApiSettings.apiBaseUrl}/resources/${this.props.appSettings.appointmentConfig.imageUrl}`}
                        style={{
                            height: 240,
                            maxHeight: 240,
                            backgroundColor: this.props.appColors.coolgray2,
                        }}
                    />

                    {this.props.currentItem?.comment !== "" ? (
                        <WnaText
                            appStyle={this.props.appStyle}
                            appColors={this.props.appColors}
                            text={this.props.currentItem?.comment}
                            fontColor={this.props.appColors.black}
                            fontSize={16}
                        />
                    ) : null}

                    <View>
                        <WnaHtmlRenderer
                            appStyle={this.props.appStyle}
                            appColors={this.props.appColors}
                            width={480}
                            html={this.state.html}
                        />
                    </View>

                    <View
                        style={{
                            gap: 24,
                            alignSelf: "center",
                            flex: 1,
                            alignItems: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}>
                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            appColors={this.props.appColors}
                            iconName={"phone"}
                            text={this.props.t(i18nKeys.actionPhoneCall)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .tel;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            Logger.error(
                                                WnaAppointmentReadOnly.name,
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        Logger.error("phone", error)
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />

                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            appColors={this.props.appColors}
                            iconName={"envelope"}
                            text={this.props.t(i18nKeys.actionEmail)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .email;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            Logger.error(
                                                WnaAppointmentReadOnly.name,
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        Logger.error("email", error)
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />

                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            appColors={this.props.appColors}
                            iconName={"map"}
                            text={this.props.t(i18nKeys.actionRoute)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .address;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            Logger.error(
                                                WnaAppointmentReadOnly.name,
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        Logger.error("address", error)
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />

                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            appColors={this.props.appColors}
                            iconName={"info-circle"}
                            text={this.props.t(i18nKeys.actionInfo)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .url;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            Logger.error(
                                                WnaAppointmentReadOnly.name,
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        Logger.error("info", error)
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />
                    </View>
                </View>
            </ScrollView>
        );
    }
}

export default WnaAppointmentReadOnly;
