import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import { WnaDiaryEntryPlaceholders } from "@/types/wnaDiaryEntryPlaceholder";
import WnaDiaryEntryDataViewEdit from "@components/diaryEntry/WnaDiaryEntryDataViewEdit";
import WnaDiaryEntryDataViewReadOnly from "@components/diaryEntry/WnaDiaryEntryDataViewReadOnly";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import React, { Component } from "react";

export type WnaDiaryEntryDataViewProps = {
    appColors: Colors;
    appStyle: AppStyle;
    diaryEntry: WnaDiaryEntry;
    onUpdate: (dateStart: Date, designator: string, comment: string) => void;
    onCommentTyping?: (isTyping: boolean) => void;
    isEditMode: boolean;
    placeholders: WnaDiaryEntryPlaceholders;
};

class WnaDiaryEntryDataView extends Component<WnaDiaryEntryDataViewProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDiaryEntryDataViewProps>
    ): boolean {
        return (
            nextProps.diaryEntry.designator !==
                this.props.diaryEntry.designator ||
            nextProps.diaryEntry.identifier !==
                this.props.diaryEntry.identifier ||
            nextProps.diaryEntry.comment !== this.props.diaryEntry.comment ||
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.isEditMode !== this.props.isEditMode
        );
    }

    render() {
        return this.props.isEditMode ? (
            <WnaDiaryEntryDataViewEdit
                diaryEntry={this.props.diaryEntry}
                onUpdate={this.props.onUpdate}
                onCommentTyping={this.props.onCommentTyping}
                appStyle={this.props.appStyle}
                appColors={this.props.appColors}
                placeholders={this.props.placeholders}
            />
        ) : (
            <WnaDiaryEntryDataViewReadOnly
                diaryEntry={this.props.diaryEntry}
                appStyle={this.props.appStyle}
                appColors={this.props.appColors}
            />
        );
    }
}

export default WnaDiaryEntryDataView;
