import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import Colors from "@constants/theme/colors";
import { Component } from "react";
import { Text } from "react-native";
import { SceneRendererProps, TabBar } from "react-native-tab-view";
export type WnaDiaryEntryTabBarProps = {
    appColors: Colors;
    userSettings: WnaUserSettings;
    sceneRendererProps: SceneRendererProps;
};

export default class WnaDiaryEntryTabBar extends Component<WnaDiaryEntryTabBarProps> {
    render() {
        return (
            <TabBar
                {...this.props.sceneRendererProps}
                activeColor={this.props.appColors.accent4}
                inactiveColor={this.props.appColors.coolgray4}
                indicatorStyle={{
                    backgroundColor: this.props.appColors.accent4,
                    top:
                        (this.props.userSettings?.tabBarPosition ?? 0) === 1
                            ? 0
                            : 40,
                    height: 8,
                }}
                style={{ backgroundColor: this.props.appColors.white }}
                // @ts-ignore
                renderLabel={({ route, focused, color }) => (
                    <Text
                        style={{
                            color,
                            width: 96,
                            textAlign: "center",
                            fontWeight: focused ? "bold" : "normal",
                            textTransform: "uppercase",
                            fontSize: focused ? 16 : 12,
                        }}>
                        {route.title}
                    </Text>
                )}
            />
        );
    }
}
