import { convertHexToRgba } from "@/utils/colorConverter";
import currentAppVersion from "@app/currentAppVersion";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonText from "@components/buttons/WnaButtonText";
import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import WnaMultilineHeader from "@components/screens/WnaMultilineHeader";
import { animationSpeed } from "@constants/animationSpeed";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { EventArg, NavigationAction } from "@react-navigation/core";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { LinearGradient } from "expo-linear-gradient";
import { Stack, useFocusEffect, useNavigation, useRouter } from "expo-router";
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from "react-native-reanimated";
import Toast from "react-native-toast-message";
import WnaImageBackground from "../images/WnaImageBackground";

export type WnaBaseScreenWithInfoProps = {
    children?: React.ReactNode;
    isBusy?: boolean;
    isBusyText?: string | null;
    backgroundImageUrl?: string;
    onCancel?: () => void;
    preventBack?: boolean;
    title?: string;
    headerButton0?: React.ReactNode;
    headerButton1?: React.ReactNode;
    headerButton2?: React.ReactNode;
};
const WnaBaseScreen: React.FC<WnaBaseScreenWithInfoProps> = (props) => {
    const { t } = useTranslation(["common"]);
    const navigation = useNavigation();
    const router = useRouter();
    const {
        isAppInitialized,
        appColors,
        appStyle,
        isInternetReachable,
        darkAppBackgroundImage,
        lightAppBackgroundImage,
    } = useContext(WnaAppContext);
    const [currentInfoColor, setCurrentInfoColor] = useState(appColors.accent6);

    const isBusyOpacity = useSharedValue(0);
    const isLazyOpacity = useSharedValue(0);
    const isBusyAnimationConfig = {
        duration: animationSpeed,
        easing: Easing.bezier(0.5, 0.01, 0, 1),
    };

    const isBusyStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(isBusyOpacity.value, isBusyAnimationConfig),
        };
    });

    const isLazyStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(isLazyOpacity.value, isBusyAnimationConfig),
        };
    });

    const currentTitleRef = useRef<string>("...");

    const onCancel = () => {
        if (props.onCancel !== undefined) props.onCancel();
    };

    const setDocumentTitle = () => {
        if (Platform.OS === "web")
            window.document.title = currentTitleRef.current;
    };

    useEffect(() => {
        isBusyOpacity.value = props.isBusy ? 1 : 0;
        isLazyOpacity.value = props.isBusy ? 0 : 1;

        currentTitleRef.current = props?.title ?? "...";
        setDocumentTitle();
        if (isInternetReachable) {
            setCurrentInfoColor(appColors.accent6);
        } else {
            setCurrentInfoColor(appColors.red4);
            Toast.show({
                type: "error",
                text1: t(i18nKeys.errorNoInternet),
            });
        }

        const beforeRemoveListener = (
            e: EventArg<"beforeRemove", true, { action: NavigationAction }>
        ) => {
            e.preventDefault();
            if (props.preventBack === true) {
                Toast.show({
                    text1: t(i18nKeys.infoPleaseWait),
                    type: "info",
                });
                return;
            }

            navigation.dispatch(e.data.action);
        };

        navigation.addListener("beforeRemove", beforeRemoveListener);

        return () => {
            navigation.removeListener("beforeRemove", beforeRemoveListener);
        };

        // eslint-disable-next-line
    }, [isInternetReachable, props.title, props.isBusy]);

    useFocusEffect(useCallback(setDocumentTitle, []));

    const needPositionRightCorrection =
        Platform.OS !== "web" &&
        navigation.getState()?.type !== "tab" &&
        navigation.canGoBack();

    if (!isAppInitialized || !props) return null;

    return (
        <>
            <Stack.Screen
                options={{
                    contentStyle: {
                        backgroundColor: appColors.isDark
                            ? appColors.staticCoolgray8
                            : appColors.staticCoolgray1,
                        overflowX: "hidden",
                        overflowY: "hidden",
                        maxWidth: "100%",
                        maxHeight: "100%",
                    },
                    headerTitle: () => {
                        return WnaMultilineHeader(
                            appColors,
                            appStyle,
                            navigation,
                            router,
                            currentTitleRef.current,
                            props.isBusy
                        );
                    },
                    headerStyle: {
                        backgroundColor: appColors.isDark
                            ? appColors.accent6
                            : appColors.accent5,
                    },
                    headerTitleStyle: {
                        fontFamily: FontFamilies.Courgette,
                        fontSize: 20,
                    },
                    headerTintColor: appColors.staticWhite,
                    headerShadowVisible: false,
                    headerRight: () => (
                        <LinearGradient
                            start={[0, 0.5]}
                            end={[1, 0.5]}
                            locations={[0, 0.1, 0.1]}
                            colors={[
                                "transparent",
                                appColors.isDark
                                    ? appColors.accent6
                                    : appColors.accent5,
                                appColors.isDark
                                    ? appColors.accent6
                                    : appColors.accent5,
                            ]}
                            style={[
                                {
                                    paddingLeft: 16,
                                    margin: 0,
                                    position: "absolute",
                                    right: needPositionRightCorrection
                                        ? -16
                                        : 0,
                                },
                            ]}>
                            <Animated.View
                                style={[
                                    isLazyStyle,
                                    {
                                        flexDirection: "row",
                                    },
                                ]}
                                pointerEvents={props.isBusy ? "none" : "auto"}>
                                <View style={{ alignItems: "center" }}>
                                    {props.headerButton0}
                                </View>
                                <View style={{ alignItems: "center" }}>
                                    {props.headerButton1}
                                </View>
                                <View style={{ alignItems: "center" }}>
                                    {props.headerButton2}
                                </View>
                            </Animated.View>
                        </LinearGradient>
                    ),
                }}
            />
            <WnaImageBackground
                imageUri={
                    props.backgroundImageUrl && props.backgroundImageUrl !== ""
                        ? props.backgroundImageUrl
                        : appColors.isDark
                          ? darkAppBackgroundImage
                          : lightAppBackgroundImage
                }
                blurRadius={
                    props.backgroundImageUrl && props.backgroundImageUrl !== ""
                        ? 16
                        : undefined
                }>
                <View
                    style={{
                        alignContent: "stretch",
                        flex: 1,
                        overflow: "hidden",
                        backgroundColor:
                            props.backgroundImageUrl &&
                            props.backgroundImageUrl !== ""
                                ? convertHexToRgba(appColors.white, 0.7)
                                : "transparent",
                    }}>
                    <>
                        {props.children}
                        <Animated.View
                            style={[
                                isBusyStyle,
                                {
                                    flex: 1,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 16,
                                    pointerEvents: props.isBusy
                                        ? "auto"
                                        : "none",
                                    backgroundColor: convertHexToRgba(
                                        appColors.white,
                                        0.7
                                    ),
                                    overflow: "hidden",
                                },
                            ]}>
                            <WnaActivityIndicator appColors={appColors} />
                            {props.isBusyText !== null &&
                            props.isBusyText !== "" ? (
                                <Text
                                    style={[
                                        appStyle.textTitleLarge,
                                        { margin: 16, textAlign: "center" },
                                    ]}>
                                    {props.isBusyText}
                                </Text>
                            ) : null}
                            {props.onCancel !== undefined ? (
                                <WnaButtonText
                                    appColors={appColors}
                                    appStyle={appStyle}
                                    text={t(i18nKeys.actionCancel)}
                                    onPress={onCancel}
                                    checkInternetConnection={false}
                                    t={t}
                                />
                            ) : null}
                        </Animated.View>
                    </>
                </View>
                <LinearGradient
                    start={[0, 1]}
                    end={[1, 1]}
                    colors={[appColors.white, currentInfoColor]}
                    pointerEvents="none"
                    style={[
                        {
                            paddingVertical: 2,
                            paddingHorizontal: 16,
                            alignItems: "flex-end",
                        },
                    ]}>
                    <Text
                        style={{
                            fontSize: 8,
                            color: appColors.staticWhite,
                        }}>
                        {isInternetReachable
                            ? ""
                            : `${t(i18nKeys.errorNoInternet).toUpperCase()} | `}
                        APP VERSION {currentAppVersion() ?? ""}
                    </Text>
                </LinearGradient>
            </WnaImageBackground>
        </>
    );
};
export default WnaBaseScreen;
