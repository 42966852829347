import getResourceAsTextAsync from "@/api/resources/getResourceAsText";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaWebView from "@components/misc/WnaWebView";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { getLangCode } from "@services/i18n/i18n";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function AccommodationScreen() {
    const { appColors } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const [isBusy, setIsBusy] = useState(true);
    const [html, setHtml] = useState("");

    const style = `
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Courgette"
    />
    <link rel="stylesheet" 
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/solid.min.css" 
        integrity="sha512-Hp+WwK4QdKZk9/W0ViDvLunYjFrGJmNDt6sCflZNkjgvNq9mY+0tMbd6tWMiAlcf1OQyqL4gn2rYp7UsfssZPA==" 
        crossorigin="anonymous" 
        referrerpolicy="no-referrer" /> 
    <style>
      h1{
        font-family: Courgette, sans-serif;
        font-size: x-large;    
        text-align: center;  
      }
      h2 {
        text-align: center;
        font-size: large;    
        text-transform: uppercase;
      }
      a {
        color: ${appColors.black};
      }
      .wrapper{
        margin: 0.5em;
      }
      .block {
        font-family: "Roboto", "Arial", sans-serif;
        font-size: 10pt;
        line-height: 16pt;
        color: ${appColors.coolgray6};
        max-width: 960px;
        background-color: ${appColors.white};
        padding: 1em;
        margin: auto;
        margin-top: 1em !important;
        margin-bottom: 1em !important;
      }
    </style>`;

    useEffect(() => {
        const document =
            getLangCode() === "en"
                ? "accommodation-en.html"
                : "accommodation-de.html";

        getResourceAsTextAsync(document).then((html) => {
            const styledUtf8decoded = html.replace("$STYLE", style);
            setHtml(styledUtf8decoded);
            setIsBusy(false);
        });

        // eslint-disable-next-line
    }, []);
    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={t(i18nKeys.screenTitleAccommodationDetails)}>
            <WnaWebView urlOrHtml={html} />
        </WnaBaseScreen>
    );
}
