import { convertHexToRgba } from "@/utils/colorConverter";
import Colors from "@constants/theme/colors";
import React, { Component } from "react";
import { View } from "react-native";

export interface IWnaListCardWhiteProps {
    appColors: Colors;
    children?: React.ReactNode;
    color?: string | null;
}

class WnaListCardWhite extends Component<IWnaListCardWhiteProps> {
    shouldComponentUpdate(
        nextProps: Readonly<IWnaListCardWhiteProps>
    ): boolean {
        return (
            nextProps.color !== this.props.color ||
            nextProps.appColors !== this.props.appColors ||
            nextProps.children !== this.props.children
        );
    }

    render() {
        let color = this.props.color ?? "transparent";
        if (color !== "transparent" && this.props.appColors.isDark)
            color = convertHexToRgba(color, 0.7);
        return this.props.children === null ? null : (
            <View
                style={[
                    {
                        backgroundColor: this.props.appColors.white,
                        borderRadius: 8,
                        borderColor: this.props.appColors.coolgray2,
                        borderWidth: 1,
                        overflow: "hidden",
                    },
                ]}>
                <View style={{ flex: 1, flexDirection: "row" }}>
                    <View style={{ width: 8, backgroundColor: color }} />
                    <View
                        style={{
                            paddingVertical: 24,
                            paddingLeft: 16,
                            paddingRight: 24,
                            width: "100%",
                        }}>
                        {this.props.children}
                    </View>
                </View>
            </View>
        );
    }
}

export default WnaListCardWhite;
