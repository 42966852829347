import Resizer from "react-image-file-resizer";

/**
 * ONLY WEB
 * @param pathOrFileBlob needs to be BLOB in WEB and FILEPATH in NATIVE
 * @param maxWidth
 * @param maxHeight
 * @param quality
 * @returns
 */
const resizeImageInternalAsync = (
    pathOrFileBlob: string | Blob,
    maxWidth: number,
    maxHeight: number,
    quality: number
) =>
    new Promise<Blob | undefined>((resolve) => {
        if (typeof pathOrFileBlob === "string") {
            resolve(undefined);
            return;
        }

        // https://javascript.plainenglish.io/compression-images-before-upload-in-a-react-app-with-react-image-file-resizer-c1870c9bcf47
        // https://github.com/onurzorluer/react-image-file-resizer
        Resizer.imageFileResizer(
            pathOrFileBlob,
            maxWidth,
            maxHeight,
            "JPEG",
            quality,
            0,
            (result) => {
                resolve(result as Blob);
            },
            "blob",
            256,
            256
        );
    });

const resizeImageAsync = async (
    pathOrFileBlob: string | Blob,
    maxWidth: number,
    maxHeight: number,
    quality: number
): Promise<Blob | undefined> => {
    return await resizeImageInternalAsync(
        pathOrFileBlob,
        maxWidth,
        maxHeight,
        quality
    );
};

export { resizeImageAsync };
