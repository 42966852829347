import sendNotificationToAllUsersAsync from "@/api/notification/sendNotificationToAllUsers";
import WnaNotification from "@/types/entities/wnaNotification";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useRouter } from "expo-router";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, View } from "react-native";
import Toast from "react-native-toast-message";

export default function CreateNotificationScreen() {
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const { isAppInitialized, currentAdminUser, appColors, appStyle } =
        useContext(WnaAppContext);

    const [currentDesignator, setCurrentDesignator] = useState("");
    const currentDesignatorRef = useRef("");
    const [currentComment, setCurrentComment] = useState("");
    const currentCommentRef = useRef("");
    const [isBusy, setIsBusy] = useState(false);

    const designatorInputRef = useRef<TextInput>(null);
    const commentInputRef = useRef<TextInput>(null);

    const send = () => {
        if (
            currentDesignatorRef.current.length < 1 ||
            currentCommentRef.current.length < 1
        ) {
            Toast.show({
                text1: t(i18nKeys.errorInputInvalid),
                type: "error",
            });
            return;
        }

        setIsBusy(true);
        const notification = {
            designator: currentDesignatorRef.current,
            comment: currentCommentRef.current,
        } as WnaNotification;

        sendNotificationToAllUsersAsync(notification).then((notification) => {
            if (notification.designator) {
                // success
                Toast.show({
                    text1: t(i18nKeys.successSend),
                    type: "success",
                });
                WnaNavigationRouteProvider.navigateToRoot(router);
            } else {
                Toast.show({
                    text1: t(i18nKeys.errorSend),
                    type: "error",
                });
            }
            setIsBusy(false);
        });
    };

    if (!isAppInitialized || !currentAdminUser)
        return <Redirect href={WnaNavigationRouteProvider.root} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            isBusyText={t(i18nKeys.infoPleaseWait)}
            title={t(i18nKeys.actionCreateNotification)}
            headerButton0={
                <WnaButtonHeader
                    appColors={appColors}
                    appStyle={appStyle}
                    iconName={"check"}
                    text={t(i18nKeys.actionSend)}
                    onPress={send}
                    checkInternetConnection={true}
                    t={t}
                />
            }>
            <View
                style={[appStyle.containerFillPage, { gap: 16, padding: 16 }]}>
                <View style={{ alignSelf: "stretch" }}>
                    <TextInput
                        ref={designatorInputRef}
                        style={[
                            appStyle.textInput,
                            {
                                width: "100%",
                                backgroundColor: appColors.white,
                                color: appColors.black,
                            },
                        ]}
                        selectionColor={appColors.accent4}
                        value={currentDesignator}
                        onChangeText={(text) => {
                            currentDesignatorRef.current = text;
                            setCurrentDesignator(text);
                        }}
                        onSubmitEditing={() => {
                            commentInputRef.current?.focus();
                        }}
                        placeholder="Titel"
                        placeholderTextColor={appColors.coolgray3}
                    />
                </View>
                <View style={{ flex: 1, alignSelf: "stretch" }}>
                    <TextInput
                        ref={commentInputRef}
                        style={[
                            appStyle.textInput,
                            {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                width: "auto",
                                verticalAlign: "top",
                                color: appColors.black,
                            },
                        ]}
                        selectionColor={appColors.accent1}
                        value={currentComment}
                        onChangeText={(text) => {
                            currentCommentRef.current = text;
                            setCurrentComment(text);
                        }}
                        placeholder="Text"
                        multiline
                        placeholderTextColor={appColors.coolgray3}
                        maxLength={1024}
                    />
                </View>
            </View>
        </WnaBaseScreen>
    );
}
