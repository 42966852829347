import Logger from "@/utils/logger";
import { decryptText, encryptText } from "@services/wnaCryptoProvider";
import {
    getStringFromStorageAsync,
    setStringToStorageAsync,
} from "@services/WnaDataStorageProvider/wnaAsyncStorageProvider";
import getCleanKeyName from "@services/WnaDataStorageProvider/wnaDataStorageKeyProvider";
import { Mutex } from "async-mutex";
import { Platform } from "react-native";
import { WnaIdbProvider } from "./wnaIdbProvider";

const mutex = new Mutex();

const getFromDbAsync = async (decrypt: boolean, cKey: string) => {
    let cItem = "";

    const encItem = ((await WnaIdbProvider.getItemAsync(cKey)) as string) ?? "";
    if (encItem === undefined || encItem === "") return "";

    if (decrypt) cItem = decryptText(encItem, cKey + cKey.length);
    else cItem = encItem;

    return cItem;
};

const getFromLocalStorageAsync = async (decrypt: boolean, cKey: string) => {
    let cItem = "";
    const encItem = (await getStringFromStorageAsync(cKey)) ?? "";

    if (encItem === undefined || encItem === "") return "";

    if (decrypt) cItem = decryptText(encItem, cKey + cKey.length);
    else cItem = encItem;

    return cItem;
};

// https://docs.expo.dev/versions/latest/sdk/securestore/
export default class WnaDataStorageProvider {
    public static async getItemAsync(
        key: string,
        decrypt: boolean,
        useDb: boolean
    ): Promise<string> {
        try {
            const cKey = getCleanKeyName(key);
            let cItem: string;

            if (useDb) cItem = await getFromDbAsync(decrypt, cKey);
            else cItem = await getFromLocalStorageAsync(decrypt, cKey);

            return cItem;
        } catch (error) {
            Logger.error(WnaDataStorageProvider.getItemAsync.name, error);
            return "";
        }
    }

    public static async setItemAsync(
        key: string,
        item: string,
        encrypt: boolean,
        useDb: boolean
    ): Promise<void> {
        return await mutex.runExclusive(async () => {
            try {
                const cKey = getCleanKeyName(key);
                const cItem = item;
                if (Platform.OS === "web" && encrypt && useDb) {
                    const encItem = encryptText(cItem, cKey + cKey.length);
                    await WnaIdbProvider.setItemAsync(cKey, encItem);
                } else if (Platform.OS === "web" && !encrypt && useDb) {
                    await WnaIdbProvider.setItemAsync(cKey, cItem);
                } else if (encrypt) {
                    const encItem = encryptText(cItem, cKey + cKey.length);
                    await setStringToStorageAsync(cKey, encItem);
                } else {
                    await setStringToStorageAsync(cKey, cItem);
                }
            } catch (error) {
                Logger.error(WnaDataStorageProvider.setItemAsync.name, error);
            }
        });
    }
}
