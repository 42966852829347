import WnaCatalogItem from "@/types/wnaCatalogItem";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { StaticColors } from "../theme/staticColors";

export const WnaCatalogMapType: WnaCatalogItem[] = [
    {
        id: 0,
        text: i18nKeys.catalogMapTypeSatellite,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.catalogMapTypeHybrid,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: i18nKeys.catalogMapTypeRoadmap,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 3,
        text: i18nKeys.catalogMapTypeTerrain,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
];
