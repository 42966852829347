import WnaCatalogItem from "@/types/wnaCatalogItem";
import { StaticColors } from "../theme/staticColors";

export const WnaCatalogGraphTolerance: WnaCatalogItem[] = [
    {
        id: 0,
        text: "0 m",
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: "2 m",
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: "5 m",
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 3,
        text: "10 m",
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
];
