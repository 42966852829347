import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { Component, LegacyRef } from "react";
import { LineChart } from "react-native-chart-kit";
import { ScrollView } from "react-native-gesture-handler";
import Svg, { Text as TextSVG } from "react-native-svg";

export type WnaMapsGraphProps = {
    appColors: Colors;
    appStyle: AppStyle;
    width: number;
    graphHeight: number;
    graphWidth: number;
    xAxisData: any[];
    yAxisData: any[];
    yAxisMin: number;
    yAxisMax: number;
    svgRef?: LegacyRef<Svg>;
    chartRef?: LegacyRef<LineChart>;
};

class WnaMapsGraph extends Component<WnaMapsGraphProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaMapsGraphProps>): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.width !== this.props.width ||
            nextProps.graphWidth !== this.props.graphWidth ||
            nextProps.graphHeight !== this.props.graphHeight ||
            nextProps.xAxisData !== this.props.xAxisData ||
            nextProps.yAxisData !== this.props.yAxisData ||
            nextProps.yAxisMin !== this.props.yAxisMin ||
            nextProps.yAxisMax !== this.props.yAxisMax
        );
    }

    render() {
        const graphScale = 1;

        // const [tooltipPos, setTooltipPos] = useState({
        //     x: 0,
        //     y: 0,
        //     visible: false,
        //     value: 0,
        // });

        return (
            <ScrollView
                horizontal
                style={{
                    height: this.props.graphHeight,
                    width: this.props.width,
                }}>
                {/* <Svg ref={this.props.svgRef}
                    height={this.props.graphHeight}
                    width={this.props.graphWidth}
                    viewBox="0 0 100 100"
                    scale={1}
                > */}
                <LineChart
                    ref={this.props.chartRef}
                    key={"altitudeChart"}
                    data={{
                        labels: this.props.xAxisData,
                        datasets: [
                            {
                                data: this.props.yAxisData,
                                color: (_ = 1) => this.props.appColors.accent5,
                                strokeWidth: 2,
                                withDots: false,
                                withScrollableDot: false,
                                key: "altitudeChartRouteData",
                            },
                            {
                                data: [this.props.yAxisMin],
                                strokeWidth: 0,
                                withDots: false,
                                withScrollableDot: false,
                                key: "altitudeChartMinData",
                            },
                            {
                                data: [this.props.yAxisMax],
                                strokeWidth: 0,
                                withDots: false,
                                withScrollableDot: false,
                                key: "altitudeChartMaxData",
                            },
                        ],
                    }}
                    withVerticalLabels={true}
                    withVerticalLines={true}
                    height={this.props.graphHeight}
                    width={this.props.graphWidth}
                    yAxisSuffix={" m"}
                    yAxisInterval={200}
                    segments={(this.props.yAxisMax - this.props.yAxisMin) / 200}
                    formatYLabel={(val) => {
                        return val.padStart(5, " ");
                    }}
                    withOuterLines={true}
                    bezier={true}
                    chartConfig={{
                        backgroundGradientFrom: this.props.appColors.white,
                        backgroundGradientFromOpacity: 1,
                        backgroundGradientTo: this.props.appColors.white,
                        backgroundGradientToOpacity: 1,
                        fillShadowGradientFrom: this.props.appColors.accent4,
                        fillShadowGradientFromOpacity: 0.5,
                        fillShadowGradientTo: this.props.appColors.accent4,
                        fillShadowGradientToOpacity: 0.5,
                        useShadowColorFromDataset: true,
                        strokeWidth: 0,
                        decimalPlaces: 0,

                        color: (_ = 255) => this.props.appColors.accent5,
                        style: {
                            borderRadius: 0,
                            marginHorizontal: 0,
                        },
                        propsForVerticalLabels: {
                            fontSize: 10,
                        },
                        propsForLabels: {
                            fontFamily: FontFamilies.MonoSpace,
                        },
                        propsForDots: {
                            r: 1,
                            //strokeWidth: 1,
                            //stroke: appColors.white,
                        },
                        propsForBackgroundLines: {
                            //color:  appColors.coolgray4
                        },

                        linejoinType: "round",
                        labelColor: (_ = 255) => this.props.appColors.coolgray6,
                    }}
                    // decorator={() => {
                    //     return tooltipPos.visible ? <View>
                    //         <Svg>
                    //             <Rect x={tooltipPos.x - 15}
                    //                 y={tooltipPos.y + 10}
                    //                 width="40"
                    //                 height="30"
                    //                 fill="black" />
                    //             <TextSVG
                    //                 x={tooltipPos.x + 5}
                    //                 y={tooltipPos.y + 30}
                    //                 fill="white"
                    //                 fontSize="16"
                    //                 fontWeight="bold"
                    //                 textAnchor="middle">
                    //                 {tooltipPos.value}
                    //             </TextSVG>
                    //         </Svg>
                    //     </View> : null
                    // }}

                    // onDataPointClick={(data) => {
                    //     let isSamePoint = (tooltipPos.x === data.x
                    //         && tooltipPos.y === data.y)

                    //     isSamePoint
                    //         ? setTooltipPos((previousState) => {
                    //             return {
                    //                 ...previousState,
                    //                 value: data.value,
                    //                 visible: !previousState.visible
                    //             }
                    //         })
                    //         : setTooltipPos({ x: data.x, value: data.value, y: data.y, visible: true });
                    // }}

                    // GEHT START
                    renderDotContent={({ x, y, index }) => {
                        if (index % 20 > 0) return null;

                        return (
                            <TextSVG
                                key={index}
                                x={x}
                                y={y - 24}
                                // rotate={90}
                                // rotation={90}
                                fill={this.props.appColors.coolgray6}
                                fontSize={8 * (graphScale * 0.75)}
                                fontFamily={FontFamilies.MonoSpace}
                                // fontWeight="normal"
                                textAnchor="middle">
                                {this.props.yAxisData[index]}
                            </TextSVG>
                        );
                    }}
                    // GEHT END
                />
                {/* </Svg> */}
            </ScrollView>
        );
    }
}

export default WnaMapsGraph;
