import WnaFileInfo from "@/types/entities/wnaFileInfo";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIconInnerIcon from "@components/buttons/WnaButtonIconInnerIcon";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaDiaryEntryNavigator from "@components/diaryEntry/WnaDiaryEntryNavigator";
import { WnaCarouselItem } from "@components/images/WnaCarousel";
import WnaImage from "@components/images/WnaImage";
import { WnaImageViewerProps } from "@components/images/WnaImageViewer/wnaImageViewerProps";
import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import WnaPaginationInfoView from "@components/misc/WnaPaginationInfoView";
import { ReactNativeZoomableView } from "@openspacelabs/react-native-zoomable-view";
import { i18nKeys } from "@services/i18n/i18nKeys";
import * as React from "react";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import Carousel from "react-native-reanimated-carousel";

const _navBtnWidth = 64;
const _navBtnHeight = 64;
const _navBtnBgColor = "transparent";

const WnaImageViewer: React.FC<WnaImageViewerProps> = (props) => {
    const {
        currentUser,
        currentWindowWidth,
        currentWindowHeight,
        isLandscape,
        appStyle,
        appColors,
    } = useContext(WnaAppContext);
    const currentWindowWidthRef = useRef(currentWindowWidth);
    const currentWindowHeightRef = useRef(currentWindowHeight);
    const isLandscapeRef = useRef(isLandscape);

    const [isBusy, setIsBusy] = useState(true);
    const [navigateToNextDiaryEntry, setNavigateToNextDiaryEntry] =
        useState(false);
    const [navigateToPrevDiaryEntry, setNavigateToPrevDiaryEntry] =
        useState(false);
    const isBusyRef = useRef(isBusy);

    const [images, setImages] = useState<WnaFileInfo[]>([]);
    const imagesRef = useRef(images);

    const [currentIndex, setCurrentIndex] = useState(0);
    const currentIndexRef = useRef(currentIndex);

    const cRef = useRef(null);
    const flRef = useRef<FlatList<WnaCarouselItem>>(null);

    const [isScrollEnabled, setIsScrollEnabled] = useState(true);
    const isScrollEnabledRef = useRef(isScrollEnabled);
    const [currentZoomLevels, setCurrentZoomLevels] = useState<number[]>([]);
    const currentZoomLevelsRef = useRef(currentZoomLevels);
    const [isZoomEnabled, setIsZoomEnabled] = useState(true);
    const isZoomEnabledRef = useRef(isZoomEnabled);
    const [zoomableRefs, setZoomableRefs] = React.useState<
        React.RefObject<ReactNativeZoomableView>[]
    >([]);

    const [isNextBtnVisible, setIsNextBtnVisible] = useState(false);
    const [isLastBtnVisible, setIsLastBtnVisible] = useState(false);
    const [isPrevBtnVisible, setIsPrevBtnVisible] = useState(false);
    const [isFirstBtnVisible, setIsFirstBtnVisible] = useState(false);

    const { t } = useTranslation(["common"]);

    const setZoomLevel = (index: number, zoomLevel: number) => {
        let newZoomLevels = [...currentZoomLevels];
        newZoomLevels[index] = zoomLevel;
        setCurrentZoomLevels(newZoomLevels);
        currentZoomLevelsRef.current = newZoomLevels;
        setBtnStates(index);
    };

    const setBtnStates = (index: number) => {
        if (currentZoomLevelsRef.current[index] > 1) {
            setIsZoomEnabled(true);
            isZoomEnabledRef.current = true;
            setIsScrollEnabled(false);
            isScrollEnabledRef.current = false;
            setIsFirstBtnVisible(false);
            setIsLastBtnVisible(false);
            setIsNextBtnVisible(false);
            setIsPrevBtnVisible(false);
            return;
        }

        setIsZoomEnabled(true);
        isZoomEnabledRef.current = true;
        setIsScrollEnabled(true);
        isScrollEnabledRef.current = true;

        if (index > 0) {
            // between - maybe not last
            setIsFirstBtnVisible(false);
            setIsLastBtnVisible(false);
            setIsPrevBtnVisible(true); //setIsPrevBtnVisible(Platform.OS === "web");
        } else {
            // first btn
            setIsLastBtnVisible(false);
            setIsPrevBtnVisible(false);
            setIsFirstBtnVisible(true);
        }

        if (index < imagesRef.current.length - 1) {
            // between - but not last
            //setIsNextBtnVisible(Platform.OS === "web");
            setIsNextBtnVisible(true);
            setIsLastBtnVisible(false);
        } else {
            // last btn
            setIsNextBtnVisible(false);
            setIsLastBtnVisible(true);
        }
    };

    const renderItem = (index: number, item: WnaFileInfo) => {
        index = currentIndexRef.current;
        item = images[index];

        // console.log(`${index} - ${item.fileName}`);

        return (
            <View
                style={[
                    styles.imgContainer,
                    {
                        width: currentWindowWidthRef.current,
                        height: currentWindowHeightRef.current,
                    },
                    // @ts-ignore
                    Platform.OS === "web"
                        ? {
                              cursor: "zoom",
                          }
                        : null,
                ]}
                key={"View" + index}>
                <ReactNativeZoomableView
                    // Give these to the zoomable view, so it can apply the boundaries around the actual content.
                    // Need to make sure the content is actually centered and the width and height are
                    // dimensions when it's rendered naturally. Not the intrinsic size.
                    // For example, an image with an intrinsic size of 400x200 will be rendered as 300x150 in this case.
                    // Therefore, we'll feed the zoomable view the 300x150 size.
                    key={"ZoomableView" + index}
                    ref={zoomableRefs[index]}
                    contentWidth={currentWindowWidthRef.current}
                    contentHeight={currentWindowHeightRef.current}
                    maxZoom={4}
                    minZoom={1}
                    zoomStep={1}
                    // @ts-ignore
                    onZoomBefore={(_, __, ___) => {
                        setBtnStates(index);
                        isScrollEnabledRef.current = false;
                    }}
                    onZoomAfter={(_, __, ___) => setBtnStates(index)}
                    onZoomEnd={(_, __, zoomableViewEventObject) =>
                        setZoomLevel(index, zoomableViewEventObject.zoomLevel)
                    }
                    onDoubleTapAfter={(_, zoomableViewEventObject) =>
                        setZoomLevel(index, zoomableViewEventObject.zoomLevel)
                    }
                    zoomEnabled={isZoomEnabledRef.current}
                    disablePanOnInitialZoom={true}>
                    <WnaImage
                        appColors={appColors}
                        style={{
                            width: currentWindowWidthRef.current,
                            height: currentWindowHeightRef.current,
                            resizeMode: "contain",
                        }}
                        imageUrl={item.url}
                        thumbnailUrl={item.fileName512}
                        hideBackground={true}
                        showActivityIndicator={true}
                    />
                </ReactNativeZoomableView>
            </View>
        );
    };

    const loadDataAsync = async () => {
        if (!props.images || props.images.length < 1) return;

        try {
            const items = props.images;
            let startIndex =
                props.startIndex > props.images.length - 1
                    ? props.images.length - 1
                    : props.startIndex;

            if (startIndex < 0) startIndex = 0;

            if (items.length > 0) {
                const currentImage = items[startIndex];
                let initialIndex = 0;
                const zoomableRefs = new Array<
                    React.RefObject<ReactNativeZoomableView>
                >();
                const zoomLevels = new Array<number>();
                for (let i = 0; i < items.length; i++) {
                    if (items[i].url === "") continue;

                    const ref = createRef<ReactNativeZoomableView>();
                    zoomableRefs.push(ref);
                    zoomLevels.push(1);
                    if (items[i].url === currentImage.url) initialIndex = i;
                }
                Logger.info("initial index will be: " + initialIndex);
                setCurrentIndex(initialIndex);
                currentIndexRef.current = initialIndex;
                setZoomableRefs(zoomableRefs);
                setCurrentZoomLevels(zoomLevels);
                currentZoomLevelsRef.current = zoomLevels;
                setImages(items);
                imagesRef.current = items;
            }
            setBtnStates(currentIndexRef.current);
        } catch (error) {
            Logger.error(loadDataAsync.name, error);
        } finally {
            setIsBusy(false);
            isBusyRef.current = false;
        }
    };

    useEffect(() => {
        currentWindowHeightRef.current = currentWindowHeight;
        currentWindowWidthRef.current = currentWindowWidth;
        isLandscapeRef.current = isLandscape;

        if (isBusyRef.current) loadDataAsync();

        // eslint-disable-next-line
    }, [props, currentWindowWidth, currentWindowHeight, isLandscape]);

    return (
        <View
            style={[
                {
                    width: currentWindowWidth,
                    height: currentWindowHeight,
                    backgroundColor: appColors.staticBlack,
                },
            ]}>
            {isBusy ? (
                <View style={appStyle.containerCenterCenter}>
                    <WnaActivityIndicator appColors={appColors} />
                </View>
            ) : (
                <>
                    <Carousel
                        ref={cRef}
                        width={currentWindowWidth}
                        height={currentWindowHeight}
                        data={images}
                        defaultIndex={currentIndex}
                        scrollAnimationDuration={1000}
                        snapEnabled={true}
                        autoPlayInterval={2000}
                        // pagingEnabled
                        //enabled={isScrollEnabled && Platform.OS !== "web"}
                        enabled={false}
                        panGestureHandlerProps={{
                            enableTrackpadTwoFingerGesture: false,
                        }}
                        onSnapToItem={(index) => {
                            setCurrentIndex(index);
                            currentIndexRef.current = index;
                            setBtnStates(index);
                        }}
                        renderItem={({ index, item }) =>
                            renderItem(index, item)
                        }
                        vertical={false}
                    />

                    {/* pic / pics */}
                    {isScrollEnabled ? (
                        <WnaPaginationInfoView
                            index={currentIndex}
                            length={images.length}
                            appStyle={appStyle}
                            appColors={appColors}
                            isLandscape={isLandscape}
                        />
                    ) : null}

                    {/* prev button */}
                    {isPrevBtnVisible ? (
                        <WnaPressable
                            toolTip=""
                            style={
                                isLandscape
                                    ? styles.btnPrevLandscape
                                    : styles.btnPrevPortrait
                            }
                            onPress={() => {
                                if (currentIndexRef.current < 1) return;

                                const newIndex = currentIndexRef.current - 1;
                                if (Platform.OS === "web") {
                                    setCurrentIndex(newIndex);
                                    currentIndexRef.current = newIndex;
                                    // onIndexChanged(newIndex);
                                    setBtnStates(newIndex);
                                } else {
                                    flRef.current?.scrollToIndex({
                                        index: newIndex,
                                        animated: true,
                                    });
                                }
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-left"}
                                    appStyle={appStyle}
                                    appColors={appColors}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* first button */}
                    {isFirstBtnVisible ? (
                        <WnaPressable
                            toolTip={t(i18nKeys.actionNextEntry)}
                            style={
                                isLandscape
                                    ? styles.btnPrevLandscape
                                    : styles.btnPrevPortrait
                            }
                            onPress={() => {
                                setNavigateToPrevDiaryEntry(true);
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-circle-left"}
                                    appStyle={appStyle}
                                    appColors={appColors}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* next button */}
                    {isNextBtnVisible ? (
                        <WnaPressable
                            toolTip=""
                            style={
                                isLandscape
                                    ? styles.btnNextLandscape
                                    : styles.btnNextPortrait
                            }
                            onPress={() => {
                                if (
                                    currentIndexRef.current >=
                                    images.length - 1
                                )
                                    return;

                                const newIndex = currentIndexRef.current + 1;
                                if (Platform.OS === "web") {
                                    setCurrentIndex(newIndex);
                                    currentIndexRef.current = newIndex;
                                    // onIndexChanged(newIndex);
                                    setBtnStates(newIndex);
                                } else {
                                    flRef.current?.scrollToIndex({
                                        index: newIndex,
                                        animated: true,
                                    });
                                }
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-right"}
                                    appStyle={appStyle}
                                    appColors={appColors}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* last button */}
                    {isLastBtnVisible ? (
                        <WnaPressable
                            toolTip={t(i18nKeys.actionNextEntry)}
                            style={
                                isLandscape
                                    ? styles.btnNextLandscape
                                    : styles.btnNextPortrait
                            }
                            onPress={() => setNavigateToNextDiaryEntry(true)}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-circle-right"}
                                    appStyle={appStyle}
                                    appColors={appColors}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* close button */}
                    {isScrollEnabled ? (
                        <WnaPressable
                            toolTip=""
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: _navBtnWidth,
                                height: _navBtnHeight,
                                flex: 1,
                                alignContent: "stretch",
                                alignItems: "stretch",
                                backgroundColor: _navBtnBgColor,
                            }}
                            onPress={props.onClose}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"times"}
                                    appStyle={appStyle}
                                    appColors={appColors}
                                />
                            </View>
                        </WnaPressable>
                    ) : (
                        <WnaPressable
                            toolTip=""
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: _navBtnWidth,
                                height: _navBtnHeight,
                                flex: 1,
                                alignContent: "stretch",
                                alignItems: "stretch",
                                backgroundColor: _navBtnBgColor,
                            }}
                            onPress={() => {
                                zoomableRefs[
                                    currentIndexRef.current
                                ].current?.zoomTo(1, { x: 0, y: 0 });
                                setZoomLevel(currentIndexRef.current, 1);
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"expand"}
                                    appStyle={appStyle}
                                    appColors={appColors}
                                />
                            </View>
                        </WnaPressable>
                    )}
                </>
            )}
            <WnaDiaryEntryNavigator
                userId={currentUser?.id}
                chapterIdentifier={props.chapterIdentifier}
                diaryEntryIdentifier={props.diaryEntryIdentifier}
                tab={props.tab}
                order={props.order}
                navigateToPev={navigateToPrevDiaryEntry}
                navigateToNext={navigateToNextDiaryEntry}
                refreshOnUpdate={false}
            />
        </View>
    );
};
export default WnaImageViewer;

const styles = StyleSheet.create({
    btnNextLandscape: {
        position: "absolute",
        top: _navBtnHeight,
        bottom: _navBtnHeight,
        right: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    btnPrevLandscape: {
        position: "absolute",
        top: _navBtnHeight,
        bottom: _navBtnHeight,
        left: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    btnNextPortrait: {
        position: "absolute",
        height: _navBtnHeight,
        bottom: _navBtnHeight,
        right: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    btnPrevPortrait: {
        position: "absolute",
        height: _navBtnHeight,
        bottom: _navBtnHeight,
        left: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    imgContainer: {
        flexDirection: "row",
    },
});
