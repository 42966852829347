import getNotificationsAsync from "@/api/notification/getNotifications";
import WnaNotification from "@/types/entities/wnaNotification";
import { dateToStringDateTimeWeekdayShort } from "@/utils/dateTimeHelper";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIcon from "@components/buttons/WnaButtonIcon";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaListCardWhite from "@components/cards/WnaListCardWhite";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { WnaCatalogNotificationState } from "@constants/catalogs/wnaCatalogNotificationState";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useRouter } from "expo-router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, RefreshControl, Text, View } from "react-native";

export default function Notifications() {
    const router = useRouter();
    const {
        isAppInitialized,
        appColors,
        appStyle,
        currentUser,
        currentAdminUser,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<WnaNotification[]>([]);
    const [extraData, setExtraData] = useState(new Date());

    const loadData = () => {
        setIsBusy(true);
        getNotificationsAsync(currentUser?.id).then((notifications) => {
            setNotifications(notifications);
            setIsBusy(false);
        });
    };

    const navigateToCreateScreen = () =>
        router.push(WnaNavigationRouteProvider.notificationsCreate);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [currentUser]);

    const renderItem = (notification: WnaNotification) => {
        return (
            <WnaPressable
                toolTip=""
                style={[
                    appStyle.containerCenterMaxWidth,
                    { borderRadius: 8, padding: 0 },
                ]}
                onPress={() => {
                    notification.state = 1;
                    setExtraData(new Date());
                    router.push(
                        WnaNavigationRouteProvider.notificationsIdentifier(
                            notification.identifier
                        )
                    );
                }}
                t={t}
                checkInternetConnection={false}>
                <WnaListCardWhite
                    appColors={appColors}
                    color={
                        WnaCatalogNotificationState[notification.state]
                            .displayColor
                    }>
                    <Text
                        style={[
                            appStyle.textSmall,
                            {
                                color: appColors.accent4,
                                textAlign: "right",
                                marginBottom: 8,
                                marginTop: -16,
                                marginRight: -8,
                            },
                        ]}>
                        {dateToStringDateTimeWeekdayShort(
                            notification.dateCreate
                        )}
                    </Text>
                    <Text
                        style={[
                            appStyle.textTitleLarge,
                            { color: appColors.black },
                        ]}
                        textBreakStrategy={"highQuality"}
                        numberOfLines={1}
                        ellipsizeMode={"tail"}>
                        {notification.designator}
                    </Text>
                    <Text
                        style={[appStyle.textSmall, { color: appColors.black }]}
                        textBreakStrategy={"highQuality"}
                        numberOfLines={3}
                        ellipsizeMode={"tail"}>
                        {notification.comment}
                    </Text>
                </WnaListCardWhite>
            </WnaPressable>
        );
    };

    if (!isAppInitialized) return null;
    if (!currentUser)
        return <Redirect href={WnaNavigationRouteProvider.root} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={t(i18nKeys.screenTitleNotificationList)}>
            <>
                <FlatList
                    data={notifications}
                    contentContainerStyle={{
                        paddingBottom: 80,
                        paddingTop: 16,
                        paddingHorizontal: 16,
                    }}
                    ListHeaderComponentStyle={{
                        paddingBottom: 8,
                        paddingHorizontal: 0,
                    }}
                    ItemSeparatorComponent={() => (
                        <View style={{ height: 8 }} />
                    )}
                    extraData={extraData}
                    keyExtractor={(item) =>
                        item.identifier ?? item.dateCreate ?? item.designator
                    }
                    renderItem={({ item: notification }) =>
                        renderItem(notification)
                    }
                    refreshControl={
                        <RefreshControl
                            refreshing={isBusy}
                            onRefresh={() => loadData()}
                        />
                    }
                />
                {/* Add Button */}
                {currentAdminUser ? (
                    <WnaButtonIcon
                        toolTip={t(i18nKeys.actionCreateNotification)}
                        toolTipPosition="left"
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="bullhorn"
                        style={{ position: "absolute", bottom: 16, right: 16 }}
                        onPress={navigateToCreateScreen}
                        checkInternetConnection={true}
                        t={t}
                    />
                ) : null}
            </>
        </WnaBaseScreen>
    );
}
