import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaChapter from "@/types/entities/wnaChapter";

const encrypt = true;
const useDb = true;

export default async function getChapterAsync(
    userId: string | undefined,
    identifier: string | undefined
) {
    if (!isUserId(userId) || !isDateTimeIdentifier(identifier))
        return undefined;

    const route = `users/${userId}/chapters/${identifier}`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return await fetchAndCacheGetAsync<WnaChapter>(route, url, encrypt, useDb);
}
