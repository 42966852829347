import WnaUser from "@/types/entities/wnaUser";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaListCardWhiteDecent from "@components/cards/WnaListCardWhiteDecent";
import WnaImage from "@components/images/WnaImage";
import WnaProfileIcon from "@components/misc/WnaProfileIcon";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View } from "react-native";

export type WnaNavigationItemProfileProps = {
    appColors: Colors;
    appStyle: AppStyle;
    currentUser?: WnaUser;
    logInTitle: string;
    onPress: () => void;
    t: TFunction<string[], undefined>;
};

class WnaNavigationItemProfile extends Component<WnaNavigationItemProfileProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaNavigationItemProfileProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.currentUser?.picture !== this.props.currentUser?.picture
        );
    }

    render() {
        const currentUserName = this.props.currentUser?.name ?? "";
        const currentUserId = this.props.currentUser?.id ?? "";
        return (
            <WnaPressable
                style={[
                    this.props.appStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                    },
                ]}
                onPress={this.props.onPress}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteDecent appColors={this.props.appColors}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            gap: 16,
                            alignContent: "center",
                        }}>
                        {/* icon or image */}
                        <View
                            style={[
                                this.props.appStyle.containerCenter,
                                {
                                    width: 32,
                                    height: 32,
                                    borderRadius: 16,
                                    borderColor:
                                        this.props.appColors.staticCoolgray2,
                                    borderWidth: 2,
                                    alignContent: "center",
                                    overflow: "hidden",
                                    marginLeft: -8,
                                },
                            ]}>
                            {this.props.currentUser &&
                            this.props.currentUser.picture !== "" ? (
                                <WnaImage
                                    appColors={this.props.appColors}
                                    imageUrl={
                                        this.props.currentUser?.picture ?? ""
                                    }
                                    style={{
                                        width: 32,
                                        height: 32,
                                    }}
                                />
                            ) : (
                                <WnaProfileIcon
                                    appColors={this.props.appColors}
                                />
                            )}
                        </View>
                        {/* text */}
                        <Text
                            style={[
                                this.props.appStyle.textTitleLarge,
                                {
                                    color: this.props.appColors.black,
                                    width: "100%",
                                    paddingRight: 32,
                                    alignSelf: "center",
                                },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {currentUserId === ""
                                ? this.props.logInTitle
                                : currentUserName}
                        </Text>
                        {/* arrow */}
                        <View
                            style={{
                                position: "absolute",
                                right: 0,
                                alignSelf: "center",
                            }}>
                            <FontAwesome5
                                name={"angle-right"}
                                size={24}
                                color={this.props.appColors.coolgray4}
                            />
                        </View>
                    </View>
                </WnaListCardWhiteDecent>
            </WnaPressable>
        );
    }
}

export default WnaNavigationItemProfile;
