export function cleanAndTruncate(
    input: string,
    maxLength: number = 64
): string {
    // Remove all line breaks (\r, \n, or \r\n)
    const cleaned = input.replace(/[\r\n]+/g, "");

    // If the cleaned string is longer than maxLength, truncate it so that adding "..." keeps it within maxLength.
    if (cleaned.length > maxLength)
        return cleaned.substring(0, maxLength - 3) + "..."; // Subtract 3 to leave room for the ellipsis.

    return cleaned;
}
