import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { setStoredUserAsync } from "@/api/user/userStorageProvider";
import WnaUser from "@/types/entities/wnaUser";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function createOrUpdateUserAsync(user: WnaUser) {
    try {
        const route = `users/createOrUpdate`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("POST");
        request.body = JSON.stringify(user);
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        if (result instanceof Response) {
            // cache it
            const obj = (await result.json()) as WnaUser;
            await setStoredUserAsync(obj);
            return obj;
        } else {
            Logger.error(
                createOrUpdateUserAsync.name,
                "could not create or update user"
            );
            return undefined;
        }
    } catch (e) {
        Logger.error(createOrUpdateUserAsync.name, e);
        return undefined;
    }
}
