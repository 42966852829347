/**
 * Below are the colors that are used in the app. The colors are defined in the light and dark mode.
 * There are many other ways to style your app. For example, [Nativewind](https://www.nativewind.dev/), [Tamagui](https://tamagui.dev/), [unistyles](https://reactnativeunistyles.vercel.app), etc.
 */
import { shadeHexColor } from "@/utils/colorConverter";
import Colors from "@constants/theme/colors";
import { StaticColors } from "@constants/theme/staticColors";

const accentColor = "#869ab7";

const accent1Light = shadeHexColor(accentColor, 0.8);
const accent2Light = shadeHexColor(accentColor, 0.6);
const accent3Light = shadeHexColor(accentColor, 0.4);
const accent4Light = shadeHexColor(accentColor, 0.2);
const accent5Light = shadeHexColor(accentColor, 0);
const accent6Light = shadeHexColor(accentColor, -0.2);
const accent7Light = shadeHexColor(accentColor, -0.4);
const accent8Light = shadeHexColor(accentColor, -0.6);

const accent1Dark = shadeHexColor(accentColor, 0.8);
const accent2Dark = shadeHexColor(accentColor, 0.6);
const accent3Dark = shadeHexColor(accentColor, 0.4);
const accent4Dark = shadeHexColor(accentColor, 0.2);
const accent5Dark = shadeHexColor(accentColor, 0);
const accent6Dark = shadeHexColor(accentColor, -0.2);
const accent7Dark = shadeHexColor(accentColor, -0.4);
const accent8Dark = shadeHexColor(accentColor, -0.6);

export const CurrentColors = {
    light: {
        id: 1,
        isDark: false,
        white: StaticColors.staticWhite,
        black: StaticColors.staticBlack,

        staticWhite: StaticColors.staticWhite,
        staticBlack: StaticColors.staticBlack,

        staticCoolgray1: StaticColors.staticCoolgray1,
        staticCoolgray2: StaticColors.staticCoolgray2,
        staticCoolgray3: StaticColors.staticCoolgray3,
        staticCoolgray4: StaticColors.staticCoolgray4,
        staticCoolgray5: StaticColors.staticCoolgray5,
        staticCoolgray6: StaticColors.staticCoolgray6,
        staticCoolgray7: StaticColors.staticCoolgray7,
        staticCoolgray8: StaticColors.staticCoolgray8,

        staticWarmgray1: StaticColors.staticWarmgray1,
        staticWarmgray2: StaticColors.staticWarmgray2,
        staticWarmgray3: StaticColors.staticWarmgray3,
        staticWarmgray4: StaticColors.staticWarmgray4,
        staticWarmgray5: StaticColors.staticWarmgray5,
        staticWarmgray6: StaticColors.staticWarmgray6,
        staticWarmgray7: StaticColors.staticWarmgray7,
        staticWarmgray8: StaticColors.staticWarmgray8,

        coolgray1: StaticColors.staticCoolgray1,
        coolgray2: StaticColors.staticCoolgray2,
        coolgray3: StaticColors.staticCoolgray3,
        coolgray4: StaticColors.staticCoolgray4,
        coolgray5: StaticColors.staticCoolgray5,
        coolgray6: StaticColors.staticCoolgray6,
        coolgray7: StaticColors.staticCoolgray7,
        coolgray8: StaticColors.staticCoolgray8,

        warmgray1: StaticColors.staticWarmgray1,
        warmgray2: StaticColors.staticWarmgray2,
        warmgray3: StaticColors.staticWarmgray3,
        warmgray4: StaticColors.staticWarmgray4,
        warmgray5: StaticColors.staticWarmgray5,
        warmgray6: StaticColors.staticWarmgray6,
        warmgray7: StaticColors.staticWarmgray7,
        warmgray8: StaticColors.staticWarmgray8,

        blue1: StaticColors.blue1,
        blue2: StaticColors.blue2,
        blue3: StaticColors.blue3,
        blue4: StaticColors.blue4,
        blue5: StaticColors.blue5,
        blue6: StaticColors.blue6,
        blue7: StaticColors.blue7,
        blue8: StaticColors.blue8,

        burgundy1: StaticColors.burgundy1,
        burgundy2: StaticColors.burgundy2,
        burgundy3: StaticColors.burgundy3,
        burgundy4: StaticColors.burgundy4,
        burgundy5: StaticColors.burgundy5,
        burgundy6: StaticColors.burgundy6,
        burgundy7: StaticColors.burgundy7,
        burgundy8: StaticColors.burgundy8,

        yellow1: StaticColors.yellow1,
        yellow2: StaticColors.yellow2,
        yellow3: StaticColors.yellow3,
        yellow4: StaticColors.yellow4,
        yellow5: StaticColors.yellow5,
        yellow6: StaticColors.yellow6,
        yellow7: StaticColors.yellow7,
        yellow8: StaticColors.yellow8,

        orange1: StaticColors.orange1,
        orange2: StaticColors.orange2,
        orange3: StaticColors.orange3,
        orange4: StaticColors.orange4,
        orange5: StaticColors.orange5,
        orange6: StaticColors.orange6,
        orange7: StaticColors.orange7,
        orange8: StaticColors.orange8,

        turquoise1: StaticColors.turquoise1,
        turquoise2: StaticColors.turquoise2,
        turquoise3: StaticColors.turquoise3,
        turquoise4: StaticColors.turquoise4,
        turquoise5: StaticColors.turquoise5,
        turquoise6: StaticColors.turquoise6,
        turquoise7: StaticColors.turquoise7,
        turquoise8: StaticColors.turquoise8,

        cyan1: StaticColors.cyan1,
        cyan2: StaticColors.cyan2,
        cyan3: StaticColors.cyan3,
        cyan4: StaticColors.cyan4,
        cyan5: StaticColors.cyan5,
        cyan6: StaticColors.cyan6,
        cyan7: StaticColors.cyan7,
        cyan8: StaticColors.cyan8,

        green1: StaticColors.green1,
        green2: StaticColors.green2,
        green3: StaticColors.green3,
        green4: StaticColors.green4,
        green5: StaticColors.green5,
        green6: StaticColors.green6,
        green7: StaticColors.green7,
        green8: StaticColors.green8,

        lightgreen1: StaticColors.lightgreen1,
        lightgreen2: StaticColors.lightgreen2,
        lightgreen3: StaticColors.lightgreen3,
        lightgreen4: StaticColors.lightgreen4,
        lightgreen5: StaticColors.lightgreen5,
        lightgreen6: StaticColors.lightgreen6,
        lightgreen7: StaticColors.lightgreen7,
        lightgreen8: StaticColors.lightgreen8,

        pink1: StaticColors.pink1,
        pink2: StaticColors.pink2,
        pink3: StaticColors.pink3,
        pink4: StaticColors.pink4,
        pink5: StaticColors.pink5,
        pink6: StaticColors.pink6,
        pink7: StaticColors.pink7,
        pink8: StaticColors.pink8,

        red1: StaticColors.red1,
        red2: StaticColors.red2,
        red3: StaticColors.red3,
        red4: StaticColors.red4,
        red5: StaticColors.red5,
        red6: StaticColors.red6,
        red7: StaticColors.red7,
        red8: StaticColors.red8,

        violet1: StaticColors.violet1,
        violet2: StaticColors.violet2,
        violet3: StaticColors.violet3,
        violet4: StaticColors.violet4,
        violet5: StaticColors.violet5,
        violet6: StaticColors.violet6,
        violet7: StaticColors.violet7,
        violet8: StaticColors.violet8,

        tint: StaticColors.staticCoolgray8,
        background: StaticColors.staticWhite,

        accent1: accent1Light,
        accent2: accent2Light,
        accent3: accent3Light,
        accent4: accent4Light,
        accent5: accent5Light,
        accent6: accent6Light,
        accent7: accent7Light,
        accent8: accent8Light,

        staticAccent1: accent1Light,
        staticAccent2: accent2Light,
        staticAccent3: accent3Light,
        staticAccent4: accent4Light,
        staticAccent5: accent5Light,
        staticAccent6: accent6Light,
        staticAccent7: accent7Light,
        staticAccent8: accent8Light,
    } as Colors,
    dark: {
        id: 2,
        isDark: true,
        white: StaticColors.staticCoolgray8,
        black: StaticColors.staticWhite,

        staticWhite: StaticColors.staticWhite,
        staticBlack: StaticColors.staticBlack,

        staticCoolgray1: StaticColors.staticCoolgray1,
        staticCoolgray2: StaticColors.staticCoolgray2,
        staticCoolgray3: StaticColors.staticCoolgray3,
        staticCoolgray4: StaticColors.staticCoolgray4,
        staticCoolgray5: StaticColors.staticCoolgray5,
        staticCoolgray6: StaticColors.staticCoolgray6,
        staticCoolgray7: StaticColors.staticCoolgray7,
        staticCoolgray8: StaticColors.staticCoolgray8,

        staticWarmgray1: StaticColors.staticWarmgray1,
        staticWarmgray2: StaticColors.staticWarmgray2,
        staticWarmgray3: StaticColors.staticWarmgray3,
        staticWarmgray4: StaticColors.staticWarmgray4,
        staticWarmgray5: StaticColors.staticWarmgray5,
        staticWarmgray6: StaticColors.staticWarmgray6,
        staticWarmgray7: StaticColors.staticWarmgray7,
        staticWarmgray8: StaticColors.staticWarmgray8,

        coolgray1: StaticColors.staticCoolgray8,
        coolgray2: StaticColors.staticCoolgray7,
        coolgray3: StaticColors.staticCoolgray6,
        coolgray4: StaticColors.staticCoolgray5,
        coolgray5: StaticColors.staticCoolgray4,
        coolgray6: StaticColors.staticCoolgray3,
        coolgray7: StaticColors.staticCoolgray2,
        coolgray8: StaticColors.staticCoolgray1,

        warmgray1: StaticColors.staticWarmgray8,
        warmgray2: StaticColors.staticWarmgray7,
        warmgray3: StaticColors.staticWarmgray6,
        warmgray4: StaticColors.staticWarmgray5,
        warmgray5: StaticColors.staticWarmgray4,
        warmgray6: StaticColors.staticWarmgray3,
        warmgray7: StaticColors.staticWarmgray2,
        warmgray8: StaticColors.staticWarmgray1,

        blue1: StaticColors.blue1,
        blue2: StaticColors.blue2,
        blue3: StaticColors.blue3,
        blue4: StaticColors.blue4,
        blue5: StaticColors.blue5,
        blue6: StaticColors.blue6,
        blue7: StaticColors.blue7,
        blue8: StaticColors.blue8,

        burgundy1: StaticColors.burgundy1,
        burgundy2: StaticColors.burgundy2,
        burgundy3: StaticColors.burgundy3,
        burgundy4: StaticColors.burgundy4,
        burgundy5: StaticColors.burgundy5,
        burgundy6: StaticColors.burgundy6,
        burgundy7: StaticColors.burgundy7,
        burgundy8: StaticColors.burgundy8,

        yellow1: StaticColors.yellow1,
        yellow2: StaticColors.yellow2,
        yellow3: StaticColors.yellow3,
        yellow4: StaticColors.yellow4,
        yellow5: StaticColors.yellow5,
        yellow6: StaticColors.yellow6,
        yellow7: StaticColors.yellow7,
        yellow8: StaticColors.yellow8,

        orange1: StaticColors.orange1,
        orange2: StaticColors.orange2,
        orange3: StaticColors.orange3,
        orange4: StaticColors.orange4,
        orange5: StaticColors.orange5,
        orange6: StaticColors.orange6,
        orange7: StaticColors.orange7,
        orange8: StaticColors.orange8,

        turquoise1: StaticColors.turquoise1,
        turquoise2: StaticColors.turquoise2,
        turquoise3: StaticColors.turquoise3,
        turquoise4: StaticColors.turquoise4,
        turquoise5: StaticColors.turquoise5,
        turquoise6: StaticColors.turquoise6,
        turquoise7: StaticColors.turquoise7,
        turquoise8: StaticColors.turquoise8,

        cyan1: StaticColors.cyan1,
        cyan2: StaticColors.cyan2,
        cyan3: StaticColors.cyan3,
        cyan4: StaticColors.cyan4,
        cyan5: StaticColors.cyan5,
        cyan6: StaticColors.cyan6,
        cyan7: StaticColors.cyan7,
        cyan8: StaticColors.cyan8,

        green1: StaticColors.green1,
        green2: StaticColors.green2,
        green3: StaticColors.green3,
        green4: StaticColors.green4,
        green5: StaticColors.green5,
        green6: StaticColors.green6,
        green7: StaticColors.green7,
        green8: StaticColors.green8,

        lightgreen1: StaticColors.lightgreen1,
        lightgreen2: StaticColors.lightgreen2,
        lightgreen3: StaticColors.lightgreen3,
        lightgreen4: StaticColors.lightgreen4,
        lightgreen5: StaticColors.lightgreen5,
        lightgreen6: StaticColors.lightgreen6,
        lightgreen7: StaticColors.lightgreen7,
        lightgreen8: StaticColors.lightgreen8,

        pink1: StaticColors.pink1,
        pink2: StaticColors.pink2,
        pink3: StaticColors.pink3,
        pink4: StaticColors.pink4,
        pink5: StaticColors.pink5,
        pink6: StaticColors.pink6,
        pink7: StaticColors.pink7,
        pink8: StaticColors.pink8,

        red1: StaticColors.red1,
        red2: StaticColors.red2,
        red3: StaticColors.red3,
        red4: StaticColors.red4,
        red5: StaticColors.red5,
        red6: StaticColors.red6,
        red7: StaticColors.red7,
        red8: StaticColors.red8,

        violet1: StaticColors.violet1,
        violet2: StaticColors.violet2,
        violet3: StaticColors.violet3,
        violet4: StaticColors.violet4,
        violet5: StaticColors.violet5,
        violet6: StaticColors.violet6,
        violet7: StaticColors.violet7,
        violet8: StaticColors.violet8,

        tint: StaticColors.staticWhite,
        background: StaticColors.staticCoolgray8,

        accent1: accent1Dark,
        accent2: accent2Dark,
        accent3: accent3Dark,
        accent4: accent4Dark,
        accent5: accent5Dark,
        accent6: accent6Dark,
        accent7: accent7Dark,
        accent8: accent8Dark,

        staticAccent1: accent1Light,
        staticAccent2: accent2Light,
        staticAccent3: accent3Light,
        staticAccent4: accent4Light,
        staticAccent5: accent5Light,
        staticAccent6: accent6Light,
        staticAccent7: accent7Light,
        staticAccent8: accent8Light,
    } as Colors,
};
