import { cleanAndTruncate } from "@/utils/stringHelper";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { NavigationProp, NavigationState } from "@react-navigation/core";
import { Image } from "expo-image";
import { Router } from "expo-router";
import { Pressable, StyleSheet, Text, View } from "react-native";

export default function WnaMultilineHeader(
    appColors: Colors,
    appStyle: AppStyle,
    navigation: Omit<
        NavigationProp<ReactNavigation.RootParamList>,
        "getState"
    > & { getState(): NavigationState | undefined },
    router: Router,
    title?: string,
    isBusy?: boolean
) {
    if (!title) return null;

    const maxLength = 4096;
    let mainTitle = "";
    let subTitle = "";
    if (title) {
        const titleSegments = title.split("|");
        if (titleSegments.length > 1) {
            mainTitle = cleanAndTruncate(titleSegments[0].trim(), maxLength);
            subTitle = cleanAndTruncate(titleSegments[1].trim(), maxLength);
        } else {
            mainTitle = cleanAndTruncate(title.trim(), maxLength);
        }
    }

    const showLogo =
        navigation.getState()?.type === "tab" || !navigation.canGoBack();
    const altText = "logo";
    return (
        <Pressable
            onPress={() => {
                if (!isBusy) router.navigate(WnaNavigationRouteProvider.root);
            }}>
            <View
                style={{
                    alignSelf: "stretch",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                }}>
                {showLogo ? (
                    <Image
                        source={require("../../../assets/images/logo_96.png")}
                        alt={altText}
                        accessibilityLabel={altText}
                        style={{ width: 48, height: 48 }}
                    />
                ) : null}
                {subTitle === "" ? (
                    <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={[
                            appStyle.textTitleLarge,
                            styles(appColors).text,
                            {
                                fontSize: 20,
                            },
                        ]}>
                        {mainTitle}
                    </Text>
                ) : (
                    <View>
                        <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={[
                                appStyle.textSmall,
                                styles(appColors).text,
                            ]}>
                            {mainTitle}
                        </Text>

                        <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={[
                                appStyle.textSmall,
                                styles(appColors).text,
                            ]}>
                            {subTitle}
                        </Text>
                    </View>
                )}
            </View>
        </Pressable>
    );
}

const styles = (appColors: Colors) =>
    StyleSheet.create({
        text: {
            color: appColors.staticWhite,
        },
    });
