import ApiSettings from "@/api/apiSettings";
import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import { convertHexToRgba } from "@/utils/colorConverter";
import { addDays } from "@/utils/dateTimeHelper";
import WnaButtonIconText from "@components/buttons/WnaButtonIconText";
import WnaDiaryEntryListItem from "@components/diaryEntry/WnaDiaryEntryListItem";
import WnaSeparatorHorizontal from "@components/misc/WnaSeparatorHorizontal";
import { WnaShadowStyle } from "@components/misc/WnaShadowStyle";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

export type WnaDiaryEntryListDummyProps = {
    appColors: Colors;
    appStyle: AppStyle;
    onLogIn: () => void;
    t: TFunction<string[], undefined>;
};

class WnaDiaryEntryListDummy extends Component<WnaDiaryEntryListDummyProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDiaryEntryListDummyProps>
    ): boolean {
        return nextProps.appColors.isDark !== this.props.appColors.isDark;
    }

    render() {
        const _today = new Date();
        const _yesterday = addDays(_today, -1);
        const _dummy0 = {
            imageUrl: `${ApiSettings.apiBaseUrl}/resources/ph_003.jpg`,
            dateStart: _today,
            dateCreate: _today,
            dateUpdate: _today,
            designator: this.props.t(i18nKeys.dummyDiaryEntry1Designator),
            comment: this.props.t(i18nKeys.dummyDiaryEntry1Comment),
            images: [{}, {}],
            routeIdentifier: "dummy",
        } as WnaDiaryEntry;

        const _dummy1 = {
            imageUrl: `${ApiSettings.apiBaseUrl}/resources/ph_006.jpg`,
            dateStart: _yesterday,
            dateCreate: _yesterday,
            dateUpdate: _yesterday,
            designator: this.props.t(i18nKeys.dummyDiaryEntry2Designator),
            comment: this.props.t(i18nKeys.dummyDiaryEntry2Comment),
            images: [{}, {}],
            routeIdentifier: "Dummy",
        } as WnaDiaryEntry;

        const diaryEntries = [_dummy0, _dummy1];
        return (
            <>
                <ScrollView
                    style={[
                        this.props.appStyle.containerFillPage,
                        {
                            padding: 16,
                        },
                    ]}>
                    <WnaDiaryEntryListItem
                        appStyle={this.props.appStyle}
                        appColors={this.props.appColors}
                        key={"dummy0"}
                        diaryEntry={_dummy0}
                        diaryEntries={diaryEntries}
                        onPress={() => {}}
                        t={this.props.t}
                    />

                    <View style={{ height: 8 }} />

                    <WnaDiaryEntryListItem
                        appStyle={this.props.appStyle}
                        appColors={this.props.appColors}
                        key={"dummy1"}
                        diaryEntry={_dummy1}
                        diaryEntries={diaryEntries}
                        onPress={() => {}}
                        t={this.props.t}
                    />
                </ScrollView>
                <View
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: convertHexToRgba("#000000", 0.7),
                    }}>
                    <View
                        style={[
                            this.props.appStyle.containerCenterCenter,
                            { marginHorizontal: 24 },
                        ]}>
                        <View
                            style={[
                                WnaShadowStyle(),
                                this.props.appStyle.containerCenter,
                                {
                                    backgroundColor: this.props.appColors.white,
                                    borderRadius: 16,
                                    paddingVertical: 32,
                                    paddingHorizontal: 24,
                                    maxWidth: 386,
                                },
                            ]}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    gap: 32,
                                    alignItems: "center",
                                }}>
                                <FontAwesome5
                                    name="google"
                                    size={24}
                                    color={this.props.appColors.coolgray6}
                                />

                                <FontAwesome5
                                    name="facebook"
                                    size={30}
                                    color={"#3A4F83"}
                                />
                            </View>
                            <WnaSeparatorHorizontal />
                            <Text
                                style={[
                                    this.props.appStyle.textTitleLarge,
                                    { textAlign: "center", marginVertical: 16 },
                                ]}>
                                {this.props.t(i18nKeys.infoNeedToLogIn)}
                            </Text>
                            <WnaSeparatorHorizontal />
                            <WnaButtonIconText
                                iconName={"sign-in-alt"}
                                text={this.props.t(i18nKeys.actionLogInForFree)}
                                appColors={this.props.appColors}
                                onPress={() => {
                                    this.props.onLogIn();
                                }}
                                t={this.props.t}
                                checkInternetConnection={true}
                            />
                        </View>
                    </View>
                </View>
            </>
        );
    }
}

export default WnaDiaryEntryListDummy;
