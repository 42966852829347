import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaNotification from "@/types/entities/wnaNotification";

export default async function getNotificationsAsync(
    userId: string | undefined
) {
    if (!isUserId(userId)) return new Array<WnaNotification>();

    const route = `users/${userId}/notifications`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return (
        (await fetchAndCacheGetAsync<WnaNotification[]>(
            route,
            url,
            true,
            true
        )) ?? new Array<WnaNotification>()
    );
}
