import getChapterAsync from "@/api/chapter/getChapter";
import createDiaryEntryAsync from "@/api/diaryEntry/createDiaryEntry";
import getDiaryEntriesAsync from "@/api/diaryEntry/getDiaryEntries";
import downloadFileAsync from "@/api/fileDownload";
import WnaChapterEditView from "@/components/chapter/WnaChapterEditView";
import WnaChapter from "@/types/entities/wnaChapter";
import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import { WnaAppContext } from "@app/WnaAppContext";
import SideMenu from "@chakrahq/react-native-side-menu";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaButtonIcon from "@components/buttons/WnaButtonIcon";
import WnaDiaryEntryListEmpty from "@components/diaryEntry/WnaDiaryEntryListEmpty";
import WnaDiaryEntryListItem from "@components/diaryEntry/WnaDiaryEntryListItem";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import {
    Redirect,
    useFocusEffect,
    useLocalSearchParams,
    useRouter,
} from "expo-router";
import { useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, RefreshControl, View } from "react-native";
import Toast from "react-native-toast-message";

export default function DiaryEntriesScreen() {
    const router = useRouter();
    const { t } = useTranslation(["common"]);
    const {
        isAppInitialized,
        appColors,
        appStyle,
        currentUser,
        currentUserSettings,
    } = useContext(WnaAppContext);
    const { chapterIdentifier } = useLocalSearchParams<{
        chapterIdentifier: string;
    }>();

    const [chapter, setChapter] = useState<WnaChapter>();
    const [diaryEntries, setDiaryEntries] = useState<WnaDiaryEntry[]>([]);
    const [isBusy, setIsBusy] = useState(true);
    const [backgroundImageB64, setBackgroundImageB64] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);

    const isSidebarOpenRef = useRef(isSidebarOpen);
    const sidebarRef = useRef<SideMenu>(null);

    const closeSideBar = () => {
        if (isSidebarOpenRef.current) {
            setIsSidebarOpen(false);
            isSidebarOpenRef.current = false;
            return false;
        }
        return true;
    };

    const navigateToDiaryEntry = (
        diaryEntryIdentifier: string,
        edit: boolean
    ) =>
        router.push(
            WnaNavigationRouteProvider.diaryEntry(
                chapterIdentifier,
                diaryEntryIdentifier,
                edit
            )
        );

    const addDiaryEntry = () => {
        if (!currentUser || !chapterIdentifier) return;
        setIsBusy(true);
        createDiaryEntryAsync(currentUser!.id, chapterIdentifier).then(
            (resultDiaryEntry) => {
                setIsBusy(false);
                if (!resultDiaryEntry) {
                    Toast.show({
                        text1: t(i18nKeys.errorSave),
                        type: "error",
                    });
                    return;
                }
                loadDataAsync().then(() => {
                    navigateToDiaryEntry(resultDiaryEntry.identifier, true);
                });
            }
        );
    };

    const menu = (
        <WnaChapterEditView
            currentChapter={chapter}
            isVisible={isSidebarOpen}
            canDelete={diaryEntries.length < 1}
            onUpdate={async (updateChapter: WnaChapter) => {
                const chapterImageB64 = await downloadFileAsync(
                    updateChapter.imageUrl
                );
                setChapter(updateChapter);
                setBackgroundImageB64(chapterImageB64);
            }}
            closeSideBar={closeSideBar}
        />
    );

    const loadDataAsync = useCallback(async () => {
        if (!currentUser) return;

        let [resultChapter, resultDiaryEntries] = await Promise.all([
            getChapterAsync(currentUser.id, chapterIdentifier),
            getDiaryEntriesAsync(
                currentUser.id,
                chapterIdentifier,
                false,
                currentUserSettings?.diaryOrderBy === 1
            ),
        ]);

        if (!resultChapter) {
            setRedirectToNotFound(true);
            Toast.show({
                text1: "not found",
                type: "error",
            });
            return;
        }

        setChapter(resultChapter);
        setBackgroundImageB64(await downloadFileAsync(resultChapter.imageUrl));

        if (resultDiaryEntries) setDiaryEntries(resultDiaryEntries);
        setIsBusy(false);
        // eslint-disable-next-line
    }, [currentUser, chapterIdentifier]);

    const renderItem = (diaryEntry: WnaDiaryEntry) => {
        return (
            <WnaDiaryEntryListItem
                key={diaryEntry.identifier}
                diaryEntry={diaryEntry}
                diaryEntries={diaryEntries}
                appStyle={appStyle}
                appColors={appColors}
                userSettings={currentUserSettings!}
                onPress={() =>
                    navigateToDiaryEntry(diaryEntry.identifier, false)
                }
                t={t}
            />
        );
    };

    useFocusEffect(
        useCallback(() => {
            loadDataAsync();
            // eslint-disable-next-line
        }, [loadDataAsync])
    );

    if (!isAppInitialized) return null;
    if (!currentUser || redirectToNotFound)
        return <Redirect href={WnaNavigationRouteProvider.chapters} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={
                chapter ? chapter.designator : t(i18nKeys.screenTitleDayList)
            }
            headerButton0={
                <WnaButtonHeader
                    text={t(i18nKeys.actionEditChapter)}
                    appStyle={appStyle}
                    appColors={appColors}
                    iconName={"book"}
                    onPress={() => {
                        if (isSidebarOpenRef.current) {
                            setIsSidebarOpen(false);
                            isSidebarOpenRef.current = false;
                        } else {
                            setIsSidebarOpen(true);
                            isSidebarOpenRef.current = true;
                        }
                    }}
                    t={t}
                    checkInternetConnection={false}
                />
            }
            backgroundImageUrl={backgroundImageB64}>
            {
                <>
                    {/*// @ts-ignore*/}
                    <SideMenu
                        menu={menu}
                        ref={sidebarRef}
                        autoClosing
                        isOpen={isSidebarOpen}
                        openMenuOffset={256}
                        onChange={(isOpen: boolean) => {
                            setIsSidebarOpen(isOpen);
                            isSidebarOpenRef.current = isOpen;
                        }}
                        menuPosition="right">
                        {diaryEntries.length > 0 && !isBusy ? (
                            <FlatList
                                contentContainerStyle={{
                                    paddingBottom: 80,
                                    paddingTop: 16,
                                    paddingHorizontal: 16,
                                }}
                                ItemSeparatorComponent={() => (
                                    <View style={{ height: 8 }} />
                                )}
                                data={diaryEntries}
                                initialNumToRender={3}
                                maxToRenderPerBatch={3}
                                maintainVisibleContentPosition={{
                                    minIndexForVisible: 0, // 🔥 Keeps scroll position
                                }}
                                keyExtractor={(item) => item.identifier}
                                refreshing={isBusy}
                                refreshControl={
                                    <RefreshControl
                                        refreshing={isBusy}
                                        onRefresh={() => {
                                            loadDataAsync();
                                        }}
                                    />
                                }
                                renderItem={({ item: currentItem }) =>
                                    renderItem(currentItem)
                                }
                            />
                        ) : null}
                        {/*empty list*/}
                        {diaryEntries.length < 1 && !isBusy ? (
                            <WnaDiaryEntryListEmpty
                                appStyle={appStyle}
                                appColors={appColors}
                                onPress={addDiaryEntry}
                                t={t}
                            />
                        ) : null}
                    </SideMenu>
                    {/* Add Button */}
                    <WnaButtonIcon
                        toolTip={t(i18nKeys.actionCreateDiaryEntry)}
                        toolTipPosition="left"
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="plus"
                        style={{
                            position: "absolute",
                            bottom: 16,
                            right: 16,
                        }}
                        onPress={addDiaryEntry}
                        t={t}
                        checkInternetConnection={true}
                    />
                </>
            }
        </WnaBaseScreen>
    );
}
