import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaChapter from "@/types/entities/wnaChapter";

const encrypt = true;
const useDb = true;

export default async function getChaptersAsync(userId: string | undefined) {
    if (!isUserId(userId)) return new Array<WnaChapter>();

    const route = `users/${userId}/chapters`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return (
        (await fetchAndCacheGetAsync<WnaChapter[]>(
            route,
            url,
            encrypt,
            useDb
        )) ?? new Array<WnaChapter>()
    );
}
