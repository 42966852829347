import {
    initJsonRequest,
    prepareRequestAsync,
    setJwt,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";

const key = "jwt";
const encrypt = true;
const useDb = false;

export default async function verifyJwtAsync(userId: string) {
    try {
        const route = `users/${userId}/verifyJwt`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );

        let jwt = "";
        if (result instanceof Response) {
            jwt = await result.text();
            await WnaDataStorageProvider.setItemAsync(key, jwt, encrypt, useDb);
            setJwt(jwt);
        }

        return jwt;
    } catch (e) {
        Logger.error(verifyJwtAsync.name, e);
        return "";
    }
}
