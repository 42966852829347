import { setUserSettingsAsync } from "@/api/userSettings/userSettingsStorageProvider";
import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import { i18nKeys } from "@/services/i18n/i18nKeys";
import WnaCatalogItem from "@/types/wnaCatalogItem";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaSettingsRadioButton from "@components/buttons/WnaSettingsRadioButton";
import WnaNavigationItem from "@components/navigation/WnaNavigationItem";
import WnaNavigationList, {
    WnaMenuItem,
} from "@components/navigation/WnaNavigationList";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { WnaCatalogAppTheme } from "@constants/catalogs/wnaCatalogAppTheme";
import { WnaCatalogTabBarPosition } from "@constants/catalogs/wnaCatalogTabBarPosition";
import { WnaCatalogYesNo } from "@constants/catalogs/wnaCatalogYesNo";
import { CurrentColors } from "@constants/currentColors";
import { Redirect } from "expo-router";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Platform, useColorScheme } from "react-native";

export default function SettingsAdvancedScreen() {
    const {
        isAppInitialized,
        currentUserSettings,
        setCurrentUserSettings,
        appStyle,
        appColors,
        setAppColors,
    } = useContext(WnaAppContext);

    const currentUserSettingsRef = useRef<WnaUserSettings>(
        currentUserSettings ?? new WnaUserSettings()
    );
    const colorScheme = useColorScheme();
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [
        {
            route: "",
            text: t(i18nKeys.settingsTheme),
            icon: "cube",
            rightIcon: "",
            type: "theme",
            data: undefined,
        },
        {
            route: "",
            text: "TabBar Position",
            icon: "cube",
            rightIcon: "",
            type: "tabbar",
            data: undefined,
        },
        {
            route: "",
            text: t(i18nKeys.settingsAdvancedLogging),
            icon: "bug",
            rightIcon: "",
            type: "appIsLoggingEnabled",
            data: undefined,
        },
    ];

    // error protocol
    if (Platform.OS !== "web") {
        items.push(
            {
                route: "",
                text: t(i18nKeys.actionDeleteLog),
                icon: "bug",
                rightIcon: "trash",
                type: "clearLog",
                data: undefined,
            },
            {
                route: "", //WnaApplicationConstants.ScreenNameErrorLog,
                text: t("common:screenErrorLog"),
                icon: "file-code",
                rightIcon: "angle-right",
                type: "screen",
                data: undefined,
            }
        );
    }

    const onSelectCurrentAppThemeAsync = async (
        currentItem: WnaCatalogItem
    ) => {
        let newCurrentAppTheme = CurrentColors[colorScheme ?? "light"];
        if (currentItem.id === 1) newCurrentAppTheme = CurrentColors["light"];
        else if (currentItem.id === 2)
            newCurrentAppTheme = CurrentColors["dark"];

        setAppColors(newCurrentAppTheme);

        currentUserSettingsRef.current.appThemeId = currentItem.id;
        await setUserSettingsAsync(currentUserSettingsRef.current);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };
    const onSelectTabbarPositionAsync = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.tabBarPosition =
            currentItem.id === 0 ? 0 : 1;
        await setUserSettingsAsync(currentUserSettingsRef.current);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };
    const onSelectAppIsLoggingEnabled = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.appIsLoggingEnabled =
            currentItem.id === 1;
        await setUserSettingsAsync(currentUserSettingsRef.current);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const clearLogAsync = async () => {
        await Logger.deleteLogFileAsync();
        // WnaToastProvider.showSuccess(t(i18nKeys.success));
    };

    const onSelectAppIsOfflineCacheEnabled = async (_: WnaCatalogItem) => {
        // Logger.start(
        //     WnaSettingsAdvancedScreen.name,
        //     onSelectAppIsOfflineCacheEnabled.name
        // );
        // const newUserSettings = new WnaUserSettings(
        //     currentUserSettingsRef.current ?? new WnaUserSettings()
        // );
        // newUserSettings.appIsOfflineCacheEnabled = currentItem.id === 1;
        // await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        // setCurrentUserSettings(newUserSettings);
        // currentUserSettingsRef.current = newUserSettings;
        // Logger.end(
        //     WnaSettingsAdvancedScreen.name,
        //     onSelectAppIsOfflineCacheEnabled.name
        // );
    };

    const handleClickAsync = async (text: string) => {
        try {
            const currentItem = items.find((x) => x.text === text)!;

            if (currentItem.type === "clearLog") {
                await clearLogAsync();
                return;
            }

            // // navigate
            // if (currentItem.route === "") return;
            // props.navigation.push(currentItem.route, {
            //     currentItem,
            // });
        } catch (error) {
            Logger.error(handleClickAsync.name, error);
        }
    };

    const renderItem = (currentItem: any) => {
        switch (currentItem.type) {
            case "theme":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogAppTheme}
                        selectedId={currentUserSettingsRef.current?.appThemeId!}
                        isEditMode
                        onSelect={onSelectCurrentAppThemeAsync}
                    />
                );
            case "tabbar":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogTabBarPosition}
                        selectedId={
                            currentUserSettingsRef.current?.tabBarPosition ?? 1
                        }
                        isEditMode
                        onSelect={onSelectTabbarPositionAsync}
                    />
                );
            case "appIsLoggingEnabled":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettingsRef.current
                                ?.appIsLoggingEnabled === true
                                ? 1
                                : 0
                        }
                        isEditMode
                        onSelect={onSelectAppIsLoggingEnabled}
                    />
                );
            case "appIsOfflineCacheEnabled":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettingsRef.current
                                ?.appIsOfflineCacheEnabled === true
                                ? 1
                                : 0
                        }
                        isEditMode
                        onSelect={onSelectAppIsOfflineCacheEnabled}
                    />
                );
            default:
                return (
                    <WnaNavigationItem
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        iconName={currentItem.icon}
                        iconRightName={currentItem.rightIcon}
                        onPress={handleClickAsync}
                        t={t}
                    />
                );
        }
    };

    if (!currentUserSettings) return null;

    if (!isAppInitialized)
        return <Redirect href={WnaNavigationRouteProvider.menu} />;

    return (
        <WnaBaseScreen title={t(i18nKeys.screenTitleSettingsAdvanced)}>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreen>
    );
}
