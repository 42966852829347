import WnaCatalogItem from "@/types/wnaCatalogItem";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { StaticColors } from "../theme/staticColors";

export const WnaCatalogAppTheme: WnaCatalogItem[] = [
    {
        id: 0,
        text: i18nKeys.catalogThemeSystem,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.catalogThemeLight,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: i18nKeys.catalogThemeDark,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
];
