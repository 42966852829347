import getRouteDetailsAsync from "@/api/route/getRouteDetails";
import verifyGeoFileAsync from "@/api/route/verifyGeoFile";
import WnaRouteDetails, {
    getDefaultWnaRouteDetails,
} from "@/types/entities/wnaRouteDetails";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIconText from "@components/buttons/WnaButtonIconText";
import pickGeoFileAsync from "@components/maps/geodata/geoFilePicker";
import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import WnaPicker, { WnaDataSourceItem } from "@components/misc/WnaPicker";
import WnaTextWithIcon from "@components/text/WnaTextWithIcon";
import { i18nKeys } from "@services/i18n/i18nKeys";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { ValueType } from "react-native-dropdown-picker";
import Svg from "react-native-svg";
import Toast from "react-native-toast-message";
import WnaMapsGraph from "./WnaMapsGraph";

export type WnaMapsProps = {
    routeDetails?: WnaRouteDetails;
    paneWidth: number;
    onSelectGeoDataFile: (routeDetails: WnaRouteDetails) => void;
    onSelectRouteFromDropDown: (routeDetails: WnaRouteDetails) => void;
    routes: WnaDataSourceItem[];
    isEditMode: boolean;
};

const WnaMapsInfos: React.FC<WnaMapsProps> = (props) => {
    const marginRight = 32;
    const _defaultGraphWidth = props.paneWidth - (marginRight + 16);
    const _defaultGraphHeight =
        props.paneWidth / 2 > 300 ? 300 : props.paneWidth / 2;
    const { appColors, appStyle, currentUserSettings } =
        useContext(WnaAppContext);
    const [currentGraphWidth, setCurrentGraphWidth] =
        useState(_defaultGraphWidth);
    const [currentGraphHeight, setCurrentGraphHeight] =
        useState(_defaultGraphHeight);
    const [currentRouteDetails, setCurrentRouteDetails] =
        useState<WnaRouteDetails>(getDefaultWnaRouteDetails());
    const [isBusy, setIsBusy] = useState(true);
    const svgRef = useRef<Svg>(null);
    const chartRef = useRef<LineChart>(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const [routesDs, setRoutesDs] = useState<WnaDataSourceItem[]>([]);
    const [selectedRouteDsVal, setSelectedRouteDsVal] = useState<any>();
    const currentGraphDataRouteIdentifierRef = useRef<string>("");
    const { t } = useTranslation(["common"]);

    const loadData = () => {
        if (!props.routeDetails) {
            setIsBusy(false);
            return;
        }

        if (
            currentRouteDetails &&
            currentRouteDetails.route &&
            currentRouteDetails.graphData &&
            currentRouteDetails.route.identifier ===
                props.routeDetails.route.identifier
        ) {
            setIsBusy(false);
            return;
        }

        currentGraphDataRouteIdentifierRef.current =
            props.routeDetails.route.identifier;

        setCurrentGraphWidth(_defaultGraphWidth);
        setCurrentGraphHeight(_defaultGraphHeight);

        const graphData = props.routeDetails.graphData;
        if (graphData.yAxisData.length > 0) {
            setTimeout(async () => {
                if (currentUserSettings?.mapGraphAutoScale === 0) {
                    const gWidth = graphData.yAxisData.length; //graphData.distance / 50;
                    const gHeight =
                        ((graphData.yAxisMax - graphData.yAxisMin) / 200) * 32;
                    Logger.info(
                        "graphWidth: " +
                            gWidth.toString() +
                            " | graphHeight: " +
                            gHeight
                    );

                    // fixed width for legend y
                    setCurrentGraphWidth(64 + gWidth);
                    // fixed height for legend x
                    setCurrentGraphHeight(64 + gHeight);
                }

                setCurrentRouteDetails(props.routeDetails!);

                setIsBusy(false);
            }, 10);
        } else {
            setCurrentRouteDetails(getDefaultWnaRouteDetails());
            setIsBusy(false);
        }
    };

    useEffect(() => {
        setIsEditMode(props.isEditMode);
        loadData();
        if (props.routes !== routesDs) setRoutesDs(props.routes);

        const selectedRoute = props.routes.find(
            (x) => x.value === currentGraphDataRouteIdentifierRef.current
        );
        if (selectedRoute && selectedRouteDsVal !== selectedRoute.value)
            setSelectedRouteDsVal(selectedRoute.value);

        // eslint-disable-next-line
    }, [props, currentUserSettings]);

    const iconColor = appColors.accent4;
    const postfixColor = appColors.coolgray6;
    const infoItemWidth = 224;
    const onRouteSelectionChanged = async (val: ValueType | null) => {
        try {
            setIsBusy(true);
            if (val === currentGraphDataRouteIdentifierRef.current) return;

            const routeDetails = await getRouteDetailsAsync(val as string);
            if (routeDetails) props.onSelectRouteFromDropDown(routeDetails);
        } catch (error) {
            Logger.error("onSelectionChanged - routeSelected", error);
        }
    };

    const onRouteFileSelect = async () => {
        try {
            setIsBusy(true);

            const fileContent = await pickGeoFileAsync((errorMessage: string) =>
                Toast.show({
                    text1: errorMessage,
                    type: "error",
                })
            );
            if (!fileContent) return;

            // unselect picker value
            currentGraphDataRouteIdentifierRef.current = "";
            setSelectedRouteDsVal(null);

            const verifiedRouteDetails = await verifyGeoFileAsync(fileContent);
            if (!verifiedRouteDetails) {
                Toast.show({
                    text1: "could not verify geofile",
                    type: "error",
                });
                return;
            }

            verifiedRouteDetails.localFileContent = fileContent;
            props.onSelectGeoDataFile(verifiedRouteDetails);
        } catch (error) {
            Logger.error(onRouteFileSelect.name, error);
        } finally {
            setIsBusy(false);
        }
    };

    if (isBusy)
        return (
            <View
                style={{
                    height: 386,
                }}>
                <View style={appStyle.containerCenterCenter}>
                    <WnaActivityIndicator appColors={appColors} />
                </View>
            </View>
        );

    return (
        <View
            style={{
                gap: 16,
                paddingVertical: 16,
                width: props.paneWidth,
            }}>
            {isEditMode ? (
                <View
                    style={[
                        appStyle.containerCenter,
                        {
                            zIndex: 1000,
                            marginHorizontal: 16,
                            padding: 16,
                            gap: 16,
                            width: props.paneWidth - marginRight,
                            backgroundColor: appColors.white,
                        },
                    ]}>
                    <WnaButtonIconText
                        text="GPX | KML | GEOJSON"
                        appColors={appColors}
                        iconName="upload"
                        style={{ marginHorizontal: 16 }}
                        onPress={onRouteFileSelect}
                        t={t}
                        checkInternetConnection={true}
                    />

                    <WnaPicker
                        appStyle={appStyle}
                        appColors={appColors}
                        dataSource={routesDs}
                        selectedKey={selectedRouteDsVal}
                        onSelectionChanged={onRouteSelectionChanged}
                    />
                </View>
            ) : null}

            {currentRouteDetails.graphData.yAxisData &&
            currentRouteDetails.graphData.yAxisData.length > 0 ? (
                <View
                    style={{
                        flex: 1,
                        gap: 24,
                        flexDirection: "column",
                    }}>
                    <View
                        style={{
                            marginHorizontal: 16,
                            padding: 16,
                            width: props.paneWidth - marginRight,
                            backgroundColor: appColors.white,
                        }}>
                        <Text style={[appStyle.textTitleLarge, {}]}>
                            {currentRouteDetails.graphData.routeName}
                        </Text>
                    </View>

                    {/* infos */}
                    <View
                        style={{
                            backgroundColor: appColors.white,
                            marginHorizontal: 16,
                            padding: 16,
                            width: props.paneWidth - marginRight,
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                        <View style={{ width: infoItemWidth }}>
                            {/* Stecken Länge */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrows-alt-h"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixLength)}
                                text={
                                    currentRouteDetails.graphData.distanceText
                                }
                            />

                            {/* Stecken Dauer */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="clock"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixDuration)}
                                text={
                                    currentRouteDetails.graphData
                                        .totalDurationText
                                }
                            />

                            {/* Höchster Punkt */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="long-arrow-alt-up"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixHighestPoint)}
                                text={currentRouteDetails.graphData.altMaxText}
                            />

                            {/* Niedrigster Punkt */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="long-arrow-alt-down"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixLowestPoint)}
                                text={currentRouteDetails.graphData.altMinText}
                            />
                            {/* Delta höchster / Niedrigster Punkt */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrows-alt-v"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixDelta)}
                                text={currentRouteDetails.graphData.altDiffText}
                            />
                        </View>
                        <View style={{ width: infoItemWidth }}>
                            {/* Aufstieg */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrow-circle-up"
                                iconRotation={45}
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixUp)}
                                text={
                                    currentRouteDetails.graphData.altUpText +
                                    " (" +
                                    currentRouteDetails.graphData
                                        .altUpDistanceText +
                                    ")"
                                }
                            />

                            {/* Abstieg */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrow-circle-right"
                                iconRotation={45}
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixDown)}
                                text={
                                    currentRouteDetails.graphData.altDownText +
                                    " (" +
                                    currentRouteDetails.graphData
                                        .altDownDistanceText +
                                    ")"
                                }
                            />

                            {/* Ebene */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                appStyle={appStyle}
                                appColors={appColors}
                                iconName="arrow-alt-circle-right"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeys.mapInfoPostfixEqual)}
                                text={
                                    currentRouteDetails.graphData
                                        .altEqualDistanceText
                                }
                            />
                        </View>
                    </View>

                    {/* graph */}
                    <View
                        style={{
                            marginLeft: 16,
                            paddingTop: 16,
                            width: props.paneWidth - marginRight,
                            backgroundColor: appColors.white,
                        }}>
                        <WnaMapsGraph
                            svgRef={svgRef}
                            chartRef={chartRef}
                            appStyle={appStyle}
                            appColors={appColors}
                            width={_defaultGraphWidth}
                            graphHeight={currentGraphHeight}
                            graphWidth={currentGraphWidth}
                            xAxisData={currentRouteDetails.graphData.xAxisData}
                            yAxisData={currentRouteDetails.graphData.yAxisData}
                            yAxisMax={currentRouteDetails.graphData.yAxisMax}
                            yAxisMin={currentRouteDetails.graphData.yAxisMin}
                        />
                    </View>
                </View>
            ) : (
                <View
                    style={{
                        marginHorizontal: 16,
                        padding: 16,
                        width: props.paneWidth - marginRight,
                        backgroundColor: appColors.white,
                    }}>
                    <Text style={[appStyle.textTitleLarge, {}]}>
                        {t(i18nKeys.infoNoMapData)}
                    </Text>
                </View>
            )}
        </View>
    );
};

export default WnaMapsInfos;
