import getNotificationAsync from "@/api/notification/getNotification";
import markAsReadAsync from "@/api/notification/markNotificationAsRead";
import WnaNotification from "@/types/entities/wnaNotification";
import { dateToStringDateTimeWeekdayShort } from "@/utils/dateTimeHelper";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { Redirect, useLocalSearchParams } from "expo-router";
import { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import Toast from "react-native-toast-message";

export default function NotificationScreen() {
    const { notificationIdentifier } = useLocalSearchParams<{
        notificationIdentifier: string;
    }>();

    const { isAppInitialized, currentUser, appColors, appStyle } =
        useContext(WnaAppContext);
    const [notification, setNotification] = useState<WnaNotification>();
    const [isBusy, setIsBusy] = useState(true);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);

    useEffect(() => {
        if (isBusy) {
            getNotificationAsync(currentUser?.id, notificationIdentifier).then(
                (notification) => {
                    if (!notification) {
                        setRedirectToNotFound(true);
                        Toast.show({
                            text1: "not found",
                            type: "error",
                        });
                        return;
                    }

                    markAsReadAsync(
                        currentUser?.id,
                        notificationIdentifier
                    ).then(() => {
                        setNotification(notification);
                        setIsBusy(false);
                    });
                }
            );
        }
        // eslint-disable-next-line
    }, [notificationIdentifier]);

    if (!isAppInitialized) return null;
    if (!currentUser)
        return <Redirect href={WnaNavigationRouteProvider.root} />;
    if (redirectToNotFound)
        return <Redirect href={WnaNavigationRouteProvider.notifications} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={dateToStringDateTimeWeekdayShort(notification?.dateCreate)}>
            <ScrollView
                style={[appStyle.containerCenterMaxWidth, { padding: 16 }]}>
                <View
                    style={{
                        backgroundColor: appColors.white,
                        padding: 16,
                    }}>
                    <View style={{ alignSelf: "stretch", paddingBottom: 0 }}>
                        <Text
                            style={[
                                appStyle.textTitleLarge,
                                {
                                    width: "100%",
                                    color: appColors.black,
                                },
                            ]}>
                            {notification?.designator}
                        </Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            alignSelf: "stretch",
                        }}>
                        <Text
                            style={[
                                appStyle.textSmall,
                                {
                                    height: "100%",
                                    width: "100%",
                                    color: appColors.black,
                                },
                            ]}>
                            {notification?.comment}
                        </Text>
                    </View>
                </View>
            </ScrollView>
        </WnaBaseScreen>
    );
}
