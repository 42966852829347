import deleteDiaryEntryAsync from "@/api/diaryEntry/deleteDiaryEntry";
import getDiaryEntriesAsync from "@/api/diaryEntry/getDiaryEntries";
import getDiaryEntryAsync from "@/api/diaryEntry/getDiaryEntry";
import updateDiaryEntryAsync from "@/api/diaryEntry/updateDiaryEntry";
import uploadDiaryEntryImageAsync from "@/api/diaryEntry/uploadDiaryEntryImage";
import downloadFileAsync from "@/api/fileDownload";
import getDiaryEntryPlaceholdersAsync from "@/api/resources/getDiaryEntryPlaceholders";
import createRouteAsync from "@/api/route/createRoute";
import getRouteDetailsAsync from "@/api/route/getRouteDetails";
import WnaDiaryEntry, {
    getDiaryEntryHeaderTitle,
    sanitizeDiaryEntry,
} from "@/types/entities/wnaDiaryEntry";
import WnaFileInfo from "@/types/entities/wnaFileInfo";
import WnaRouteDetails, {
    getDefaultWnaRouteDetails,
} from "@/types/entities/wnaRouteDetails";
import {
    DefaultWnaDiaryEntryPlaceholders,
    WnaDiaryEntryPlaceholders,
} from "@/types/wnaDiaryEntryPlaceholder";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import DialogProvider from "@components/dialog/dialogProvider";
import WnaDiaryEntryDataView from "@components/diaryEntry/WnaDiaryEntryDataView";
import WnaDiaryEntryNavigator from "@components/diaryEntry/WnaDiaryEntryNavigator";
import WnaDiaryEntryTabBar from "@components/diaryEntry/WnaDiaryEntryTabBar";
import WnaCarousel from "@components/images/WnaCarousel";
import WnaMaps from "@components/maps/WnaMaps";
import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useLocalSearchParams, useRouter } from "expo-router";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowDimensions, View } from "react-native";
import { SceneRendererProps, TabView } from "react-native-tab-view";
import Toast from "react-native-toast-message";

export default function DiaryEntryScreen() {
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const layout = useWindowDimensions();
    const {
        isAppInitialized,
        currentUser,
        currentUserSettings,
        appColors,
        appStyle,
    } = useContext(WnaAppContext);

    const { chapterIdentifier, diaryEntryIdentifier, tab, isEditMode } =
        useLocalSearchParams<{
            chapterIdentifier: string;
            diaryEntryIdentifier: string;
            isEditMode?: string;
            tab?: string;
        }>();

    const [isBusy, setIsBusy] = useState(false);
    const [isBusyText, setIsBusyText] = useState("");
    const cancelSaveRef = useRef<boolean>(false);
    const [refreshOnUpdate, setRefreshOnUpdate] = useState(false);
    const [preventGoBack, setPreventGoBack] = useState(false);
    const [navigateNext, setNavigateNext] = useState(false);
    const [navigatePrev, setNavigatePrev] = useState(false);
    const diaryEntryRef = useRef<WnaDiaryEntry>();
    const diaryEntriesRef = useRef<WnaDiaryEntry[]>();
    const diaryEntryRouteDetailsRef = useRef<WnaRouteDetails | undefined>();
    const [diaryEntryRouteDetails, setDiaryEntryRouteDetails] = useState<
        WnaRouteDetails | undefined
    >(undefined);
    const [backgroundImageB64, setBackgroundImageB64] = useState("");
    const [placeholders, setPlaceholders] = useState<WnaDiaryEntryPlaceholders>(
        DefaultWnaDiaryEntryPlaceholders
    );

    let initialTab = currentUserSettings?.diaryDefaultTab ?? 0;
    if (
        !(currentUserSettings?.diaryRestoreDiaryTab ?? false) &&
        tab &&
        !Number.isNaN(tab)
    ) {
        const pTabIndex = Number.parseInt(tab);
        if (pTabIndex >= 0 && pTabIndex <= 2) initialTab = pTabIndex;
    }

    const isEditModeRef = useRef<boolean>(!!isEditMode);
    const [redirectTo, setRedirectTo] = useState(false);
    const tabViewIndexRef = useRef<number>(initialTab);
    const [tabViewIndex, setTabViewIndex] = useState(initialTab);
    const [tabViewRoutes] = useState([
        { key: "entry", title: t(i18nKeys.diaryEntryTabNameEntry) },
        { key: "map", title: t(i18nKeys.diaryEntryTabNameMap) },
        { key: "fotos", title: t(i18nKeys.diaryEntryTabNamePictures) },
    ]);

    const enableEditMode = () =>
        router.replace(
            WnaNavigationRouteProvider.diaryEntry(
                chapterIdentifier,
                diaryEntryRef.current?.identifier!,
                true,
                tabViewIndexRef.current
            )
        );

    const undoChanges = () =>
        router.replace(
            WnaNavigationRouteProvider.diaryEntry(
                chapterIdentifier,
                diaryEntryRef.current?.identifier!
            )
        );

    const saveChanges = async () => {
        if (isBusy) return;

        setIsBusyText("");
        setIsBusy(true);
        cancelSaveRef.current = false;
        const localImages = diaryEntryRef.current!.images.filter(
            (x) => x.isLocal
        );

        // images
        if (localImages && localImages.length > 0) {
            let localImageProgress = 1;
            for (const localImage of localImages) {
                setIsBusyText(`${localImageProgress} / ${localImages.length}`);
                if (cancelSaveRef.current) break;

                const resultDiaryEntry = await uploadDiaryEntryImageAsync(
                    currentUser!.id,
                    chapterIdentifier,
                    diaryEntryIdentifier,
                    localImage.fileName,
                    localImage.url
                );

                if (!resultDiaryEntry) {
                    Toast.show({
                        text1: t(i18nKeys.errorSave),
                        type: "error",
                    });
                    setIsBusy(false);
                    return;
                }
                localImageProgress += 1;
            }
        }

        // route
        if (
            diaryEntryRouteDetailsRef.current &&
            diaryEntryRouteDetailsRef.current.localFileContent &&
            diaryEntryRouteDetailsRef.current.route &&
            diaryEntryRouteDetailsRef.current.graphData
        ) {
            setIsBusyText(`Route ...`);
            const resultRoute = await createRouteAsync(
                diaryEntryRouteDetailsRef.current.route,
                diaryEntryRouteDetailsRef.current.localFileContent
            );
            if (resultRoute) {
                diaryEntryRouteDetailsRef.current = {
                    route: resultRoute,
                    graphData: diaryEntryRouteDetailsRef.current.graphData,
                } as WnaRouteDetails;
                setDiaryEntryRouteDetails(diaryEntryRouteDetailsRef.current);
            }
        }

        const resultDiaryEntry = await updateDiaryEntryAsync(
            currentUser!.id,
            chapterIdentifier,
            sanitizeDiaryEntry(diaryEntryRef.current!)
        );

        if (!resultDiaryEntry) {
            Toast.show({
                text1: t(i18nKeys.errorSave),
                type: "error",
            });
            setIsBusy(false);
            return;
        }

        setRefreshOnUpdate(true);
        Toast.show({
            text1: t(i18nKeys.successSave),
            type: "success",
        });
        setIsBusy(false);
    };

    const updateData = (
        dateStart: Date,
        designator: string,
        comment: string
    ) => {
        diaryEntryRef.current!.dateStart = dateStart;
        diaryEntryRef.current!.dateEnd = dateStart;
        diaryEntryRef.current!.designator = designator;
        diaryEntryRef.current!.comment = comment;
    };

    const updateImages = (fileInfos: WnaFileInfo[]) => {
        diaryEntryRef.current!.images = fileInfos;
    };

    const updateRoute = (routeDetails: WnaRouteDetails) => {
        diaryEntryRef.current!.routeIdentifier = routeDetails.route.identifier;
        diaryEntryRouteDetailsRef.current = routeDetails;
        setDiaryEntryRouteDetails(routeDetails);
    };

    const deleteDiaryEntry = () => {
        DialogProvider.showYesNo(t(i18nKeys.questionDelete), () => {
            if (
                !currentUser ||
                !chapterIdentifier ||
                !diaryEntryRef.current ||
                isBusy
            )
                return;

            setIsBusyText("");
            setIsBusy(true);
            deleteDiaryEntryAsync(
                currentUser.id,
                chapterIdentifier,
                diaryEntryRef.current.identifier
            ).then((resultDiaryEntry) => {
                if (!resultDiaryEntry) {
                    Toast.show({
                        text1: t(i18nKeys.errorDelete),
                        type: "error",
                    });
                    setIsBusy(false);
                    return;
                }

                setIsBusy(false);
                const route =
                    WnaNavigationRouteProvider.chaptersIdentifierDiaryEntries(
                        chapterIdentifier
                    );
                router.dismissTo(route);
                router.replace(route);
                Toast.show({
                    text1: t(i18nKeys.successDelete),
                    type: "success",
                });
            });
        });
    };

    const loadDataAsync = async () => {
        try {
            setIsBusy(true);

            if (isEditModeRef.current) {
                // Fetch placeholders first
                const placeholders = await getDiaryEntryPlaceholdersAsync();
                setPlaceholders(placeholders);
            }

            diaryEntriesRef.current = await getDiaryEntriesAsync(
                currentUser?.id ?? "",
                chapterIdentifier,
                true,
                currentUserSettings?.diaryOrderBy === 1
            );

            // Fetch diary entry details
            const diaryEntry = await getDiaryEntryAsync(
                currentUser?.id ?? "",
                chapterIdentifier,
                diaryEntryIdentifier,
                false
            );

            if (!diaryEntry) {
                setRedirectTo(true);
                Toast.show({
                    text1: "not found",
                    type: "error",
                });
                return;
            }

            // Set the routeDetails, either from the diary entry or default
            diaryEntryRouteDetailsRef.current = diaryEntry.routeIdentifier
                ? await getRouteDetailsAsync(diaryEntry.routeIdentifier)
                : getDefaultWnaRouteDetails();
            setDiaryEntryRouteDetails(diaryEntryRouteDetailsRef.current);

            // Download the image and set it
            const diaryEntryImageB64 = await downloadFileAsync(
                diaryEntry!.imageUrl
            );
            setBackgroundImageB64(diaryEntryImageB64);

            // Store the diary entry reference
            diaryEntryRef.current = diaryEntry;
        } catch (error) {
            console.error("Error loading data:", error);
            // Optionally handle the error by updating state or showing an error message
        } finally {
            setIsBusy(false);
        }
    };

    // @ts-ignore
    const renderScene = ({ route }) => {
        if (!diaryEntryRef.current) return null;

        switch (route.key) {
            case "entry":
                return (
                    <WnaDiaryEntryDataView
                        appStyle={appStyle}
                        appColors={appColors}
                        diaryEntry={diaryEntryRef.current}
                        onUpdate={updateData}
                        isEditMode={isEditModeRef.current}
                        placeholders={placeholders!}
                    />
                );
            case "fotos":
                return (
                    <WnaCarousel
                        fileInfos={diaryEntryRef.current?.images}
                        onCollectionChanged={updateImages}
                        onSetIsBusy={(isBusy) => setPreventGoBack(isBusy)}
                        isEditMode={isEditModeRef.current}
                        userId={currentUser!.id}
                        chapterIdentifier={chapterIdentifier}
                        diaryEntryIdentifier={diaryEntryIdentifier}
                        currentTab={tabViewIndexRef.current}
                        currentOrder={currentUserSettings?.diaryOrderBy ?? 0}
                    />
                );
            case "map":
                return (
                    <WnaMaps
                        routeDetails={diaryEntryRouteDetails}
                        onRouteSelected={updateRoute}
                        isEditMode={isEditModeRef.current}
                    />
                );
            default:
                return null;
        }
    };

    const renderPlaceHolder = () => {
        return (
            <View style={appStyle.containerCenterCenter}>
                <WnaActivityIndicator appColors={appColors} />
            </View>
        );
    };

    useEffect(() => {
        loadDataAsync();
        // eslint-disable-next-line
    }, [currentUser, chapterIdentifier, diaryEntryIdentifier]);

    if (!isAppInitialized) return null;
    if (!currentUser || redirectTo)
        return <Redirect href={WnaNavigationRouteProvider.chapters} />;

    let title = getDiaryEntryHeaderTitle(
        diaryEntryRef.current,
        diaryEntriesRef.current
    );

    return (
        <WnaBaseScreen
            title={title}
            isBusy={isBusy}
            isBusyText={isBusyText}
            backgroundImageUrl={backgroundImageB64}
            preventBack={preventGoBack}
            headerButton0={
                isEditModeRef.current ? (
                    <WnaButtonHeader
                        text={t(i18nKeys.actionDelete)}
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="trash-alt"
                        onPress={deleteDiaryEntry}
                        t={t}
                        checkInternetConnection={true}
                    />
                ) : (
                    <WnaButtonHeader
                        text={t(i18nKeys.actionEdit)}
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="pen"
                        onPress={enableEditMode}
                        t={t}
                        checkInternetConnection={true}
                    />
                )
            }
            headerButton1={
                isEditModeRef.current ? (
                    <WnaButtonHeader
                        text={t(i18nKeys.actionUndo)}
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="undo"
                        onPress={undoChanges}
                        t={t}
                        checkInternetConnection={true}
                    />
                ) : (
                    <WnaButtonHeader
                        text={t(i18nKeys.actionPreviousEntry)}
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="arrow-alt-circle-left"
                        onPress={() => setNavigatePrev(true)}
                        t={t}
                        checkInternetConnection={false}
                    />
                )
            }
            headerButton2={
                isEditModeRef.current ? (
                    <WnaButtonHeader
                        text={t(i18nKeys.actionSave)}
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="check"
                        onPress={saveChanges}
                        t={t}
                        checkInternetConnection={true}
                    />
                ) : (
                    <WnaButtonHeader
                        text={t(i18nKeys.actionNextEntry)}
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="arrow-alt-circle-right"
                        onPress={() => setNavigateNext(true)}
                        t={t}
                        checkInternetConnection={false}
                    />
                )
            }>
            <>
                <TabView
                    navigationState={{
                        index: tabViewIndex,
                        routes: tabViewRoutes,
                    }}
                    renderScene={renderScene}
                    renderTabBar={(props: SceneRendererProps) => (
                        <WnaDiaryEntryTabBar
                            appColors={appColors}
                            userSettings={currentUserSettings!}
                            sceneRendererProps={props}
                        />
                    )}
                    onIndexChange={(index) => {
                        setTabViewIndex(index);
                        tabViewIndexRef.current = index;
                    }}
                    swipeEnabled={false}
                    initialLayout={{ width: layout.width }}
                    lazy
                    tabBarPosition={
                        (currentUserSettings?.tabBarPosition ?? 1) === 0
                            ? "top"
                            : "bottom"
                    }
                    renderLazyPlaceholder={renderPlaceHolder}
                />
                <WnaDiaryEntryNavigator
                    userId={currentUser.id}
                    chapterIdentifier={chapterIdentifier}
                    diaryEntryIdentifier={diaryEntryIdentifier}
                    tab={tabViewIndexRef.current}
                    navigateToPev={navigatePrev}
                    navigateToNext={navigateNext}
                    refreshOnUpdate={refreshOnUpdate}
                    order={currentUserSettings?.diaryOrderBy ?? 0}
                />
            </>
        </WnaBaseScreen>
    );
}
