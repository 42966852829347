import { WnaAppContext } from "@app/WnaAppContext";
import WnaNavigationItem from "@components/navigation/WnaNavigationItem";
import WnaNavigationList, {
    WnaMenuItem,
} from "@components/navigation/WnaNavigationList";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useRouter } from "expo-router";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function SettingsScreen() {
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const { isAppInitialized, appColors, appStyle } = useContext(WnaAppContext);

    const cancelActionRef = useRef(false);
    const items: WnaMenuItem[] = [
        {
            route: WnaNavigationRouteProvider.menuSettingsDiary,
            text: t(i18nKeys.wordDiary),
            icon: "book-open",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            route: WnaNavigationRouteProvider.menuSettingsMap,
            text: t(i18nKeys.wordMap),
            icon: "map",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            route: WnaNavigationRouteProvider.menuSettingsAdvanced,
            text: t(i18nKeys.wordAdvanced),
            icon: "cogs",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
    ];

    const handleClick = async (text: string) => {
        const currentItem = items.find((x) => x.text === text)!;
        if (currentItem.route === "") return;

        // navigate
        router.push(currentItem.route);
    };

    const renderItem = (currentItem: WnaMenuItem) => (
        <WnaNavigationItem
            appStyle={appStyle}
            appColors={appColors}
            text={currentItem.text}
            iconName={currentItem.icon}
            onPress={handleClick}
            iconRightName={currentItem.rightIcon}
            t={t}
        />
    );

    if (!isAppInitialized)
        return <Redirect href={WnaNavigationRouteProvider.root} />;

    return (
        <WnaBaseScreen
            title={t(i18nKeys.screenTitleSettings)}
            onCancel={() => (cancelActionRef.current = true)}>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreen>
    );
}
