import WnaCatalogItem from "@/types/wnaCatalogItem";
import { WnaAppContext } from "@app/WnaAppContext";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, Text, View } from "react-native";

export type WnaRadioButtonVerticalProps = {
    data: WnaCatalogItem[];
    selectedId: number | null;
    onSelect: (item: WnaCatalogItem) => void;
    isEditMode: boolean;
};
const WnaRadioButtonVertical: React.FC<WnaRadioButtonVerticalProps> = (
    props
) => {
    const { appColors } = useContext(WnaAppContext);
    const [selectedItem, setSelectedItem] = useState<WnaCatalogItem | null>(
        props.data.find((item) => item.id === 0) ?? null
    );

    const [isEditMode, setIsEditMode] = useState(false);
    const isEditModeRef = useRef(isEditMode);
    const { t } = useTranslation(["common"]);
    const selectHandler = (item: WnaCatalogItem) => {
        if (!isEditMode) return;

        props.onSelect(item);
        setSelectedItem(item);
    };

    useEffect(() => {
        setIsEditMode(props.isEditMode);
        isEditModeRef.current = props.isEditMode;

        let selectedItem =
            props.selectedId === undefined || props.selectedId === null
                ? (props.data.find((item) => item.id === 0) ?? null)
                : (props.data.find((item) => item.id === props.selectedId) ??
                  null);
        setSelectedItem(selectedItem);
    }, [props]);

    if (props.data.length < 1) return null;
    return (
        <View style={styles(appColors).container}>
            {props.data.map((item) => {
                return (
                    <Pressable
                        key={item.id}
                        onPress={() => selectHandler(item)}
                        style={[
                            {
                                backgroundColor:
                                    item.selectionColor ?? appColors.coolgray6,
                                flexGrow: 1,
                                flexShrink: 1,
                            },
                            item.id === selectedItem?.id
                                ? styles(appColors).selected
                                : styles(appColors).unselected,
                        ]}>
                        <View style={styles(appColors).item}>
                            <FontAwesome5
                                color={appColors.staticWhite}
                                size={20}
                                name={
                                    item.id === selectedItem?.id
                                        ? "dot-circle"
                                        : "circle"
                                }
                            />
                            {item.selectionIcon !== null ? (
                                <FontAwesome5 name={item.selectionIcon} />
                            ) : null}
                            <Text style={styles(appColors).text}>
                                {t(item.text ?? "")}
                            </Text>
                        </View>
                    </Pressable>
                );
            })}
        </View>
    );
};

export default WnaRadioButtonVertical;

const styles = (appColors: Colors) =>
    StyleSheet.create({
        container: {
            display: "flex",
            borderRadius: 20,
            borderColor: appColors.coolgray2,
            borderWidth: 1,
            backgroundColor: appColors.coolgray2,
            width: "100%",
            overflow: "hidden",
            flexDirection: "column",
            gap: 1,
        },
        item: {
            width: 96,
            height: 40,
            maxHeight: 40,
            paddingVertical: 8,
            paddingHorizontal: 16,
            gap: 4,
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        text: {
            color: appColors.staticWhite,
        },
        unselected: {
            opacity: 0.7,
        },
        selected: {
            opacity: 1,
        },
    });
