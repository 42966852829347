import Colors from "@constants/theme/colors";
import { Component } from "react";
import { ActivityIndicator, ViewStyle } from "react-native";

export type WnaActivityIndicatorProps = {
    appColors: Colors;
    style?: ViewStyle;
};

class WnaActivityIndicator extends Component<WnaActivityIndicatorProps> {
    render() {
        return (
            <ActivityIndicator
                size={48}
                style={[this.props.style]}
                color={this.props.appColors.accent4}
                aria-label={"activity-indicator"}
            />
        );
    }
}

export default WnaActivityIndicator;
