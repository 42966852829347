import { isHtml, stripHtml } from "@/utils/htmlSanitizer";
import WnaHtmlRenderer from "@components/misc/WnaHtmlRenderer";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { Component } from "react";
import { Text, TextStyle } from "react-native";

export type WnaTextProps = {
    appColors: Colors;
    appStyle: AppStyle;
    text?: string;
    style?: TextStyle | TextStyle[];
    numberOfLines?: number;
    ellipseMode?: "clip" | "head" | "middle" | "tail";
    textBreakStrategy?: "balanced" | "simple" | "highQuality";
    showHtml?: boolean;
    fontFamily?: string;
    fontSize?: number;
    fontColor?: string;
    maxHeight?: number;
};

class WnaText extends Component<WnaTextProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaTextProps>): boolean {
        return (
            nextProps.text !== this.props.text ||
            nextProps.appColors.isDark !== this.props.appColors.isDark
        );
    }

    render() {
        const showHtml = this.props.showHtml ?? isHtml(this.props.text);
        const numberOfLines = this.props.numberOfLines
            ? this.props.numberOfLines
            : 0;
        const ellipseMode = this.props.ellipseMode
            ? this.props.ellipseMode
            : "clip";
        const textBreakStrategy = this.props.textBreakStrategy
            ? this.props.textBreakStrategy
            : "simple";
        const fontColor = this.props.fontColor ?? this.props.appColors.black;
        return showHtml ? (
            <WnaHtmlRenderer
                appStyle={this.props.appStyle}
                appColors={this.props.appColors}
                width={2048}
                maxHeight={this.props.maxHeight}
                html={this.props.text}
                fontFamily={this.props.fontFamily}
                fontSize={this.props.fontSize}
                fontColor={fontColor}
            />
        ) : (
            <Text
                style={[
                    this.props.style
                        ? this.props.style
                        : this.props.appStyle.textSmall,
                    {
                        color: fontColor,
                    },
                ]}
                textBreakStrategy={textBreakStrategy}
                numberOfLines={numberOfLines}
                ellipsizeMode={ellipseMode}>
                {stripHtml(this.props.text)}
            </Text>
        );
    }
}

export default WnaText;
