import createChapterAsync from "@/api/chapter/createChapter";
import getChaptersAsync from "@/api/chapter/getChapters";
import WnaChapter, { getDefaultChapter } from "@/types/entities/wnaChapter";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIcon from "@components/buttons/WnaButtonIcon";
import WnaChapterListEntry from "@components/chapter/WnaChapterListEntry";
import DialogProvider from "@components/dialog/dialogProvider";
import WnaDiaryEntryListDummy from "@components/diaryEntry/WnaDiaryEntryListDummy";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaNotificationHeaderRight from "@components/navigation/WnaNotificationHeaderRight";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { useFocusEffect, useRouter } from "expo-router";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, RefreshControl, View } from "react-native";
import Toast from "react-native-toast-message";

export default function ChaptersScreen() {
    const router = useRouter();
    const { t } = useTranslation(["common"]);
    const {
        isAppInitialized,
        appColors,
        appStyle,
        currentUser,
        unreadNotificationCount,
    } = useContext(WnaAppContext);

    const navigateToLogin = () => {
        router.push(WnaNavigationRouteProvider.menuProfileLogin);
    };

    const [chapters, setChapters] = useState<WnaChapter[]>([]);
    const [isBusy, setIsBusy] = useState(true);

    const loadDataAsync = useCallback(async () => {
        if (!currentUser) return;

        const resultChapters = await getChaptersAsync(currentUser.id);
        setChapters(resultChapters);

        setIsBusy(false);
        // eslint-disable-next-line
    }, [currentUser]);

    const renderItem = (chapter: WnaChapter) => {
        return (
            <WnaChapterListEntry
                appStyle={appStyle}
                appColors={appColors}
                currentItem={chapter}
                onPress={() => {
                    router.push(
                        WnaNavigationRouteProvider.chaptersIdentifierDiaryEntries(
                            chapter.identifier
                        )
                    );
                }}
                t={t}
            />
        );
    };

    const createChapter = () => {
        if (!currentUser) return;

        DialogProvider.showYesNo(t(i18nKeys.questionCreateNewChapter), () => {
            const chapterToCreate = getDefaultChapter();
            chapterToCreate.designator =
                "Kapitel " + (chapters.length + 1).toString();

            createChapterAsync(currentUser.id, chapterToCreate).then(
                async (resultChapter) => {
                    if (!resultChapter) {
                        Toast.show({
                            text1: t(i18nKeys.errorSave),
                            type: "error",
                        });
                        return;
                    }

                    await loadDataAsync();

                    router.push(
                        WnaNavigationRouteProvider.chaptersIdentifierDiaryEntries(
                            chapterToCreate.identifier
                        )
                    );

                    Toast.show({
                        text1: t(i18nKeys.successSave),
                        type: "success",
                    });
                }
            );
        });
    };

    useFocusEffect(
        useCallback(() => {
            loadDataAsync();
            // eslint-disable-next-line
        }, [loadDataAsync])
    );

    if (!isAppInitialized) return null;

    return (
        <WnaBaseScreen
            title={t(i18nKeys.screenTitleDayList)}
            headerButton0={
                <WnaNotificationHeaderRight
                    unreadNotificationCount={unreadNotificationCount}
                    appStyle={appStyle}
                    appColors={appColors}
                    currentUser={currentUser}
                    router={router}
                    t={t}
                />
            }>
            {!currentUser ? (
                <WnaDiaryEntryListDummy
                    appColors={appColors}
                    appStyle={appStyle}
                    onLogIn={navigateToLogin}
                    t={t}
                />
            ) : (
                <>
                    <FlatList
                        keyExtractor={(item) => item.identifier}
                        ItemSeparatorComponent={() => (
                            <View style={{ height: 8 }} />
                        )}
                        data={chapters}
                        contentContainerStyle={{
                            paddingBottom: 80,
                            paddingTop: 16,
                            paddingHorizontal: 16,
                        }}
                        initialNumToRender={3}
                        maxToRenderPerBatch={3}
                        refreshing={isBusy}
                        maintainVisibleContentPosition={{
                            minIndexForVisible: 0, // 🔥 Keeps scroll position
                        }}
                        refreshControl={
                            <RefreshControl
                                refreshing={isBusy}
                                onRefresh={() => loadDataAsync()}
                            />
                        }
                        renderItem={({ item: currentItem }) =>
                            renderItem(currentItem)
                        }
                    />
                    {/* Add Button */}
                    <WnaButtonIcon
                        toolTip={t(i18nKeys.actionCreateChapter)}
                        toolTipPosition="left"
                        appStyle={appStyle}
                        appColors={appColors}
                        iconName="plus"
                        style={{
                            position: "absolute",
                            bottom: 16,
                            right: 16,
                        }}
                        onPress={createChapter}
                        t={t}
                        checkInternetConnection={true}
                    />
                </>
            )}
        </WnaBaseScreen>
    );
}
