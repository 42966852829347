import WnaCatalogItem from "@/types/wnaCatalogItem";
import { StaticColors } from "../theme/staticColors";

export const WnaCatalogTabBarPosition: WnaCatalogItem[] = [
    {
        id: 0,
        text: "Top",
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: "Bottom",
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
];
