import { getPdfAsync } from "@/api/diaryGenerator/getDiaryAsPdf";
import { blobToBase64Async } from "@/utils/base64Converter";
import Logger from "@/utils/logger";
import { i18nKeys } from "@services/i18n/i18nKeys";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { TFunction } from "i18next";
import { Platform } from "react-native";
import Toast from "react-native-toast-message";

const showPdfAsync = async (data: Blob) => {
    if (Platform.OS !== "web") {
        const b64 = await blobToBase64Async(data);
        const fileUri = `${FileSystem.documentDirectory}/pdf.pdf`;
        await FileSystem.writeAsStringAsync(fileUri, b64, {
            encoding: "base64",
        });
        await Sharing.shareAsync(fileUri);
    } else {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL, "_blank");
    }
};

export default async function generatePdfAsync(
    userId: string | undefined,
    chapterIdentifier: string | undefined,
    t: TFunction<string[], undefined>
) {
    try {
        const getPdfResponse = await getPdfAsync(userId, chapterIdentifier);
        switch (getPdfResponse!.httpStatusCode) {
            case 200:
                await showPdfAsync(getPdfResponse!.data);
                break;
            case 201:
            case 202:
            case 203:
            case 204:
            case 205:
            case 206:
                Toast.show({
                    text1: t(i18nKeys.infoPdfCreation),
                    type: "info",
                });
                break;
            default:
                Toast.show({
                    text1: t(i18nKeys.errorUnknown),
                    type: "error",
                });
                break;
        }
    } catch (error) {
        Logger.error(generatePdfAsync.name, error);
    }
}
