import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIconDecent from "@components/buttons/WnaButtonIconDecent";
import WnaButtonTextDecent from "@components/buttons/WnaButtonTextDecent";
import DialogProvider from "@components/dialog/dialogProvider";
import { WnaShadowStyle } from "@components/misc/WnaShadowStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, StyleSheet, Text, View } from "react-native";

export default function WnaDialog() {
    const { appColors, appStyle } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const [isVisible, setIsVisible] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    useEffect(() => {
        DialogProvider.init(setIsVisible, setShowCancel);
    }, []);
    return (
        <Modal
            transparent
            statusBarTranslucent
            visible={isVisible}
            onRequestClose={() => DialogProvider.onCancel}
            animationType="fade">
            <View
                style={[
                    appStyle.containerCenterCenter,
                    { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                ]}>
                <View
                    style={[
                        componentStyle(appColors).dialogIcon,
                        { backgroundColor: appColors.accent4 },
                    ]}>
                    {showCancel ? (
                        <FontAwesome5
                            name="question"
                            size={32}
                            color={appColors.staticWhite}
                        />
                    ) : (
                        <FontAwesome5
                            name="info"
                            size={32}
                            color={appColors.staticWhite}
                        />
                    )}
                </View>
                <View
                    style={[
                        WnaShadowStyle(),
                        appStyle.containerCenter,
                        componentStyle(appColors).mainWrapper,
                    ]}>
                    <View style={componentStyle(appColors).dialogTextContainer}>
                        <Text
                            style={[
                                appStyle.textSmall,
                                {
                                    fontSize: 20,
                                    color: appColors.black,
                                },
                            ]}>
                            {DialogProvider.text}
                        </Text>
                    </View>
                    <View style={componentStyle(appColors).buttonContainer}>
                        {showCancel ? (
                            <>
                                <WnaButtonTextDecent
                                    text={t("answerNo")}
                                    onPress={DialogProvider.onCancel}
                                    t={t}
                                    checkInternetConnection={false}
                                    appColors={appColors}
                                    style={[
                                        componentStyle(appColors).button,
                                        {
                                            borderRightColor:
                                                appColors.coolgray2,
                                            borderRightWidth: 1,
                                        },
                                    ]}
                                />
                                <WnaButtonTextDecent
                                    text={t("answerYes")}
                                    onPress={DialogProvider.onOk}
                                    t={t}
                                    checkInternetConnection={false}
                                    appColors={appColors}
                                    style={[componentStyle(appColors).button]}
                                />
                            </>
                        ) : (
                            <WnaButtonIconDecent
                                iconName="check"
                                onPress={DialogProvider.onOk}
                                style={[componentStyle(appColors).buttonFull]}
                            />
                        )}
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const componentStyle = (colors: Colors) =>
    StyleSheet.create({
        mainWrapper: {
            backgroundColor: colors.white,
            borderRadius: 16,
            maxHeight: 256,
            overflow: "hidden",
            margin: 32,
        },
        dialogIcon: {
            width: 64,
            height: 64,
            borderRadius: 32,
            borderWidth: 0,
            marginBottom: -64,
            zIndex: 3, // works on ios
            elevation: 3, // works on android
            alignItems: "center",
            padding: 16,
        },
        dialogTextContainer: {
            alignItems: "center",
            gap: 16,
            marginTop: 56,
            marginBottom: 32,
            marginHorizontal: 24,
        },
        buttonContainer: {
            flex: 1,
            flexDirection: "row",
            alignContent: "center",
            height: 44,
            maxHeight: 44,
            width: "100%",
            borderTopColor: colors.coolgray2,
            borderTopWidth: 1,
        },
        button: {
            width: "50%", // is 50% of container width
            margin: 0,
            height: 44,
        },
        buttonFull: {
            width: "100%", // is 50% of container width
            margin: 0,
            height: 44,
        },
    });
