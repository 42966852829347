import { getToken, isSupported, onMessage } from "firebase/messaging";

import WnaAppSettings from "@/types/entities/wnaAppSettings";
import Logger from "@/utils/logger";
import { initializeApp } from "@firebase/app";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";
import { getMessaging } from "firebase/messaging/sw";

const key = "fcmToken";
const encrypt = false;
const useDb = false;

export function initFcmProvider(
    appSettings: WnaAppSettings,
    onTokenReceived: (token: string) => void,
    onNotificationReceived: (title: string, body: string) => void
) {
    Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
            const firebaseApp = initializeApp({
                apiKey: appSettings.firebaseAppConfig.apiKey,
                appId: appSettings.firebaseAppConfig.appId,
                authDomain: appSettings.firebaseAppConfig.authDomain,
                measurementId: appSettings.firebaseAppConfig.measurementId,
                messagingSenderId:
                    appSettings.firebaseAppConfig.messagingSenderId,
                projectId: appSettings.firebaseAppConfig.projectId,
                storageBucket: appSettings.firebaseAppConfig.storageBucket,
            });

            const messagingSw = getMessaging(firebaseApp);
            const isFcmSupported = await isSupported();
            Logger.warn(
                initFcmProvider.name,
                "isFcmSupported: " + isFcmSupported
            );

            // TODO: ENV
            getToken(messagingSw, {
                vapidKey:
                    "BNJ7y-J3jQuuGnMrjKx82bLSy-UW4d-8XgjAWBbQDT0qn9UclB6f4yGLTDKZq_7v870wQ__C075mxZ7FA_Ll5rg",
            })
                .then(async (token) => {
                    if (token) {
                        Logger.warn(getToken.name, "fcmToken: " + token);
                        await WnaDataStorageProvider.setItemAsync(
                            key,
                            token,
                            encrypt,
                            useDb
                        );
                        onTokenReceived(token);
                    } else {
                        Logger.warn(
                            getToken.name,
                            "No registration token available. Request permission to generate one."
                        );
                    }
                })
                .catch((error) => {
                    Logger.error(getToken.name, error);
                });
            onMessage(messagingSw, (remoteMessage) => {
                Logger.warn(
                    "A new FCM message arrived!",
                    JSON.stringify(remoteMessage)
                );
                if (remoteMessage.notification) {
                    onNotificationReceived(
                        remoteMessage.notification.title ?? "",
                        remoteMessage.notification.body ?? ""
                    );
                } else {
                    Logger.error(
                        onMessage.name,
                        "remoteMessage.notification was undefined"
                    );
                }
            });
        } else {
            Logger.warn(
                Notification.requestPermission.name,
                "Permission for fcm not granted!"
            );
        }
    });
}
