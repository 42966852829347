import { convertHexToRgba } from "@/utils/colorConverter";
import WnaButtonIconInnerIcon from "@components/buttons/WnaButtonIconInnerIcon";
import { WnaPressableState } from "@components/buttons/WnaPressable";
import { WnaShadowStyle } from "@components/misc/WnaShadowStyle";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { execWhenOnline } from "@services/wnaExecWhenOnline";
import { TFunction } from "i18next";
import { Component } from "react";
import { Platform, Pressable } from "react-native";
import { Popable } from "react-native-popable";

const _shadowStyle = WnaShadowStyle();
const _defaultToolTipPos = "top";

export type WnaButtonIconProps = {
    appColors: Colors;
    appStyle: AppStyle;
    iconName: keyof typeof FontAwesome5.glyphMap;
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
    toolTip?: string;
    color?: string;
    style?: object;
    toolTipPosition?: "top" | "right" | "bottom" | "left" | undefined;
};

const _size = 52;
const _borderRadius = _size / 2;

class WnaButtonIcon extends Component<WnaButtonIconProps> {
    state = {
        isToolTipVisible: false,
    };

    render() {
        const toolTipPos = this.props.toolTipPosition ?? _defaultToolTipPos;
        const defaultClr = convertHexToRgba(
            this.props.appColors.staticBlack,
            0.6
        );
        const shadowStyle = Platform.OS === "web" ? _shadowStyle : null;
        const toolTip = this.props.toolTip ?? "";
        return (
            <Pressable
                style={({ pressed, hovered, focused }: WnaPressableState) => [
                    Platform.OS === "web" ? { cursor: "pointer" } : null,
                    shadowStyle,
                    {
                        opacity: pressed ? 0.7 : hovered || focused ? 0.9 : 1,
                        backgroundColor: defaultClr,
                    },
                    {
                        borderRadius: _borderRadius,
                        width: _size,
                        height: _size,
                        overflow: Platform.OS === "web" ? "visible" : "hidden",
                    },
                    this.props.style,
                ]}
                onHoverIn={() => {
                    if (toolTip !== "" && Platform.OS === "web")
                        this.setState({ isToolTipVisible: true });
                }}
                onHoverOut={() => {
                    this.setState({ isToolTipVisible: false });
                }}
                android_ripple={{
                    color: this.props.appColors.white,
                    borderless: true,
                }}
                onPress={() => {
                    this.setState({ isToolTipVisible: false });
                    execWhenOnline(
                        this.props.checkInternetConnection,
                        this.props.t,
                        this.props.onPress
                    );
                }}>
                <>
                    <WnaButtonIconInnerIcon
                        appStyle={this.props.appStyle}
                        appColors={this.props.appColors}
                        color={this.props.color}
                        iconName={this.props.iconName}
                    />
                    <Popable
                        content={toolTip}
                        position={
                            this.props.toolTipPosition ?? _defaultToolTipPos
                        }
                        numberOfLines={2}
                        style={{
                            bottom:
                                toolTipPos === "right" || toolTipPos === "left"
                                    ? 0
                                    : _size,
                        }}
                        visible={toolTip !== "" && this.state.isToolTipVisible}>
                        {null}
                    </Popable>
                </>
            </Pressable>
        );
    }
}

export default WnaButtonIcon;
