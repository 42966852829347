import { getUnixMinDate } from "@/utils/dateTimeHelper";

export default interface WnaNotification {
    identifier: string;
    designator: string;
    comment: string;
    dateCreate: Date;
    dateUpdate: Date;
    dateRead: Date;
    dateDelete: Date;
    state: number;
    imageUrl: string;
}

export function getDefaultNotification() {
    return {
        identifier: "",
        designator: "",
        comment: "",
        dateCreate: getUnixMinDate(),
        dateUpdate: getUnixMinDate(),
        dateRead: getUnixMinDate(),
        dateDelete: getUnixMinDate(),
        imageUrl: "",
        state: 0,
    } as WnaNotification;
}
