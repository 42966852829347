import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { TFunction } from "i18next";
import { Component } from "react";
import { View } from "react-native";
import WnaPressable from "./WnaPressable";

export type WnaButtonHeaderProps = {
    appColors: Colors;
    appStyle: AppStyle;
    iconName: keyof typeof FontAwesome5.glyphMap;
    text: string;
    color?: string;
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
    badgeVisible?: boolean;
    toolTipPosition?: "top" | "right" | "bottom" | "left" | undefined;
};

class WnaButtonHeader extends Component<WnaButtonHeaderProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaButtonHeaderProps>): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.color !== this.props.color
        );
    }

    render() {
        const clr =
            this.props.color && this.props.color !== ""
                ? this.props.color
                : this.props.appColors.staticWhite;
        const size = 56; //Platform.OS === "web" ? 64 : 52;
        const iconSize = 20; //size * 0.34;

        return (
            <WnaPressable
                toolTip={this.props.text}
                toolTipPosition={this.props.toolTipPosition}
                style={{
                    width: size,
                    height: size,
                }}
                onPress={this.props.onPress}
                checkInternetConnection={this.props.checkInternetConnection}
                t={this.props.t}
                rippleWhite={true}>
                <View
                    style={[
                        this.props.appStyle.containerCenterCenter,
                        {
                            width: size,
                            height: size,
                            backgroundColor: "transparent",
                        },
                    ]}>
                    <FontAwesome5
                        name={this.props.iconName}
                        size={iconSize}
                        color={clr}
                    />
                    {this.props.badgeVisible ? (
                        <View
                            style={{
                                backgroundColor: this.props.appColors.red3,
                                height: 8,
                                width: 8,
                                borderRadius: 4,
                                position: "absolute",
                                top: 14,
                                right: 14,
                            }}
                        />
                    ) : null}
                </View>
            </WnaPressable>
        );
    }
}

export default WnaButtonHeader;
