import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function updateDiaryEntryAsync(
    userId: string | undefined,
    chapterIdentifier: string | undefined,
    diaryEntry: WnaDiaryEntry
) {
    if (
        !isUserId(userId) ||
        !isDateTimeIdentifier(chapterIdentifier) ||
        !diaryEntry ||
        !isDateTimeIdentifier(diaryEntry.identifier)
    )
        return undefined;

    try {
        const route = `users/${userId}/chapters/${chapterIdentifier}/diaryentries/update`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        const request = initJsonRequest("PUT");
        request.body = JSON.stringify(diaryEntry);
        await prepareRequestAsync();
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaDiaryEntry;
        } else {
            Logger.error(
                updateDiaryEntryAsync.name,
                "could not update DiaryEntry"
            );
            return undefined;
        }
    } catch (e) {
        Logger.error(updateDiaryEntryAsync.name, e);
        return undefined;
    }
}
