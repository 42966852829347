import React, { Component } from "react";
import { Linking, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import WebView from "react-native-webview";

export type WnaWebViewProps = {
    urlOrHtml: string;
    jsCode?: string;
    onLoaded?: () => void;
    marginTop?: number;
    marginBottom?: number;
};

const getKey = () => new Date().toString();

export default class WnaWebView extends Component<WnaWebViewProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaWebViewProps>): boolean {
        return nextProps.urlOrHtml !== this.props.urlOrHtml;
    }

    render() {
        const pUrlOrHtml = this.props.urlOrHtml ?? "";
        const currentSource = pUrlOrHtml.startsWith("http")
            ? { uri: this.props.urlOrHtml }
            : { html: this.props.urlOrHtml ?? "<html lang='en'></html>" };

        if (Platform.OS === "web" && pUrlOrHtml.startsWith("http")) {
            // url in iframe
            return (
                <iframe
                    id="ifr"
                    key={"webViewKey"}
                    style={{
                        borderWidth: 0,
                    }}
                    onLoad={() => {
                        if (this.props.onLoaded) this.props.onLoaded();
                    }}
                    src={pUrlOrHtml}
                    height={"100%"}
                    width={"100%"}
                />
            );
        } else if (Platform.OS === "web" && !pUrlOrHtml.startsWith("http")) {
            // plain html in div
            return (
                <ScrollView>
                    <div
                        key={getKey()}
                        dangerouslySetInnerHTML={{
                            __html: pUrlOrHtml,
                        }}
                    />
                    <img
                        key={"img" + getKey()}
                        width={1}
                        height={1}
                        alt=""
                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        onLoad={() => {
                            if (this.props.onLoaded) this.props.onLoaded();
                        }}
                    />
                </ScrollView>
            );
        } else {
            // native webview
            return (
                <WebView
                    containerStyle={{ backgroundColor: "transparent" }}
                    source={currentSource}
                    pullToRefreshEnabled={true}
                    sharedCookiesEnabled
                    thirdPartyCookiesEnabled
                    domStorageEnabled
                    scalesPageToFit={true}
                    style={{
                        marginTop: this.props.marginTop ?? 0,
                        marginBottom: this.props.marginBottom ?? 0,
                        flex: 1,
                        backgroundColor: "transparent",
                    }}
                    setSupportMultipleWindows={false}
                    originWhitelist={["*"]}
                    injectedJavaScript={this.props.jsCode ?? ""}
                    onShouldStartLoadWithRequest={(request) => {
                        try {
                            // short circuit these - open external links in system browser
                            if (
                                !request.url ||
                                request.url.startsWith("http") ||
                                request.url.startsWith("https") ||
                                request.url.startsWith("/") ||
                                request.url.startsWith("#") ||
                                request.url.startsWith("javascript") ||
                                request.url.startsWith("about:blank")
                            ) {
                                Linking.openURL(request.url);
                                return false;
                            }

                            // blocked blobs
                            if (request.url.startsWith("blob")) return false;

                            // list of schemas we will allow the webview
                            // to open natively
                            if (
                                request.url.startsWith("tel:") ||
                                request.url.startsWith("mailto:") ||
                                request.url.startsWith("maps:") ||
                                request.url.startsWith("geo:") ||
                                request.url.startsWith("sms:")
                            ) {
                                Linking.openURL(request.url).catch(() => {
                                    // Logger.error(
                                    //     WnaWebView.name,
                                    //     "onShouldStartLoadWithRequest",
                                    //     "Failed to open Link: " + error.message
                                    // );
                                    // WnaToastProvider.showError(
                                    //     "Failed to open Link: " + error.message
                                    // );
                                });
                                return false;
                            }

                            // let everything else to the webview
                            return true;
                        } catch {
                            return false;
                        }
                    }}
                    onLoadEnd={() => {
                        if (this.props.onLoaded) this.props.onLoaded();
                    }}
                />
            );
        }
    }
}
