import getResourceAsTextAsync from "@/api/resources/getResourceAsText";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaHtmlRenderer from "@components/misc/WnaHtmlRenderer";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { getLangCode } from "@services/i18n/i18n";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect } from "expo-router";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

const fallbackHtml = `<html lang="en"></html>`;

export default function DisclaimerScreen() {
    const { isAppInitialized, appColors, appStyle, currentWindowWidth } =
        useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const [isBusy, setIsBusy] = useState(true);
    const [html, setHtml] = useState("");
    useEffect(() => {
        const document =
            getLangCode() === "en"
                ? "disclaimer-en.html"
                : "disclaimer-de.html";
        getResourceAsTextAsync(document).then((html) => {
            if (html === "") html = fallbackHtml;

            // const utf8decoded = require("utf8").decode(html);
            // setHtml(utf8decoded);
            setHtml(html);
            setIsBusy(false);
        });
    }, []);

    if (!isAppInitialized)
        return <Redirect href={WnaNavigationRouteProvider.menu} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={t(i18nKeys.screenTitleDisclaimer)}>
            <ScrollView style={[appStyle.containerFillPage]}>
                <View
                    style={[
                        appStyle.containerCenterMaxWidth,
                        { paddingVertical: 24 },
                    ]}>
                    <View
                        style={{
                            backgroundColor: appColors.white,
                        }}>
                        <WnaHtmlRenderer
                            appStyle={appStyle}
                            appColors={appColors}
                            width={currentWindowWidth}
                            html={html}
                            fallbackHtml={fallbackHtml}
                            padding={24}
                        />
                    </View>
                </View>
            </ScrollView>
        </WnaBaseScreen>
    );
}
