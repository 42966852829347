import downloadFileAsync from "@/api/fileDownload";
import { convertHexToRgba } from "@/utils/colorConverter";
import Logger from "@/utils/logger";
import { cleanAndTruncate } from "@/utils/stringHelper";
import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import { animationSpeed } from "@constants/animationSpeed";
import Colors from "@constants/theme/colors";
import { Image } from "expo-image";
import * as React from "react";
import { Component } from "react";
import { Platform, View } from "react-native";

export type WnaImageStyleProps = {
    height?: number;
    width?: number;
    minWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    maxWidth?: number;
    borderRadius?: number;
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    opacity?: number;
    resizeMode?: "contain" | "cover";
};

export type WnaImageProps = {
    appColors: Colors;
    imageUrl: string;
    style?: WnaImageStyleProps | WnaImageStyleProps[];
    thumbnailUrl?: string | null;
    placeholderUrl?: string | null;
    hideBackground?: boolean;
    showActivityIndicator?: boolean;
};

type imageState = {
    loadedImageUrl: string;
    cachedImageBase64Url: string;
};

const _blurHash =
    "|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[";

class WnaImage extends Component<WnaImageProps> {
    state = { cachedImageBase64Url: "", loadedImageUrl: "" } as imageState;

    shouldComponentUpdate(nextProps: Readonly<WnaImageProps>): boolean {
        return (
            nextProps.imageUrl !== this.props.imageUrl ||
            nextProps.hideBackground !== this.props.hideBackground ||
            nextProps.placeholderUrl !== this.props.placeholderUrl ||
            nextProps.style !== this.props.style ||
            nextProps.thumbnailUrl !== this.props.thumbnailUrl ||
            this.state.cachedImageBase64Url === "" ||
            this.state.loadedImageUrl !== this.props.imageUrl
        );
    }

    render() {
        const altText = cleanAndTruncate(this.props.imageUrl);
        const loadImage = () => {
            try {
                let imageUrl = this.props.imageUrl;

                if (imageUrl === "") imageUrl = this.props.thumbnailUrl ?? "";

                if (imageUrl === "") imageUrl = this.props.placeholderUrl ?? "";

                if (imageUrl === "") {
                    Logger.warn(WnaImage.name, "imageUrl is empty");
                    return;
                }

                if (!imageUrl.startsWith("http")) {
                    this.setState({
                        cachedImageBase64Url: imageUrl,
                        loadedImageUrl: imageUrl,
                    } as imageState);
                } else {
                    downloadFileAsync(imageUrl).then((b64) => {
                        this.setState({
                            cachedImageBase64Url: b64,
                            loadedImageUrl: this.props.imageUrl,
                        } as imageState);
                    });
                }
            } catch (error) {
                Logger.error(loadImage.name, error);
            }
        };

        const needsToLoadImage =
            this.state.loadedImageUrl !== this.props.imageUrl;
        const needsToShowActivityIndcator =
            needsToLoadImage || this.state.cachedImageBase64Url === "";

        if (needsToLoadImage) loadImage();

        return (
            <View
                style={[
                    {
                        backgroundColor:
                            this.props.hideBackground === true
                                ? "transparent"
                                : convertHexToRgba("#000000", 0.5),
                        overflow: "hidden",
                    },
                    this.props.style,
                ]}>
                {this.props.showActivityIndicator ? (
                    <WnaActivityIndicator
                        appColors={this.props.appColors}
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}
                    />
                ) : null}
                {needsToShowActivityIndcator ? (
                    <></>
                ) : (
                    <Image
                        style={[this.props.style]}
                        alt={altText}
                        accessibilityLabel={altText}
                        cachePolicy={
                            Platform.OS === "web" ? "memory-disk" : "none"
                        }
                        source={{
                            uri:
                                this.state.cachedImageBase64Url !== null &&
                                this.state.cachedImageBase64Url !== ""
                                    ? this.state.cachedImageBase64Url
                                    : (this.props.placeholderUrl ?? _blurHash),
                        }}
                        transition={animationSpeed}
                    />
                )}
            </View>
        );
    }
}

export default WnaImage;
