// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(tabs)/_layout.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/(tabs)/_layout.tsx"); } },
  "./(tabs)/appointments.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/(tabs)/appointments.tsx"); } },
  "./(tabs)/chapters.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/(tabs)/chapters.tsx"); } },
  "./(tabs)/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/(tabs)/index.tsx"); } },
  "./(tabs)/menu.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/(tabs)/menu.tsx"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/+not-found.tsx"); } },
  "./WnaApp.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/WnaApp.tsx"); } },
  "./WnaAppContext.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/WnaAppContext.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/_layout.tsx"); } },
  "./appointments/[appointmentIdentifier]/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/appointments/[appointmentIdentifier]/index.tsx"); } },
  "./appointments/[appointmentIdentifier]/update.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/appointments/[appointmentIdentifier]/update.tsx"); } },
  "./appointments/_layout.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/appointments/_layout.tsx"); } },
  "./appointments/accommodation.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/appointments/accommodation.tsx"); } },
  "./appointments/create.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/appointments/create.tsx"); } },
  "./chapters/[chapterIdentifier]/diaryentries/[diaryEntryIdentifier]/images.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/chapters/[chapterIdentifier]/diaryentries/[diaryEntryIdentifier]/images.tsx"); } },
  "./chapters/[chapterIdentifier]/diaryentries/[diaryEntryIdentifier]/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/chapters/[chapterIdentifier]/diaryentries/[diaryEntryIdentifier]/index.tsx"); } },
  "./chapters/[chapterIdentifier]/diaryentries/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/chapters/[chapterIdentifier]/diaryentries/index.tsx"); } },
  "./chapters/[chapterIdentifier]/preview.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/chapters/[chapterIdentifier]/preview.tsx"); } },
  "./chapters/_layout.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/chapters/_layout.tsx"); } },
  "./currentAppVersion.ts": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/currentAppVersion.ts"); } },
  "./menu/_layout.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/_layout.tsx"); } },
  "./menu/disclaimer.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/disclaimer.tsx"); } },
  "./menu/privacy.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/privacy.tsx"); } },
  "./menu/profile/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/profile/index.tsx"); } },
  "./menu/profile/login.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/profile/login.tsx"); } },
  "./menu/settings/advanced.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/settings/advanced.tsx"); } },
  "./menu/settings/diary.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/settings/diary.tsx"); } },
  "./menu/settings/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/settings/index.tsx"); } },
  "./menu/settings/map.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/menu/settings/map.tsx"); } },
  "./notifications/[notificationIdentifier].tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/notifications/[notificationIdentifier].tsx"); } },
  "./notifications/_layout.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/notifications/_layout.tsx"); } },
  "./notifications/create.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/notifications/create.tsx"); } },
  "./notifications/index.tsx": { enumerable: true, get() { return require("/home/wna/code/test.expo52/src/app/notifications/index.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;