const getMapTypeValByCatalogId = (id: number) => {
    switch (id) {
        case 0:
            return google.maps.MapTypeId.SATELLITE;
        case 1:
            return google.maps.MapTypeId.HYBRID;
        case 2:
            return google.maps.MapTypeId.ROADMAP;
        case 3:
            return google.maps.MapTypeId.TERRAIN;
        default:
            return google.maps.MapTypeId.HYBRID;
    }
};

const getMapTypeNameValByCatalogId = (id: number) => {
    switch (id) {
        case 0:
            return "satellite";
        case 1:
            return "hybrid";
        case 2:
            return "roadmap";
        case 3:
            return "terrain";
        default:
            return "hybrid";
    }
};

const getCatalogIdIdByMapTypeVal = (val: string) => {
    switch (val) {
        case google.maps.MapTypeId.SATELLITE:
            return 0;
        case google.maps.MapTypeId.HYBRID:
            return 1;
        case google.maps.MapTypeId.ROADMAP:
            return 2;
        case google.maps.MapTypeId.TERRAIN:
            return 3;
        default:
            return 1; // hybrid
    }
};

export {
    getCatalogIdIdByMapTypeVal,
    getMapTypeNameValByCatalogId,
    getMapTypeValByCatalogId,
};
