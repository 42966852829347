import Logger from "@/utils/logger";
import { resizeImageAsync } from "@services/images/WnaImageResizer/wnaImageResizer";
import { Image, Platform } from "react-native";

const getResizedImageBlobAsync = async (
    fileUri: string,
    width: number,
    height: number,
    pMaxWidth: number,
    pMaxHeight: number,
    pQuality: number
): Promise<Blob | undefined> => {
    try {
        const isLandscape = width > height;
        const maxHeight = isLandscape ? pMaxHeight : pMaxWidth;
        const maxWidth = isLandscape ? pMaxWidth : pMaxHeight;
        return Platform.OS === "web"
            ? getResizedImageBlobWebAsync(
                  fileUri,
                  maxWidth,
                  maxHeight,
                  pQuality
              )
            : getResizedImageBlobNativeAsync(
                  fileUri,
                  maxWidth,
                  maxHeight,
                  pQuality
              );
    } catch (error) {
        Logger.error(getResizedImageBlobAsync.name, error);
        return undefined;
    }
};

const minifyImageAsync = async (
    fileUri: string,
    pMaxWidth?: number,
    pMaxHeight?: number,
    pQuality?: number
): Promise<Blob | undefined> => {
    try {
        let ppMaxWidth = pMaxWidth ?? 0;
        let ppMaxHeight = pMaxHeight ?? 0;
        Image.getSize(fileUri, (width, height) => {
            ppMaxWidth = width;
            ppMaxHeight = height;
        });

        return await getResizedImageBlobAsync(
            fileUri,
            pMaxWidth ?? 1,
            pMaxHeight ?? 1,
            ppMaxWidth ?? 1,
            ppMaxHeight ?? 1,
            pQuality ?? 100
        );
    } catch (error) {
        Logger.error(minifyImageAsync.name, error);
        return undefined;
    }
};

const getResizedImageBlobWebAsync = async (
    fileUri: string,
    maxWidth: number,
    maxHeight: number,
    quality: number
): Promise<Blob | undefined> => {
    try {
        const r = await fetch(fileUri);
        if (!r.ok) return undefined;

        const rawBlob = await r.blob();
        if (rawBlob) {
            return (await resizeImageAsync(
                rawBlob,
                maxWidth,
                maxHeight,
                quality
            )) as Blob;
        }
    } catch (error) {
        Logger.error(getResizedImageBlobAsync.name, error);
        return undefined;
    }
};

const getResizedImageBlobNativeAsync = async (
    fileUri: string,
    maxWidth: number,
    maxHeight: number,
    quality: number
): Promise<Blob | undefined> => {
    try {
        return (await resizeImageAsync(
            fileUri,
            maxWidth,
            maxHeight,
            quality
        )) as Blob;
    } catch (error) {
        Logger.error(getResizedImageBlobAsync.name, error);
        return undefined;
    }
};

export default minifyImageAsync;
