import Colors from "@constants/theme/colors";
import React, { Component } from "react";
import { View, ViewStyle } from "react-native";

export type WnaListCardWhiteDecentProps = {
    appColors: Colors;
    children: React.ReactNode;
    style?: ViewStyle | ViewStyle[];
};

class WnaListCardWhiteDecent extends Component<WnaListCardWhiteDecentProps> {
    render() {
        return (
            <View
                style={[
                    {
                        backgroundColor: this.props.appColors.white,
                        borderRadius: 8,
                        borderColor: this.props.appColors.coolgray2,
                        borderWidth: 1,
                        paddingVertical: 24,
                        paddingHorizontal: 24,
                    },
                    this.props.style,
                ]}>
                {this.props.children}
            </View>
        );
    }
}

export default WnaListCardWhiteDecent;
