import getAppointmentsAsync from "@/api/appointment/getAppointments";
import WnaAppointment, {
    getAppointmentDisplayText,
} from "@/types/entities/wnaAppointment";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaAppointmentListHeader from "@components/appointment/WnaAppointmentListHeader";
import WnaButtonIcon from "@components/buttons/WnaButtonIcon";
import WnaNavigationItem from "@components/navigation/WnaNavigationItem";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaNotificationHeaderRight from "@components/navigation/WnaNotificationHeaderRight";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { useRouter } from "expo-router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";

export default function Appointments() {
    const router = useRouter();
    const {
        isAppInitialized,
        appColors,
        appStyle,
        currentAdminUser,
        unreadNotificationCount,
        currentUser,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);

    const [appointments, setAppointments] = useState<WnaAppointment[]>([]);
    const [extraData] = useState(new Date());

    useEffect(() => {
        getAppointmentsAsync().then((appointments) =>
            setAppointments(appointments ?? new Array<WnaAppointment>())
        );
    }, []);

    const renderItem = (appointment: WnaAppointment) => {
        const displayText = getAppointmentDisplayText(appointment);
        return (
            <WnaNavigationItem
                appStyle={appStyle}
                appColors={appColors}
                text={displayText}
                iconName={"walking"}
                onPress={() => {
                    router.push(
                        WnaNavigationRouteProvider.appointmentsIdentifier(
                            appointment.identifier
                        )
                    );
                }}
                t={t}
            />
        );
    };

    if (!isAppInitialized) return null;

    return (
        <>
            <WnaBaseScreen
                title={t(i18nKeys.screenTitleAppointmentList)}
                headerButton0={
                    <WnaNotificationHeaderRight
                        unreadNotificationCount={unreadNotificationCount}
                        appStyle={appStyle}
                        appColors={appColors}
                        currentUser={currentUser}
                        router={router}
                        t={t}
                    />
                }
                isBusy={appointments.length < 1}>
                <>
                    <FlatList
                        data={appointments}
                        contentContainerStyle={{
                            paddingBottom: 80,
                            paddingTop: 16,
                            paddingHorizontal: 16,
                        }}
                        ListHeaderComponentStyle={{
                            paddingBottom: 8,
                            paddingHorizontal: 0,
                        }}
                        ItemSeparatorComponent={() => (
                            <View style={{ height: 8 }} />
                        )}
                        extraData={extraData}
                        keyExtractor={(item) => item.identifier}
                        ListHeaderComponent={
                            <WnaAppointmentListHeader
                                appStyle={appStyle}
                                appColors={appColors}
                                navigateToAccommodationScreen={() => {
                                    router.push(
                                        WnaNavigationRouteProvider.appointmentsAccommodation
                                    );
                                }}
                                t={t}
                            />
                        }
                        renderItem={({ item: appointment }) =>
                            renderItem(appointment)
                        }
                    />
                    {currentAdminUser !== null ? (
                        <WnaButtonIcon
                            toolTip={t(i18nKeys.actionCreateAppointment)}
                            toolTipPosition="left"
                            appStyle={appStyle}
                            appColors={appColors}
                            iconName="plus"
                            style={{
                                position: "absolute",
                                bottom: 16,
                                right: 16,
                            }}
                            onPress={() =>
                                router.push(
                                    WnaNavigationRouteProvider.appointmentsCreate
                                )
                            }
                            t={t}
                            checkInternetConnection={false}
                        />
                    ) : null}
                </>
            </WnaBaseScreen>
        </>
    );
}
