import { setUserSettingsAsync } from "@/api/userSettings/userSettingsStorageProvider";
import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import { i18nKeys } from "@/services/i18n/i18nKeys";
import WnaCatalogItem from "@/types/wnaCatalogItem";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaSettingsRadioButton from "@components/buttons/WnaSettingsRadioButton";
import WnaNavigationList, {
    WnaMenuItem,
} from "@components/navigation/WnaNavigationList";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { WnaCatalogDefaultDiaryTab } from "@constants/catalogs/wnaCatalogDefaultDiaryTab";
import { WnaCatalogOrderBy } from "@constants/catalogs/wnaCatalogOrderBy";
import { WnaCatalogYesNo } from "@constants/catalogs/wnaCatalogYesNo";
import { Redirect } from "expo-router";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function SettingsDiaryScreen() {
    const {
        isAppInitialized,
        currentUserSettings,
        setCurrentUserSettings,
        appStyle,
        appColors,
    } = useContext(WnaAppContext);

    const currentUserSettingsRef = useRef<WnaUserSettings>(
        currentUserSettings ?? new WnaUserSettings()
    );

    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [];
    items.push(
        {
            route: "",
            text: t(i18nKeys.settingsDiaryOrderItems),
            icon: "book-open",
            rightIcon: "",
            type: "diaryOrderBy",
            data: undefined,
        },
        {
            route: "",
            text: t(i18nKeys.settingsDiaryDefaultTab),
            icon: "book-open",
            rightIcon: "",
            type: "diaryDefaultTab",
            data: undefined,
        },
        {
            route: "",
            text: t(i18nKeys.settingsDiarySwitchToDefaultTab),
            icon: "book-open",
            rightIcon: "",
            type: "diaryRestoreDiaryTab",
            data: undefined,
        }
    );

    const onSelectDiaryDefaultTab = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.diaryDefaultTab = currentItem.id;
        await setUserSettingsAsync(currentUserSettingsRef.current);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const onSelectDiaryOrderBy = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.diaryOrderBy =
            currentItem.id < 1 ? 0 : 1;
        await setUserSettingsAsync(currentUserSettingsRef.current);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const onSelectDiaryRestoreDiaryTab = async (
        currentItem: WnaCatalogItem
    ) => {
        currentUserSettingsRef.current.diaryRestoreDiaryTab =
            currentItem.id === 1;
        await setUserSettingsAsync(currentUserSettingsRef.current);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const renderItem = (currentItem: any) => {
        switch (currentItem.type) {
            case "diaryDefaultTab":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogDefaultDiaryTab}
                        selectedId={currentUserSettings?.diaryDefaultTab ?? 0}
                        isEditMode
                        onSelect={onSelectDiaryDefaultTab}
                    />
                );
            case "diaryOrderBy":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogOrderBy}
                        selectedId={currentUserSettings?.diaryOrderBy ?? 1}
                        isEditMode
                        onSelect={onSelectDiaryOrderBy}
                    />
                );
            case "diaryRestoreDiaryTab":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettings?.diaryRestoreDiaryTab === true
                                ? 1
                                : 0
                        }
                        isEditMode
                        onSelect={onSelectDiaryRestoreDiaryTab}
                    />
                );
            default:
                return <></>;
        }
    };

    if (!currentUserSettings) return null;

    if (!isAppInitialized)
        return <Redirect href={WnaNavigationRouteProvider.menu} />;

    return (
        <WnaBaseScreen title={t(i18nKeys.screenTitleSettingsDiary)}>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreen>
    );
}
