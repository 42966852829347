import getAppSettingsAsync from "@/api/appSettings/getAppSettings";
import { getUserSettingsAsync } from "@/api/userSettings/userSettingsStorageProvider";
import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import WnaApp from "@app/WnaApp";
import WnaAppContextProvider from "@app/WnaAppContext";
import { initializeGoogleAuthAsync } from "@services/auth/google/wnaGoogleSignIn";
import { i18n } from "@services/i18n/i18n";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import Toast from "react-native-toast-message";

// Prevent the splash screen from auto-hiding before asset loading is complete.
SplashScreen.preventAutoHideAsync();

export default function RootLayout() {
    const [loaded] = useFonts({
        SpaceMono: require("../../assets/fonts/SpaceMono-Regular.ttf"),
        Courgette_400Regular: require("../../assets/fonts/Courgette-Regular.ttf"),
    });

    const [appSettings, setAppSettings] = useState<WnaAppSettings | null>(null);
    const [userSettings, setUserSettings] = useState<WnaUserSettings | null>(
        null
    );

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const settings = await getAppSettingsAsync();
                if (!settings) {
                    throw new Error("Failed to load app settings");
                }

                await initializeGoogleAuthAsync(settings);
                setAppSettings(settings);

                const userSettings = await getUserSettingsAsync();
                setUserSettings(userSettings);
            } catch (error: unknown) {
                if (error instanceof Error) {
                    Toast.show({
                        text1: error.message || "An error occurred",
                        type: "error",
                    });
                } else {
                    Toast.show({
                        text1: "An unknown error occurred",
                        type: "error",
                    });
                }
            }
        };

        if (loaded && !appSettings) {
            loadSettings();
        }
    }, [loaded, appSettings]);

    if (!loaded || !appSettings || !userSettings) {
        return null;
    }

    return (
        // <SafeAreaView>
        <GestureHandlerRootView style={{ flex: 1, overflow: "hidden" }}>
            <I18nextProvider i18n={i18n}>
                <WnaAppContextProvider>
                    <WnaApp
                        appSettings={appSettings}
                        userSettings={userSettings}
                    />
                </WnaAppContextProvider>
            </I18nextProvider>
        </GestureHandlerRootView>
        // </SafeAreaView>
    );
}
