import ApiSettings from "@/api/apiSettings";
import uploadImageAsync from "@/api/fileUpload";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isFileName } from "@/api/validators/fileNameValidator";
import { isUri } from "@/api/validators/isUriValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaChapter from "@/types/entities/wnaChapter";
import Logger from "@/utils/logger";

export default async function uploadChapterImageAsync(
    userId: string | undefined,
    identifier: string | undefined,
    fileName: string | undefined,
    fileUri: string | undefined
) {
    if (
        !isUserId(userId) ||
        !isDateTimeIdentifier(identifier) ||
        !isFileName(fileName) ||
        !isUri(fileUri)
    )
        return undefined;

    try {
        const route = `users/${userId}/chapters/${identifier}/uploadImage/${fileName}`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        const result = await uploadImageAsync(url, fileName, fileUri);
        if (result instanceof Response) {
            return (await result.json()) as WnaChapter;
        } else {
            Logger.error(
                uploadChapterImageAsync.name,
                "could not upload chapter image."
            );
            return undefined;
        }
    } catch (e) {
        Logger.error(uploadChapterImageAsync.name, e);
        return undefined;
    }
}
