{
  "common": {
    "actionAddPicture": "Foto hinzufügen",
    "actionCancel": "Abbrechen",
    "actionClearCache": "Offline-Cache leeren",
    "actionCreateAppointment": "Neuer Kurs",
    "actionCreateChapter": "Neues Kapitel",
    "actionCreateDiaryEntry": "Neuer Eintrag",
    "actionCreateNotification": "Neue Nachricht",
    "actionDelete": "Löschen",
    "actionDeleteLog": "Log löschen",
    "actionDonate": "Spenden",
    "actionDownload": "Herunterladen",
    "actionDownloadCache": "Offline-Cache aufbauen",
    "actionDownloadDiaryPdf": "PDF herunterladen",
    "actionEdit": "Bearbeiten",
    "actionEditChapter": "Kapitel Bearbeiten",
    "actionEmail": "E-Mail",
    "actionFullscreen": "Vollbild",
    "actionGoBack": "Zurück",
    "actionInfo": "Weitere Infos",
    "actionLogIn": "Anmelden",
    "actionLogInForFree": "Kostenlos anmelden",
    "actionLogInWithFacebook": "Facebook",
    "actionLogInWithGoogle": "Google",
    "actionLogOut": "Abmelden",
    "actionLogOutAndDeleteData": "Abmelden und Daten löschen",
    "actionNextEntry": "Nächster Eintrag",
    "actionNextPage": "Nächste Seite",
    "actionPhoneCall": "Anrufen",
    "actionPreviousEntry": "Vorheriger Eintrag",
    "actionPreviousPage": "Vorherige Seite",
    "actionPrint": "Drucken",
    "actionRoute": "Anfahrt",
    "actionSave": "Speichern",
    "actionSend": "Senden",
    "actionSendErrorLog": "Fehlerprotokoll senden",
    "actionUndo": "Änderungen verwerfen",
    "answerNo": "Nein",
    "answerYes": "Ja",
    "catalogDefaultDiaryTabEntry": "Eintrag",
    "catalogDefaultDiaryTabMap": "Route",
    "catalogDefaultDiaryTabPictures": "Fotos",
    "catalogGraphQualityHigh": "Hoch",
    "catalogGraphQualityLow": "Niedrig",
    "catalogGraphQualityMedium": "Normal",
    "catalogMapTypeHybrid": "Hybrid",
    "catalogMapTypeRoadmap": "Karte",
    "catalogMapTypeSatellite": "Satellit",
    "catalogMapTypeTerrain": "Terrain",
    "catalogNotificationStateDeleted": "Gelöscht",
    "catalogNotificationStateRead": "Gelesen",
    "catalogNotificationStateUnread": "Ungelesen",
    "catalogOrderByAsc": "Aufsteigend",
    "catalogOrderByDesc": "Absteigend",
    "catalogThemeDark": "Dunkel",
    "catalogThemeLight": "Hell",
    "catalogThemeSystem": "System",
    "diaryEntryTabNameEntry": "Eintrag",
    "diaryEntryTabNameMap": "Route",
    "diaryEntryTabNamePictures": "Fotos",
    "dummyDiaryEntry1Comment": "Liebes Tagebuch, heute war ein Tag voller Ruhe, Natur und innerer Einkehr. Ich befinde mich gerade an einem wunderschönen Strand, umgeben von endlosen Weiten des Ozeans und dem beruhigenden Rauschen der Wellen. Es ist der zweite Tag meiner Wanderung am Strand, und ich fühle mich bereits jetzt in einer tieferen Verbindung mit der Natur. Der Morgen begann mit einer Meditation direkt am Wasser. Das sanfte Plätschern der Wellen und das Zirpen der Vögel haben mich in einen Zustand der inneren Ruhe versetzt. Die aufgehende Sonne tauchte den Himmel in ein warmes, orangenes Licht, das auf der Wasseroberfläche glitzerte.",
    "dummyDiaryEntry1Designator": "Mein vierter Tag",
    "dummyDiaryEntry2Comment": "Liebes Tagebuch, heute habe ich mich auf eine Wanderung durch den Wald begeben, und es war eine unvergessliche Erfahrung. Die Natur hat mich mit ihrer Pracht und Stille in ihren Bann gezogen. Der Morgen begann mit dem Zwitschern der Vögel und dem sanften Rascheln der Blätter. Ich konnte den erfrischenden Duft des Waldes in der kühlen Morgenluft förmlich einatmen. Die Sonnenstrahlen drangen durch die dichten Baumkronen und ließen den Wald in ein warmes, goldenes Licht tauchen.",
    "dummyDiaryEntry2Designator": "Mein dritter Tag",
    "errorActionCanceled": "Aktion abgebrochen",
    "errorDelete": "Fehler beim Löschen",
    "errorInputInvalid": "Eingaben unvollständig oder ungültig",
    "errorNoInternet": "Du bist gerade offline",
    "errorSave": "Fehler beim Speichern",
    "errorSend": "Fehler beim Senden",
    "errorUnknown": "Es ist ein Fehler aufgetreten",
    "infoNeedToLogIn": "Du musst dich anmelden um das Tagebuch nutzen zu können.",
    "infoNoMapData": "Keine Informationen zur Route",
    "infoNoRoute": "Keine Route",
    "infoNotAvailable": "Leider noch nicht verfügbar",
    "infoPdfCreation": "Dein Tagebuch wird gerade erstellt. Du erhältst eine Benachrichtigung, sobald der Prozess abgeschlossen ist.",
    "infoPleaseWait": "Bitte warten",
    "infoPreviewInProgress": "Vorschau wird erstellt - das kann einige Zeit dauern.",
    "infoUserLoggedIn": "Du wurdest angemeldet",
    "infoUserLoggedOut": "Du wurdest abgemeldet",
    "infoWorkInProgress": "... in Arbeit ...",
    "mapInfoPostfixDelta": "Delta",
    "mapInfoPostfixDown": "Abstieg",
    "mapInfoPostfixDuration": "Dauer",
    "mapInfoPostfixEqual": "Eben",
    "mapInfoPostfixHighestPoint": "höchster Punkt",
    "mapInfoPostfixLength": "Streckenlänge",
    "mapInfoPostfixLowestPoint": "niedrigster Punkt",
    "mapInfoPostfixUp": "Aufstieg",
    "placeholderAppointmentDetails": "Infos / Preise",
    "placeholderChapter": "Kapitel",
    "questionCreateNewChapter": "Wirklich neues Kapitel anlegen?",
    "questionDelete": "Wirklich löschen?",
    "questionClearCache": "Wirklich Offline-Cache leeren?",
    "questionLogOut": "Wirklich abmelden?",
    "questionLogOutAndDeleteData": "Wirklich abmelden und Daten alle löschen?",
    "questionLongRunningProcess": "Dieser Prozess kann einige Zeit in anspruch nehmen. Fortfahren?",
    "questionSaveChanges": "Änderungen speichern?",
    "questionSend": "Wirklich senden?",
    "screenErrorLog": "Fehlerprotokoll",
    "screenSubtitleAppointmentList": "Kommende Fastenwanderkurse",
    "screenTitleAccommodationDetails": "Hotel Haus Kranich",
    "screenTitleAdministration": "Administration",
    "screenTitleAppointmentDetails": "Kurs",
    "screenTitleAppointmentList": "Kurse",
    "screenTitleDayDetails": "Tagebucheintrag",
    "screenTitleDayList": "Tagebucheinträge",
    "screenTitleDiaryPreview": "Druckvorschau",
    "screenTitleDisclaimer": "Haftungsausschluss",
    "screenTitleMenu": "Mehr ...",
    "screenTitleNotificationDetails": "Nachricht",
    "screenTitleNotificationList": "Nachrichten",
    "screenTitlePrivacy": "Datenschutz",
    "screenTitleProfile": "Profil",
    "screenTitleSettings": "Einstellungen",
    "screenTitleSettingsAdvanced": "Einstellungen: Erweitert",
    "screenTitleSettingsDiary": "Einstellungen: Tagebuch",
    "screenTitleSettingsMap": "Einstellungen: Karte",
    "screenTitleStartPage": "Fasten mit Volker",
    "settingsAdvancedLogging": "Erweitertes Logging",
    "settingsDiaryDefaultTab": "Standard-Tab Tagebuch",
    "settingsDiaryOrderItems": "Tagebucheinträge sortierten",
    "settingsDiarySwitchToDefaultTab": "Beim Umblättern zu Standard-Tab wechseln",
    "settingsMapControls": "Karten-Steuerelemente anzeigen",
    "settingsMapDefaultMap": "Standard-Karte",
    "settingsMapScaleElevationChart": "Höhenprofil skalieren",
    "settingsTheme": "Thema",
    "success": "Das hat geklappt",
    "successDelete": "Erfolgreich gelöscht",
    "successDownload": "Download abgeschlossen",
    "successSave": "Erfolgreich gespeichert",
    "successSend": "Erfolgreich gesendet",
    "warningCreateNewChapter": "Lege bitte ein neues Kapitel an.",
    "warningWaitForProgress": "Bitte warte, bis der Prozess beendet wurde.",
    "wordAdvanced": "Erweitert",
    "wordBegin": "Beginn",
    "wordDiary": "Tagebuch",
    "wordEnd": "Ende",
    "wordMap": "Karte",
    "wordNo": "Nein",
    "wordYes": "Ja"
  }
}
