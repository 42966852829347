import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function registerFcmUserAsync(
    userId: string,
    fcmToken: string
) {
    try {
        const route = `users/${userId}/fcmTokens/register/${fcmToken}`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        return result instanceof Response;
    } catch (e) {
        Logger.error(registerFcmUserAsync.name, e);
        return false;
    }
}
