import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import WnaFileInfo, { sanitizeFileInfos } from "@/types/entities/wnaFileInfo";
import {
    dateToStringDateWeekdayShort,
    getUnixMinDate,
    isGreaterThan,
} from "@/utils/dateTimeHelper";
import { getMapTypeNameValByCatalogId } from "@components/maps/wnaCatalogMapTypeMapper";

export default interface WnaDiaryEntry {
    identifier: string;
    designator: string;
    comment: string;
    dateCreate: Date;
    dateUpdate: Date;
    dateRead: Date;
    dateDelete: Date;

    priority: number;
    dateStart: Date;
    dateEnd: Date;
    chapterIdentifier: string;

    // #region Images
    imageUrl: string;
    images: WnaFileInfo[];
    // #endregion

    // #region Route
    geoJson: string;
    routeIdentifier: string;
    routeGeoJsonUrl: string;
    routeStaticUrl: string;
    routeStatic512Url: string;
    routeStatic256Url: string;
    // #endregion

    // #region Fasting
    fastingMyLastNight: string;
    fastingGeneralCondition: string;
    fastingTemperaturePerception: string;
    fastingBowelMovement: string;
    fastingUrineColor: string;
    fastingWeightInTheMorning: string;
    fastingDrank: string;
    fastingEaten: string;
    fastingMealAtNoon: string;
    fastingMealInTheEvening: string;
    fastingDailyRoutine: string;
    fastingMorningExercise: string;
    fastingHiking: string;
    fastingPersonalThoughts: string;

    // #endregion
}

export function sanitizeDiaryEntry(source: WnaDiaryEntry) {
    return {
        identifier: source.identifier,
        designator: source.designator,
        comment: source.comment,
        dateCreate: source.dateCreate,
        dateUpdate: source.dateUpdate,
        dateRead: source.dateRead,
        dateDelete: source.dateDelete,
        priority: source.priority,
        dateStart: source.dateStart,
        dateEnd: source.dateEnd,
        chapterIdentifier: source.chapterIdentifier,
        imageUrl: source.imageUrl,
        images: sanitizeFileInfos(source.images),
        geoJson: "",
        routeIdentifier: source.routeIdentifier,
        routeGeoJsonUrl: "",
        routeStaticUrl: "",
        routeStatic512Url: "",
        routeStatic256Url: "",
        fastingMyLastNight: source.fastingMyLastNight,
        fastingGeneralCondition: source.fastingGeneralCondition,
        fastingTemperaturePerception: source.fastingTemperaturePerception,
        fastingBowelMovement: source.fastingBowelMovement,
        fastingDailyRoutine: source.fastingDailyRoutine,
        fastingMorningExercise: source.fastingMorningExercise,
        fastingHiking: source.fastingHiking,
        fastingPersonalThoughts: source.fastingPersonalThoughts,
    } as WnaDiaryEntry;
}

export function getDefaultDiaryEntry() {
    return {
        identifier: "",
        designator: "",
        comment: "",
        dateCreate: getUnixMinDate(),
        dateUpdate: getUnixMinDate(),
        dateRead: getUnixMinDate(),
        dateDelete: getUnixMinDate(),
        priority: 0,
        dateStart: getUnixMinDate(),
        dateEnd: getUnixMinDate(),
        chapterIdentifier: "",
        imageUrl: "",
        images: new Array<WnaFileInfo>(),
        geoJson: "",
        routeIdentifier: "",
        routeGeoJsonUrl: "",
        routeStaticUrl: "",
        routeStatic512Url: "",
        routeStatic256Url: "",
        fastingMyLastNight: "",
        fastingGeneralCondition: "",
        fastingTemperaturePerception: "",
        fastingBowelMovement: "",
        fastingDailyRoutine: "",
        fastingMorningExercise: "",
        fastingHiking: "",
        fastingPersonalThoughts: "",
    } as WnaDiaryEntry;
}

export function getDiaryEntryDateStartTitle(
    diaryEntry?: WnaDiaryEntry,
    diaryEntries?: WnaDiaryEntry[]
) {
    if (!diaryEntry) return "";

    if (!diaryEntries || diaryEntries.length < 2)
        return dateToStringDateWeekdayShort(diaryEntry.dateStart);

    const itemDateStart = new Date(diaryEntry.dateStart);
    const itemDateIso = itemDateStart.toISOString();
    let dateStartDesignator = dateToStringDateWeekdayShort(
        diaryEntry.dateStart
    );
    const itemsOnSameDate = diaryEntries
        .filter(
            (entry) => new Date(entry.dateStart).toISOString() === itemDateIso
        )
        .sort((a, b) => isGreaterThan(a.dateCreate, b.dateCreate));

    const countOfItemsWithSameTitle = itemsOnSameDate.findIndex(
        (entry) => entry.identifier === diaryEntry.identifier
    );

    if (countOfItemsWithSameTitle > 0)
        dateStartDesignator += ` (${countOfItemsWithSameTitle})`;

    return dateStartDesignator;
}

export function getDiaryEntryHeaderTitle(
    diaryEntry?: WnaDiaryEntry,
    diaryEntries?: WnaDiaryEntry[]
) {
    let title = getDiaryEntryDateStartTitle(diaryEntry, diaryEntries);
    if (diaryEntry && diaryEntry.designator !== "")
        title += ` | ${diaryEntry.designator}`;
    return title;
}

export function getMapImageUrl(mapUrl: string, userSettings?: WnaUserSettings) {
    if (!mapUrl || mapUrl === "" || !userSettings) return mapUrl;

    const targetMapType = getMapTypeNameValByCatalogId(
        userSettings.mapDefaultType
    );
    const mapTypeSatellite = getMapTypeNameValByCatalogId(0);
    const mapTypeHybrid = getMapTypeNameValByCatalogId(1);
    const mapTypeRoadmap = getMapTypeNameValByCatalogId(2);
    const mapTypeTerrain = getMapTypeNameValByCatalogId(3);

    return mapUrl
        .replace(mapTypeSatellite, targetMapType)
        .replace(mapTypeHybrid, targetMapType)
        .replace(mapTypeRoadmap, targetMapType)
        .replace(mapTypeTerrain, targetMapType);
}
