import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { Component } from "react";
import { Text, View } from "react-native";

export type WnaTextWithIconProps = {
    appStyle: AppStyle;
    appColors: Colors;
    iconName: keyof typeof FontAwesome5.glyphMap;
    iconColor?: string;
    iconRotation?: number;
    text: string;
    textColor?: string;
    iconSize?: number;
    postfix?: string;
    postfixColor?: string;
    width?: number;
};

class WnaTextWithIcon extends Component<WnaTextWithIconProps> {
    render() {
        const iconSize = this.props.iconSize ?? 18;
        const lineSize = 24;
        const iconColor = this.props.iconColor ?? this.props.appColors.black;
        const postfixColor =
            this.props.postfixColor ?? this.props.appColors.black;
        const textColor = this.props.textColor ?? this.props.appColors.black;
        const postfix = this.props.postfix ?? "";
        const iconRotation = this.props.iconRotation ?? 0;
        return (
            <View
                style={{
                    flexDirection: "row",
                    gap: 8,
                    height: lineSize,
                    width: this.props.width,
                    overflow: "hidden",
                    // backgroundColor: "green"
                }}>
                <View
                    style={{
                        width: lineSize,
                        height: lineSize,
                        // backgroundColor: "red",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                    }}>
                    <View
                        style={{
                            transform: [{ rotate: iconRotation + "deg" }],
                        }}>
                        <FontAwesome5
                            name={this.props.iconName}
                            size={iconSize}
                            color={iconColor}
                        />
                    </View>
                </View>

                <View
                    style={{
                        alignSelf: "center",
                        height: lineSize,
                        // backgroundColor: "yellow",
                        justifyContent: "center",
                    }}>
                    <Text
                        style={[
                            this.props.appStyle.textNeutralMedium,
                            {
                                color: textColor,
                            },
                        ]}>
                        {this.props.text}
                    </Text>
                </View>

                {postfix !== "" ? (
                    <View
                        style={{
                            alignSelf: "center",
                            height: lineSize,
                            justifyContent: "center",
                        }}>
                        <Text
                            style={[
                                this.props.appStyle.textNeutralMedium,
                                {
                                    color: postfixColor,
                                },
                            ]}>
                            {this.props.postfix}
                        </Text>
                    </View>
                ) : null}
            </View>
        );
    }
}

export default WnaTextWithIcon;
