import WnaPressable from "@components/buttons/WnaPressable";
import WnaListCardWhiteDecent from "@components/cards/WnaListCardWhiteDecent";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View, ViewStyle } from "react-native";

export type WnaNavigationItemProps = {
    appColors: Colors;
    appStyle: AppStyle;
    text: string;
    iconName: string;
    onPress: (text: string) => void;
    iconRightName?: string;
    cardStyle?: ViewStyle | ViewStyle[];
    t: TFunction<string[], undefined>;
};

class WnaNavigationItem extends Component<WnaNavigationItemProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaNavigationItemProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.text !== this.props.text
        );
    }

    render() {
        const iconRightName = this.props.iconRightName ?? "angle-right";
        return (
            <WnaPressable
                toolTip=""
                style={[
                    this.props.appStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                    },
                ]}
                onPress={() => this.props.onPress(this.props.text)}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteDecent
                    appColors={this.props.appColors}
                    style={this.props.cardStyle}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            gap: 20,
                            alignContent: "center",
                            // backgroundColor: "red"
                        }}>
                        {/* icon */}
                        <FontAwesome5
                            name={this.props.iconName}
                            size={24}
                            color={this.props.appColors.accent5}
                        />
                        {/* text */}
                        <Text
                            style={[
                                this.props.appStyle.textTitleLarge,
                                {
                                    color: this.props.appColors.black,
                                    width: "100%",
                                    paddingRight: 32,
                                    alignSelf: "center",
                                },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {/* {this.props.text.replace(/\.[^.$]+$/, '')} */}
                            {this.props.text}
                        </Text>
                        {/* arrow */}
                        <View
                            style={{
                                position: "absolute",
                                right: 0,
                                alignSelf: "center",
                            }}>
                            <FontAwesome5
                                name={iconRightName}
                                size={24}
                                color={this.props.appColors.coolgray4}
                            />
                        </View>
                    </View>
                </WnaListCardWhiteDecent>
            </WnaPressable>
        );
    }
}

export default WnaNavigationItem;
