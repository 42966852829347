import WnaPressable from "@components/buttons/WnaPressable";
import WnaImage from "@components/images/WnaImage";
import Colors from "@constants/theme/colors";
import { TFunction } from "i18next";
import { Component } from "react";
import { Linking, View } from "react-native";
import QRCode from "react-native-qrcode-svg";

export type WnaQrCodeProps = {
    appColors: Colors;
    size: number;
    url: string;
    imageUrl?: string;
    t: TFunction<string[], undefined>;
};

class WnaQrCode extends Component<WnaQrCodeProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaQrCodeProps>): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.url !== this.props.url ||
            nextProps.imageUrl !== this.props.imageUrl
        );
    }

    render() {
        const imageUrl = this.props.imageUrl ?? "";
        const logoSize = this.props.size * 0.2;
        return (
            <WnaPressable
                toolTip={this.props.url}
                toolTipPosition="top"
                rippleWhite
                style={{
                    backgroundColor: this.props.appColors.staticWhite,
                    borderRadius: 16,
                    overflow: "hidden",
                }}
                onPress={() => Linking.openURL(this.props.url)}
                t={this.props.t}
                checkInternetConnection={false}>
                <View
                    style={{
                        padding: 16,
                    }}>
                    {imageUrl !== "" ? (
                        <View
                            style={{
                                marginBottom: 16,
                                alignSelf: "center",
                            }}>
                            <View
                                style={{
                                    height: logoSize,
                                    width: logoSize,
                                    backgroundColor:
                                        this.props.appColors.staticWhite,
                                }}>
                                <WnaImage
                                    imageUrl={imageUrl}
                                    style={{
                                        height: logoSize,
                                        width: logoSize,
                                        backgroundColor:
                                            this.props.appColors.staticWhite,
                                    }}
                                    appColors={this.props.appColors}
                                />
                            </View>
                        </View>
                    ) : null}
                    <QRCode value={this.props.url} size={this.props.size} />
                </View>
            </WnaPressable>
        );
    }
}

export default WnaQrCode;
