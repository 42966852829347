import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import WnaAppointment from "@/types/entities/wnaAppointment";

const encrypt = false;
const useDb = true;

export default async function getAppointmentsAsync() {
    const route = "appointments";
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return await fetchAndCacheGetAsync<WnaAppointment[]>(
        route,
        url,
        encrypt,
        useDb
    );
}
