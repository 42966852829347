import ApiSettings from "@/api/apiSettings";
import Logger from "@/utils/logger";
import getCleanKeyName from "@services/WnaDataStorageProvider/wnaDataStorageKeyProvider";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";

const blobToBase64DataURL = (b: Blob) =>
    new Promise((resolvePromise) => {
        const reader = new FileReader();
        reader.onload = () => resolvePromise(reader.result);
        reader.readAsDataURL(b);
    });

const getLocalUriAsync = async (key: string) => {
    const cacheDir = FileSystem.cacheDirectory + "c";
    const di = await FileSystem.getInfoAsync(cacheDir);
    if (!di.exists)
        await FileSystem.makeDirectoryAsync(cacheDir, { intermediates: true });

    return cacheDir + "/" + key;
};

async function downloadFileWebAsync(url: string) {
    try {
        const key = getCleanKeyName(url);
        const logInfo = `url: '${url}'\nkey: '${key}'\nmsg: `;

        let base64DataUrl = await WnaDataStorageProvider.getItemAsync(
            key,
            false,
            true
        );
        if (base64DataUrl !== "") return base64DataUrl;

        const request = url.startsWith(ApiSettings.apiBaseUrl)
            ? {
                  headers: {
                      ApiKey: ApiSettings.apiKey,
                  },
                  method: "GET",
                  mode: "cors",
              }
            : {
                  method: "GET",
              };

        const response = await fetch(url, request as RequestInit);
        if (!response.ok) {
            Logger.warn(downloadFileWebAsync.name, `${logInfo}download failed`);
            return "";
        }

        // Logger.info(downloadFileWebAsync.name, `${logInfo}download success`);
        const bl = await response.blob();
        base64DataUrl = (await blobToBase64DataURL(bl)) as string;

        if (base64DataUrl === "") {
            Logger.warn(
                downloadFileWebAsync.name,
                `${logInfo}could not convert blob to base64dataUrl`
            );
            return "";
        }

        await WnaDataStorageProvider.setItemAsync(
            key,
            base64DataUrl,
            false,
            true
        );

        return base64DataUrl;
    } catch (e) {
        Logger.error(downloadFileWebAsync.name, e);
        return "";
    }
}

async function downloadTextFileWebAsync(url: string) {
    try {
        const key = getCleanKeyName(url);
        const logInfo = `url: '${url}'\nkey: '${key}'\nmsg: `;

        let text = await WnaDataStorageProvider.getItemAsync(key, false, true);
        if (text !== "") return text;

        const request = url.startsWith(ApiSettings.apiBaseUrl)
            ? {
                  headers: {
                      ApiKey: ApiSettings.apiKey,
                  },
                  method: "GET",
                  mode: "cors",
              }
            : {
                  method: "GET",
              };

        const response = await fetch(url, request as RequestInit);
        if (!response.ok) {
            Logger.warn(
                downloadTextFileWebAsync.name,
                `${logInfo}download failed`
            );
            return "";
        }

        text = await response.text();

        if (text === "") {
            Logger.warn(
                downloadTextFileWebAsync.name,
                `${logInfo}could not get text`
            );
            return "";
        }

        await WnaDataStorageProvider.setItemAsync(key, text, false, true);

        return text;
    } catch (e) {
        Logger.error(downloadTextFileWebAsync.name, e);
        return "";
    }
}

async function downloadFileNativeAsync(url: string) {
    try {
        const key = getCleanKeyName(url);
        const localUri = await getLocalUriAsync(key);
        const logInfo = `url: '${url}'\nkey: '${key}'\nlocalUri: '${localUri}'\nmsg: `;

        const fi = await FileSystem.getInfoAsync(localUri);
        if (fi.exists) return fi.uri;

        const dlResult = await FileSystem.downloadAsync(url, localUri, {
            headers: {
                ApiKey: ApiSettings.apiKey,
            },
        });

        if (dlResult.status === 200) return dlResult.uri;

        Logger.warn(downloadFileNativeAsync.name, `${logInfo}download failed`);
        return "";
    } catch (e) {
        Logger.error(downloadFileNativeAsync.name, e);
        return "";
    }
}

async function downloadTextFileNativeAsync(url: string) {
    try {
        const key = getCleanKeyName(url);
        const localUri = await getLocalUriAsync(key);
        const logInfo = `url: '${url}'\nkey: '${key}'\nlocalUri: '${localUri}'\nmsg: `;

        const fi = await FileSystem.getInfoAsync(localUri);
        if (fi.exists) {
            // Logger.info(
            //     downloadTextFileNativeAsync.name,
            //     `${logInfo}file already cached`
            // );

            return await FileSystem.readAsStringAsync(fi.uri);
        }
        const dlResult = await FileSystem.downloadAsync(url, localUri, {
            headers: {
                ApiKey: ApiSettings.apiKey,
            },
        });

        if (dlResult.status === 200) {
            // Logger.info(
            //     downloadTextFileNativeAsync.name,
            //     `${logInfo}download success`
            // );
            return await FileSystem.readAsStringAsync(dlResult.uri);
        } else {
            Logger.warn(
                downloadTextFileNativeAsync.name,
                `${logInfo}download failed`
            );
            return "";
        }
    } catch (e) {
        Logger.error(downloadTextFileNativeAsync.name, e);
        return "";
    }
}

/* returns b64Uri for web and local uri for native */
export default async function downloadFileAsync(url: string) {
    return Platform.OS === "web"
        ? await downloadFileWebAsync(url)
        : await downloadFileNativeAsync(url);
}

/* returns file content */
export async function downloadTextFileAsync(url: string) {
    return Platform.OS === "web"
        ? await downloadTextFileWebAsync(url)
        : await downloadTextFileNativeAsync(url);
}
