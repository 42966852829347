import {
    initFormRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import getAppSettingsAsync from "@/api/appSettings/getAppSettings";
import { isFileName } from "@/api/validators/fileNameValidator";
import { isUri } from "@/api/validators/isUriValidator";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import { apiTimeouts } from "@constants/apiTimeouts";
import minifyImageAsync from "@services/images/wnaImageService";
import { Platform } from "react-native";
import Logger from "wna-logger";

let appSettings: WnaAppSettings | undefined;

export default async function uploadImageAsync(
    url: string,
    fileName: string | undefined,
    fileUri: string | undefined
) {
    if (!isUri(fileUri) || !isFileName(fileName)) return undefined;

    try {
        const formData = new FormData();

        if (Platform.OS !== "web") {
            formData.append("file", {
                uri: fileUri,
                name: fileName,
                type: "image/jpeg",
            } as unknown as Blob);
        } else {
            if (!appSettings) appSettings = await getAppSettingsAsync();

            const minifiedImageBlob = await minifyImageAsync(
                fileUri!,
                appSettings!.imageMaxWidth,
                appSettings!.imageMaxHeight,
                appSettings!.imageQuality
            );
            if (minifiedImageBlob) {
                formData.append("file", minifiedImageBlob);
            } else {
                Logger.warn(
                    uploadImageAsync.name,
                    "could not get minified image blob"
                );
            }
        }
        await prepareRequestAsync();
        const request = initFormRequest();
        request.body = formData;

        return await handleResponseAsync(url, request, apiTimeouts.long);
    } catch (e) {
        Logger.error(uploadImageAsync.name, e);
        return undefined;
    }
}
