/** https://blog.spirokit.com/localization-in-react-native */

import Logger from "@/utils/logger";
import dayjs from "dayjs";
import * as Localization from "expo-localization";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDe from "./de.json";
import translationEn from "./en.json";

require("dayjs/locale/de");
require("dayjs/locale/en");

const getLangCode = () => {
    let langCode = "de";
    try {
        const code = Localization.getLocales().shift();
        //Logger.info("i18n", getLangCode.name, "code: " + code?.languageCode);
        if (code) langCode = code.languageCode ?? "";
    } catch (error) {
        Logger.error(getLangCode.name, error);
    }

    return langCode;
};

i18n.use(initReactI18next)
    .init({
        compatibilityJSON: "v4",
        lng: getLangCode(),
        defaultNS: "translation",
        interpolation: {
            // We disable this because it's not required, given that react already escapes the text
            escapeValue: false,
        },
        // Here you can add all your supported languages
        resources: {
            de: translationDe,
            en: translationEn,
        },
    })
    .then(() => {
        const langCode = getLangCode();
        //Logger.info("i18n", "langCode: " + langCode);
        dayjs.locale(langCode);
    });

i18n.on("languageChanged", (lng) => dayjs.locale(lng));

export { getLangCode, i18n };
