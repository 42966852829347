import getResourceAsTextAsync from "@/api/resources/getResourceAsText";
import { WnaDiaryEntryPlaceholders } from "@/types/wnaDiaryEntryPlaceholder";
import Logger from "@/utils/logger";

export default async function getDiaryEntryPlaceholdersAsync() {
    try {
        const json = await getResourceAsTextAsync(
            "appSettingsDiaryPlaceholders.json"
        );
        return JSON.parse(json) as WnaDiaryEntryPlaceholders;
    } catch (e) {
        Logger.error(getDiaryEntryPlaceholdersAsync.name, e);
        return {} as WnaDiaryEntryPlaceholders;
    }
}
