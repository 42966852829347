import { isWnaUserAdmin } from "@/api/user/isWnaUserAdmin";
import { setStoredUserAsync } from "@/api/user/userStorageProvider";
import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import WnaUser from "@/types/entities/wnaUser";
import { CurrentColors } from "@constants/currentColors";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { setAppStyle } from "@constants/theme/WnaStyleService";
import React from "react";
import { Dimensions } from "react-native";

const initialColors = CurrentColors["light"];
const sW = Dimensions.get("screen").width;
const sH = Dimensions.get("screen").height;
const wW = Dimensions.get("window").width;
const wH = Dimensions.get("window").height;
const isLandscape = wW > wH;

export interface WnaGlobalState {
    isAppInitialized: boolean;
    setIsAppInitialized: () => void;
    currentScreenWidth: number;
    setCurrentScreenWidth: (screenWidth: number) => void;
    currentScreenHeight: number;
    setCurrentScreenHeight: (screenHeight: number) => void;
    currentWindowWidth: number;
    setCurrentWindowWidth: (windowWidth: number) => void;
    currentWindowHeight: number;
    setCurrentWindowHeight: (windowHeight: number) => void;
    isLandscape: boolean;
    setIsLandscape: (isLandscape: boolean) => void;
    appColors: Colors;
    setAppColors: (appColors: Colors | null) => void;
    appStyle: AppStyle;
    currentUser?: WnaUser;
    setCurrentUserAsync: (currentUser: WnaUser | null) => Promise<void>;
    currentAdminUser?: WnaUser;
    currentUserSettings: WnaUserSettings | null;
    setCurrentUserSettings: (
        currentUserSettings: WnaUserSettings | null
    ) => void;
    appSettings?: WnaAppSettings;
    setAppSettings: (appSettings: WnaAppSettings | null) => void;
    isInternetReachable: boolean | null;
    setIsInternetReachable: (isConnected: boolean | null) => void;
    isInternetSlow: boolean | null;
    setIsInternetSlow: (isSlow: boolean | null) => void;
    unreadNotificationCount: number;
    setUnreadNotificationCount: (
        unreadNotificationCount: number | null
    ) => void;
    darkAppBackgroundImage: string;
    setDarkAppBackgroundImage: (uri: string) => void;
    lightAppBackgroundImage: string;
    setLightAppBackgroundImage: (uri: string) => void;
    isStatusBarVisible: boolean;
    setIsStatusBarVisible: (isVisible: boolean) => void;
}

// Declaring the state object globally.
const initialState: WnaGlobalState = {
    isAppInitialized: false,
    setIsAppInitialized: () => {},
    currentScreenWidth: sW,
    setCurrentScreenWidth: () => {},
    currentScreenHeight: sH,
    setCurrentScreenHeight: () => {},
    currentWindowWidth: wW,
    setCurrentWindowWidth: () => {},
    currentWindowHeight: wH,
    setCurrentWindowHeight: () => {},
    isLandscape: isLandscape,
    setIsLandscape: () => {},
    appColors: initialColors,
    setAppColors: () => {},
    appStyle: setAppStyle(initialColors),
    currentUser: undefined,
    setCurrentUserAsync: async () => {},
    currentAdminUser: undefined,
    currentUserSettings: null,
    setCurrentUserSettings: () => {},
    isInternetReachable: true,
    setIsInternetReachable: () => {},
    isInternetSlow: false,
    setIsInternetSlow: () => {},
    appSettings: undefined,
    setAppSettings: () => {},
    unreadNotificationCount: 0,
    setUnreadNotificationCount: () => {},
    darkAppBackgroundImage: "",
    setDarkAppBackgroundImage: () => {},
    lightAppBackgroundImage: "",
    setLightAppBackgroundImage: () => {},
    isStatusBarVisible: true,
    setIsStatusBarVisible: () => {},
};
export const wnaAppContext = (component?: React.Component) => ({
    ...initialState,
    setIsAppInitialized: (isInitialized: boolean) => {
        initialState.isAppInitialized = isInitialized;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentScreenWidth: (screenWidth: number) => {
        initialState.currentScreenWidth = screenWidth;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentScreenHeight: (screenHeight: number) => {
        initialState.currentScreenHeight = screenHeight;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentWindowWidth: (windowWidth: number) => {
        initialState.currentWindowWidth = windowWidth;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentWindowHeight: (windowHeight: number) => {
        initialState.currentWindowHeight = windowHeight;
        component?.setState({ context: wnaAppContext(component) });
    },
    setIsLandscape: (isLandscape: boolean) => {
        initialState.isLandscape = isLandscape;
        component?.setState({ context: wnaAppContext(component) });
    },
    setAppColors: (appColors: Colors) => {
        initialState.appColors = appColors;
        initialState.appStyle = setAppStyle(appColors);
        component?.setState({ context: wnaAppContext(component) });
    },
    setIsInternetReachable: (isInternetReachable: boolean | null) => {
        initialState.isInternetReachable = isInternetReachable;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentUserAsync: async (currentUser?: WnaUser) => {
        if (currentUser && currentUser.id === "") currentUser = undefined;
        if (!initialState.currentUser || !currentUser)
            await setStoredUserAsync(currentUser);

        initialState.currentUser = currentUser;
        if (isWnaUserAdmin(currentUser, initialState.appSettings)) {
            initialState.currentAdminUser = currentUser;
        } else {
            if (!currentUser)
                // keep first admin - if other user context is not admin, ignore
                initialState.currentAdminUser = undefined;
        }
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentUserSettings: (currentUserSettings: WnaUserSettings | null) => {
        initialState.currentUserSettings = currentUserSettings;
        component?.setState({ context: wnaAppContext(component) });
    },
    setAppSettings: (appSettings?: WnaAppSettings) => {
        initialState.appSettings = appSettings;
        component?.setState({ context: wnaAppContext(component) });
    },
    setUnreadNotificationCount: (unreadNotificationCount: number) => {
        initialState.unreadNotificationCount = unreadNotificationCount;
        component?.setState({ context: wnaAppContext(component) });
    },
    setDarkAppBackgroundImage: (uri: string) => {
        initialState.darkAppBackgroundImage = uri;
        component?.setState({ context: wnaAppContext(component) });
    },
    setLightAppBackgroundImage: (uri: string) => {
        initialState.lightAppBackgroundImage = uri;
        component?.setState({ context: wnaAppContext(component) });
    },
});

export type Context = ReturnType<typeof wnaAppContext>;

export const WnaAppContext = React.createContext<Context>(wnaAppContext());

export interface State {
    context: Context;
}

export default class WnaAppContextProvider extends React.Component {
    state: State = {
        context: wnaAppContext(this),
    };

    render() {
        return (
            <WnaAppContext.Provider value={this.state.context}>
                {
                    // @ts-ignore
                    this.props.children
                }
            </WnaAppContext.Provider>
        );
    }
}
