import { WnaPressableState } from "@components/buttons/WnaPressable";
import { WnaShadowStyle } from "@components/misc/WnaShadowStyle";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { execWhenOnline } from "@services/wnaExecWhenOnline";
import { TFunction } from "i18next";
import { Component } from "react";
import { Pressable, Text, ViewStyle } from "react-native";

export type WnaButtonTextProps = {
    appColors: Colors;
    appStyle: AppStyle;
    text: string;
    textColor?: string;
    backgroundColor?: string;
    style?: ViewStyle | ViewStyle[];
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
};

// Use the full props within the actual component
export default class WnaButtonText extends Component<WnaButtonTextProps> {
    render() {
        const textColor =
            this.props.textColor ?? this.props.appColors.staticWhite;

        const backgroundColor =
            this.props.backgroundColor ?? this.props.appColors.accent6;
        return (
            <Pressable
                style={({ pressed, hovered, focused }: WnaPressableState) => [
                    WnaShadowStyle(),
                    {
                        opacity: pressed ? 0.7 : hovered || focused ? 0.9 : 1,
                    },
                    this.props.appStyle.buttonWithText,
                    this.props.style,
                    {
                        backgroundColor: backgroundColor,
                    },
                ]}
                android_ripple={{
                    color: this.props.appColors.staticWhite,
                }}
                onPress={() =>
                    execWhenOnline(
                        this.props.checkInternetConnection,
                        this.props.t,
                        this.props.onPress
                    )
                }>
                <Text
                    style={{
                        color: textColor,
                        verticalAlign: "middle",
                        textAlign: "center",
                        textTransform: "uppercase",
                    }}>
                    {this.props.text}
                </Text>
            </Pressable>
        );
    }
}
