import WnaUser from "@/types/entities/wnaUser";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaProfileIcon from "@components/misc/WnaProfileIcon";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Router } from "expo-router";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { View } from "react-native";

export type WnaNotificationHeaderRightProps = {
    appColors: Colors;
    appStyle: AppStyle;
    currentUser?: WnaUser;
    unreadNotificationCount: number;
    router: Router;
    t: TFunction<string[], undefined>;
};

export default class WnaNotificationHeaderRight extends Component<WnaNotificationHeaderRightProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaNotificationHeaderRightProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.currentUser !== this.props.currentUser ||
            nextProps.unreadNotificationCount !==
                this.props.unreadNotificationCount
        );
    }

    render() {
        return !this.props.currentUser ? (
            <View
                style={[
                    this.props.appStyle.containerCenter,
                    {
                        marginRight: 16,
                        marginTop: 4,
                        width: 32,
                        height: 32,
                        borderRadius: 16,
                        borderColor: this.props.appColors.staticWhite,
                        borderWidth: 2,
                        overflow: "hidden",
                    },
                ]}>
                <WnaPressable
                    onPress={() =>
                        this.props.router.push(
                            WnaNavigationRouteProvider.menuProfileLogin
                        )
                    }
                    t={this.props.t}
                    checkInternetConnection={false}>
                    <WnaProfileIcon appColors={this.props.appColors} />
                </WnaPressable>
            </View>
        ) : (
            <View style={{ alignItems: "center" }}>
                <WnaButtonHeader
                    appStyle={this.props.appStyle}
                    appColors={this.props.appColors}
                    text={this.props.t(i18nKeys.screenTitleNotificationList)}
                    toolTipPosition="bottom"
                    iconName="bell"
                    badgeVisible={this.props.unreadNotificationCount > 0}
                    onPress={() =>
                        this.props.router.push(
                            WnaNavigationRouteProvider.notifications
                        )
                    }
                    t={this.props.t}
                    checkInternetConnection={false}
                />
            </View>
        );
    }
}
