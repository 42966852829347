import { WnaMapPoint } from "@/types/wnaMapPoint";
import { getUnixMinDate } from "@/utils/dateTimeHelper";

export default interface WnaGraphData {
    // Allgemeine Angaben
    routeName: string;
    timeStampStart: Date; // ISO-Datum als String (z.B. "2025-02-07T12:34:56Z")
    timeStampEnd: Date;

    // Distance
    distanceScale: number;
    distanceUnitText: string;
    distance: number;
    distanceText: string;
    distanceDurationInMinutes: number;
    totalDurationInMinutes: number;
    totalDurationText: string;

    // Altitude
    altMin: number;
    altMinText: string;
    altMax: number;
    altMaxText: string;
    altDiff: number;
    altDiffText: string;
    altUp: number;
    altUpText: string;
    altUpDistance: number;
    altUpDistanceText: string;
    altUpDurationInMinutes: number;
    altDown: number;
    altDownText: string;
    altDownDistance: number;
    altDownDistanceText: string;
    altDownDurationInMinutes: number;
    altEqual: number;
    altEqualText: string;
    altEqualDistance: number;
    altEqualDistanceText: string;
    altEqualDurationInMinutes: number;

    // Altitude Graph
    yAxisMin: number;
    yAxisMax: number;
    yAxisData: number[];
    xAxisData: string[];

    // GeoData
    routePoints: WnaMapPoint[];
    featurePoints: WnaMapPoint[];
}

export function getDefaultWnaGraphData() {
    return {
        routeName: "",
        routeHash: "",
        xAxisData: [],
        yAxisData: [],
        yAxisMax: 3000,
        yAxisMin: 0,
        routePoints: [],
        featurePoints: [],

        distanceScale: 0,
        timeStampStart: getUnixMinDate(),
        timeStampEnd: getUnixMinDate(),
        distanceUnitText: "",

        distance: 0,
        distanceText: "",
        distanceDurationInMinutes: 0,

        altMin: 0,
        altMinText: "",

        altMax: 0,
        altMaxText: "",

        altDiff: 0,
        altDiffText: "",

        altUp: 0,
        altUpText: "",
        altUpDistance: 0,
        altUpDistanceText: "",
        altUpDurationInMinutes: 0,

        altDown: 0,
        altDownText: "",
        altDownDistance: 0,
        altDownDistanceText: "",
        altDownDurationInMinutes: 0,

        altEqual: 0,
        altEqualText: "",
        altEqualDistance: 0,
        altEqualDistanceText: "",
        altEqualDurationInMinutes: 0,

        totalDurationInMinutes: 0,
        totalDurationText: "",
    } as WnaGraphData;
}
