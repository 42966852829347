import WnaCatalogItem from "@/types/wnaCatalogItem";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { StaticColors } from "../theme/staticColors";

export const WnaCatalogNotificationState: WnaCatalogItem[] = [
    {
        id: 0,
        text: i18nKeys.catalogNotificationStateUnread,
        displayColor: StaticColors.burgundy3,
    },
    {
        id: 1,
        text: i18nKeys.catalogNotificationStateRead,
        displayColor: "transparent",
    },
    {
        id: 2,
        text: i18nKeys.catalogNotificationStateDeleted,
        displayColor: "transparent",
    },
];
