import { WnaAppContext } from "@app/WnaAppContext";
import { WnaHapticTab } from "@components/tabbar/WnaHapticTab";
import TabBarBackground from "@components/tabbar/WnaTabBarBackground";
import { FontAwesome5 } from "@expo/vector-icons";
import { Tabs } from "expo-router";
import React, { useContext } from "react";
import { Platform } from "react-native";

export default function TabLayout() {
    const { appColors } = useContext(WnaAppContext);
    const iconSize = 20;
    const iconSizeFocused = 22;

    return (
        <Tabs
            screenOptions={{
                title: "",
                headerStyle: {
                    backgroundColor: appColors.isDark
                        ? appColors.accent6
                        : appColors.accent5,
                    borderColor: appColors.isDark
                        ? appColors.accent6
                        : appColors.accent5,
                },
                headerTintColor: appColors.staticWhite,
                headerShadowVisible: false,
                tabBarActiveTintColor: appColors.isDark
                    ? appColors.accent1
                    : appColors.accent5,
                tabBarInactiveTintColor: appColors.isDark
                    ? appColors.staticCoolgray5
                    : appColors.staticCoolgray6,
                tabBarButton: WnaHapticTab,
                tabBarBackground: TabBarBackground,
                tabBarStyle: Platform.select({
                    ios: {
                        // Use a transparent background on iOS to show the blur effect
                        position: "absolute",
                    },
                    default: {
                        backgroundColor: appColors.white,
                        shadowColor: appColors.white,
                        borderColor: appColors.white,
                        borderWidth: 0,
                        height: 40,
                    },
                }),
            }}>
            <Tabs.Screen
                name="index"
                options={{
                    sceneStyle: {
                        backgroundColor: appColors.isDark
                            ? appColors.staticCoolgray8
                            : appColors.staticCoolgray1,
                    },
                    tabBarLabel: () => null,
                    tabBarIcon: ({ focused, color }) => (
                        <FontAwesome5
                            size={focused ? iconSizeFocused : iconSize}
                            name="home"
                            color={color}
                        />
                    ),
                }}
            />

            <Tabs.Screen
                name="appointments"
                options={{
                    sceneStyle: {
                        backgroundColor: appColors.isDark
                            ? appColors.staticCoolgray8
                            : appColors.staticCoolgray1,
                    },
                    tabBarLabel: () => null,
                    tabBarIcon: ({ focused, color }) => (
                        <FontAwesome5
                            size={focused ? iconSizeFocused : iconSize}
                            name="calendar-alt"
                            color={color}
                        />
                    ),
                }}
            />

            <Tabs.Screen
                name="chapters"
                options={{
                    sceneStyle: {
                        backgroundColor: appColors.isDark
                            ? appColors.staticCoolgray8
                            : appColors.staticCoolgray1,
                    },
                    tabBarLabel: () => null,
                    tabBarIcon: ({ focused, color }) => (
                        <FontAwesome5
                            size={focused ? iconSizeFocused : iconSize}
                            name="book-open"
                            color={color}
                        />
                    ),
                }}
            />

            <Tabs.Screen
                name="menu"
                options={{
                    sceneStyle: {
                        backgroundColor: appColors.isDark
                            ? appColors.staticCoolgray8
                            : appColors.staticCoolgray1,
                    },
                    tabBarLabel: () => null,
                    tabBarIcon: ({ focused, color }) => (
                        <FontAwesome5
                            size={focused ? iconSizeFocused : iconSize}
                            name="bars"
                            color={color}
                        />
                    ),
                }}
            />
        </Tabs>
    );
}
