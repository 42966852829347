import { WnaAppContext } from "@app/WnaAppContext";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { Stack } from "expo-router";
import { useContext } from "react";

export default function Layout() {
    const { appColors } = useContext(WnaAppContext);
    return (
        <Stack
            screenOptions={{
                title: "",
                headerStyle: {
                    backgroundColor: appColors.isDark
                        ? appColors.accent6
                        : appColors.accent5,
                },
                headerTitleStyle: { fontFamily: FontFamilies.Courgette },
                headerTintColor: appColors.staticWhite,
                headerShadowVisible: false,
            }}
        />
    );
}
