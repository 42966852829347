import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { Component } from "react";
import { Text } from "react-native";
import WnaListCardWhiteDecent from "./WnaListCardWhiteDecent";

export type WnaCardActivityIndicatorProps = {
    appColors: Colors;
    appStyle: AppStyle;
    isBusyText?: string;
};

class WnaCardActivityIndicator extends Component<WnaCardActivityIndicatorProps> {
    render() {
        const text =
            this.props && this.props.isBusyText && this.props.isBusyText !== ""
                ? this.props.isBusyText
                : "...";

        return (
            <WnaListCardWhiteDecent appColors={this.props.appColors}>
                <WnaActivityIndicator appColors={this.props.appColors} />
                <Text
                    style={[
                        this.props.appStyle.textTitleLarge,
                        {
                            width: 128,
                            marginTop: 24,
                            textAlign: "center",
                        },
                    ]}>
                    {text}
                </Text>
            </WnaListCardWhiteDecent>
        );
    }
}

export default WnaCardActivityIndicator;
