import WnaImage from "@components/images/WnaImage";
import { WnaShadowStyle } from "@components/misc/WnaShadowStyle";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import React, { Component } from "react";
import { Dimensions, View } from "react-native";

export type WnaListCardWhiteImageProps = {
    appColors: Colors;
    appStyle: AppStyle;
    imageUrl?: string;
    tinyImageUrl?: string;
};

class WnaListCardWhiteImage extends Component<WnaListCardWhiteImageProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaListCardWhiteImageProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.imageUrl !== this.props.imageUrl ||
            nextProps.tinyImageUrl !== this.props.tinyImageUrl
        );
    }

    render() {
        const windowWidth = Dimensions.get("screen").width;
        const windowHeight = Dimensions.get("screen").height;
        const isLandscape = windowWidth > windowHeight;
        const imageHeight = isLandscape ? windowWidth / 4 : windowHeight / 4;
        const tinyImageSize = isLandscape ? windowWidth / 8 : windowHeight / 8;
        return (
            <>
                <WnaImage
                    appColors={this.props.appColors}
                    style={{
                        height: imageHeight,
                        maxHeight: imageHeight,
                        minHeight: imageHeight,
                        backgroundColor: this.props.appColors.coolgray2,
                    }}
                    imageUrl={this.props.imageUrl ?? ""}
                    showActivityIndicator={true}
                />
                {(this.props.tinyImageUrl ?? "") !== "" ? (
                    <View
                        style={[
                            WnaShadowStyle(),
                            {
                                height: tinyImageSize,
                                width: tinyImageSize,
                                position: "absolute",
                                bottom: 8,
                                right: 8,
                                borderWidth: 1,
                                borderColor:
                                    this.props.appColors.staticCoolgray6,
                                borderRadius: 16,
                                overflow: "hidden",
                            },
                        ]}>
                        <WnaImage
                            appColors={this.props.appColors}
                            style={{
                                height: tinyImageSize,
                                width: tinyImageSize,
                                backgroundColor: this.props.appColors.coolgray3,
                            }}
                            imageUrl={this.props.tinyImageUrl ?? ""}
                            showActivityIndicator={false}
                        />
                    </View>
                ) : null}
            </>
        );
    }
}

export default WnaListCardWhiteImage;
