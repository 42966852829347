import {
    initFormRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import WnaRouteDetails from "@/types/entities/wnaRouteDetails";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function verifyGeoFileAsync(file: Blob) {
    try {
        const route = `routes/verifyGeoFile`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initFormRequest();
        request.body = new FormData();
        request.body.append("file", file);
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.long
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaRouteDetails;
        } else {
            Logger.error(verifyGeoFileAsync.name, "could not verify geofile.");
            return undefined;
        }
    } catch (e) {
        Logger.error(verifyGeoFileAsync.name, e);
        return undefined;
    }
}
