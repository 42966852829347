import { WnaMapPoint } from "@/types/wnaMapPoint";
import * as geolib from "geolib";
import { GeolibInputCoordinates } from "geolib/es/types";
import { LatLng } from "react-native-maps";

const getRegionOfLatLng = (origin: LatLng, destination: LatLng) => {
    const oLat = Math.abs(origin.latitude);
    const oLng = Math.abs(origin.longitude);
    const dLat = Math.abs(destination.latitude);
    const dLng = Math.abs(destination.longitude);
    const z = 0.05;
    return {
        latitude: (origin.latitude + destination.latitude) / 2,
        longitude: (origin.longitude + destination.longitude) / 2,
        latitudeDelta: Math.abs(oLat - dLat) + z,
        longitudeDelta: Math.abs(oLng - dLng) + z,
    };
};
const getBoundsOfMapPoints = (points: WnaMapPoint[]) => {
    const geoPoints = new Array<GeolibInputCoordinates>();
    points.forEach((p) =>
        geoPoints.push({
            latitude: p.lat ?? 0,
            longitude: p.lng ?? 0,
        })
    );
    return geolib.getBounds(geoPoints);
};
export { getBoundsOfMapPoints, getRegionOfLatLng };
