import Logger from "@/utils/logger";
import * as DocumentPicker from "expo-document-picker";
import { Platform } from "react-native";
import * as mime from "react-native-mime-types";

export default async function pickGeoFileAsync(
    onError: (errorMessage: string) => void
) {
    try {
        const mimeTypeKml = mime.lookup("kml");
        const mimeTypeGeoJson = mime.lookup("geojson");
        const mimeTypeGpx = mime.lookup("gpx");
        const result = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: Platform.OS !== "web",
            type:
                Platform.OS !== "web"
                    ? [
                          "text/xml",
                          "text/gpsxml",
                          "application/xml",
                          "application/gpsxml",

                          "text/kml",
                          "application/vnd.google-earth.kml",
                          "application/vnd.google-earth.kml+xml",

                          "text/kmz",
                          "application/vnd.google-earth.kmz",
                          "application/vnd.google-earth.kmz+xml",

                          "text/gpx",
                          "application/gpx",
                          "application/gpx+xml",
                          "application/vnd.google-earth.gpx",
                          "application/vnd.google-earth.gpx+xml",

                          "application/octet-stream",

                          mimeTypeKml !== false ? mimeTypeKml : "",
                          mimeTypeGeoJson !== false ? mimeTypeGeoJson : "",
                          mimeTypeGpx !== false ? mimeTypeGpx : "",
                      ]
                    : ["*.*"],
        });

        if (result.assets === null) return undefined;

        const fileName = result.assets[0].name;
        const fileExt = fileName.split(".").pop();
        const fileExtLow = fileExt!.toLocaleLowerCase();
        switch (fileExtLow) {
            case "geojson":
            case "kml":
            case "gpx":
                break;
            default:
                onError("*.geojson, *.kml, *.gpx");
                return undefined;
        }
        // https://stackoverflow.com/a/76913577
        const response = await fetch(result.assets[0].uri);
        return await response.blob();
    } catch (error) {
        Logger.error(pickGeoFileAsync.name, error);
        onError("something went wrong");
        return undefined;
    }
}
