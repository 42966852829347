import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { Component } from "react";
import { View } from "react-native";

export type WnaProfileIconProps = {
    appColors: Colors;
    iconSize?: number;
};

class WnaProfileIcon extends Component<WnaProfileIconProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaProfileIconProps>): boolean {
        return nextProps.appColors.isDark !== this.props.appColors.isDark;
    }

    render() {
        const iconSize = this.props.iconSize ?? 32;
        const faSize = iconSize * 0.56;
        return (
            <View
                style={[
                    {
                        width: iconSize,
                        height: iconSize,
                        maxHeight: iconSize,
                        backgroundColor: this.props.appColors.staticCoolgray4,
                        overflow: "hidden",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                ]}>
                <FontAwesome5
                    name={"user"}
                    size={faSize}
                    color={this.props.appColors.staticWhite}
                />
            </View>
        );
    }
}

export default WnaProfileIcon;
