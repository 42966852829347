import WnaCatalogItem from "@/types/wnaCatalogItem";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { StaticColors } from "../theme/staticColors";

export const WnaCatalogOrderBy: WnaCatalogItem[] = [
    {
        id: 0,
        text: i18nKeys.catalogOrderByAsc,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.catalogOrderByDesc,
        displayColor: "transparent",
        selectionColor: StaticColors.staticCoolgray6,
    },
];
