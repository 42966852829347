import WnaWebView from "@/components/misc/WnaWebView";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaNotificationHeaderRight from "@components/navigation/WnaNotificationHeaderRight";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { useRouter } from "expo-router";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";

export default function HomeScreen() {
    const {
        appSettings,
        appColors,
        appStyle,
        unreadNotificationCount,
        currentUser,
    } = useContext(WnaAppContext);
    const [isBusy, setIsBusy] = useState(true);
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const currentUrlRef = useRef<string>("");
    useEffect(() => {
        if (appSettings !== null) {
            currentUrlRef.current = appColors.isDark
                ? appSettings!.startPageDarkUrl
                : appSettings!.startPageLightUrl;
            setIsBusy(false);
        }
        // eslint-disable-next-line
    }, [appSettings]);

    return (
        <>
            <WnaBaseScreen
                title={t(i18nKeys.screenTitleStartPage)}
                headerButton0={
                    <WnaNotificationHeaderRight
                        unreadNotificationCount={unreadNotificationCount}
                        appStyle={appStyle}
                        appColors={appColors}
                        currentUser={currentUser}
                        router={router}
                        t={t}
                    />
                }>
                {isBusy ? (
                    <View
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            flex: 1,
                            justifyContent: "center",
                        }}>
                        <Image
                            style={{
                                width: 256,
                                height: 256,
                                alignSelf: "center",
                            }}
                            source={require("../../../assets/images/adaptive-icon-2.png")}
                        />
                    </View>
                ) : (
                    <WnaWebView urlOrHtml={currentUrlRef.current} />
                )}
            </WnaBaseScreen>
        </>
    );
}
