import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaDiaryEntry, {
    getDefaultDiaryEntry,
} from "@/types/entities/wnaDiaryEntry";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function createDiaryEntryAsync(
    userId: string | undefined,
    chapterIdentifier: string | undefined
) {
    if (!isUserId(userId) || !isDateTimeIdentifier(chapterIdentifier))
        return undefined;

    try {
        const diaryEntry = getDefaultDiaryEntry();
        diaryEntry.chapterIdentifier = chapterIdentifier!;
        diaryEntry.dateStart = new Date();
        diaryEntry.dateEnd = diaryEntry.dateStart;
        const route = `users/${userId}/chapters/${chapterIdentifier}/diaryentries/create`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        const request = initJsonRequest("POST");
        request.body = JSON.stringify(diaryEntry);
        await prepareRequestAsync();
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaDiaryEntry;
        } else {
            Logger.error(
                createDiaryEntryAsync.name,
                "could not create DiaryEntry"
            );
            return undefined;
        }
    } catch (e) {
        Logger.error(createDiaryEntryAsync.name, e);
        return undefined;
    }
}
