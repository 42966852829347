// 2023-08-20
import { StaticColors } from "@constants/theme/staticColors";
import dayjs from "dayjs";

const getUnixMinDate = () => new Date(Date.UTC(1970, 0, 1));

const _formatDDMMYYYY = "DD.MM.YY";

// Montag, 01.12.23
const _formatDDDDMMYYYY = "dddd, DD.MM.YY";
// Mo, 01.12.23
const _formatDDDMMYYYY = "ddd, DD.MM.YY";
// Montag, 01.12.23
const _formatDDDDMMYYYYHHMM = "dddd, DD.MM.YY HH:mm";

// Mo, 01.12.23
const _formatDDDMMYYYYHHMM = "ddd, DD.MM.YY HH:mm";

const getDurationByMinutes = (pMinutes: number) => {
    const date = new Date(0);
    date.setMinutes(pMinutes);
    const parts = [];

    const years = date.getUTCFullYear() - 1970;
    if (years > 0) parts.push(years, "J");

    const months = date.getUTCMonth();
    if (months > 0) parts.push(months, " M ");

    const days = date.getUTCDate() - 1;
    if (days > 0) parts.push(days, " T ");

    const hours = date.getUTCHours();
    parts.push(hours.toString().padStart(2, "0"), ":");

    const minutes = date.getUTCMinutes();
    parts.push(minutes.toString().padStart(2, "0"), "");

    return parts.join("");
};

/**
 * Montag, 01.12.23
 * @param dt
 * @returns string
 */
const toStringDateWeekday = (dt?: Date) =>
    dt ? dayjs(dt).format(_formatDDDDMMYYYY) : "";

/**
 * Mo, 01.12.23
 * @param dt
 * @returns string
 */
const dateToStringDateWeekdayShort = (dt?: Date) =>
    dt ? dayjs(dt).format(_formatDDDMMYYYY) : "";

const toStringDateTimeWeekday = (dt?: Date) =>
    dt ? dayjs(dt).format(_formatDDDDMMYYYYHHMM) : "";

/**
 * Montag, 01.12.23 08:15
 * @param dt
 * @returns
 */
const dateToStringDateTimeWeekday = (dt?: Date) =>
    dt ? dayjs(dt).format(_formatDDDDMMYYYYHHMM) : "";

/**
 * Mo, 01.12.23 08:15
 * @param dt
 * @returns
 */
const dateToStringDateTimeWeekdayShort = (dt?: Date) =>
    dt ? dayjs(dt).format(_formatDDDMMYYYYHHMM) : "";

const addDays = (date: Date, days: number): Date => {
    date.setDate(date.getDate() + days);
    return date;
};

const getDateArrayByRange = (dateStart: Date, dateEnd: Date) => {
    const ret = new Array<Date>();
    let currentDate = new Date(dateStart);
    while (currentDate < dateEnd) {
        ret.push(new Date(currentDate));
        currentDate = addDays(currentDate, 1);
    }
    return ret;
};

const getColorByWeekday = (dt: Date) => {
    const dtt = new Date(dt);
    switch (dtt.getDay()) {
        case 0:
            // so
            return StaticColors.burgundy4;
        case 1:
            // mo
            return StaticColors.red4;
        case 2:
            // di
            return StaticColors.violet4;
        case 3:
            // mi
            return StaticColors.blue4;
        case 4:
            // do
            return StaticColors.turquoise4;
        case 5:
            // fr
            return StaticColors.green4;
        case 6:
            // sa
            return StaticColors.orange4;
        default:
            return StaticColors.staticCoolgray4;
    }
};

/**
 * DD.MM.YYYY
 * @param dt
 * @returns
 */
const toStringDate = (dt?: Date) =>
    dt ? dayjs(dt).format(_formatDDMMYYYY) : "";

const isGreaterThan = (a: Date, b: Date) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate.toISOString() === bDate.toISOString()
        ? 0
        : bDate.toISOString() < aDate.toISOString()
          ? -1
          : 1;
};

const isSmallerThan = (a: Date, b: Date) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate.toISOString() === bDate.toISOString()
        ? 0
        : bDate.toISOString() > aDate.toISOString()
          ? -1
          : 1;
};

export {
    addDays,
    dateToStringDateTimeWeekday,
    dateToStringDateTimeWeekdayShort,
    dateToStringDateWeekdayShort,
    getColorByWeekday,
    getDateArrayByRange,
    getDurationByMinutes,
    getUnixMinDate,
    isGreaterThan,
    isSmallerThan,
    toStringDate,
    toStringDateTimeWeekday,
    toStringDateWeekday,
};
