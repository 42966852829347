import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync, {
    getCachedResponseAsync,
} from "@/api/fetchAndCacheGet";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaDiaryEntry from "@/types/entities/wnaDiaryEntry";

const encrypt = true;
const useDb = true;

export default async function getDiaryEntryAsync(
    userId: string | undefined,
    chapterIdentifier: string | undefined,
    identifier: string | undefined,
    getCached: boolean
) {
    if (
        !isUserId(userId) ||
        !isDateTimeIdentifier(chapterIdentifier) ||
        !isDateTimeIdentifier(identifier)
    )
        return undefined;

    const route = `users/${userId}/chapters/${chapterIdentifier}/diaryentries/${identifier}`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;

    let diaryEntry: WnaDiaryEntry | undefined;
    if (getCached)
        diaryEntry =
            (await getCachedResponseAsync<WnaDiaryEntry>(
                route,
                encrypt,
                useDb
            )) ?? undefined;
    else
        diaryEntry =
            (await fetchAndCacheGetAsync<WnaDiaryEntry>(
                route,
                url,
                encrypt,
                useDb
            )) ?? undefined;

    return diaryEntry;
}
