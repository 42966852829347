import Logger from "@/utils/logger";
import { ImageStyle } from "expo-image";
import React, { Component } from "react";
import { ImageBackground } from "react-native";

export type WnaImageBackgroundProps = {
    imageUri: string | undefined;
    style?: ImageStyle;
    children: React.ReactNode;
    blurRadius?: number;
};

const defaultStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
} as ImageStyle;

class WnaImageBackground extends Component<WnaImageBackgroundProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaImageBackgroundProps>
    ): boolean {
        return (
            nextProps.imageUri !== this.props.imageUri ||
            nextProps.children !== this.props.children
        );
    }

    render() {
        const altText = "background";
        if (this.props.imageUri === "") {
            Logger.warn(WnaImageBackground.name, "imageUri is empty");
            return <>{this.props.children}</>;
        } else {
            return (
                <ImageBackground
                    source={{ uri: this.props.imageUri, cache: "force-cache" }}
                    style={{ flex: 1 }}
                    alt={altText}
                    accessibilityLabel={altText}
                    resizeMode="cover"
                    blurRadius={
                        this.props.blurRadius
                            ? this.props.blurRadius
                            : undefined
                    }
                    imageStyle={this.props.style ?? defaultStyle}>
                    {this.props.children}
                </ImageBackground>
            );
        }
    }
}

export default WnaImageBackground;
