import { Router } from "expo-router";

export default class WnaNavigationRouteProvider {
    // root
    public static root = "/";

    public static navigateToRoot(router: Router) {
        router.dismissAll();
        router.replace(this.root);
    }

    // menu
    public static menu = `${this.root}menu`;
    public static menuDisclaimer = `${this.menu}/disclaimer`;
    public static menuPrivacy = `${this.menu}/privacy`;

    // menu / settings
    public static menuSettings = `${this.menu}/settings`;
    public static menuSettingsAdvanced = `${this.menuSettings}/advanced`;
    public static menuSettingsDiary = `${this.menuSettings}/diary`;
    public static menuSettingsMap = `${this.menuSettings}/map`;

    // menu / profile
    public static menuProfile = `${this.menu}/profile`;
    public static menuProfileLogin = `${this.menuProfile}/login`;

    // appointment
    public static appointments = `${this.root}appointments`;
    public static appointmentsCreate = `${this.appointments}/create`;
    public static appointmentsAccommodation = `${this.appointments}/accommodation`;

    public static appointmentsIdentifier(identifier: string) {
        return `${this.appointments}/${identifier}`;
    }

    public static appointmentsIdentifierUpdate(identifier: string) {
        return `${this.appointmentsIdentifier(identifier)}/update`;
    }

    // chapters
    public static chapters = `${this.root}chapters`;

    public static chaptersIdentifierDiaryEntries(identifier: string) {
        return `${this.chapters}/${identifier}/diaryentries`;
    }

    public static chaptersIdentifierPreview(identifier: string) {
        return `${this.chapters}/${identifier}/preview`;
    }

    // diaryEntries
    public static diaryEntry(
        chapterIdentifier: string,
        identifier: string,
        isEditMode?: boolean,
        tab?: number,
        order?: number
    ) {
        let route = `${this.chaptersIdentifierDiaryEntries(chapterIdentifier)}/${identifier}`;

        if (isEditMode === true || tab || order) route += `?`;

        if (isEditMode === true) route += `isEditMode=${isEditMode ? 1 : 0}`;

        if (tab) route += (isEditMode === true ? `&` : "") + `tab=${tab}`;

        if (order)
            route += (isEditMode === true || tab ? `&` : "") + `order=${order}`;

        return route;
    }

    public static diaryEntryImages(
        chapterIdentifier: string,
        identifier: string,
        tab: number,
        startIndex: number
    ) {
        return `${this.chaptersIdentifierDiaryEntries(chapterIdentifier)}/${identifier}/images?tab=${tab}&startIndex=${startIndex}`;
    }

    // notifications
    public static notifications = `${this.root}notifications`;
    public static notificationsCreate = `${this.notifications}/create`;

    public static notificationsIdentifier(identifier: string) {
        return `${this.notifications}/${identifier}`;
    }

    public static notificationsIdentifierUpdate(identifier: string) {
        return `${this.notificationsIdentifier(identifier)}/update`;
    }
}
