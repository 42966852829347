export const StaticColors = {
    staticAccent1: "#fcfcfc",
    staticAccent2: "#d6d6d6",
    staticAccent3: "#b0b0b0",
    staticAccent4: "#8a8a8a",
    staticAccent5: "#646464",
    staticAccent6: "#3e3e3e",
    staticAccent7: "#282D37",
    staticAccent8: "#181818",

    statusBarBackground: "#87a5c8",

    staticBlack: "#111111",
    staticWhite: "#ffffff",

    staticWarmgray1: "#f5f4f1",
    staticWarmgray2: "#ddded6",
    staticWarmgray3: "#bcbbb2",
    staticWarmgray4: "#9c9a8e",
    staticWarmgray5: "#858379",
    staticWarmgray6: "#747067",
    staticWarmgray7: "#4f4b41",
    staticWarmgray8: "#38342f",

    staticCoolgray1: "#fcfcfc",
    staticCoolgray2: "#d6d6d6",
    staticCoolgray3: "#b0b0b0",
    staticCoolgray4: "#8a8a8a",
    staticCoolgray5: "#646464",
    staticCoolgray6: "#3e3e3e",
    staticCoolgray7: "#282D37",
    staticCoolgray8: "#181818",

    blue1: "#e0effb",
    blue2: "#b4d5f6",
    blue3: "#73aef4",
    blue4: "#347de0",
    blue5: "#1455c0",
    blue6: "#0c3992",
    blue7: "#0a1e6e",
    blue8: "#061350",

    red1: "#fee6e6",
    red2: "#fcc8c3",
    red3: "#fa9090",
    red4: "#f75056",
    red5: "#ec0016",
    red6: "#c50014",
    red7: "#9b000e",
    red8: "#740009",

    lightgreen1: "#ebf7dd",
    lightgreen2: "#c9eb9e",
    lightgreen3: "#9fd45f",
    lightgreen4: "#78be14",
    lightgreen5: "#63a615",
    lightgreen6: "#508b1b",
    lightgreen7: "#44741a",
    lightgreen8: "#375f15",

    green1: "#e2f3e5",
    green2: "#bddbb9",
    green3: "#8cbc80",
    green4: "#66a558",
    green5: "#408335",
    green6: "#2a7230",
    green7: "#165c27",
    green8: "#154a26",

    turquoise1: "#e3f5f4",
    turquoise2: "#bee2e5",
    turquoise3: "#83caca",
    turquoise4: "#3cb5ae",
    turquoise5: "#00a099",
    turquoise6: "#008984",
    turquoise7: "#006e6b",
    turquoise8: "#005752",

    cyan1: "#e5faff",
    cyan2: "#bbe6f8",
    cyan3: "#84cfef",
    cyan4: "#55b9e6",
    cyan5: "#309fd1",
    cyan6: "#0087b9",
    cyan7: "#006a96",
    cyan8: "#004b6d",

    orange1: "#fff4d8",
    orange2: "#fce3b4",
    orange3: "#faca7f",
    orange4: "#f8ab37",
    orange5: "#f39200",
    orange6: "#d77b00",
    orange7: "#c05e00",
    orange8: "#a24800",

    yellow1: "#ffffdc",
    yellow2: "#ffffaf",
    yellow3: "#fff876",
    yellow4: "#fff000",
    yellow5: "#ffd800",
    yellow6: "#ffbb00",
    yellow7: "#ff9b00",
    yellow8: "#ff7a00",

    burgundy1: "#f4e8ed",
    burgundy2: "#edcbd6",
    burgundy3: "#da9aa8",
    burgundy4: "#c0687b",
    burgundy5: "#a9455d",
    burgundy6: "#8c2e46",
    burgundy7: "#641e32",
    burgundy8: "#4d0820",

    pink1: "#fdeef8",
    pink2: "#f9d2e5",
    pink3: "#f4aece",
    pink4: "#ee7bae",
    pink5: "#e93e8f",
    pink6: "#db0078",
    pink7: "#b80065",
    pink8: "#970052",

    violet1: "#f4eefa",
    violet2: "#e0cde4",
    violet3: "#c2a1c7",
    violet4: "#9a6ca6",
    violet5: "#814997",
    violet6: "#6e368c",
    violet7: "#581d70",
    violet8: "#421857",
};
