import {
    dateToStringDateWeekdayShort,
    getUnixMinDate,
} from "@/utils/dateTimeHelper";

export default interface WnaAppointment {
    identifier: string;
    designator: string;
    comment: string;
    dateCreate: Date;
    dateUpdate: Date;
    dateRead: Date;
    dateDelete: Date;
    state: number;
    imageUrl: string;
    dateStart: Date;
    dateEnd: Date;
    slotsMin: number;
    slotsMax: number;
    slotsTaken: number;
    location: string;
}

export function getDefaultAppointment() {
    return {
        identifier: "",
        designator: "",
        comment: "",
        dateCreate: getUnixMinDate(),
        dateUpdate: getUnixMinDate(),
        dateRead: getUnixMinDate(),
        dateDelete: getUnixMinDate(),
        state: 0,
        imageUrl: "",
        dateStart: getUnixMinDate(),
        dateEnd: getUnixMinDate(),
        slotsMin: 0,
        slotsMax: 0,
        location: "",
    } as WnaAppointment;
}

export function getAppointmentDisplayText(
    appointment: WnaAppointment | undefined
) {
    if (!appointment) return "...";
    const dtStartString = dateToStringDateWeekdayShort(appointment.dateStart);
    const dtEndString = dateToStringDateWeekdayShort(appointment.dateEnd);
    let displayText = dtStartString;
    if (dtEndString !== dtStartString) displayText += " - " + dtEndString;
    return displayText;
}
