import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import { apiTimeouts } from "@constants/apiTimeouts";

const encrypt = true;
const useDb = false;
const route = "appsettings";
let appSettings: WnaAppSettings | undefined = undefined;

export default async function getAppSettingsAsync() {
    if (appSettings !== undefined) return appSettings;

    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return await fetchAndCacheGetAsync<WnaAppSettings>(
        route,
        url,
        encrypt,
        useDb,
        apiTimeouts.default
    );
}
