import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontFamilies } from "@constants/theme/fontFamilies";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component, CSSProperties } from "react";
import { Platform, View } from "react-native";
import RenderHtml, {
    defaultSystemFonts,
    MixedStyleDeclaration,
} from "react-native-render-html";

const _fallbackHtml = `<!DOCTYPE html><html lang="de"><body></body></html>`;

export type WnaHtmlRendererProps = {
    appColors: Colors;
    appStyle: AppStyle;
    width: number;
    maxHeight?: number;
    html?: string;
    fallbackHtml?: string | null;
    padding?: number;
    fontFamily?: string;
    fontSize?: number;
    fontColor?: string;
};

class WnaHtmlRenderer extends Component<WnaHtmlRendererProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaHtmlRendererProps>) {
        return (
            nextProps.html !== this.props.html ||
            nextProps.appColors.isDark !== this.props.appColors.isDark
        );
    }

    render() {
        const padding = this.props.padding ?? 0;
        const fontSize = this.props.fontSize ?? 14;
        const fontFamily = this.props.fontFamily ?? FontFamilies.Roboto;
        const fontColor =
            this.props.fontColor ?? this.props.appColors.coolgray6;
        const fallbackHtml = this.props.fallbackHtml ?? _fallbackHtml;
        // https://stackoverflow.com/questions/68966120/react-native-render-html-you-seem-to-update-the-x-prop-of-the-y-component-in-s
        const systemFonts = [
            ...defaultSystemFonts,
            FontFamilies.Courgette,
            FontFamilies.MonoSpace,
            FontFamilies.Roboto,
        ];
        const isMaxHeightSet = this.props.maxHeight ?? false;
        const maxHeight = this.props.maxHeight ?? 1;
        const nativeStyles: Readonly<Record<string, MixedStyleDeclaration>> = {
            body: {
                padding: padding,
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
            },
            a: {
                color: fontColor,
            },
            p: {
                lineHeight: fontSize * 1.5,
            },
        };

        const webStyles: CSSProperties = {
            padding: padding,
            fontFamily: fontFamily,
            fontSize: fontSize,
            lineHeight: 1.5,
            backgroundColor: "transparent",
            color: fontColor,
        };

        const html = this.props.html === "" ? fallbackHtml : this.props.html;
        return (
            <>
                {Platform.OS === "web" ? (
                    <div
                        style={webStyles}
                        dangerouslySetInnerHTML={{ __html: html ?? "" }}></div>
                ) : (
                    <View>
                        <RenderHtml
                            source={{ html: html ?? "" }}
                            contentWidth={this.props.width}
                            tagsStyles={nativeStyles}
                            systemFonts={systemFonts}
                        />
                    </View>
                )}
                {isMaxHeightSet ? (
                    <LinearGradient
                        start={[1, 1]}
                        end={[1, 0]}
                        colors={[this.props.appColors.white, "transparent"]}
                        pointerEvents="none"
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: maxHeight / 2,
                        }}
                    />
                ) : null}
            </>
        );
    }
}

export default WnaHtmlRenderer;
