import { healthcheckAsync } from "@/api/apiHealthcheck";
import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isFileName } from "@/api/validators/fileNameValidator";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";

const encrypt = true;
const useDb = true;

export default async function getResourceAsTextAsync(
    fileName: string | undefined
): Promise<string> {
    if (!isFileName(fileName)) return "";
    try {
        const route = `resources/${fileName}/text`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;

        if (!(await healthcheckAsync()))
            return await WnaDataStorageProvider.getItemAsync(
                route,
                encrypt,
                useDb
            );

        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.long
        );
        if (result instanceof Response) {
            // cache it
            const fileContent = await result.text();
            await WnaDataStorageProvider.setItemAsync(
                route,
                fileContent,
                encrypt,
                useDb
            );
            return fileContent;
        } else {
            // get cached on error
            return await WnaDataStorageProvider.getItemAsync(
                route,
                encrypt,
                useDb
            );
        }
    } catch (e) {
        Logger.error(getResourceAsTextAsync.name, e);
        return "";
    }
}
