import deleteChapterAsync from "@/api/chapter/deleteChapter";
import updateChapterAsync from "@/api/chapter/updateChapter";
import uploadChapterImageAsync from "@/api/chapter/uploadChapterImage";
import WnaChapter from "@/types/entities/wnaChapter";
import WnaFileInfo, { getDefaultFileInfo } from "@/types/entities/wnaFileInfo";
import { convertHexToRgba } from "@/utils/colorConverter";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIconText from "@components/buttons/WnaButtonIconText";
import WnaPressable from "@components/buttons/WnaPressable";
import DialogProvider from "@components/dialog/dialogProvider";
import WnaImage from "@components/images/WnaImage";
import WnaActivityIndicator from "@components/misc/WnaActivityIndicator";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import { animationSpeed } from "@constants/animationSpeed";
import { i18nKeys } from "@services/i18n/i18nKeys";
import * as DocumentPicker from "expo-document-picker";
import { useRouter } from "expo-router";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, View } from "react-native";
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from "react-native-reanimated";
import Toast from "react-native-toast-message";

export type WnaChapterEditViewProps = {
    currentChapter?: WnaChapter;
    canDelete: boolean;
    isVisible: boolean;
    onUpdate: (updatedChapter: WnaChapter) => void;
    closeSideBar: () => void;
};

export default function WnaChapterEditView(props: WnaChapterEditViewProps) {
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const { isAppInitialized, currentUser, appColors, appStyle } =
        useContext(WnaAppContext);

    const isLoadedRef = useRef<boolean>(false);
    const [isBusy, setIsBusy] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [currentDesignator, setCurrentDesignator] = useState<string>("");
    const [chapterImage, setChapterImage] =
        useState<WnaFileInfo>(getDefaultFileInfo());

    const viewWidth = useSharedValue(0);
    const viewAnimationConfig = {
        duration: animationSpeed,
        easing: Easing.bezier(0.5, 0.01, 0, 1),
    };

    const viewStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(viewWidth.value, viewAnimationConfig),
        };
    });

    const saveChanges = () => {
        if (!isDirty) return;

        props.currentChapter!.designator = currentDesignator;

        setIsBusy(true);
        updateChapterAsync(currentUser!.id, props.currentChapter!).then(
            (resultChapter) => {
                if (!resultChapter) {
                    Toast.show({
                        text1: t(i18nKeys.errorSave),
                        type: "error",
                    });
                    setIsBusy(false);
                    return;
                }

                setIsDirty(false);
                props.onUpdate(resultChapter);
                props.closeSideBar();
                setIsBusy(false);
            }
        );
    };

    const onUploadImage = () => {
        setIsBusy(true);
        DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: true,
            type: "image/*",
            multiple: false,
        }).then(async (result) => {
            if (
                result.canceled ||
                result.assets === null ||
                result.assets.length < 1
            ) {
                setIsBusy(false);
                return;
            }

            const fileInfo = {
                url: result.assets[0].uri,
                isLocal: true,
                fileName: result.assets[0].name,
            } as WnaFileInfo;

            const resultChapter = await uploadChapterImageAsync(
                currentUser!.id,
                props.currentChapter!.identifier,
                fileInfo.fileName,
                fileInfo.url
            );

            if (!resultChapter) {
                Toast.show({
                    text1: t(i18nKeys.errorSave),
                    type: "error",
                });
                setIsBusy(false);
                return;
            }

            setChapterImage(fileInfo);
            props.onUpdate(resultChapter);
            setIsBusy(false);
        });
    };

    const onDelete = () => {
        if (!currentUser || isBusy) return;

        setIsBusy(true);

        DialogProvider.showYesNoAsync(t(i18nKeys.questionDelete)).then(
            (goAhead) => {
                if (!goAhead) {
                    setIsBusy(false);
                    return;
                }

                deleteChapterAsync(
                    currentUser.id,
                    props.currentChapter!.identifier
                ).then((resultChapter) => {
                    if (!resultChapter) {
                        setIsBusy(false);
                        Toast.show({
                            text1: t(i18nKeys.errorDelete),
                            type: "error",
                        });
                        return;
                    }

                    router.dismissAll();
                    router.replace(WnaNavigationRouteProvider.chapters);
                    Toast.show({
                        text1: t(i18nKeys.successDelete),
                        type: "success",
                    });
                });
            }
        );
    };

    const navigateToPreview = () =>
        router.push(
            WnaNavigationRouteProvider.chaptersIdentifierPreview(
                props.currentChapter?.identifier!
            )
        );

    useEffect(() => {
        viewWidth.value = props.isVisible ? 1 : 0;

        if (props.currentChapter && !isLoadedRef.current) {
            isLoadedRef.current = true;
            setCurrentDesignator(props.currentChapter.designator);
            setChapterImage({
                url: props.currentChapter.imageUrl ?? "",
            } as WnaFileInfo);
        }

        // eslint-disable-next-line
    }, [props.currentChapter, props.isVisible]);

    if (!isAppInitialized || !currentUser) return null;

    return (
        <Animated.View
            style={[
                {
                    backgroundColor: convertHexToRgba(appColors.accent5, 0.5),
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                },
                viewStyle,
            ]}>
            {isBusy ? (
                <View style={appStyle.containerCenterCenter}>
                    <WnaActivityIndicator appColors={appColors} />
                </View>
            ) : (
                <View
                    style={{
                        flex: 1,
                        alignItems: "flex-start",
                    }}>
                    {/* designator */}
                    <View style={{ height: 64, width: 256 }}>
                        <TextInput
                            style={[
                                appStyle.textInput,
                                {
                                    position: "absolute",
                                    width: "auto",
                                    top: 8,
                                    left: 8,
                                    right: 8,
                                    backgroundColor: appColors.white,
                                    color: appColors.black,
                                },
                            ]}
                            selectionColor={appColors.accent1}
                            value={currentDesignator}
                            onChangeText={(text) => {
                                setCurrentDesignator(text);
                                setIsDirty(true);
                            }}
                            placeholder={t(i18nKeys.placeholderChapter)}
                            maxLength={1024}
                            editable={true}
                            placeholderTextColor={appColors.coolgray3}
                        />
                    </View>
                    {/* image */}
                    <View style={{ height: 144, width: 256 }}>
                        <WnaPressable
                            style={{
                                borderRadius: 8,
                                position: "absolute",
                                left: 8,
                                right: 8,
                                backgroundColor: appColors.coolgray2,
                            }}
                            onPress={() => {
                                onUploadImage();
                            }}
                            t={t}
                            checkInternetConnection={true}>
                            <WnaImage
                                appColors={appColors}
                                imageUrl={chapterImage.url}
                                style={{
                                    height: 144,
                                    backgroundColor: appColors.coolgray2,
                                }}
                            />
                        </WnaPressable>
                    </View>

                    {/*print pdf preview*/}
                    <View
                        style={{
                            height: 64,
                            width: 256,
                            marginVertical: 16,
                        }}>
                        <WnaButtonIconText
                            iconName={"file-pdf"}
                            style={{
                                position: "absolute",
                                left: 8,
                                right: 8,
                            }}
                            text={t(i18nKeys.actionPrint)}
                            appColors={appColors}
                            onPress={navigateToPreview}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>

                    {/* delete */}
                    {props.canDelete ? (
                        <View
                            style={{
                                height: 64,
                                width: 256,
                                marginVertical: 16,
                            }}>
                            <WnaButtonIconText
                                iconName={"trash"}
                                style={{
                                    position: "absolute",
                                    left: 8,
                                    right: 8,
                                }}
                                text={t(i18nKeys.actionDelete)}
                                appColors={appColors}
                                onPress={onDelete}
                                t={t}
                                checkInternetConnection={true}
                            />
                        </View>
                    ) : null}
                    {isDirty ? (
                        <View
                            style={{
                                height: 64,
                                width: 256,
                                marginVertical: 16,
                                position: "absolute",
                                bottom: 0,
                            }}>
                            <WnaButtonIconText
                                iconName={"check"}
                                style={{
                                    position: "absolute",
                                    left: 8,
                                    right: 8,
                                }}
                                text={t(i18nKeys.actionSave)}
                                appColors={appColors}
                                onPress={saveChanges}
                                t={t}
                                checkInternetConnection={true}
                            />
                        </View>
                    ) : null}
                </View>
            )}
        </Animated.View>
    );
}
