import createAppointmentAsync from "@/api/appointment/createAppointment";
import WnaAppointment from "@/types/entities/wnaAppointment";
import { addDays } from "@/utils/dateTimeHelper";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaAppointmentEdit from "@components/appointment/WnaAppointmentEdit";
import WnaButtonHeader from "@components/buttons/WnaButtonHeader";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { i18nKeys } from "@services/i18n/i18nKeys";
import { Redirect, useRouter } from "expo-router";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Toast from "react-native-toast-message";

export default function CreateAppointmentScreen() {
    const {
        isAppInitialized,
        appColors,
        currentAdminUser,
        appStyle,
        appSettings,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [appointment] = useState<WnaAppointment>({
        comment: "",
        designator: "",
        dateStart: new Date(),
        dateEnd: addDays(new Date(), 7),
    } as WnaAppointment);

    const appointmentRef = useRef<WnaAppointment>(appointment);

    const save = () => {
        setIsBusy(true);
        createAppointmentAsync(appointmentRef.current).then((appointment) => {
            setIsBusy(false);
            if (appointment === undefined) {
                Toast.show({
                    text1: t(i18nKeys.errorSave),
                    type: "error",
                });
            } else {
                Toast.show({
                    text1: t(i18nKeys.successSave),
                    type: "success",
                });
                router.replace(WnaNavigationRouteProvider.appointments);
            }
        });
    };

    if (!isAppInitialized || !currentAdminUser)
        return <Redirect href={WnaNavigationRouteProvider.appointments} />;

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            title={t(i18nKeys.screenTitleAppointmentDetails)}
            headerButton0={
                currentAdminUser ? (
                    <WnaButtonHeader
                        appColors={appColors}
                        appStyle={appStyle}
                        iconName={"check"}
                        text={t(i18nKeys.actionSave)}
                        onPress={save}
                        checkInternetConnection={true}
                        t={t}
                    />
                ) : null
            }>
            <WnaAppointmentEdit
                appColors={appColors}
                appStyle={appStyle}
                appSettings={appSettings!}
                currentItem={appointment}
                onUpdate={(newAppointment) => {
                    appointmentRef.current = newAppointment;
                }}
                t={t}
            />
        </WnaBaseScreen>
    );
}
