// https://www.youtube.com/watch?v=rbuSx1yEgV8
import { setJwt } from "@/api/apiRequestInitializer";
import createOrUpdateUserAsync from "@/api/user/createOrUpdate";
import verifyIdTokenAsync from "@/api/user/verifyIdToken";
import WnaAppSettings from "@/types/entities/wnaAppSettings";
import WnaUser from "@/types/entities/wnaUser";
import Logger from "@/utils/logger";
import { initializeApp } from "@firebase/app";
import {
    Auth,
    browserSessionPersistence,
    User as FirebaseUser,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
} from "@firebase/auth";

let firebaseAuth: Auth;

const provider = new GoogleAuthProvider();
provider.addScope("profile");
provider.addScope("email");

async function firebaseUserToWnaUserAsync(firebaseUser?: FirebaseUser) {
    if (!firebaseUser) return undefined;

    const providerData = firebaseUser.providerData[0];
    const wnaUser = {
        id: providerData.uid,
        email: firebaseUser.email ?? "",
        picture: firebaseUser.photoURL ?? "",
        given_name: firebaseUser.displayName ?? "",
        name: firebaseUser.displayName ?? "",
        verified_email: firebaseUser.emailVerified ?? true,
        refreshToken: firebaseUser.refreshToken,
    } as WnaUser;

    wnaUser.idToken = await firebaseUser.getIdToken();
    wnaUser.wnaApiJwt = await verifyIdTokenAsync(wnaUser.id, wnaUser.idToken);
    return await createOrUpdateUserAsync(wnaUser);
}

export async function initializeGoogleAuthAsync(appSettings: WnaAppSettings) {
    const firebaseApp = initializeApp({
        apiKey: appSettings.firebaseAppConfig.apiKey,
        appId: appSettings.firebaseAppConfig.appId,
        authDomain: appSettings.firebaseAppConfig.authDomain,
        measurementId: appSettings.firebaseAppConfig.measurementId,
        messagingSenderId: appSettings.firebaseAppConfig.messagingSenderId,
        projectId: appSettings.firebaseAppConfig.projectId,
        storageBucket: appSettings.firebaseAppConfig.storageBucket,
    });

    firebaseAuth = getAuth(firebaseApp);
    await firebaseAuth.setPersistence(browserSessionPersistence);
}

export async function signOutWithGoogleAsync() {
    try {
        await signOut(firebaseAuth);
        setJwt("");
    } catch (error) {
        Logger.error(signOutWithGoogleAsync.name, JSON.stringify(error));
    }
}

export async function signInWithGoogleAsync() {
    try {
        const result = await signInWithPopup(firebaseAuth, provider);
        return await firebaseUserToWnaUserAsync(result.user);
    } catch (error) {
        Logger.error(signInWithGoogleAsync.name, error);
        return undefined;
    }
}
