import { Component } from "react";
import { FlatList, View } from "react-native";

export interface WnaMenuItem {
    route: string;
    text: string;
    icon: string;
    rightIcon: string;
    type: string;
    data: any;
}

export type WnaNavigationListProps = {
    items: WnaMenuItem[];
    renderItem: (item: WnaMenuItem) => void;
};

class WnaNavigationList extends Component<WnaNavigationListProps> {
    render() {
        return (
            <FlatList
                contentContainerStyle={{
                    paddingBottom: 16,
                    paddingTop: 16,
                    paddingHorizontal: 16,
                }}
                ItemSeparatorComponent={() => <View style={{ height: 8 }} />}
                data={this.props.items}
                // extraData={extraData}
                // @ts-ignore
                renderItem={({ item: currentItem }) =>
                    this.props.renderItem(currentItem)
                }
            />
        );
    }
}

export default WnaNavigationList;
