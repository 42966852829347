import { convertHexToRgba } from "@/utils/colorConverter";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { FontAwesome5 } from "@expo/vector-icons";
import { Component } from "react";
import { View, ViewStyle } from "react-native";

export type WnaButtonIconInnerIconProps = {
    appColors: Colors;
    appStyle: AppStyle;
    iconName: keyof typeof FontAwesome5.glyphMap;
    color?: string;
    style?: ViewStyle;
    size?: number;
};

const _size = 52;
const _borderRadius = _size / 2;
const _iconSize = _size * 0.35;

class WnaButtonIconInnerIcon extends Component<WnaButtonIconInnerIconProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaButtonIconInnerIconProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.color !== this.props.color ||
            nextProps.iconName !== this.props.iconName
        );
    }

    render() {
        const defaultClr = convertHexToRgba(
            this.props.appColors.staticBlack,
            0.6
        );
        const size = this.props.size ?? _size;
        return (
            <View
                style={[
                    this.props.appStyle.containerCenterCenter,
                    {
                        width: size,
                        height: size,
                        maxWidth: size,
                        maxHeight: size,
                        backgroundColor: defaultClr,
                        borderRadius: _borderRadius,
                    },
                ]}>
                <FontAwesome5
                    name={this.props.iconName}
                    size={_iconSize}
                    color={this.props.color ?? this.props.appColors.staticWhite}
                />
            </View>
        );
    }
}

export default WnaButtonIconInnerIcon;
