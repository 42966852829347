import ApiSettings from "@/api/apiSettings";
import fetchAndCacheGetAsync from "@/api/fetchAndCacheGet";
import { isSha256Identifier } from "@/api/validators/sha256IdentifierValidator";
import WnaRouteDetails, {
    getDefaultWnaRouteDetails,
} from "@/types/entities/wnaRouteDetails";

export default async function getRouteDetailsAsync(
    identifier: string | undefined
) {
    if (!isSha256Identifier(identifier)) getDefaultWnaRouteDetails();

    const route = `routes/${identifier}`;
    const url = `${ApiSettings.apiBaseUrl}/${route}`;
    return (
        (await fetchAndCacheGetAsync<WnaRouteDetails>(
            route,
            url,
            true,
            true
        )) ?? getDefaultWnaRouteDetails()
    );
}
