import WnaChapter from "@/types/entities/wnaChapter";
import { getColorByWeekday } from "@/utils/dateTimeHelper";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaListCardWhiteImage from "@components/cards/WnaListCardWhiteImage";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { Text } from "react-native";

export type WnaChapterListEntryProps = {
    currentItem: WnaChapter;
    appColors: Colors;
    appStyle: AppStyle;
    onPress: (currentItem: WnaChapter) => void;
    t: TFunction<string[], undefined>;
};

class WnaChapterListEntry extends Component<WnaChapterListEntryProps> {
    shouldComponentUpdate(nextProps: WnaChapterListEntryProps) {
        const nextDateCreate = new Date(nextProps.currentItem.dateCreate);
        const nextDateUpdate = new Date(nextProps.currentItem.dateUpdate);
        const currentDateCreate = new Date(this.props.currentItem.dateCreate);
        const currentDateUpdate = new Date(this.props.currentItem.dateUpdate);
        return (
            nextDateCreate.toISOString() !== currentDateCreate.toISOString() ||
            nextDateUpdate.toISOString() !== currentDateUpdate.toISOString() ||
            nextProps.appColors.isDark !== this.props.appColors.isDark
        );
    }

    render() {
        // Logger.info(ChapterListEntry.name, this.render.name, this.props.currentItem.designator);
        return (
            <WnaPressable
                disableHover
                toolTip=""
                style={[
                    this.props.appStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                    },
                ]}
                onPress={() => this.props.onPress(this.props.currentItem)}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteImage
                    appStyle={this.props.appStyle}
                    appColors={this.props.appColors}
                    headerTitle={""}
                    color={getColorByWeekday(this.props.currentItem.dateCreate)}
                    hasRoute={false}
                    hasImages={false}
                    hasDownload={this.props.currentItem.pdfDownloadUrl !== ""}
                    imageUrl={this.props.currentItem.imageUrl}>
                    {this.props.currentItem.designator !== "" &&
                    this.props.currentItem.designator.trim() !== "" ? (
                        <Text
                            style={[
                                this.props.appStyle.textTitleLarge,
                                { marginBottom: 8 },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {this.props.currentItem.designator}
                        </Text>
                    ) : null}
                </WnaListCardWhiteImage>
            </WnaPressable>
        );
    }
}

export default WnaChapterListEntry;
