{
  "common": {
    "actionAddPicture": "Add",
    "actionCancel": "Cancel",
    "actionClearCache": "Clear offline cache",
    "actionCreateAppointment": "New course",
    "actionCreateChapter": "New chapter",
    "actionCreateDiaryEntry": "new entry",
    "actionCreateNotification": "New notification",
    "actionDelete": "Delete",
    "actionDeleteLog": "Delete log",
    "actionDonate": "Donate",
    "actionDownload": "Download",
    "actionDownloadCache": "Create offline cache",
    "actionDownloadDiaryPdf": "Download PDF",
    "actionEdit": "Edit",
    "actionEditChapter": "Edit chapter",
    "actionEmail": "E-Mail",
    "actionFullscreen": "Fullscreen",
    "actionGoBack": "Back",
    "actionInfo": "Further Infos",
    "actionLogIn": "Sign in",
    "actionLogInForFree": "Sign in for free",
    "actionLogInWithFacebook": "Facebook",
    "actionLogInWithGoogle": "Google",
    "actionLogOut": "Sign out",
    "actionLogOutAndDeleteData": "Sign out and delete data",
    "actionNextEntry": "Next entry",
    "actionNextPage": "Next page",
    "actionPhoneCall": "Call",
    "actionPreviousEntry": "Previous entry",
    "actionPreviousPage": "Previous page",
    "actionPrint": "Print",
    "actionRoute": "Route",
    "actionSave": "Save",
    "actionSend": "Send",
    "actionSendErrorLog": "Send error log",
    "actionUndo": "Undo",
    "answerNo": "No",
    "answerYes": "Yes",
    "catalogDefaultDiaryTabEntry": "Entry",
    "catalogDefaultDiaryTabMap": "Route",
    "catalogDefaultDiaryTabPictures": "Pictures",
    "catalogGraphQualityHigh": "High",
    "catalogGraphQualityLow": "Low",
    "catalogGraphQualityMedium": "Normal",
    "catalogMapTypeHybrid": "Hybrid",
    "catalogMapTypeRoadmap": "Roadmap",
    "catalogMapTypeSatellite": "Satellite",
    "catalogMapTypeTerrain": "Terrain",
    "catalogNotificationStateDeleted": "Deleted",
    "catalogNotificationStateRead": "Read",
    "catalogNotificationStateUnread": "Unread",
    "catalogOrderByAsc": "Ascending",
    "catalogOrderByDesc": "Descending",
    "catalogThemeDark": "Dark",
    "catalogThemeLight": "Light",
    "catalogThemeSystem": "System",
    "diaryEntryTabNameEntry": "Entry",
    "diaryEntryTabNameMap": "Route",
    "diaryEntryTabNamePictures": "Pictures",
    "dummyDiaryEntry1Comment": "Dear Diary, today was a day full of tranquility, nature, and inner reflection. I am currently at a beautiful beach, surrounded by the endless expanses of the ocean and the soothing sound of the waves. It is the second day of my hike along the beach, and I already feel a deeper connection with nature. The morning began with a meditation right by the water. The gentle lapping of the waves and the chirping of the birds put me in a state of inner calm. The rising sun bathed the sky in a warm, orange light that glittered on the water's surface.",
    "dummyDiaryEntry1Designator": "My Fourth Day",
    "dummyDiaryEntry2Comment": "Dear Diary, today I went on a hike through the forest, and it was an unforgettable experience. Nature captivated me with its splendor and silence. The morning began with the chirping of birds and the gentle rustling of leaves. I could almost inhale the refreshing scent of the forest in the cool morning air. The sun's rays filtered through the dense tree canopies, bathing the forest in a warm, golden light.",
    "dummyDiaryEntry2Designator": "My Third Day",
    "errorActionCanceled": "Action canceled",
    "errorDelete": "Error deleting",
    "errorInputInvalid": "Input invalid",
    "errorNoInternet": "You are currently offline",
    "errorSave": "Error saving",
    "errorSend": "Error sending",
    "errorUnknown": "An error has occurred",
    "infoNeedToLogIn": "You must sign in to use the diary.",
    "infoNoMapData": "No information about the route",
    "infoNoRoute": "No route",
    "infoNotAvailable": "Unfortunately not yet available",
    "infoPdfCreation": "Your diary is currently being created. You will receive a notification once the process is complete.",
    "infoPleaseWait": "Please wait",
    "infoPreviewInProgress": "Preview is being created - this may take some time.",
    "infoUserLoggedIn": "You have been logged in",
    "infoUserLoggedOut": "You have been logged out",
    "infoWorkInProgress": "... in  progress ...",
    "mapInfoPostfixDelta": "Delta",
    "mapInfoPostfixDown": "Descent",
    "mapInfoPostfixDuration": "Duration",
    "mapInfoPostfixEqual": "Flat",
    "mapInfoPostfixHighestPoint": "Highest point",
    "mapInfoPostfixLength": "Route length",
    "mapInfoPostfixLowestPoint": "Lowes point",
    "mapInfoPostfixUp": "Ascent",
    "placeholderAppointmentDetails": "Infos / Prices",
    "placeholderChapter": "Chapter",
    "questionCreateNewChapter": "Really create a new chapter?",
    "questionClearCache": "Really clear offline cache?",
    "questionDelete": "Really delete?",
    "questionLogOut": "Really sign out?",
    "questionLogOutAndDeleteData": "Really sign out and delete all data?",
    "questionLongRunningProcess": "This process may take some time. Continue?",
    "questionSaveChanges": "Save changes?",
    "questionSend": "Really send?",
    "screenErrorLog": "Logs",
    "screenSubtitleAppointmentList": "Upcoming fasting hiking courses",
    "screenTitleAccommodationDetails": "Hotel Haus Kranich",
    "screenTitleAdministration": "Administration",
    "screenTitleAppointmentDetails": "Course",
    "screenTitleAppointmentList": "Courses",
    "screenTitleDayDetails": "Diary entry",
    "screenTitleDayList": "Diary",
    "screenTitleDiaryPreview": "Print preview",
    "screenTitleDisclaimer": "Disclaimer",
    "screenTitleMenu": "More ...",
    "screenTitleNotificationDetails": "Notification",
    "screenTitleNotificationList": "Notifications",
    "screenTitlePrivacy": "Privacy",
    "screenTitleProfile": "Profile",
    "screenTitleSettings": "Settings",
    "screenTitleSettingsAdvanced": "Settings: Advanced",
    "screenTitleSettingsDiary": "Settings: Diary",
    "screenTitleSettingsMap": "Settings: Map",
    "screenTitleStartPage": "Fasten mit Volker",
    "settingsAdvancedLogging": "Advanced Logging",
    "settingsDiaryDefaultTab": "Default tab",
    "settingsDiaryOrderItems": "Order diary entries",
    "settingsDiarySwitchToDefaultTab": "Switch to standard tab when turning pages",
    "settingsMapControls": "Show map controls",
    "settingsMapDefaultMap": "Default map type",
    "settingsMapScaleElevationChart": "Scale elevation profile",
    "settingsTheme": "Theme",
    "success": "Successfully executed",
    "successDelete": "Successfully deleted",
    "successDownload": "Download completed",
    "successSave": "Successfully saved",
    "successSend": "Successfully send",
    "warningCreateNewChapter": "Please create a new chapter.",
    "warningWaitForProgress": "Please wait until the process has finished.",
    "wordAdvanced": "Advanced",
    "wordBegin": "Begin",
    "wordDiary": "Diary",
    "wordEnd": "End",
    "wordMap": "Map",
    "wordNo": "No",
    "wordYes": "Yes"
  }
}
