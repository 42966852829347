export default interface WnaUser {
    id: string;
    email: string;
    given_name: string;
    name: string;
    picture: string;
    locale: string;
    verified_email: boolean;
    docRefId: string;
    fcmToken: string;
    isBlocked: boolean;
    idToken: string;
    refreshToken: string;
    wnaApiJwt: string;
}

export function getDefaultWnaUser() {
    return {
        id: "",
        email: "",
        given_name: "",
        name: "",
        picture: "",
        locale: "",
        verified_email: false,
        docRefId: "",
        fcmToken: "",
        isBlocked: false,
        idToken: "",
        refreshToken: "",
        wnaApiJwt: "",
    } as WnaUser;
}
