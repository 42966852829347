import { healthcheckAsync } from "@/api/apiHealthcheck";
import { TFunction } from "i18next";

const execWhenOnline = (
    checkInternetConnection: boolean,
    t: TFunction<string[], undefined>,
    exec: () => void
) => {
    if (checkInternetConnection) {
        healthcheckAsync().then((isHealthy) => {
            if (isHealthy) exec();
        });
    } else {
        exec();
    }
};

export { execWhenOnline };
