import { convertHexToRgba } from "@/utils/colorConverter";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import * as React from "react";
import { Component } from "react";
import { Text, View } from "react-native";

export type WnaPaginationInfoViewProps = {
    appColors: Colors;
    appStyle: AppStyle;
    index: number;
    length: number;
    isLandscape: boolean;
};

class WnaPaginationInfoView extends Component<WnaPaginationInfoViewProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaPaginationInfoViewProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.length !== this.props.length ||
            nextProps.index !== this.props.index ||
            nextProps.isLandscape !== this.props.isLandscape
        );
    }

    render() {
        const _navBtnWidth = 64;
        const _navBtnHeight = 64;
        const _portraitBottom = 20;
        return (
            <View
                pointerEvents="box-none"
                style={{
                    position: "absolute",
                    left: _navBtnWidth,
                    right: _navBtnWidth,
                    bottom: this.props.isLandscape ? 0 : _portraitBottom - 8,
                    height: _navBtnHeight,
                    flex: 1,
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <View
                    style={[
                        {
                            backgroundColor: convertHexToRgba("#000000", 0.5),
                            borderRadius: 16,
                            height: 32,
                            justifyContent: "center",
                            paddingHorizontal: 24,
                        },
                    ]}>
                    <Text
                        style={[
                            this.props.appStyle.textNeutralSmall,
                            {
                                color: this.props.appColors.staticWhite,
                                textAlign: "center",
                            },
                        ]}>
                        {this.props.index + 1} / {this.props.length}
                    </Text>
                </View>
            </View>
        );
    }
}

export default WnaPaginationInfoView;
