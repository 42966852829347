import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isUserId } from "@/api/validators/userIdValidator";
import Logger from "@/utils/logger";

export default async function getCountUnreadNotifications(
    userId: string | undefined
): Promise<number> {
    if (!isUserId(userId)) return 0;

    try {
        const route = `users/${userId}/notifications/countUnread`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const result = await handleResponseAsync(url, request, 5000);
        if (result instanceof Response) {
            const val = await result.json();
            const resultCount = Number(val);
            return Number.isNaN(resultCount) ? 0 : resultCount;
        } else {
            return 0;
        }
    } catch (e) {
        Logger.error(getCountUnreadNotifications.name, e);
        return 0;
    }
}
