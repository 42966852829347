import WnaUser, { getDefaultWnaUser } from "@/types/entities/wnaUser";
import Logger from "@/utils/logger";
import WnaDataStorageProvider from "@services/WnaDataStorageProvider/wnaDataStorageProvider";

const keyUser = "user";
const encrypt = true;
const userDb = false;

export async function getStoredUserAsync(): Promise<WnaUser | undefined> {
    try {
        const objJson = await WnaDataStorageProvider.getItemAsync(
            keyUser,
            encrypt,
            userDb
        );
        const user = JSON.parse(objJson) as WnaUser;
        if (!user || user.id === "") return undefined;
        return user;
    } catch (e) {
        Logger.error(getStoredUserAsync.name, e);
        return undefined;
    }
}

export async function setStoredUserAsync(user?: WnaUser) {
    try {
        const objJson = JSON.stringify(user ?? getDefaultWnaUser());
        await WnaDataStorageProvider.setItemAsync(
            keyUser,
            objJson,
            encrypt,
            userDb
        );
    } catch (e) {
        Logger.error(setStoredUserAsync.name, e);
    }
}
