import WnaRouteDetails, {
    getDefaultWnaRouteDetails,
} from "@/types/entities/wnaRouteDetails";
import { WnaMapPoint } from "@/types/wnaMapPoint";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import { getBoundsOfMapPoints } from "@components/maps/geodata/mapsGraphDataService";
import {
    getCatalogIdIdByMapTypeVal,
    getMapTypeValByCatalogId,
} from "@components/maps/wnaCatalogMapTypeMapper";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { LoadScriptUrlOptions } from "@react-google-maps/api/src/utils/make-load-script-url";
import { getLangCode } from "@services/i18n/i18n";
import React, { useCallback, useContext, useEffect, useRef } from "react";

export type WnaMapsGoogleProps = {
    routeDetails?: WnaRouteDetails;
};

const containerStyle = {
    width: "100%",
    height: "100%",
};

const centerMapLatLng = {
    lat: 54.4472012,
    lng: 12.5591166,
};

const mapId = "c0a0a9f61df46629";
const mapsApi = {
    id: "google-map-script",
    libraries: ["geometry", "marker"],
    googleMapsApiKey: "",
    language: getLangCode(),
    mapIds: [mapId],
} as LoadScriptUrlOptions;

const routePointZindex = 9000;

const WnaMapsGoogle = (props: WnaMapsGoogleProps) => {
    const { appColors, appSettings, currentUserSettings } =
        useContext(WnaAppContext);

    if (!appSettings || !currentUserSettings) return null;

    mapsApi.googleMapsApiKey = appSettings.googleApiKey;

    const { isLoaded } = useJsApiLoader(mapsApi);
    const [currentMap, setCurrentMap] = React.useState<google.maps.Map | null>(
        null
    );
    const currentRouteDetialsRef = useRef(getDefaultWnaRouteDetails());
    const currentPolylineRef = useRef<google.maps.Polyline>();
    const currentMarkersRef =
        useRef<google.maps.marker.AdvancedMarkerElement[]>();

    const onLoad = useCallback(
        function callback(map: google.maps.Map) {
            if (!isLoaded) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: GeolocationPosition) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                                accuracy: position.coords.accuracy,
                                altitude: position.coords.altitude,
                                altitudeAccuracy:
                                    position.coords.altitudeAccuracy,
                                speed: position.coords.speed,
                                heading: position.coords.heading,
                                timestamp: position.timestamp,
                            };
                            // setCurrentLocation(pos);
                            //WnaToastProvider.showSuccess("lat: " + pos.lat + " | lon: " + pos.lng);
                            //Logger.info("pos: " + JSON.stringify(pos));
                            // map.fitBounds(bounds);
                            // map.setCenter(pos);

                            // @ts-ignore

                            // if (currentLocation.lat === null) {

                            // }

                            // if (document.getElementById("panToCurrentLocationButton") === null) {
                            //     debugger
                            //     const btn = createCurrentLocationButton(map);
                            //     const mapElem = document.getElementById("map") as HTMLElement
                            //     const mapInPaneElem = document.getElementById("mapInPane") as HTMLElement
                            //     mapElem.appendChild(btn);
                            //     mapInPaneElem.appendChild(btn);

                            // }

                            // setCurrentLocation(pos);
                        },
                        (err) => {
                            Logger.warn(
                                WnaMapsGoogle.name,
                                `FAILED TO GET LOCATION: ${err}`
                            );
                        }
                    );
                } else {
                    Logger.warn(
                        WnaMapsGoogle.name,
                        "geolocation not available"
                    );
                }
                // https://cloud.google.com/blog/products/maps-platform/smart-scrolling-comes-to-mobile-web-maps?hl=en

                map.setOptions({ gestureHandling: "auto" });
                map.setMapTypeId(
                    getMapTypeValByCatalogId(currentUserSettings.mapDefaultType)
                );

                window.google.maps.event.addListener(
                    map,
                    "mapTypeId_changed",
                    async () => {
                        try {
                            // const currentMapType = map.getMapTypeId();
                            // const newMapTypeId = getCatalogIdIdByMapTypeVal(
                            //     currentMapType ?? ""
                            // );
                            // const newUserSettings = new WnaUserSettings(
                            //     currentUserSettings ?? new WnaUserSettings()
                            // );
                            // if (
                            //     newUserSettings.mapDefaultType !== newMapTypeId
                            // ) {
                            //     newUserSettings.mapDefaultType = newMapTypeId;
                            //     await WnaUserSettingsDao.createOrUpdateAsync(
                            //         newUserSettings
                            //     );
                            //     setCurrentUserSettings(newUserSettings);
                            // }
                        } catch (error) {
                            Logger.error("maptypeid_changed", error);
                        }
                    }
                );
            }

            setCurrentMap(map);
        },
        // eslint-disable-next-line
        [props]
    );

    const loadDefault = (map: google.maps.Map) => {
        clearMap();
        currentRouteDetialsRef.current = getDefaultWnaRouteDetails();
        map.setCenter(centerMapLatLng);
    };

    const clearMap = () => {
        if (currentPolylineRef.current) {
            currentPolylineRef.current.setMap(null);
            currentPolylineRef.current = undefined;
        }

        if (currentMarkersRef.current) {
            for (const marker of currentMarkersRef.current) {
                marker.map = null;
            }
        }
        currentMarkersRef.current =
            new Array<google.maps.marker.AdvancedMarkerElement>();
    };

    const loadRouteAsync = async (
        map: google.maps.Map,
        pRouteDetails: WnaRouteDetails
    ) => {
        try {
            clearMap();
            const pinStart = new window.google.maps.marker.PinElement({
                glyphColor: "white",
                glyph: "S",
            });

            const pinEnd = new window.google.maps.marker.PinElement({
                glyphColor: "white",
                glyph: "Z",
            });

            currentRouteDetialsRef.current = pRouteDetails;

            const iWindow = new window.google.maps.InfoWindow();
            const showInfoMarker = (
                fPoint: google.maps.marker.AdvancedMarkerElement,
                mapPoint: WnaMapPoint
            ) => {
                iWindow.setHeaderContent(fPoint.title);
                iWindow.setContent(
                    `<div style="z-index:${fPoint.zIndex}; overflow: hidden !important; padding-left: 1em; padding-right: 1em; padding-bottom: 1em;">
                       ${mapPoint.distanceToStartPoint !== undefined ? `<p>${mapPoint.distanceToStartPoint}</p>` : ``}  
                        <p>${mapPoint.altitude} m</p>
                    </div>`
                );
                iWindow.open(fPoint.map, fPoint);
            };

            const routeCoordinates = [];
            for (let routePoint of pRouteDetails.graphData.routePoints) {
                const pos = {
                    lat: routePoint.lat ?? 0,
                    lng: routePoint.lng ?? 0,
                    altitude: routePoint.altitude ?? 0,
                };

                routeCoordinates.push(pos);
            }

            currentPolylineRef.current = new window.google.maps.Polyline({
                geodesic: true,
                strokeColor: appColors.red4,
                strokeOpacity: 0.75,
                strokeWeight: 4,
                map: map,
                path: routeCoordinates,
            });
            const bounds = getBoundsOfMapPoints(routeCoordinates);
            const minB = new window.google.maps.LatLng(
                bounds.minLat,
                bounds.minLng
            );
            const maxB = new window.google.maps.LatLng(
                bounds.maxLat,
                bounds.maxLng
            );
            const gBounds = new window.google.maps.LatLngBounds(minB, maxB);
            map.fitBounds(gBounds);

            if (pRouteDetails.graphData.featurePoints.length > 0) {
                let fPointCounter = 1;
                pRouteDetails.graphData.featurePoints.forEach((fp) => {
                    const fPointZindex = routePointZindex + fPointCounter;
                    const fPoint =
                        new window.google.maps.marker.AdvancedMarkerElement({
                            map: map,
                            title: fp.title ?? "",
                            zIndex: fPointZindex,
                            position: {
                                lat: fp.lat ?? 0,
                                lng: fp.lng ?? 0,
                            },
                            content: new window.google.maps.marker.PinElement({
                                glyphColor: "white",
                                glyph: "i",
                                background: appColors.blue5,
                                borderColor: appColors.staticWhite,
                            }).element,
                        });
                    fPoint.addListener("click", () =>
                        showInfoMarker(fPoint, fp)
                    );
                    currentMarkersRef.current!.push(fPoint);
                    fPointCounter += 1;
                });
            }

            const sPoint = routeCoordinates[0];
            const startMarker =
                new window.google.maps.marker.AdvancedMarkerElement({
                    map: map,
                    title: "Start (" + sPoint.altitude + " m)",
                    position: routeCoordinates[0],
                    content: pinStart.element,
                    zIndex: routePointZindex,
                });
            startMarker.addListener("click", () =>
                showInfoMarker(
                    startMarker,
                    pRouteDetails.graphData.routePoints[0]
                )
            );
            currentMarkersRef.current!.push(startMarker);

            const endMarkerIndex = routeCoordinates.length - 1;
            const endPoint = routeCoordinates[endMarkerIndex];
            const endMarker =
                new window.google.maps.marker.AdvancedMarkerElement({
                    map: map,
                    title: "Ziel (" + endPoint.altitude + " m)",
                    position: endPoint,
                    content: pinEnd.element,
                    zIndex: routePointZindex + endMarkerIndex,
                });
            endMarker.addListener("click", () =>
                showInfoMarker(endMarker, endPoint)
            );
            currentMarkersRef.current!.push(endMarker);
        } catch (error) {
            Logger.error(loadRouteAsync.name, error);
        }
    };

    // @ts-ignore
    const onUnmount = React.useCallback(function callback() {
        setCurrentMap(null);
    }, []);

    useEffect(() => {
        if (currentMap && isLoaded) {
            const currentMapType = getCatalogIdIdByMapTypeVal(
                currentMap.getMapTypeId() ?? ""
            );
            if (currentMapType !== currentUserSettings.mapDefaultType)
                currentMap.setMapTypeId(
                    getMapTypeValByCatalogId(currentUserSettings.mapDefaultType)
                );

            if (
                props.routeDetails?.graphData &&
                props.routeDetails?.route &&
                props.routeDetails.route.identifier !== "" &&
                props.routeDetails.route.identifier !==
                    currentRouteDetialsRef.current.route.identifier
            )
                loadRouteAsync(currentMap, props.routeDetails);
            else if (
                props.routeDetails?.graphData &&
                props.routeDetails?.route &&
                props.routeDetails.route.identifier !==
                    currentRouteDetialsRef.current.route.identifier
            )
                loadDefault(currentMap);
        }
        // eslint-disable-next-line
    }, [props, currentUserSettings, isLoaded, currentMap]);

    return isLoaded ? (
        <>
            <GoogleMap
                id={mapId}
                options={{
                    mapId: mapId,
                    scrollwheel: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    panControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    disableDefaultUI:
                        currentUserSettings?.mapDisableDefaultUI ?? false,
                }}
                mapContainerStyle={containerStyle}
                center={centerMapLatLng}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}>
                <></>
            </GoogleMap>
        </>
    ) : null;
};
export default WnaMapsGoogle;
