import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import WnaAppointment from "@/types/entities/wnaAppointment";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function deleteAppointmentAsync(
    identifier: string | undefined
) {
    if (!isDateTimeIdentifier(identifier)) return undefined;

    try {
        const route = `admin/appointments/${identifier}/delete`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        const request = initJsonRequest("DELETE");
        await prepareRequestAsync();
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaAppointment;
        } else {
            Logger.error(
                deleteAppointmentAsync.name,
                "could not delete Appointment"
            );
            return undefined;
        }
    } catch (e) {
        Logger.error(deleteAppointmentAsync.name, e);
        return undefined;
    }
}
