export default class WnaUserSettings {
    appThemeId: number = 0;
    mapDisableDefaultUI: boolean = false;
    mapDefaultType: number = 0;
    diaryDefaultTab: number = 0;
    diaryOrderBy: 0 | 1 = 1;
    mapGraphQuality: number = 2;
    mapGraphTolerance: number = 0;
    mapGraphAutoScale: number = 1;
    diaryRestoreDiaryTab: boolean = false;
    appIsLoggingEnabled: boolean = false;
    appIsOfflineCacheEnabled: boolean = false;
    tabBarPosition: 0 | 1 = 1;
}
