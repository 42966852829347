import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import WnaChapter from "@/types/entities/wnaChapter";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function updateChapterAsync(
    userId: string | undefined,
    chapter: WnaChapter | undefined
) {
    if (
        !isUserId(userId) ||
        !chapter ||
        isDateTimeIdentifier(chapter.identifier)
    )
        return undefined;

    try {
        const route = `users/${userId}/chapters/update`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        const request = initJsonRequest("PUT");
        request.body = JSON.stringify(chapter);
        await prepareRequestAsync();
        const result = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        if (result instanceof Response) {
            return (await result.json()) as WnaChapter;
        } else {
            Logger.error(updateChapterAsync.name, "could not update Chapter");
            return undefined;
        }
    } catch (e) {
        Logger.error(updateChapterAsync.name, e);
        return undefined;
    }
}
