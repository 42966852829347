import { getUnixMinDate } from "@/utils/dateTimeHelper";

export default interface WnaRoute {
    identifier: string;
    designator: string;
    comment: string;
    dateCreate: Date;
    dateUpdate: Date;
    dateRead: Date;
    dateDelete: Date;

    imageUrl: string;
    geoJson: string;
    geoJsonUrl: string;

    staticSatelliteUrl: string;
    staticSatellite512Url: string;
    staticSatellite256Url: string;

    staticHybridUrl: string;
    staticHybrid512Url: string;
    staticHybrid256Url: string;

    staticRoadMapUrl: string;
    staticRoadMap512Url: string;
    staticRoadMap256Url: string;

    staticTerrainUrl: string;
    staticTerrain512Url: string;
    staticTerrain256Url: string;

    geoJsonHash: string;
    distance: number;
}

export function getDefaultWnaRoute() {
    return {
        identifier: "",
        designator: "",
        comment: "",
        dateCreate: getUnixMinDate(),
        dateUpdate: getUnixMinDate(),
        dateRead: getUnixMinDate(),
        dateDelete: getUnixMinDate(),
        imageUrl: "",
        geoJson: "",
        geoJsonUrl: "",
        staticSatelliteUrl: "",
        staticSatellite512Url: "",
        staticSatellite256Url: "",
        staticHybridUrl: "",
        staticHybrid512Url: "",
        staticHybrid256Url: "",
        staticRoadMapUrl: "",
        staticRoadMap512Url: "",
        staticRoadMap256Url: "",
        staticTerrainUrl: "",
        staticTerrain512Url: "",
        staticTerrain256Url: "",
        geoJsonHash: "",
        distance: 0,
    } as WnaRoute;
}
