import WnaCatalogItem from "@/types/wnaCatalogItem";
import WnaSettingsItemFrame from "@components/misc/WnaSettingsItemFrame";
import WnaRadioButton from "@components/radio/WnaRadioButton";
import WnaRadioButtonVertical from "@components/radio/WnaRadioButtonVertical";
import AppStyle from "@constants/theme/appStyle";
import Colors from "@constants/theme/colors";
import { Component } from "react";

export type WnaSettingsRadioButtonProps = {
    appColors: Colors;
    appStyle: AppStyle;
    data: WnaCatalogItem[];
    selectedId: number | null | 0 | 1;
    onSelect: (item: WnaCatalogItem) => void;
    isEditMode: boolean;
    text: string;
    icon: string;
    vertical?: boolean;
    horizontal?: boolean;
};

class WnaSettingsRadioButton extends Component<WnaSettingsRadioButtonProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaSettingsRadioButtonProps>
    ): boolean {
        return (
            nextProps.appColors.isDark !== this.props.appColors.isDark ||
            nextProps.text !== this.props.text ||
            nextProps.vertical !== this.props.vertical ||
            nextProps.horizontal !== this.props.horizontal ||
            nextProps.selectedId !== this.props.selectedId ||
            nextProps.isEditMode !== this.props.isEditMode
        );
    }

    render() {
        return (
            <WnaSettingsItemFrame
                appColors={this.props.appColors}
                appStyle={this.props.appStyle}
                text={this.props.text}
                icon={this.props.icon}>
                {this.props.vertical || !(this.props.horizontal ?? true) ? (
                    <WnaRadioButtonVertical
                        data={this.props.data}
                        selectedId={this.props.selectedId}
                        isEditMode={true}
                        onSelect={this.props.onSelect}
                    />
                ) : (
                    <WnaRadioButton
                        data={this.props.data}
                        selectedId={this.props.selectedId}
                        isEditMode={true}
                        onSelect={this.props.onSelect}
                    />
                )}
            </WnaSettingsItemFrame>
        );
    }
}

export default WnaSettingsRadioButton;
