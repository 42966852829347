import { setUserSettingsAsync } from "@/api/userSettings/userSettingsStorageProvider";
import WnaUserSettings from "@/api/userSettings/wnaUserSettings";
import { i18nKeys } from "@/services/i18n/i18nKeys";
import WnaCatalogItem from "@/types/wnaCatalogItem";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaSettingsRadioButton from "@components/buttons/WnaSettingsRadioButton";
import WnaNavigationList, {
    WnaMenuItem,
} from "@components/navigation/WnaNavigationList";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import WnaBaseScreen from "@components/screens/WnaBaseScreen";
import { WnaCatalogGraphQuality } from "@constants/catalogs/wnaCatalogGraphQuality";
import { WnaCatalogGraphTolerance } from "@constants/catalogs/wnaCatalogGraphTolerance";
import { WnaCatalogMapType } from "@constants/catalogs/wnaCatalogMapType";
import { WnaCatalogYesNo } from "@constants/catalogs/wnaCatalogYesNo";
import { Redirect } from "expo-router";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function SettingsMapScreen() {
    const {
        isAppInitialized,
        currentUserSettings,
        setCurrentUserSettings,
        appStyle,
        appColors,
        isLandscape,
    } = useContext(WnaAppContext);

    const currentUserSettingsRef = useRef<WnaUserSettings>(
        currentUserSettings ?? new WnaUserSettings()
    );

    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [];
    // map controls
    items.push(
        {
            route: "",
            text: t(i18nKeys.settingsMapDefaultMap),
            icon: "map",
            rightIcon: "",
            type: "mapDefaultType",
            data: undefined,
        },
        {
            route: "",
            text: t(i18nKeys.settingsMapControls),
            icon: "map",
            rightIcon: "",
            type: "mapDisableDefaultUI",
            data: undefined,
        },
        {
            route: "",
            text: t(i18nKeys.settingsMapScaleElevationChart),
            icon: "arrows-alt-v",
            rightIcon: "",
            type: "mapGraphAutoScale",
            data: undefined,
        }
        //, {
        //     route: "",
        //     text: "Höhenprofil-Qualität",
        //     icon: "arrows-alt-v",
        //     rightIcon: "",
        //     type: "mapGraphQuality",
        //     data: undefined,
        // }
        // , {
        //     route: "",
        //     text: "Höhenprofil-Ungenauigkeit",
        //     icon: "arrows-alt-v",
        //     rightIcon: "",
        //     type: "mapGraphTolerance",
        //     data: undefined,
        // }
    );

    const onSelectMapDisableUi = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.mapDisableDefaultUI =
            currentItem.id === 0;
        await setUserSettingsAsync(currentUserSettingsRef.current!);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const onSelectMapDefaultType = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.mapDefaultType = currentItem.id;
        await setUserSettingsAsync(currentUserSettingsRef.current!);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const onSelectMapGraphQuality = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.mapGraphQuality = currentItem.id;
        await setUserSettingsAsync(currentUserSettingsRef.current!);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const onSelectMapGraphTolerance = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.mapGraphTolerance = currentItem.id;
        await setUserSettingsAsync(currentUserSettingsRef.current!);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const onSelectMapGraphAutoScale = async (currentItem: WnaCatalogItem) => {
        currentUserSettingsRef.current.mapGraphAutoScale = currentItem.id;
        await setUserSettingsAsync(currentUserSettingsRef.current!);
        setCurrentUserSettings(currentUserSettingsRef.current);
    };

    const renderItem = (currentItem: WnaMenuItem) => {
        switch (currentItem.type) {
            case "mapDisableDefaultUI":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettings?.mapDisableDefaultUI === true
                                ? 0
                                : 1
                        }
                        isEditMode={true}
                        onSelect={onSelectMapDisableUi}
                    />
                );
            case "mapDefaultType":
                return (
                    <WnaSettingsRadioButton
                        horizontal={isLandscape}
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogMapType}
                        selectedId={currentUserSettings?.mapDefaultType ?? 0}
                        isEditMode={true}
                        onSelect={onSelectMapDefaultType}
                    />
                );
            case "mapGraphQuality":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogGraphQuality}
                        selectedId={currentUserSettings?.mapGraphQuality ?? 1}
                        isEditMode={true}
                        onSelect={onSelectMapGraphQuality}
                    />
                );
            case "mapGraphTolerance":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogGraphTolerance}
                        selectedId={currentUserSettings?.mapGraphTolerance ?? 0}
                        isEditMode={true}
                        onSelect={onSelectMapGraphTolerance}
                    />
                );
            case "mapGraphAutoScale":
                return (
                    <WnaSettingsRadioButton
                        appStyle={appStyle}
                        appColors={appColors}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={currentUserSettings?.mapGraphAutoScale ?? 0}
                        isEditMode={true}
                        onSelect={onSelectMapGraphAutoScale}
                    />
                );
            default:
                return <></>;
        }
    };

    if (!isAppInitialized)
        return <Redirect href={WnaNavigationRouteProvider.menu} />;

    if (!currentUserSettings) return null;

    return (
        <WnaBaseScreen title={t(i18nKeys.screenTitleSettingsMap)}>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreen>
    );
}
