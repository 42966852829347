import {
    initJsonRequest,
    prepareRequestAsync,
} from "@/api/apiRequestInitializer";
import handleResponseAsync from "@/api/apiResponseHandler";
import ApiSettings from "@/api/apiSettings";
import { isDateTimeIdentifier } from "@/api/validators/dateTimeIdentifierValidator";
import { isUserId } from "@/api/validators/userIdValidator";
import Logger from "@/utils/logger";
import { apiTimeouts } from "@constants/apiTimeouts";

export default async function markAsReadAsync(
    userId: string | undefined,
    identifier: string | undefined
) {
    if (!isUserId(userId) || !isDateTimeIdentifier(identifier)) return false;

    try {
        const route = `users/${userId}/notifications/${identifier}/markAsRead/true`;
        const url = `${ApiSettings.apiBaseUrl}/${route}`;
        await prepareRequestAsync();
        const request = initJsonRequest("GET");
        const response = await handleResponseAsync(
            url,
            request,
            apiTimeouts.default
        );
        return response instanceof Response;
    } catch (e) {
        Logger.error(markAsReadAsync.name, e);
        return false;
    }
}
