import getRoutesAsync from "@/api/route/getRoutes";
import WnaRoute from "@/types/entities/wnaRoute";
import WnaRouteDetails, {
    getDefaultWnaRouteDetails,
} from "@/types/entities/wnaRouteDetails";
import { convertHexToRgba } from "@/utils/colorConverter";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaPressable from "@components/buttons/WnaPressable";
import { WnaDataSourceItem } from "@components/misc/WnaPicker";
import { animationSpeed } from "@constants/animationSpeed";
import { FontAwesome5 } from "@expo/vector-icons";
import { i18nKeys } from "@services/i18n/i18nKeys";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from "react-native-reanimated";
import WnaMapsGoogle from "./WnaMapsGoogle";
import WnaMapsInfos from "./WnaMapsInfos";

export type WnaMapsProps = {
    routeDetails?: WnaRouteDetails;
    onRouteSelected: (routeDetails: WnaRouteDetails) => void;
    isEditMode: boolean;
};

const mapsInfoWidth = 512;
const mapsInfoBtnSize = 64;
const WnaMaps: React.FC<WnaMapsProps> = (props) => {
    const {
        appColors,
        appStyle,
        appSettings,
        currentWindowWidth,
        currentWindowHeight,
        isLandscape,
    } = useContext(WnaAppContext);

    if (appSettings === null) return null;

    const [currentRouteDetails, setCurrentRouteDetails] =
        useState<WnaRouteDetails>(getDefaultWnaRouteDetails());

    const currentRouteDetailsRef = useRef<WnaRouteDetails | undefined>();
    const [isEditMode, setIsEditMode] = useState(false);
    const isLandscapeInternal =
        isLandscape && currentWindowWidth > mapsInfoWidth * 1.5;

    const routesDsRawRef = useRef<WnaRoute[]>([]);
    const [routesDs, setRoutesDs] = useState<WnaDataSourceItem[]>([]);
    const { t } = useTranslation(["common"]);

    const routeInfoOverlayHeight = useSharedValue(0);
    const routeInfoAnimationConfig = {
        duration: animationSpeed,
        easing: Easing.bezier(0.5, 0.01, 0, 1),
    };

    const routeInfoStyle = useAnimatedStyle(() => {
        return {
            height: withTiming(
                routeInfoOverlayHeight.value,
                routeInfoAnimationConfig
            ),
        };
    });

    const loadRoutesDataSourceAsync = async () => {
        try {
            if (routesDsRawRef.current.length > 0) return;

            routesDsRawRef.current = await getRoutesAsync();
            setRoutesDs(
                routesDsRawRef.current.map((item) => {
                    return {
                        value: item.identifier,
                        label: item.designator,
                    } as WnaDataSourceItem;
                })
            );
        } catch (error) {
            Logger.error(loadRoutesDataSourceAsync.name, error);
        }
    };
    useEffect(() => {
        setIsEditMode(props.isEditMode);
        if (props.routeDetails) {
            if (
                props.routeDetails.route.identifier !== "" &&
                props.routeDetails.route.identifier !==
                    currentRouteDetails.route.identifier
            ) {
                currentRouteDetailsRef.current = props.routeDetails;
                setCurrentRouteDetails(props.routeDetails);
            } else if (
                props.routeDetails.route.identifier !==
                currentRouteDetails.route.identifier
            )
                loadDefault();
        }
        if (props.isEditMode) loadRoutesDataSourceAsync();
        // eslint-disable-next-line
    }, [props, isLandscape]);

    const loadDefault = () => {
        currentRouteDetailsRef.current = getDefaultWnaRouteDetails();
        setCurrentRouteDetails(getDefaultWnaRouteDetails());
    };

    return (
        <>
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View
                    style={{
                        flex: 1,
                        backgroundColor: appColors.white,
                    }}>
                    {!isLandscapeInternal ? (
                        /*route info button portrait*/
                        <View
                            style={{
                                height: mapsInfoBtnSize,
                                backgroundColor: appColors.coolgray1,
                            }}>
                            <WnaPressable
                                style={{
                                    alignSelf: "stretch",
                                    height: mapsInfoBtnSize,
                                }}
                                rippleWhite
                                onPress={() => {
                                    if (routeInfoOverlayHeight.value > 0)
                                        routeInfoOverlayHeight.value = 0;
                                    else
                                        routeInfoOverlayHeight.value =
                                            currentWindowHeight - 64;
                                }}
                                t={t}
                                checkInternetConnection={false}>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            paddingHorizontal: 8,
                                        }}>
                                        <Text
                                            numberOfLines={2}
                                            textBreakStrategy="highQuality"
                                            lineBreakMode="tail"
                                            style={appStyle.textSmall}>
                                            {!currentRouteDetailsRef.current ||
                                            currentRouteDetailsRef.current
                                                .graphData.routeName === ""
                                                ? t(i18nKeys.infoNoRoute)
                                                : currentRouteDetailsRef.current
                                                      .graphData.routeName}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            alignSelf: "flex-end",
                                            justifyContent: "center",
                                        }}>
                                        <View
                                            style={{
                                                height: mapsInfoBtnSize,
                                                width: mapsInfoBtnSize,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                            {isEditMode ? (
                                                <FontAwesome5
                                                    name={"pen"}
                                                    size={24}
                                                    color={appColors.black}
                                                />
                                            ) : (
                                                <FontAwesome5
                                                    name={"info-circle"}
                                                    size={24}
                                                    color={appColors.black}
                                                />
                                            )}
                                        </View>
                                    </View>
                                </View>
                            </WnaPressable>
                        </View>
                    ) : null}
                    {/*google maps component*/}
                    <WnaMapsGoogle
                        routeDetails={currentRouteDetailsRef.current}
                    />
                </View>
                {isLandscapeInternal ? (
                    /*route info landscape*/
                    <View
                        style={{
                            width: mapsInfoWidth,
                        }}>
                        <ScrollView overScrollMode="auto">
                            <WnaMapsInfos
                                routeDetails={currentRouteDetailsRef.current}
                                paneWidth={mapsInfoWidth}
                                isEditMode={isEditMode}
                                routes={routesDs}
                                onSelectGeoDataFile={props.onRouteSelected}
                                onSelectRouteFromDropDown={
                                    props.onRouteSelected
                                }
                            />
                        </ScrollView>
                    </View>
                ) : null}
            </View>
            {!isLandscapeInternal ? (
                /*route info overlay for portrait */
                <Animated.View
                    style={[
                        {
                            backdropFilter: "blur(16px)",
                            backgroundColor:
                                Platform.OS !== "web"
                                    ? convertHexToRgba(
                                          appColors.staticCoolgray8,
                                          0.7
                                      )
                                    : "",
                            position: "absolute",
                            top: 64,
                            left: 0,
                            right: 0,
                            zIndex: 999999,
                            height: 0,
                            overflow: "hidden",
                        },
                        routeInfoStyle,
                    ]}>
                    <ScrollView overScrollMode="auto">
                        <WnaMapsInfos
                            routeDetails={currentRouteDetailsRef.current}
                            paneWidth={currentWindowWidth}
                            isEditMode={isEditMode}
                            routes={routesDs}
                            onSelectGeoDataFile={props.onRouteSelected}
                            onSelectRouteFromDropDown={props.onRouteSelected}
                        />
                    </ScrollView>
                </Animated.View>
            ) : null}
        </>
    );
};
export default WnaMaps;
