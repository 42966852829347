import ApiSettings from "@/api/apiSettings";

function replaceFirstChar(str: string) {
    return str.startsWith("_") ? str.substring(1) : str;
}

export default function getCleanKeyName(key: string) {
    return !key || key === ""
        ? "KEY_IS_EMPTY"
        : replaceFirstChar(
              key
                  .toLowerCase()
                  .replace(ApiSettings.apiBaseUrl, "")
                  .replace(/[^A-Z0-9]+/gi, "_")
                  .replace("_jpg", ".jpg")
                  .replace("_jpeg", ".jpeg")
                  .replace("_png", ".png")
                  .replace("_txt", ".txt")
                  .replace("_json", ".json")
                  .replace("_geojson", ".geojson")
                  .replace("_xml", ".xml")
                  .replace("_html", ".html")
          );
}
