import { i18nKeys } from "@/services/i18n/i18nKeys";
import WnaFileInfo, { getDefaultFileInfo } from "@/types/entities/wnaFileInfo";
import { convertHexToRgba } from "@/utils/colorConverter";
import Logger from "@/utils/logger";
import { WnaAppContext } from "@app/WnaAppContext";
import WnaButtonIcon from "@components/buttons/WnaButtonIcon";
import WnaButtonText from "@components/buttons/WnaButtonText";
import WnaPressable from "@components/buttons/WnaPressable";
import WnaCardActivityIndicator from "@components/cards/WnaCardActivityIndicator";
import pickImagesAsync from "@components/images/imageFilePicker";
import WnaNavigationRouteProvider from "@components/navigation/WnaNavigationRouteProvider";
import { FontAwesome5 } from "@expo/vector-icons";
import getCleanKeyName from "@services/WnaDataStorageProvider/wnaDataStorageKeyProvider";
import { useRouter } from "expo-router";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Platform, ScrollView, StyleSheet, View } from "react-native";
import Toast from "react-native-toast-message";
import WnaImage from "./WnaImage";

export type WnaCarouselItem = {
    index: number;
    key: string;
    fileInfo: WnaFileInfo;
};

export interface WnaCarouselProps {
    fileInfos: WnaFileInfo[];
    isEditMode: boolean;
    onCollectionChanged: (changedFileInfos: WnaFileInfo[]) => void;
    onSetIsBusy: (isBusy: boolean) => void;
    userId: string;
    chapterIdentifier: string;
    diaryEntryIdentifier: string;
    currentTab: number;
    currentOrder: number;
}

const _itemSize = 256;

const WnaCarousel: FC<WnaCarouselProps> = (props) => {
    const { t } = useTranslation(["common"]);
    const router = useRouter();
    const { appColors, appStyle, appSettings } = useContext(WnaAppContext);

    const [isBusy, setIsBusy] = useState(false);
    const cancelActionRef = useRef(false);
    const [cancelAction, setCancelAction] = useState(false);
    const [isBusyText, setIsBusyText] = useState("");
    const style = getStyle(_itemSize);
    const [carouselItems, setCarouselItems] = useState<WnaCarouselItem[]>([]);
    const [extraData, setExtraData] = useState(new Date());
    const scrollViewRef = useRef<ScrollView>(null);
    const flRef = useRef<FlatList<WnaCarouselItem>>(null);
    const isLoadedRef = useRef(false);

    const onCancelAction = (value: boolean) => {
        cancelActionRef.current = value;
        setCancelAction(value);
    };

    const getFileInfosFromCarouselItems = (
        carouselItems: WnaCarouselItem[]
    ) => {
        const fileInfos = new Array<WnaFileInfo>();
        carouselItems.forEach((x) => {
            if (x.fileInfo && x.fileInfo.fileName !== "")
                fileInfos.push(x.fileInfo);
        });
        return fileInfos;
    };

    const setFirstNotDeletedItemToFavourite = () => {
        const newCarouselItems = [...carouselItems];
        for (const x of newCarouselItems) {
            x.fileInfo.isFavourite = false;
        }
        for (const x of newCarouselItems) {
            if (!x.fileInfo.isDeleted && x.fileInfo.fileName !== "") {
                x.fileInfo.isFavourite = true;
                break;
            }
        }
        setCarouselItems(newCarouselItems);
    };

    const loadImages = () => {
        setIsBusy(true);
        const wnaCarouselItems = new Array<WnaCarouselItem>();
        let index = 0;
        for (const fileInfo of props.fileInfos) {
            const carouselIem = {
                index: index,
                key: `${index}-${getCleanKeyName(fileInfo.fileName)}`,
                fileInfo: fileInfo,
            } as WnaCarouselItem;

            index++;
            wnaCarouselItems.push(carouselIem);
            isLoadedRef.current = true;
        }

        const dataWithPlaceholders = [...wnaCarouselItems];
        if (
            props.isEditMode &&
            wnaCarouselItems.length < appSettings!.maxUploadFiles
        ) {
            // add
            dataWithPlaceholders.push({
                index: index + 1,
                key: "add",
                fileInfo: getDefaultFileInfo(),
            } as WnaCarouselItem);
        }

        setCarouselItems(dataWithPlaceholders);
        setIsBusy(false);
    };

    const addImagesAsync = () => {
        try {
            setIsBusyText(t(i18nKeys.infoPleaseWait));
            setIsBusy(true);
            props.onSetIsBusy(true);

            pickImagesAsync(true, (e) =>
                Logger.error(addImagesAsync.name, e)
            ).then(async (selectedFileInfos) => {
                const wnaCarouselItems = [...carouselItems];
                let maxAllowedItems =
                    appSettings!.maxUploadFiles - wnaCarouselItems.length;
                if (maxAllowedItems < 1) maxAllowedItems = 0;

                if (selectedFileInfos.length < maxAllowedItems)
                    maxAllowedItems = selectedFileInfos.length;

                for (let i = 0; i < maxAllowedItems; i++) {
                    const progressVal = (
                        ((i + 1) / selectedFileInfos.length) *
                        100
                    ).toFixed(0);
                    setIsBusyText(progressVal + " %");
                    Logger.info(progressVal + " %");
                    const fileItem = selectedFileInfos[i];

                    if (fileItem === null) continue;

                    // check if file exists
                    const existingItems = carouselItems.find(
                        (item) => item.fileInfo.fileName === fileItem!.fileName
                    );
                    if (existingItems !== undefined) {
                        continue;
                    }
                    const addedItem = { fileInfo: fileItem } as WnaCarouselItem;

                    wnaCarouselItems.splice(
                        wnaCarouselItems.length - 1,
                        0,
                        addedItem
                    );
                    if (wnaCarouselItems.length > appSettings!.maxUploadFiles) {
                        wnaCarouselItems.pop();
                        break;
                    }
                }

                setIsBusy(false);
                setIsBusyText("");
                props.onSetIsBusy(false);
                setCarouselItems(wnaCarouselItems);
                setExtraData(new Date());
                props.onCollectionChanged(
                    getFileInfosFromCarouselItems(wnaCarouselItems)
                );
                setTimeout(() => {
                    if (Platform.OS === "web")
                        scrollViewRef.current?.scrollToEnd();
                    else flRef.current?.scrollToEnd();
                }, 300);
            });
        } catch (error) {
            Toast.show({
                text1: t(i18nKeys.errorUnknown),
                type: "error",
            });
            Logger.error(addImagesAsync.name, error);
            setIsBusy(false);
            props.onSetIsBusy(false);
            setIsBusyText("");
        }
    };

    const setFavourite = (item: WnaFileInfo) => {
        if (!item) return;
        const wnaCarouselItems = [...carouselItems];
        wnaCarouselItems.forEach((x) => {
            x.fileInfo.isFavourite = false;
        });

        item.isDirty = true;
        item.isFavourite = true;
        setCarouselItems(wnaCarouselItems);

        props.onCollectionChanged(
            getFileInfosFromCarouselItems(wnaCarouselItems)
        );
    };

    const deleteImage = (item: WnaFileInfo, undo?: boolean) => {
        if (!item) return;
        item.isDeleted = undo !== true;
        item.isDirty = true;
        if (item.isFavourite && item.isDeleted)
            setFirstNotDeletedItemToFavourite();
        setExtraData(new Date());
        props.onCollectionChanged(getFileInfosFromCarouselItems(carouselItems));
    };

    useEffect(() => {
        if (!isLoadedRef.current) loadImages();

        // eslint-disable-next-line
    }, [props]);

    const viewImage = (item: WnaCarouselItem) =>
        router.push(
            WnaNavigationRouteProvider.diaryEntryImages(
                props.chapterIdentifier,
                props.diaryEntryIdentifier,
                props.currentTab,
                item.index
            )
        );

    const renderItem = (carouselItem: WnaCarouselItem) => {
        return (
            <View key={carouselItem.key} style={style.itemContent}>
                {props.isEditMode && carouselItem.key === "add" ? (
                    // Add
                    <WnaPressable
                        disableHover
                        style={style.itemImage}
                        toolTipPosition="top"
                        onPress={() => {
                            addImagesAsync();
                        }}
                        t={t}
                        checkInternetConnection={false}>
                        <View
                            style={[
                                {
                                    minHeight: _itemSize,
                                    width: _itemSize,
                                    backgroundColor: convertHexToRgba(
                                        appColors.coolgray4,
                                        0.5
                                    ),
                                },
                                appStyle.containerCenterCenter,
                            ]}>
                            <FontAwesome5
                                size={42}
                                name={"camera"}
                                color={appColors.coolgray6}
                            />
                        </View>
                    </WnaPressable>
                ) : (
                    // ViewImage
                    <WnaPressable
                        disableHover
                        style={style.itemImage}
                        toolTipPosition="top"
                        onPress={() => {
                            if (carouselItem.fileInfo.isDeleted) {
                                // undo delete
                                deleteImage(carouselItem.fileInfo, true);
                            } else {
                                if (!props.isEditMode) viewImage(carouselItem);
                            }
                        }}
                        t={t}
                        checkInternetConnection={false}>
                        <View>
                            <WnaImage
                                appColors={appColors}
                                imageUrl={carouselItem.fileInfo.urlTh512}
                                style={[
                                    style.itemImage,
                                    {
                                        opacity: carouselItem.fileInfo.isDeleted
                                            ? 0.5
                                            : 1,
                                    },
                                ]}
                            />
                            {carouselItem.fileInfo.isDeleted ? (
                                <View
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: convertHexToRgba(
                                            appColors.staticBlack,
                                            0.7
                                        ),
                                    }}>
                                    <FontAwesome5
                                        size={48}
                                        name={"trash-alt"}
                                        color={appColors.staticWhite}
                                    />
                                </View>
                            ) : null}
                            <View
                                style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    flex: 1,
                                    flexDirection: "row",
                                    gap: 16,
                                    alignItems: "center",
                                }}>
                                {carouselItem.fileInfo.isLocal &&
                                !carouselItem.fileInfo.isDeleted ? (
                                    <FontAwesome5
                                        size={24}
                                        name={"upload"}
                                        color={appColors.staticWhite}
                                    />
                                ) : null}
                                {props.isEditMode &&
                                !carouselItem.fileInfo.isDeleted ? (
                                    <>
                                        <WnaButtonIcon
                                            appStyle={appStyle}
                                            appColors={appColors}
                                            iconName="trash-alt"
                                            onPress={() =>
                                                deleteImage(
                                                    carouselItem.fileInfo
                                                )
                                            }
                                            t={t}
                                            checkInternetConnection={false}
                                        />
                                        <WnaButtonIcon
                                            appStyle={appStyle}
                                            appColors={appColors}
                                            iconName={
                                                carouselItem.fileInfo
                                                    .isFavourite
                                                    ? "star"
                                                    : "circle"
                                            }
                                            onPress={() =>
                                                setFavourite(
                                                    carouselItem.fileInfo
                                                )
                                            }
                                            color={
                                                carouselItem.fileInfo
                                                    .isFavourite
                                                    ? appColors.yellow7
                                                    : appColors.staticWhite
                                            }
                                            t={t}
                                            checkInternetConnection={false}
                                        />
                                    </>
                                ) : null}
                            </View>
                        </View>
                    </WnaPressable>
                )}
            </View>
        );
    };

    return (
        <>
            {Platform.OS === "web" ? (
                <ScrollView
                    ref={scrollViewRef}
                    contentContainerStyle={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                    pointerEvents={isBusy ? "none" : "auto"}>
                    {carouselItems.map((carouselItem) =>
                        renderItem(carouselItem)
                    )}
                </ScrollView>
            ) : (
                <FlatList
                    ref={flRef}
                    data={carouselItems}
                    keyExtractor={(item) => item.key}
                    renderItem={({ item }) => renderItem(item)}
                    pointerEvents={isBusy ? "none" : "auto"}
                    extraData={extraData}
                    // estimatedItemSize={_itemSize}
                />
            )}
            {isBusy ? (
                <View
                    style={[
                        {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            flex: 1,
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: convertHexToRgba("#000000", 0.7),
                        },
                    ]}
                    pointerEvents="none">
                    <WnaCardActivityIndicator
                        appStyle={appStyle}
                        appColors={appColors}
                        isBusyText={isBusyText}
                    />
                    {/*cancel btn*/}
                    {cancelAction ? null : (
                        <WnaButtonText
                            style={{
                                margin: 16,
                            }}
                            appColors={appColors}
                            appStyle={appStyle}
                            text={t(i18nKeys.actionCancel)}
                            onPress={() => onCancelAction(true)}
                            checkInternetConnection={false}
                            t={t}
                        />
                    )}
                </View>
            ) : null}
        </>
    );
};

export default WnaCarousel;

const getStyle = (itemSize: number) =>
    StyleSheet.create({
        itemContent: {
            alignItems: "center",
            margin: 16,
            borderRadius: 16,
        },
        itemImage: {
            height: itemSize,
            width: itemSize,
            borderRadius: 16,
        },
    });
